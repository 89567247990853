import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { Route } from './shell/shell.service';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { StaticResolver } from './resolvers/static.resolver';
import { InviteComponent } from './pages/auth/invite/invite.component';
import { LandingGuard } from './guards/landing/landing.guard';
import { DemoComponent } from './pages/demo/demo.component';
import { DemoModule } from './pages/demo/demo.module';
const routes: Routes = [
  Route.withShell([
    //Other Pages
    { path: '', redirectTo: '/default', pathMatch: 'full' },
    {
      path: 'demo/:screen',
      component: DemoComponent,
      data: {
        title: 'Iconic Air',
      },
    },
    {
      path: 'knowledge-hub',
      loadChildren: () =>
        import('./pages/knowledge-hub/knowledge-hub.module').then(
          (m) => m.KnowledgeHubModule,
        ),
    },
    {
      path: 'admin',
      loadChildren: () =>
        import('./pages/admin/admin.module').then((m) => m.AdminModule),
    },
    {
      path: 'internal-admin',
      loadChildren: () =>
        import('./pages/internal-admin/internal-admin.module').then(
          (m) => m.InternalAdminModule,
        ),
    },
    {
      path: 'admin-tools',
      loadChildren: () =>
        import('./pages/admin-tools/admin-tools.module').then(
          (m) => m.AdminToolsModule,
        ),
    },
    {
      path: 'analytics',
      loadChildren: () =>
        import('./pages/analytics/analytics.module').then(
          (m) => m.AnalyticsModule,
        ),
    },
    {
      path: 'default',
      loadChildren: () =>
        import('./pages/default/default.module').then((m) => m.DefaultModule),
    },
    {
      path: 'profile',
      loadChildren: () =>
        import('./pages/profile/profile.module').then(
          (m) => m.ProfileSettingsModule,
        ),
    },
    {
      path: 'tutorials',
      loadChildren: () =>
        import('./pages/tutorials/tutorials.module').then(
          (m) => m.TutorialsModule,
        ),
    },
    {
      path: 'onboarding-checklist',
      loadChildren: () =>
        import('./pages/onboarding-checklist/onboarding-checklist.module').then(
          (m) => m.OnboardingChecklistModule,
        ),
    },
    {
      path: 'metrics',
      loadChildren: () =>
        import('./pages/esg-metrics/esg-metrics.module').then(
          (m) => m.EsgMetricsModule,
        ),
    },
    {
      path: 'integrations',
      loadChildren: () =>
        import('./pages/integrations/integrations.module').then(
          (m) => m.IntegrationsModule,
        ),
    },

    // ----- Analyze -----
    // Overview
    { path: 'dashboard', redirectTo: 'overview' },
    { path: 'ldar/dashboard', redirectTo: 'overview/leaks' },
    {
      path: 'overview',
      loadChildren: () =>
        import('./pages/overview/overview.module').then(
          (m) => m.OverviewModule,
        ),
    },
    // Reports
    { path: 'emissions/reports', redirectTo: 'reports/emissions' },
    { path: 'ldar/reports', redirectTo: 'reports/leaks' },
    {
      path: 'reports',
      loadChildren: () =>
        import('./pages/reports/reports.module').then((m) => m.ReportsModule),
    },
    // Benchmarks
    {
      path: 'benchmark',
      resolve: {
        static: StaticResolver,
      },
      loadChildren: () =>
        import('./pages/analytics/analytics.module').then(
          (m) => m.AnalyticsModule,
        ),
    },
    // ----- Plan -----
    // Projects
    {
      path: 'forecast',
      resolve: {
        static: StaticResolver,
      },
      loadChildren: () =>
        import('./pages/forecast/forecast.module').then(
          (m) => m.ForecastModule,
        ),
    },
    // Scenarios (Coming Later)

    // ----- Manage -----
    // Emissions
    {
      path: 'emissions/equipment',
      redirectTo: 'emissions',
    },
    {
      path: 'emissions',
      loadChildren: () =>
        import('./pages/emissions-uploads/emissions-uploads.module').then(
          (m) => m.EmissionsUploadsModule,
        ),
    },

    // Leaks (Coming Later)

    // ----- Control -----
    // Facilities
    {
      path: 'emissions/facilities',
      redirectTo: 'facilities/emissions',
    },
    {
      path: 'ldar/facilities',
      redirectTo: 'leaks',
    },
    { path: 'facilities', redirectTo: 'facilities/emissions' },
    { path: 'facilities', redirectTo: 'locations' },
    {
      path: 'locations',
      loadChildren: () =>
        import('./pages/locations/locations.module').then(
          (m) => m.LocationsModule,
        ),
    },
    {
      path: 'leaks',
      loadChildren: () =>
        import('./pages/leaks/leaks.module').then((m) => m.LeaksModule),
    },
    {
      path: 'ldar/facility',
      redirectTo: 'leaks',
    },
    {
      path: 'emissions/reporting-facility',
      redirectTo: 'location/reporting-facility',
    },
    {
      path: 'emissions/facility',
      redirectTo: 'location',
    },
    {
      path: 'location',
      loadChildren: () =>
        import('./pages/locations/location-details/location.module').then(
          (m) => m.LocationModule,
        ),
      data: {
        isLdar: false,
      },
    },

    // Equipment
    {
      path: 'emissions/equipment',
      redirectTo: 'equipment',
    },
    {
      path: 'equipment',
      loadChildren: () =>
        import('./pages/emissions-uploads/equipment/equipment.module').then(
          (m) => m.EquipmentModule,
        ),
    },

    // Data Sources
    {
      path: 'emissions/data-upload',
      redirectTo: 'data-sources/emissions',
    },
    {
      path: 'data-sources',
      loadChildren: () =>
        import('./pages/data-upload/data-upload.module').then(
          (m) => m.DataUploadModule,
        ),
    },
    {
      path: 'wec-tool',
      data: {
        wec: true,
        title: 'WEC Estimate | Iconic Air',
      },
      loadChildren: () =>
        import('./pages/forecast/forecast.module').then(
          (m) => m.ForecastModule,
        ),
    },
  ]),
  {
    path: 'wec',
    resolve: {
      static: StaticResolver,
    },
    loadChildren: () =>
      import('./pages/forecast/forecast.module').then((m) => m.ForecastModule),
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/knowledge-hub/knowledge-hub.module').then(
        (m) => m.KnowledgeHubModule,
      ),
    canActivate: [LandingGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/auth/forgot-password/forgot-password.module').then(
        (m) => m.AuthForgotPasswordModule,
      ),
  },
  {
    path: 'create-password',
    data: {
      create: true,
      title: 'Create Password | Iconic Air',
    },
    loadChildren: () =>
      import('./pages/auth/forgot-password/forgot-password.module').then(
        (m) => m.AuthForgotPasswordModule,
      ),
  },
  { path: 'login', component: SignInComponent },
  { path: 'invite/:companyId', component: InviteComponent },

  { path: '**', redirectTo: '/default', pathMatch: 'full' },
];

@NgModule({
  imports: [
    DemoModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
