import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../theme/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReleaseDialogComponent } from './release-dialog.component';

@NgModule({
  declarations: [ReleaseDialogComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [ReleaseDialogComponent],
})
export class ReleaseDialogModule {}
