<div class="flex flex-col h-full">
  <div
    class="flex-auto flex flex-col pt-0 px-4 py-4 pb-4 overflow-y-auto scroll-shadow"
  >
    <ng-container *ngIf="loading">
      <div class="flex flex-auto items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <div class="flex-auto">
        <ng-container *ngIf="activeCategory">
          <div
            class="flex items-center tracking-tight leading-8 text-secondary"
          >
            <span (click)="setActive('')" class="cursor-pointer"> All </span>
            <mat-icon class="icon-size-5 inner-icon"> chevron_right </mat-icon>
            {{activeCategory}}
          </div>
        </ng-container>
        <div class="relative" *ngFor="let data of dataSource">
          <div
            iconicAirMonorepoTooltip
            [contentTemplate]="megaFeedTooltip"
            [data]="data.label"
            class="relative"
          >
            <div class="flex flex-row pt-3">
              <div class="tracking-tight leading-8 text-secondary">
                {{data.label}}
              </div>
              <div
                class="ml-auto tracking-tight leading-8 text-secondary"
                #percWidth
              >
                {{ ((data.value / total) * 100).toFixed(3) }}%
              </div>
            </div>
            <div class="pb-4">
              <mat-progress-bar
                color="primary"
                mode="determinate"
                [value]="((data.value / total) * 100).toFixed(3)"
              >
              </mat-progress-bar>
            </div>
          </div>
          <div
            class="absolute flex items-center pr-2 z-24"
            [style.right]="percWidth.offsetWidth + 'px'"
            [style.top]="17 + 'px'"
            *ngIf="!activeCategory && data.hasMore"
          >
            <button
              mat-button
              class="small-more-info-btn border border-solid px-0 p-1 border-primary bg-primary-container text-primary rounded-full"
              [ngClass]="{'cursor-pointer': !activeCategory}"
              (click)="setActive(data.label);"
            >
              <small class="text-sm">More Info</small>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="py-2 pagination">
        <div class="flex justify-between items-center"  *ngIf="!activeCategory">
          <button mat-stroked-button color="primary" class="border border-solid border-primary" (click)="paginateMethane()" [disabled]="methaneIntensityPage === 0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <div class="flex flex-wrap items-center justify-center flex-col md:flex-row">
            <ng-container *ngFor="let page of methanePages">
              <ng-container
                *ngIf="page === 0
                  ||
                    (
                      (methaneIntensityPage < 2 && page < 2)
                        ||
                          (page < methaneIntensityPage + 1 && page > methaneIntensityPage - 1)
                      )
                      ||
                      (page > methanePages.length - 3 && methaneIntensityPage > methanePages.length - 3)
                  || page === methanePages.length - 1"
                >
                <span *ngIf="page === methanePages.length - 1 && methaneIntensityPage <= methanePages.length - 3">
                  ...
                </span>
                <button mat-stroked-button color="primary" class="border border-solid border-primary mx-1" [ngClass]="{'mat-flat-button': page === methaneIntensityPage}" (click)="goToPageMethane(page)">
                  {{page + 1}}
                </button>
                <span *ngIf="page === 0 && methaneIntensityPage > 1 ">
                  ...
                </span>
              </ng-container>
            </ng-container>
          </div>
          <button mat-stroked-button color="primary" class="border border-solid border-primary" (click)="paginateMethane(true)" [disabled]="methaneIntensityPage === methanePages.length - 1">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div> -->
    </ng-container>
  </div>
</div>
<ng-template #megaFeedTooltip let-data="data">
  <div
    *ngIf="showTooltip"
    class="flex flex-col bg-white shadow-md border border-solid border-black p-4 md:p-6 min-w-100"
  >
    <div [innerHtml]="getTooltipData | tooltipData : data"></div>
  </div>
</ng-template>
