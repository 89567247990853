<div
  class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0"
>
  <div
    class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card"
  >
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
      <!-- Logo -->
      <div class="w-48">
        <a href="https://iconicair.io">
          <img src="/assets/logo.svg" />
        </a>
      </div>

      <!-- Title -->
      <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
        Sign in
      </div>
      <!-- <div class="flex items-baseline mt-0.5 font-medium">
        <div>Don't have an account?</div>
        <a
          class="ml-1 text-primary-500 hover:underline"
          href="https://iconicair.io/beta/"
          >Sign up
        </a>
      </div> -->

      <!-- Sign in form -->
      <form class="mt-8" (submit)="userLogin()" [formGroup]="signInForm">
        <!-- Email field -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Email Address</mat-label>
          <input
            id="email"
            matInput
            placeholder="john.smith@iconicair.io"
            formControlName="email"
            #emailField
          />
          <mat-error *ngIf="signInForm.get('email').hasError('required')">
            Email address is required
          </mat-error>
          <mat-error *ngIf="signInForm.get('email').hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Password</mat-label>
          <input
            id="password"
            matInput
            type="password"
            formControlName="password"
            #passwordField
          />
          <button
            mat-icon-button
            type="button"
            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
            matSuffix
          >
            <mat-icon
              class="icon-size-5 inner-icon"
              *ngIf="passwordField.type === 'password'"
              >visibility_on</mat-icon
            >
            <mat-icon
              class="icon-size-5 inner-icon"
              *ngIf="passwordField.type === 'text'"
              >visibility_off</mat-icon
            >
          </button>
          <mat-error> Password is required </mat-error>
        </mat-form-field>
        <div class="flex items-center justify-between w-full mt-1.5">
          <!-- Remember Me -->
          <mat-checkbox color="primary" formControlName="rememberMe">
            Remember Me
          </mat-checkbox>
          <!-- Forgot Password -->
          <a
            class="text-md font-medium text-primary-500 hover:underline"
            [routerLink]="['/forgot-password']"
            >Forgot password?
          </a>
        </div>

        <!-- Submit button -->
        <!--
          -->
        <button
          class="iconic-mat-button-large w-full mt-6"
          [disabled]="!signInForm.valid || loading"
          mat-stroked-button
          [color]="'primary'"
        >
          <span
            [ngStyle]="{'color':signInForm.valid && !loading ? 'inherit' : '#475569'}"
            *ngIf="!loading"
          >
            Sign In
          </span>
          <mat-progress-spinner
            *ngIf="loading"
            [diameter]="24"
            [mode]="'indeterminate'"
          >
          </mat-progress-spinner>
        </button>
      </form>

      <button
        class="iconic-mat-button-large w-full mt-6"
        (click)="userLoginSSO()"
      >
        <img
          *ngIf="!loading"
          src="/assets/ms-symbollockup_signin_light.svg"
          class="h-10"
        />
      </button>
    </div>
  </div>
  <div
    class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-primary dark:border-l"
  >
    <!-- Background - @formatter:off -->
    <!-- Rings -->
    <!--
    <svg
      class="absolute inset-0 pointer-events-none"
      viewBox="0 0 960 540"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMax slice"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        class="text-gray-700 opacity-25"
        fill="none"
        stroke="currentColor"
        stroke-width="100"
      >
        <circle r="234" cx="196" cy="23"></circle>
        <circle r="234" cx="790" cy="491"></circle>
      </g>
    </svg>
    <svg
      viewBox="0 0 960 540"
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute inset-0 pointer-events-none"
    >
      <g xmlns="http://www.w3.org/2000/svg" class="text-gray-700 opacity-25">
        <path d="M23.9157 0.000326921L0.480469 23.4355L47.9368 70.8919L71.372 47.4567L23.9157 0.000326921Z" fill="#ffffff"/>
        <path d="M47.9251 107.571L0.46875 155.027L23.904 178.463L71.3603 131.006L47.9251 107.571Z" fill="#ffffff"/>
        <path d="M131.474 107.586L108.039 131.021L155.495 178.477L178.931 155.042L131.474 107.586Z" fill="#ffffff"/>
        <path d="M155.566 0.0153433L108.109 47.4717L131.545 70.9069L179.001 23.4506L155.566 0.0153433Z" fill="#ffffff"/>
      </g>
    </svg>
  -->
    <svg
      class="absolute inset-0 pointer-events-none -m-l-3"
      width="1654"
      height="517"
      viewBox="0 0 1654 517"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="margin-left: -120px; margin-top: 80px"
    >
      <g opacity="0.1">
        <path
          d="M67.8825 2.13426e-05L0.0390625 67.8434L137.422 205.226L205.265 137.383L67.8825 2.13426e-05Z"
          fill="white"
        />
        <path
          d="M137.383 311.411L0 448.794L67.8434 516.637L205.226 379.254L137.383 311.411Z"
          fill="white"
        />
        <path
          d="M379.257 311.455L311.414 379.298L448.797 516.681L516.64 448.837L379.257 311.455Z"
          fill="white"
        />
        <path
          d="M449 0.0417942L311.617 137.425L379.461 205.268L516.844 67.8852L449 0.0417942Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M635.968 2.13426e-05L568.125 67.8434L705.508 205.226L773.351 137.383L635.968 2.13426e-05Z"
          fill="white"
        />
        <path
          d="M705.469 311.411L568.086 448.794L635.929 516.637L773.312 379.254L705.469 311.411Z"
          fill="white"
        />
        <path
          d="M947.343 311.455L879.5 379.298L1016.88 516.681L1084.73 448.837L947.343 311.455Z"
          fill="white"
        />
        <path
          d="M1017.09 0.0418553L879.703 137.425L947.547 205.268L1084.93 67.8853L1017.09 0.0418553Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M1204.06 2.13426e-05L1136.22 67.8434L1273.6 205.226L1341.44 137.383L1204.06 2.13426e-05Z"
          fill="white"
        />
        <path
          d="M1273.56 311.411L1136.18 448.794L1204.02 516.637L1341.4 379.254L1273.56 311.411Z"
          fill="white"
        />
        <path
          d="M1515.43 311.455L1447.59 379.298L1584.97 516.681L1652.81 448.837L1515.43 311.455Z"
          fill="white"
        />
        <path
          d="M1585.18 0.0418553L1447.79 137.425L1515.64 205.268L1653.02 67.8853L1585.18 0.0418553Z"
          fill="white"
        />
      </g>
    </svg>
    <!-- Dots -->
    <svg
      class="absolute -top-16 -right-16 text-gray-100"
      viewBox="0 0 220 192"
      width="220"
      height="192"
      fill="none"
    >
      <defs>
        <pattern
          id="837c3e70-6c3a-44e6-8854-cc48c737b659"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect
        width="220"
        height="192"
        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
      ></rect>
    </svg>
    <!-- @formatter:on -->
    <!-- Content -->
    <div class="z-10 relative w-full max-w-2xl">
      <div class="text-7xl font-bold leading-none text-gray-100">
        <div>Accelerating your</div>
        <div>path to net-zero.</div>
      </div>
      <div class="mt-6 text-lg tracking-tight leading-6 text-gray-100">
        Helping the world's largest-emitting industries decarbonize faster with
        our leading sustainability tools.
      </div>
    </div>
  </div>
</div>
