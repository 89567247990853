import { Component, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  FormGroup,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateService } from '../../services/date/date.service';
import { GasSampleService } from '../../services/gas-sample/gas-sample.service';

@Component({
  selector: 'app-gas-sample',
  templateUrl: './gas-sample.component.html',
  styleUrls: ['./gas-sample.component.scss'],
})
export class GasSampleComponent {
  public formGroup: FormGroup;
  #facilityId: string;
  #reportingFacilityId: string;
  saving = false;

  get errors() {
    const errors: { [key: string]: ValidationErrors | null | boolean } = {};
    const controls = this.formGroup.controls;
    for (const key in controls) {
      if (
        controls[key]?.invalid &&
        controls[key]?.errors &&
        (controls[key]?.dirty || controls[key]?.touched)
      )
        errors[key] = controls[key].errors;
    }
    if (this.formGroup.hasError('sumExceeded')) errors.sumExceeded = true;
    return errors;
  }

  constructor(
    private _afs: AngularFirestore,
    public dialogRef: MatDialogRef<GasSampleComponent>,
    private _dateService: DateService,
    private _gasSampleService: GasSampleService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.#facilityId = data.facilityId;
    this.#reportingFacilityId = data.reportingFacilityId;
    this.formGroup = new FormGroup(
      {
        id: new FormControl(this._afs.createId(), Validators.required),
        c2: new FormControl('', Validators.required),
        c3: new FormControl('', Validators.required),
        c4: new FormControl('', Validators.required),
        c5: new FormControl('', Validators.required),
        co2: new FormControl('', Validators.required),
        ch4: new FormControl('', Validators.required),
        hhvBtu: new FormControl(),
        gasSampleBasis: new FormControl(),
        hhvBasis: new FormControl(),
        name: new FormControl(),
        sampleDate: new FormControl(new Date(), Validators.required),
        effectiveDate: new FormControl(new Date(), Validators.required),
        mw: new FormControl(),
        relativeDensity: new FormControl(),
      },
      {
        validators: this.#sumValidator(
          ['c2', 'c3', 'c4', 'c5', 'ch4', 'co2'],
          1,
        ),
      },
    );

    if (data) {
      const gasSample = JSON.parse(JSON.stringify(data.gasSample));
      if (!data.isNewSample) {
        gasSample.sampleDate = this._dateService.convertToDateTime(
          gasSample.sampleDate,
        );
        gasSample.effectiveDate = this._dateService.convertToDateTime(
          gasSample.effectiveDate,
        );
      }

      this.formGroup.patchValue(gasSample);
    }
  }

  async save() {
    if (!this.formGroup.valid) throw 'Please fill in required fields.';
    this.saving = true;
    try {
      const gasSample = this.formGroup.getRawValue();
      gasSample.hhv = (gasSample.hhvBtu || 0) / 1000000;
      await this._gasSampleService.saveGasSample(
        this.#reportingFacilityId as string,
        gasSample,
        this.#facilityId,
      );
      this.dialogRef.close({
        gasSample,
        isNewSample: this.data.isNewSample,
      });
    } finally {
      this.saving = false;
    }
  }

  #sumValidator(fields: string[], maxSum: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control as any;
      const sum = fields.reduce(
        (acc, field) => acc + (parseFloat(formGroup.get(field)?.value) || 0),
        0,
      );
      return sum > maxSum ? { sumExceeded: { value: sum, max: maxSum } } : null;
    };
  }
}
