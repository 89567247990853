import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipIconComponent } from './tooltip-icon.component';
import { MaterialModule } from '../../theme/material.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [TooltipIconComponent],
  imports: [CommonModule, MaterialModule, PipesModule],
  exports: [TooltipIconComponent],
})
export class TooltipIconModule {}
