<!-- Logo -->
<div class="w-48">
  <img src="/assets/test3-logo.png" />
</div>

<div class="flex flex-col">
  <div [hidden]="showSMSInput">
    <div class="flex flex-col">
      <!-- Title -->
      <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
        Send Verification Code
      </div>
      <!-- Directions -->
      <div class="flex items-baseline mt-0.5">
        We’ll text you a code to verify it’s you.
      </div>
      <div class="flex items-baseline mt-4">Send code to:</div>

      <form
        #EditItemForm="ngForm"
        appearance="outline"
        class="mt-4 self-center"
      >
        <mat-radio-group
          [(ngModel)]="selectedMFA"
          name="selectedMFAForm"
          required
          #selectedMFAForm="ngModel"
          class="flex flex-col gap-4"
        >
          <mat-radio-button *ngFor="let hint of resolver.hints" [value]="hint">
            Text: {{hint.phoneNumber}}
          </mat-radio-button>
          <div
            *ngIf="selectedMFAForm.invalid && (selectedMFAForm.dirty || selectedMFAForm.touched)"
            class="alert"
          >
            <mat-error *ngIf="selectedMFAForm.errors?.['required']"
              >A phone number is required.</mat-error
            >
          </div>
        </mat-radio-group>
      </form>

      <button
        class="mt-6 align-center"
        mat-flat-button
        [color]="'primary'"
        [disabled]="!selectedMFAForm.valid || loading"
        (click)="getSMSVerification()"
      >
        <div *ngIf="selectedMFAForm.valid && !loading">
          Request Verification Code
        </div>
        <mat-progress-spinner
          *ngIf="loading"
          [diameter]="24"
          [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
      </button>
      <div class="mt-0.5 text-sm self-center">
        Message and data rates may apply for text.
      </div>
    </div>
  </div>

  <div [hidden]="!showSMSInput">
    <div class="flex flex-col">
      <!-- Title -->
      <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
        Enter verification code
      </div>
      <!-- Directions -->
      <div class="flex items-baseline mt-0.5 font-medium">
        An SMS text message has been sent to your phone. Please enter the
        verification code to continue.
      </div>
      <mat-form-field appearance="outline" class="mt-4 self-center">
        <mat-label>Verification Code</mat-label>
        <input
          name="verificationCodeForm"
          #verificationCodeForm="ngModel"
          [(ngModel)]="verificationCode"
          matInput
          type="text"
          required
          placeholder="Verification Code"
        />
        <mat-error *ngIf="verificationCodeForm.errors?.['required']"
          >A verification code is required.</mat-error
        >
      </mat-form-field>

      <button
        class="mt-6 align-center"
        mat-flat-button
        [color]="'primary'"
        [disabled]="!verificationCodeForm.valid || loading"
        (click)="sendSMSVerification()"
      >
        <div *ngIf="selectedMFAForm.valid">Verify</div>
        <mat-progress-spinner
          *ngIf="loading"
          [diameter]="24"
          [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
      </button>
    </div>
  </div>
</div>
<div id="mf-auth-recaptcha-container"></div>
