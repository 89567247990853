import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Facility, GasSample } from '@iconic-air/models';
import { GasSampleComponent } from '../../../../components/gas-sample/gas-sample.component';
import * as moment from 'moment';
import { PermissionsWebGuard } from '../../../../guards/permissions/permissions-web-guard.guard';
import { FacilitiesDatabaseService } from '../../../../services-database/facilities-service/facilities-database.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-location-gas-samples',
  templateUrl: './location-gas-samples.component.html',
  styleUrls: ['./location-gas-samples.component.scss'],
})
export class LocationGasSamplesComponent {
  isAdmin = false;
  gasSampleColumns: string[] = [
    'name',
    'sampleDate',
    'effectiveDate',
    'co2',
    'ch4',
    'edit',
  ];
  @Input() facility: Facility;
  loading = false;
  constructor(
    private perms: PermissionsWebGuard,
    private dialog: MatDialog,
    private _facilitiesDatabaseService: FacilitiesDatabaseService,
  ) {
    this.isAdmin = this.perms.userData.isAdmin;
  }

  addGasSample(event, isNewSample: boolean, sample?: GasSample) {
    event.stopPropagation();
    const data = sample ? sample : null;
    const index = this.facility.gasSamples?.findIndex(
      (gasSample) => gasSample === sample,
    );

    const gasSampleDialogRef = this.dialog.open(GasSampleComponent, {
      data: {
        gasSample: data,
        isNewSample: isNewSample,
      },
    });

    gasSampleDialogRef.afterClosed().subscribe(async (data: any) => {
      if (!data) return;
      // not every facility has a gasSample so initialize the empty array here.
      if (!this.facility.gasSamples) {
        this.facility.gasSamples = [];
      }

      data.gasSample.sampleDate = data.gasSample.sampleDate = moment(
        moment(data.gasSample.sampleDate).unix() * 1000,
      ).format();
      data.gasSample.effectiveDate = moment(
        moment(data.gasSample.effectiveDate).unix() * 1000,
      )
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format();

      if (isNewSample) {
        this.facility.gasSamples.push(data.gasSample);
      } else if (index > -1) this.facility.gasSamples[index] = data.gasSample;

      this.loading = true;
      try {
        await lastValueFrom(
          this._facilitiesDatabaseService.saveUpdateGasSample(
            this.facility.reportingFacilityId as string,
            this.facility.id,
            data.gasSample,
          ),
        );
      } finally {
        this.loading = false;
      }
    });
  }
}
