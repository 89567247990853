import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { StaticDataService } from '../../services/static-data/static.data.service';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-facility-type-column-selection-editor',
  templateUrl: './facility-type-column-selection-editor.component.html',
  styleUrls: ['./facility-type-column-selection-editor.component.scss']
})
export class FacilityTypeColumnSelectionEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {
  public params: ICellEditorParams;
  public value: number;
  @ViewChild('matSelect')
  public selectOption: MatSelect;
  @ViewChild('matWrapper')
  public selectWrapper: ElementRef;
  get facilityTypes() {
    return this.staticService.facilityTypes;
  }

  constructor(private staticService: StaticDataService) {}

  public ngAfterViewInit() {
    window.setTimeout(() => {
      this.selectOption.toggle();
      this.selectOption.focus();
      this.selectWrapper.nativeElement.focus();
    });
  }

  public changeValue(val: MatSelectChange): void {
    this.value = val.value;
    this.params.node.setDataValue(this.params.column, this.value);
  }

  public getValue(): any {
    this.params.data.type = this.value;
    return this.value;
  }

  public agInit(params: any): void {
    this.params = params;
    if (params.value) {
      this.value = params.value;
    }
  }

  public isPopup(): boolean {
    return true;
  }

  public onKeyDown(event: any): void {
    const key = event.which || event.keyCode;
    if (key === 38 || key === 40) {
      this.preventDefaultAndPropagation(event);
    }
  }

  private preventDefaultAndPropagation(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}
