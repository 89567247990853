import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as FS from '@fullstory/browser';

import { environment } from '../environments/environment';
import { AuthenticationWebService } from './services/authentication/authentication-web.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'monorepo-iconic-app';
  isScrolled = false;

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private _auth: AuthenticationWebService,
  ) {
    FS.init({
      orgId: 'o-1D1R1D-na1',
      devMode: environment.develop,
      debug: true,
    });
  }

  ngOnInit() {
    this.afAuth.onAuthStateChanged(async (auth) => {
      if (auth) {
        const user = await this.afAuth.currentUser;
        this._auth.idToken = await user?.getIdToken(true);
      } else {
        this._auth.userUid = undefined;
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(title);
        }
      });
  }
}
