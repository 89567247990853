import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private perms: PermissionsWebGuard,
    private afs: AngularFirestore,
  ) {}

  createUser = (form: any): Observable<any> => {
    return this.http.post('/api/admin/create-user', form);
  };

  updateUser = (form: any): Observable<any> => {
    return this.http.post('/api/admin/update-user', form);
  };

  getDashboardSettings() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('dashboardSettings')
      .snapshotChanges();
  }

  updateDashboardSettings(settings) {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('dashboardSettings')
      .update(settings);
  }

  getGlobalWarmingPotentialValues() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('globalWarmingPotentialValues')
      .snapshotChanges();
  }

  async updateGlobalWarmingPotentialValues(values) {
    try {
      await this.afs
        .collection('customers')
        .doc(this.perms.activeCustomerId)
        .collection('companyValues')
        .doc('globalWarmingPotentialValues')
        .update(values);
    } catch (e) {
      if (e?.message?.includes('No document to update')) {
        await this.afs
          .collection('customers')
          .doc(this.perms.activeCustomerId)
          .collection('companyValues')
          .doc('globalWarmingPotentialValues')
          .set(values);
      } else {
        throw e;
      }
    }
    return;
  }
}
