<div class="m-0.5">
  <div class="flex items-center justify-between mb-4">
    <h2 class="text-2xl font-semibold">Email survey report</h2>
    <mat-icon class="cursor-pointer" (click)="closeModal()">close</mat-icon>
  </div>
  <div>
    <mat-form-field class="w-full py-2" appearance="outline">
      <mat-label>Email recipients:</mat-label>
      <mat-chip-list #chipList aria-label="Email selection">
        <div *ngFor="let email of emails">
          <mat-chip
            *ngIf="email !== ''"
            disableRipple
            (removed)="removeChip(email)"
            class="flex items-center"
          >
            <div>{{email}}</div>
            <button matChipRemove class="flex items-center">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </div>
        <input
          #currentChip
          placeholder="Add email..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addChip($event)"
          (keyup)="checkEmail(currentChip.value)"
          type="email"
        />
      </mat-chip-list>
    </mat-form-field>

    <div *ngIf="emailFound" class="mt-2 mb-2 text-error">
      <strong>{{emailFound}}</strong> is already in the list are you sure want
      to add it?
    </div>
    <div class="flex items-center">
      <div clas=" mt-2 mb-2">
        <button
          mat-raised-button
          color="primary"
          [class.spinner]="isProcessing"
          [disabled]="isProcessing || emails.length === 0"
          (click)="sendEmail()"
        >
          <span *ngIf="!isProcessing">Send Email</span>
        </button>
      </div>
      <div *ngIf="isProcessing" class="text-primary ml-2">
        We're creating your report now!
      </div>
      <div *ngIf="isUploadingPdf" class="text-primary ml-2">
        <ng-container *ngIf="uploadPercent | async as pct">
          <span><strong>({{pct | number}}%)</strong></span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
