import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationWebService } from '../../../services/authentication/authentication-web.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, AfterViewInit {
  @ViewChild('emailField', { static: false }) emailField: ElementRef;
  @ViewChild('passwordField', { static: false }) passwordField: ElementRef;
  public loading = false;
  public signInForm: FormGroup;
  #redirectUrl?: string;

  constructor(
    private auth: AuthenticationWebService,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private router: Router,
    private _route: ActivatedRoute,
  ) {
    // Create the form
    this.signInForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(true),
    });
  }

  async ngOnInit() {
    const params = await firstValueFrom(this._route.queryParamMap);
    const redirectUrl = params.get('redirectUrl');
    if (redirectUrl) this.#redirectUrl = redirectUrl;
    const userEmail = params.get('email');
    if (userEmail) {
      this.signInForm.get('email')?.setValue(userEmail);
      // focus on the password
      this.passwordField.nativeElement.focus();
    }
    this.userLoginSSORedirect();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.setControlValuesFromNativeValues(), 300);
  }

  setControlValuesFromNativeValues(): void {
    this.signInForm.get('email')?.setValue(this.emailField.nativeElement.value);
    this.signInForm
      .get('password')
      ?.setValue(this.passwordField.nativeElement.value);
  }

  userLogin() {
    const email = this.signInForm.get('email')?.value;
    const password = this.signInForm.get('password')?.value;
    const rememberMe = this.signInForm.get('rememberMe')?.value;
    const targetUrl = ['/'];

    if (this.#redirectUrl) {
      if (this.#redirectUrl.startsWith('/')) targetUrl.pop();
      targetUrl.push(this.#redirectUrl);
    }

    this.loading = true;

    this.auth
      .signIn(email, password, rememberMe)
      .then(async (credential) => {
        if (credential !== undefined) {
          await this.ngZone.run(async () => {
            await this.router.navigate(targetUrl).catch((e) => {
              this.loading = false;
              throw e;
            });
          });
        }

        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  userLoginSSO() {
    const rememberMe = true;
    this.loading = true;
    this.auth.signInSSO(rememberMe);
  }

  userLoginSSORedirect() {
    this.loading = true;

    this.auth
      .signInSSORedirect()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        throw error;
      });
  }
}
