import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { firebaseProjectAlias } from '../../../environments/environment';
import { ObservableService } from '../../services/observable-service/observable.service';
import { VersionService } from '../../services/version/version.service';
import { ReleaseDialogComponent } from '../../components/release-dialog/release-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ContactSupportDialogComponent } from '../../components/contact-support-dialog/contact-support-dialog.component';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShellComponent implements OnInit {
  firebaseProjectAlias;
  mini = false;
  mobile = false;
  isInternal = false;
  hidden = false;
  isAliasMode;
  hideCarrot = false;
  get activeCustomer() {
    return this.perms.customers.find((cc) => {
      return this.perms.activeCustomerId;
    });
  }
  isScrolled = false;
  isAdmin = false;
  isHeaderClosed = false;
  @ViewChild('scrollContainer', { static: false }) containerRef: ElementRef;
  containerOffset = 0;
  timeLeft: { days: number; hours: number };
  showChat = false;

  showReleaseBanner = false;
  #release;

  constructor(
    private router: Router,
    private perms: PermissionsWebGuard,
    private _observableService: ObservableService,
    private _cd: ChangeDetectorRef,
    private _versionService: VersionService,
    private _dialog: MatDialog,
  ) {
    this.isInternal = this.perms.userData.internal;
    this.isAliasMode = this.perms.userData.isAliasMode;
    this.isAdmin = this.perms.userData.isAdmin;
    if (firebaseProjectAlias) {
      this.firebaseProjectAlias = firebaseProjectAlias;
    }

    if (
      this.perms.currentCustomer?.isSandbox &&
      this.perms.currentCustomer?.sandBoxTrialEndDate
    ) {
      this.timeLeft = this.getTimeLeftOfTrial(
        this.perms.currentCustomer?.sandBoxTrialEndDate,
      );
    }
  }

  openReleaseDialog() {
    const data = {
      version: this._versionService.version,
      release: this.#release,
    };
    this._dialog.open(ReleaseDialogComponent, {
      data,
      disableClose: true,
    });
  }

  contactSupport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog-flush-bottom-footer';
    dialogConfig.data = {
      comments: `Feedback on version ${this._versionService.version}:
      Liked:
          1.
          2.
          3.
      Disliked:
          1.
          2.
          3.
      Suggestions:
          1.
          2.
          3.`,
    };
    this._dialog.open(ContactSupportDialogComponent, dialogConfig);
  }

  ngOnInit(): void {
    const userVersion = JSON.parse(
      JSON.stringify(this.perms.userData?.version || ''),
    );
    if (userVersion !== this._versionService.version) {
      // set their version to the current version
      this._versionService.setUserVersion();
    }
    this._versionService
      .getReleaseInformation(this._versionService.version)
      .then((release) => {
        const keys = Object.keys(release || {});
        if (
          keys.includes('videoLink') ||
          keys.includes('improvements') ||
          keys.includes('fixes') ||
          keys.includes('newFeatures') ||
          keys.includes('supplementalVideoLinks')
        ) {
          if (
            Number(new Date(release.expires || Date.now())) < Number(new Date())
          )
            return;

          this.showReleaseBanner = true;
          this.#release = release;
          if (userVersion !== this._versionService.version) {
            // they haven't seen the popup yet show it
            this.openReleaseDialog();
          }
        }
      });
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.mobile = false;
      });
    this._observableService
      .subscribe('shell-hide-menu-carrot')
      .subscribe((hide) => {
        this.hideCarrot = hide;
        this._cd.detectChanges();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(): void {
    this.mini = !this.mini;
  }

  toggleMobile(): void {
    this.mobile = !this.mobile;
  }

  toggleChat() {
    debugger;
    this.showChat = !this.showChat;
  }

  onScroll() {
    if (this.containerRef && this.containerRef.nativeElement) {
      const containerElement = this.containerRef.nativeElement as HTMLElement;
      this.containerOffset =
        containerElement.getBoundingClientRect().top + window.pageYOffset;

      if (this.containerOffset !== 0 && this.containerOffset !== 52) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    }
  }

  closeHeader() {
    this.isHeaderClosed = true;
    this.isScrolled = true;
  }

  getTimeLeftOfTrial(sandBoxTrialEndDate) {
    const now = Date.now();
    const differenceInMs = sandBoxTrialEndDate - now;
    const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );

    return {
      days: days,
      hours: hours,
    };
  }
}
