import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-icon-snack-bar',
  templateUrl: './icon-snack-bar.component.html',
  styleUrls: ['./icon-snack-bar.component.scss'],
})
export class IconSnackBarComponent implements OnInit {
  constructor(
    private snackBarRef: MatSnackBarRef<IconSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { message?: string; icon?: string },
  ) {}

  ngOnInit(): void {}

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
