<div
  class="flex flex-col"
  [class]="(data.icon) ? 'justify-center items-center': ''"
>
  <mat-icon *ngIf="data.icon">{{data.icon}}</mat-icon>
  <h1 mat-dialog-title>{{data.title}}</h1>
</div>

<div mat-dialog-content class="py-1">
  <p class="{{data.textClass}}" *ngIf="!isArray">{{data.text}}</p>
  <ng-container *ngIf="isArray">
    <ng-container *ngFor="let text of data.text">
      <p *ngIf="text" class="{{data.textClass}}">{{text}}</p>
      <br *ngIf="!text" />
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions class="flex justify-end">
  <button
    mat-button
    mat-flat-button
    *ngIf="data.button1"
    class="flex flex-row items-center small-clear-filters rounded-3xl mt-1 h-12 force-animate-refresh"
    (click)="onButton1Click()"
  >
    {{data.button1}}
  </button>
  <button
    mat-button
    mat-flat-button
    *ngIf="data.button2"
    class="flex flex-row items-center small-clear-filters rounded-3xl mt-1 h-12 force-animate-refresh"
    (click)="onButton2Click()"
  >
    {{data.button2}}
  </button>
</div>
