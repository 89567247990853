import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PermissionsWebGuard } from '../guards/permissions/permissions-web-guard.guard';

@Injectable({
  providedIn: 'root',
})
export class RoleResolver implements Resolve<any> {
  constructor(
    private permService: PermissionsWebGuard,
    private afs: AngularFirestore,
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    rState: RouterStateSnapshot,
  ): Observable<QuerySnapshot<DocumentData>> | boolean {
    return this.afs
      .collection('roles')
      .get()
      .pipe(
        catchError((err) => {
          throw err;
        }),
        map((data: any) => {
          const roles = [];
          for (const role of data.docs) {
            roles.push({ id: role.id, ...role.data() });
          }
          this.permService.globalRoles = roles;
          return data;
        }),
      );
  }
}
