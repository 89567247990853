import { Injectable } from '@angular/core';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { Route as ngRoute, Routes } from '@angular/router';
import { PermissionsWebGuard } from '../guards/permissions/permissions-web-guard.guard';
import { CustomerDataResolver } from '../resolvers/customer-data.resolver'
import { RoleResolver } from '../resolvers/role.resolver';
import { StaticResolver } from '../resolvers/static.resolver';
import { ShellComponent } from './shell/shell.component';

/**
 * Provides helper methods to create routes.
 */
export class Route {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Route} The new route using shell as the base.
   */
  static withShell(routes: Routes): ngRoute {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      // canActivate: [AuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true },
      resolve: {
        role: RoleResolver,
        static: StaticResolver,
        customer: CustomerDataResolver
      },
      canActivate: [AngularFireAuthGuard, PermissionsWebGuard],
      canActivateChild: [PermissionsWebGuard],
    };
  }
}
