import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class IdGeneratorService {
  constructor(private _afs: AngularFirestore) {}

  createId() {
    return this._afs.createId();
  }
}
