import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';

@Component({
  selector: 'app-business-area-select',
  templateUrl: './business-area-select.component.html',
  styleUrls: ['./business-area-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BusinessAreaSelectComponent,
      multi: true,
    },
  ],
})
export class BusinessAreaSelectComponent
  implements ControlValueAccessor, AfterViewInit, OnInit
{
  @ViewChild('matSelect', { static: true }) select: any;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @Input() disableScroll: boolean;
  @Input() businessAreas: string[] = [];
  @Input() multiple: boolean;
  @Input() fullObj: boolean;
  @Input() selectedBusinessArea: string[] | string = [];
  @Input() classes = 'w-full';
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Output() selectedBusinessAreaChange: EventEmitter<string | string[]> =
    new EventEmitter();
  @ViewChild('matSelect')
  selectOption: MatSelect;
  @ViewChild('matWrapper')
  selectWrapper: ElementRef;
  value: string; // Ex. North
  isDisabled = false;
  businessAreaFilter = '';
  @ViewChildren(MatOption)
  options: QueryList<MatOption>;
  private onChangeCallback: any = () => undefined;
  private onTouchedCallback: any = () => undefined;
  private selectedBusinessAreasGospel: string[] | string;
  private businessAreaGospel: string[] = [];

  constructor(readonly sd: ScrollDispatcher, private cd: ChangeDetectorRef) {
    if (this.multiple) {
      this.selectedBusinessArea = [];
    }
  }

  ngOnInit() {
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.code === 'Space') event.stopPropagation();
      else {
        // Call original _handleKeydown for other keys
        MatSelect.prototype._handleKeydown.call(this.select, event);
      }
    };
  }

  ngAfterViewInit(): void {
    this.businessAreaGospel = JSON.parse(JSON.stringify(this.businessAreas));
    this.businessAreaGospel = this.businessAreaGospel.sort();
    this.selectedBusinessAreasGospel = JSON.parse(
      JSON.stringify(this.selectedBusinessArea),
    );
    this.selectOption.optionSelectionChanges.subscribe((change) => {
      if (change.isUserInput) {
        if (Array.isArray(this.selectedBusinessAreasGospel)) {
          if (!change.source.selected) {
            const index = this.selectedBusinessAreasGospel.findIndex((area) => {
              return area === change.source.value;
            });
            if (index >= 0) {
              this.selectedBusinessAreasGospel.splice(index, 1);
            }
          }

          if (change.source.selected) {
            const index = this.selectedBusinessAreasGospel.findIndex((area) => {
              return area === change.source.value;
            });
            if (index === -1) {
              this.selectedBusinessAreasGospel.push(change.source.value);
            }
          }

          this.selectedBusinessArea = JSON.parse(
            JSON.stringify(this.selectedBusinessAreasGospel),
          );
        }
        this.selectedBusinessAreaChange.emit(this.selectedBusinessArea);
        this.onChangeCallback(this.selectedBusinessArea);
      }
    });
  }

  onSelect(event: MatOptionSelectionChange) {
    this.onChangeCallback(event.source.value);
    this.selectedBusinessAreaChange.emit(event.source.value);
  }

  writeValue(value: string | string[]) {
    this.selectedBusinessArea = value;
  }

  // Implement ControlValueAccessor registerOnChange.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // Implement ControlValueAccessor registerOnTouched.
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // Propagate enable/disable to the form control.
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public focus() {
    this.selectOption._elementRef.nativeElement.focus();
  }

  selectAllBusinessAreas(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedBusinessAreasGospel = JSON.parse(
        JSON.stringify(this.businessAreaGospel),
      );
      this.selectedBusinessArea = JSON.parse(
        JSON.stringify(this.selectedBusinessAreasGospel),
      );
    } else {
      this.selectedBusinessArea = [];
      this.selectedBusinessAreasGospel = [];
    }
    this.onChangeCallback(this.selectedBusinessArea);
    this.selectedBusinessAreaChange.emit(this.selectedBusinessArea);
  }

  trackByFn(index: number, item: any): any {
    return item.area || index;
  }
}
