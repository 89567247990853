import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  EmissionsCategory,
  EmissionSource,
  StaticItems,
  ReportingPeriodType,
  BulkUpload,
  CodeAndDescription,
  TemplateColumnValidation,
  TemplateDataValidation,
  ProgramTypeConfig,
  PopulationCountEmissionFactor,
  Region,
  State,
} from '@iconic-air/models';
import { ColDef } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaticDataService {
  manageItems: StaticItems = {
    folders: [],
    files: [],
    path: [],
  };
  reportingPeriods: ReportingPeriodType;
  industrySegments;
  reportRegulationTypes;
  ldarFrequencies: string[];
  eGridRegions: any[] = [];
  ogiRegulations: string[] = [];
  m21Regulations: string[] = [];
  requiredLdarFacilityKeys: string[];
  requiredEmissionsFacilityKeys: string[];
  standardizedLdarFacilityKeys: string[];
  standardizedFacilityKeys: string[];
  standardizedEmissionsFacilityKeys: string[];
  requiredComponentKeys: string[];
  standardizedComponentKeys: string[];
  requiredAvoSurveyKeys: string[];
  standardizedAvoSurveyKeys: string[];
  surveyTypes: string[];
  cameras: string[];
  skyConditions: string[];
  windDirections: string[];
  gasTypes: { code: string; description: string }[];
  basins: any;
  states: State[];
  formulas: any;
  templates: any;
  presets: any[] = [];
  equipmentEmissionColumns: { [key: string]: ColDef[] };
  equipmentEmissionsFields: any;
  equipmentSelectorColumns: { [key: string]: ColDef[] };
  get emissionSourcesLabels() {
    return this.scopeOneSources.concat(this.scopeTwoSources).map((key) => {
      return key.label;
    });
  }
  get emissionSources(): EmissionSource[] {
    return this.scopeOneSources.concat(this.scopeTwoSources);
  }
  scopeOneSources: EmissionSource[] = [];
  scopeTwoSources: EmissionSource[] = [];
  emissionOptions = ['CO2e (AR5)', 'CO2e (AR4)', 'CO2', 'CH4', 'N20']; // this is only called from the overview page dashboard.component.ts as of 1/24/21
  benchmarkYears: any;
  componentTypes;
  calculations: any[] = [];
  emissionSourceFields: { [key: string]: any };
  equipmentSelectedDisplayFields: { [key: string]: any };
  diversityData: { [key: string]: string[] };
  equipmentListColumns: any;
  industries: string[] = [];
  epaWorksheetCategories: EmissionsCategory[] = [];
  productionCategories: { [key: string]: { rawEmissionsKey: string } } = {};
  rfProductionProfiles: string[];
  rfRegulations: string[];
  futureHardcodedLines: any[] = [];
  calculationMethods: string[] = [];
  combustionEquipmentSubTypes: any[] = [];
  auditReportHeaders: any[] = [];
  methaneEmissionFormulas: {
    [protocol: string]: {
      [version: string]: {
        [industrySegment: string]:
          | {
              [key: string]: string;
            }
          | string;
        lastUpdated: string;
        version: string;
        display: string;
      };
    }[];
  } = {};
  carbonEmissionFormulas: {
    [industrySegment: string]: {
      [key: string]: string;
    }[];
  }[] = [];
  bulkUpload: {
    [type: string]: BulkUpload;
  } = {};
  mapboxApiTokens: string[] = [];
  compressorOperatingModes: string[] = [];
  facilityTypes: string[] = [];
  locationSubTypes: string[] = [];
  repairMethods: string[] = [];
  repairStatus: CodeAndDescription[] = [];
  repairConfMethods: string[] = [];
  repairConfStatus: string[] = [];
  scopes: string[] = [];
  templateColumnValidation: TemplateColumnValidation[] = [];
  templateDataValidation: TemplateDataValidation[] = [];
  programTypes: string[] = [];
  flareRepairMethods: string[] = [];
  flareIssueTypes: string[] = [];
  leakStatus: CodeAndDescription[] = [];
  surveyStatus: CodeAndDescription[] = [];
  emissionsSourcesForecastMethods: { value: string; description: string }[] =
    [];
  programTypeConfigs: ProgramTypeConfig[] = [];

  constructor(private afs: AngularFirestore) {
    this.setStaticValues();
  }

  private setStaticValues() {
    const staticValues = [
      'componentTypes',
      'rfProductionProfiles',
      'rfRegulations',
      'windDirections',
    ];

    staticValues.forEach((staticValue) => {
      this.afs
        .collection('static')
        .doc(staticValue)
        .get()
        .toPromise()
        .then((docRef: any) => {
          const data: any = docRef.data() as any;
          this[staticValue] = data.data as any[];
        })
        .catch(() => {
          this[staticValue] = [];
        });
    });
  }

  async getData(documentId: string) {
    const data = (
      await lastValueFrom(this.afs.collection('static').doc(documentId).get())
    )?.data() as any;
    return data?.data || data;
  }

  async getPopulationCountValues() {
    const emissionFactors: PopulationCountEmissionFactor[] =
      (
        (
          await lastValueFrom(
            this.afs
              .collection('static')
              .doc('populationCountEmissionFactors')
              .get(),
          )
        )?.data() as any
      )?.data || [];
    const regions: Region[] =
      (
        (
          await lastValueFrom(
            this.afs.collection('static').doc('regions').get(),
          )
        )?.data() as any
      )?.data || [];
    return {
      emissionFactors,
      regions,
      states: this.states,
    };
  }
}
