import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '@iconic-air-monorepo/models';
import { finalize } from 'rxjs';
import { SignInOrRegisterModalComponent } from '../../../components/sign-in-or-register-modal/sign-in-or-register-modal.component';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent {
  customer: Customer;
  loading = false;
  email: string;
  constructor(
    private afs: AngularFirestore,
    private actRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.actRoute.params.subscribe((params) => {
      if (params.email) {
        this.email = params.email;
      }
      if (params.companyId) {
        this.loading = true;
        this.afs
          .collection('customers')
          .doc(params.companyId)
          .get()
          .pipe(
            finalize(() => {
              this.loading = false;
            }),
          )
          .subscribe((data) => {
            if (data.exists) {
              // we know this exists
              this.customer = data.data() as Customer;
            } else {
              this.router.navigate(['/login']);
            }
          });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  register() {
    this.dialog.open(SignInOrRegisterModalComponent, {
      data: {
        customers: [this.customer],
        register: true,
        redirect: '/',
        email: this.email ? this.email : '',
      },
    });
  }
}
