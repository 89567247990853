<div *ngIf="loading" class="flex w-[200px]">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<ng-container *ngIf="!loading">
  <div class="flex flex-col">
    <div class="flex items-center justify-between py-4">
      <a href="app.iconicair.io">
        <img src="/assets/logo.svg" style="width: 120px" />
      </a>
    </div>
    <!-- Sign in form -->
    <form class="mt-4" (submit)="signupUser()" [formGroup]="signupForm">
      <ng-container *ngIf="step === 0">
        <div class="text-2xl font-extrabold tracking-tight leading-tight">
          Create an account {{data?.reason ? 'to ' + data?.reason : ''}}
        </div>
        <p class="text-md mb-3 mt-2">Please enter your email address.</p>
      </ng-container>
      <ng-container *ngIf="step === 1">
        <div class="text-2xl font-extrabold tracking-tight leading-tight">
          Check email
        </div>
        <p class="text-md mb-3 mt-2">
          Please check your email for a code sent from Iconic Air.
        </p>
      </ng-container>
      <!-- Email field -->
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Email Address</mat-label>
        <input
          id="email"
          matInput
          placeholder="john.smith@iconicair.io"
          formControlName="email"
        />
        <mat-error *ngIf="signupForm.get('email').hasError('required')">
          Email address is required
        </mat-error>
        <mat-error *ngIf="signupForm.get('email').hasError('email')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <ng-container *ngIf="step === 0">
        <div
          class="flex justify-between items-center"
          [ngClass]="{'mt-4': signupForm.get('email')?.touched && signupForm.get('email')?.hasError('required') || signupForm.get('email')?.hasError('email')}"
        >
          <p class="text-md mr-6">
            Already have an account?
            <a
              class="text-md font-medium text-primary-500 hover:underline"
              href="/login?redirectUrl={{url}}"
              >Click here
            </a>
          </p>
          <a
            class="text-md font-medium text-primary-500 hover:underline"
            href="/forgot-password"
            >Forgot Password?
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="step === 1">
        <!-- Code field -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Code (emailed)</mat-label>
          <input id="code" matInput formControlName="code" #codeField />
          <mat-error> Code is required </mat-error>
        </mat-form-field>
        <!-- Display name field -->
        <mat-form-field
          class="w-full"
          appearance="outline"
          *ngIf="signupForm?.get('displayName')"
        >
          <mat-label>First and last name</mat-label>
          <input
            id="displayName"
            matInput
            formControlName="displayName"
            #displayNameField
          />
          <mat-error> Enter your first and last name </mat-error>
        </mat-form-field>
        <!-- Password field -->
        <mat-form-field
          class="w-full"
          appearance="outline"
          *ngIf="signupForm?.get('password')"
        >
          <mat-label>Password</mat-label>
          <input
            id="password"
            matInput
            type="password"
            formControlName="password"
            #passwordField
          />
          <button
            mat-icon-button
            type="button"
            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
            matSuffix
          >
            <mat-icon
              class="icon-size-5 inner-icon"
              *ngIf="passwordField.type === 'password'"
              >visibility_on</mat-icon
            >
            <mat-icon
              class="icon-size-5 inner-icon"
              *ngIf="passwordField.type === 'text'"
              >visibility_off</mat-icon
            >
          </button>

          <mat-error *ngIf="signupForm.get('password')?.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="signupForm.get('password')?.hasError('minlength')">
            Password must be at least <strong>8 characters long</strong>
          </mat-error>
          <mat-error *ngIf="signupForm.get('password')?.hasError('maxlength')">
            Password must be less than <strong>30 characters long</strong>
          </mat-error>
          <mat-error *ngIf="signupForm.get('password')?.hasError('pattern')">
            Password must have at least
            <strong
              >1 symbol, 1 capital letter, 1 number. Example: $*!A1234</strong
            >
          </mat-error>
        </mat-form-field>
      </ng-container>
      <!-- Submit button -->
      <button
        class="iconic-mat-button-large w-full mt-6"
        mat-flat-button
        [color]="'primary'"
        [disabled]="!signupForm.valid || loading"
      >
        <span *ngIf="signupForm.valid && !loading">
          {{step === 0 ? 'Start sign up': 'Complete sign up'}}
        </span>
        <mat-progress-spinner
          *ngIf="loading"
          [diameter]="24"
          [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
      </button>
    </form>
  </div>

  <mat-progress-bar
    [value]="step === 0 ? 0 : 50"
    color="primary"
  ></mat-progress-bar>
</ng-container>
