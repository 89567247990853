import { Injectable } from '@angular/core';
import { Facility, GasSample, ReportingFacility } from '@iconic-air/models';
import { ReportingPeriodService } from '../reporting-period/reporting-period.service';
import { FacilitiesDatabaseService } from '../../services-database/facilities-service/facilities-database.service';
import { ReportingFacilityDatabaseService } from '../../services-database/reporting-facilities/reporting-facility-database.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GasSampleService {
  constructor(
    private _reportingPeriodService: ReportingPeriodService,
    private _facilityDatabseService: FacilitiesDatabaseService,
    private _reportingFacilityDatabaseService: ReportingFacilityDatabaseService,
    private _http: HttpClient,
  ) {}

  async saveGasSample(
    reportingFacilityId: string,
    gasSample: GasSample,
    facilityId?: string,
  ) {
    let url = `/api/reporting-facilities/${reportingFacilityId}`;
    if (facilityId) url += `/facilities/${facilityId}`;
    url += '/gas-samples';
    return await lastValueFrom(
      this._http.post(url, {
        ...gasSample,
      }),
    );
  }

  getGasSample(
    facility: Facility,
    reportingFacility: ReportingFacility,
    reportingPeriod: string,
    year: string,
  ) {
    const startDate = this._reportingPeriodService.startOfPeriod(
      reportingPeriod,
      year,
    );
    if (!startDate) return undefined;
    // first check the facility
    if (facility.gasSamples?.length) {
      const gasSample = this.#findGasSample(startDate, facility.gasSamples);
      if (gasSample) return JSON.parse(JSON.stringify(gasSample)) as GasSample;
    }
    // if none at the facility check the reporting facility
    if (reportingFacility.gasSamples?.length) {
      const gasSample = this.#findGasSample(
        startDate,
        reportingFacility.gasSamples,
      );
      if (gasSample) return JSON.parse(JSON.stringify(gasSample)) as GasSample;
    }
    return undefined;
  }

  async getGasSampleFromIds(
    reportingFacilityId: string,
    facilityId: string,
    reportingPeriod: string,
    year: string,
  ) {
    const reportingFacility = await lastValueFrom(
      this._reportingFacilityDatabaseService.getReportingFacility(
        reportingFacilityId,
      ),
    );
    const facility = await firstValueFrom(
      await this._facilityDatabseService.getFacility(facilityId),
    );
    return await this.getGasSample(
      facility,
      reportingFacility,
      reportingPeriod,
      year,
    );
  }

  #findGasSample(startDate: Date, gasSamples: GasSample[]) {
    return gasSamples
      ?.filter((gasSample) => gasSample.effectiveDate)
      ?.sort((a, b) => {
        return (
          new Date(b.effectiveDate).getTime() -
          new Date(a.effectiveDate).getTime()
        );
      })
      .find((gasSample) => {
        return (
          new Date(gasSample.effectiveDate).getTime() <= startDate.getTime()
        );
      });
  }
}
