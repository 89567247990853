import {
  Input,
  Component,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BenchmarkService } from '../../pages/analytics/benchmark/benchmark.service';
import { StaticDataService } from '../../services/static-data/static.data.service';

@Component({
  selector: 'app-chart-horizontal-bar-progress',
  templateUrl: './chart-horizontal-bar-progress.component.html',
  styleUrls: ['./chart-horizontal-bar-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartHorizontalBarProgressComponent implements OnChanges {
  @Input() public selectedCompanies: any[] = [];
  @Input() public selectedSegments: any[] = [];
  @Input() public selectedBasins: any[] = [];
  @Input() public selectedHighLevels: any[] = [];
  @Input() public data: any[] = [];
  @Input() public selectedIntensity = '';
  @Input() public selectedYear;
  @Input() public loading = false;
  @Input() public filteredAttribute = '';
  @Input() showTooltip = true;
  public methanePages: number[] = [];
  public methaneIntensityPage = 0;
  public methaneIntensityOldPage = 0;
  public methaneIntensityPagecount = 0;
  public activeCategory = '';
  public dataSource: any[] = [];
  public total = 0;
  get basins() {
    return this.staticData.basins;
  }
  get industrySegments() {
    return this.staticData.industrySegments;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private benchmarkService: BenchmarkService,
    private staticData: StaticDataService,
    private sanitizer: DomSanitizer,
  ) {}

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (!changes.loading?.firstChange && changes.loading) ||
      changes.data?.firstChange
    ) {
      this.getMethaneIntensity();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  public setActive(label: string) {
    // don't reset until it's finished
    if (this.activeCategory && label) {
      return;
    }
    this.activeCategory = label;

    this.getMethaneIntensity();
    this.cdRef.detectChanges();
  }

  public getCommaNumber(n: number) {
    return parseFloat(n.toFixed(3)).toLocaleString();
  }

  public getTooltipData = (data?: any) => {
    const ids: string[] = [];
    if (this.filteredAttribute === 'basins') {
      const basin = this.basins.find((bb) => {
        return (
          bb.basinFullName.replace(/ *\([^)]*\) */g, '') ===
          data.replace(/ *\([^)]*\) */g, '')
        );
      });

      if (basin) {
        ids.push(basin.id);
      }
    }
    if (this.filteredAttribute === 'segments') {
      const segment = this.industrySegments.find((seg) => {
        return seg.longName === data;
      });

      if (segment) {
        ids.push(segment.id);
      }
    }

    const feed = this.benchmarkService.dataFaster(
      this.selectedIntensity,
      [this.selectedYear],
      this.selectedYear,
      this.basins,
      this.filteredAttribute === 'basins' ? ids : this.selectedBasins,
      false,
      this.selectedCompanies,
      this.selectedCompanies,
      false,
      this.industrySegments,
      this.filteredAttribute === 'segments'
        ? ids
        : this.selectedSegments.map((a) => a.id),
      false,
      this.filteredAttribute === 'source'
        ? [this.activeCategory ? this.activeCategory : data]
        : this.selectedHighLevels,
      false,
      this.staticData.emissionSourcesLabels,
      this.activeCategory ? data : '',
      false,
      true,
    );

    let tooltipLabel = '';
    // chart is emissions and is now checking for if it's set to CO2e or any other emissions category.
    if (this.selectedIntensity === 'CO2e (AR5)') {
      tooltipLabel = 'mT CO2e';
    } else {
      tooltipLabel = 'mT';
    }

    let html = '<table class="w-full">';
    html += '<thead>';
    html += '<tr><th class="text-left"><b>' + data + '</ b></th></tr>';
    html += '</thead><tbody>';

    let co2 = 0;
    let ch4 = 0;
    let n20 = 0;
    let total = 0;

    feed.companyData.forEach((company) => {
      co2 += company.value.co2;
      ch4 += company.value.ch4;
      n20 += company.value.n20;
      total +=
        company.value.co2 + company.value.ch4 * 28 + company.value.n20 * 265;
    });

    if (this.selectedIntensity === 'CO2e (AR5)') {
      const span = '<span></span>';
      html +=
        '<tr><td class="text-md">' +
        span +
        parseFloat(total.toFixed(3)).toLocaleString() +
        ' ' +
        tooltipLabel +
        '</td></tr>';
      html += '<tr class="py-2 block"><td></td></tr>';
    }
    if (this.selectedIntensity === 'CO2e (AR5)') {
      co2 = co2 * 1;
      ch4 = ch4 * 28;
      n20 = n20 * 265;
    }

    const co2span =
      '<span class="flex-auto mr-2">CO2:</span><span>' +
      parseFloat(co2.toFixed(3)).toLocaleString() +
      ' ' +
      tooltipLabel +
      '</span>';

    html += '<tr><td class="flex text-md">' + co2span + '</td></tr>';

    const ch4span =
      '<span class="flex-auto mr-2">CH4:</span><span>' +
      parseFloat(ch4.toFixed(3)).toLocaleString() +
      ' ' +
      tooltipLabel +
      '</span>';

    html += '<tr><td class="flex text-md">' + ch4span + '</td></tr>';

    const n20span =
      '<span class="flex-auto mr-2">N20:</span><span>' +
      parseFloat(n20.toFixed(3)).toLocaleString() +
      ' ' +
      tooltipLabel +
      '</span>';

    html += '<tr><td class="flex text-md">' + n20span + '</td></tr>';

    html += '</tbody>';
    html += '</table>';
    return this.sanitizer.bypassSecurityTrustHtml(html);
  };

  private getMethaneIntensity(dontResetPage?: boolean) {
    const dataSource: any[] = [];
    let total = 0;

    if (this.data && this.data.length) {
      // checks if it's emissions sources data that has highLevel etc.
      if (this.data[0].highLevel) {
        this.data.forEach((metric) => {
          if (this.activeCategory) {
            if (this.activeCategory === metric.highLevel) {
              const existing = dataSource.findIndex((pd) => {
                return pd.label === metric.sourceType;
              });

              if (existing === -1) {
                dataSource.push({
                  label: metric.sourceType,
                  value: metric.value,
                });
              } else {
                // add to highlevel;
                dataSource[existing].value += metric.value;
              }

              total += metric.value;
            }
          } else {
            const existing = dataSource.findIndex((pd) => {
              return pd.label === metric.highLevel;
            });

            if (existing === -1) {
              dataSource.push({
                label: metric.highLevel ? metric.highLevel : 'Other',
                value: metric.value,
              });
            } else {
              // add to highlevel;
              dataSource[existing].hasMore = true;
              dataSource[existing].value += metric.value;
            }

            total += metric.value;
          }
        });
      } // else statement is for the type of data that has no key value pairsa and is just an array, i.e. industry segment or basin
      else {
        this.data.forEach((dd) => {
          if (this.activeCategory) {
            if (this.activeCategory === dd.label) {
              const existing = dataSource.findIndex((pd) => {
                return pd.label === dd.label;
              });

              if (existing === -1) {
                dataSource.push({
                  label: dd.label,
                  value: dd.value,
                });
              } else {
                // add to highlevel;
                dataSource[existing].value += dd.value;
              }

              total += dd.value;
            }
          } else {
            const existing = dataSource.findIndex((pd) => {
              return pd.label === dd.label;
            });

            if (existing === -1) {
              dataSource.push({
                /* label: metric.label ? metric.highLevel : 'Other', */
                label: dd.label,
                value: dd.value,
              });
            } else {
              // add to highlevel;
              dataSource[existing].value += dd.value;
            }

            total += dd.value;
          }
        });
      }
    }

    dataSource.sort((a, b) => {
      // Compare the 2 dates
      if (a.value > b.value) return -1;
      if (a.value < b.value) return 1;
      return 0;
    });

    this.total = total;

    if (this.activeCategory) {
      this.dataSource = dataSource;
    } else {
      this.dataSource = dataSource;
    }
  }
}
