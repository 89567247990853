<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <ng-contianer *ngIf="data?.message?.length">
      <ng-container *ngFor="let msg of data.message">
        <p>{{msg}}</p>
      </ng-container>
    </ng-contianer>
    <div *ngIf="!data?.successful" class="go-to-components">
      <p>Quick Navigation</p>
      <a (click)="gotoFacilities()">
        <span>View All Facilities</span> <mat-icon>launch</mat-icon>
      </a>
    </div>
  </div>
  <div mat-dialog-actions class="b-t dialog-actions">
    <button mat-button color="warn" mat-dialog-close *ngIf="!data?.successful">
      <mat-icon>close</mat-icon> Close
    </button>
    <button mat-button color="warn" (click)="gotoFacilities()" *ngIf="data?.successful">
      <mat-icon>close</mat-icon> Close
    </button>
  </div>
</div>
