<div id="sign-in" class="flex flex-col sm:flex-row flex-auto min-w-0">
  <ng-container *ngIf="!registrationCompleted">
    <div
      class="bg-white min-w-1/2 flex-auto md:flex md:items-center md:justify-center sm:w-auto md:h-full py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none"
    >
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-48">
          <a href="https://iconicair.io">
            <img src="/assets/logo.svg" />
          </a>
        </div>

        <ng-container *ngIf="!register; else showRegisterForm">
          <!-- Title -->
          <div
            class="mt-8 text-4xl font-extrabold tracking-tight leading-tight"
          >
            Sign in
          </div>
          <!-- <div class="flex items-baseline mt-0.5 font-medium">
            <div>Don't have an account?</div>
            <a
              class="ml-1 text-primary-500 hover:underline"
              (click)="register = true"
              >Sign up
            </a>
          </div> -->

          <!-- Sign in form -->
          <form class="mt-8" (submit)="userLogin()" [formGroup]="signInForm">
            <!-- Email field -->
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Email Address</mat-label>
              <input
                id="email"
                matInput
                placeholder="john.smith@iconicair.io"
                formControlName="email"
              />
              <mat-error *ngIf="signInForm.get('email').hasError('required')">
                Email address is required
              </mat-error>
              <mat-error *ngIf="signInForm.get('email').hasError('email')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Password</mat-label>
              <input
                id="password"
                matInput
                type="password"
                formControlName="password"
                #passwordField
              />
              <button
                mat-icon-button
                type="button"
                (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                matSuffix
              >
                <mat-icon
                  class="icon-size-5 inner-icon"
                  *ngIf="passwordField.type === 'password'"
                  >visibility_on</mat-icon
                >
                <mat-icon
                  class="icon-size-5 inner-icon"
                  *ngIf="passwordField.type === 'text'"
                  >visibility_off</mat-icon
                >
              </button>
              <mat-error> Password is required </mat-error>
            </mat-form-field>
            <div class="flex items-center justify-between w-full mt-1.5">
              <!-- Remember Me -->
              <mat-checkbox formControlName="rememberMe">
                Remember Me
              </mat-checkbox>
              <a
                class="text-md font-medium text-primary-500 hover:underline"
                (click)="forgot()"
                >Forgot password?
              </a>
            </div>

            <!-- Submit button -->
            <button
              class="iconic-mat-button-large w-full mt-6"
              mat-flat-button
              [color]="'primary'"
              [disabled]="!signInForm.valid || loading"
              (click)="userLogin()"
            >
              <span *ngIf="signInForm.valid && !loading"> Sign in </span>
              <mat-progress-spinner
                *ngIf="loading"
                [diameter]="24"
                [mode]="'indeterminate'"
              >
              </mat-progress-spinner>
            </button>
          </form>
        </ng-container>
        <ng-template #showRegisterForm>
          <!-- Title -->
          <div
            class="mt-8 text-4xl font-extrabold tracking-tight leading-tight"
          >
            Sign up
          </div>
          <div class="flex items-baseline mt-0.5 font-medium">
            <div>Already have an account?</div>
            <a
              class="ml-1 text-primary-500 hover:underline"
              (click)="register = false"
              >Sign in
            </a>
          </div>

          <!-- Sign in form -->
          <form class="mt-8" (submit)="userLogin()" [formGroup]="signInForm">
            <!-- Email field -->
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Email Address</mat-label>
              <input
                id="email"
                matInput
                placeholder="john.smith@iconicair.io"
                formControlName="email"
              />
              <mat-error *ngIf="signInForm.get('email').hasError('required')">
                Email address is required
              </mat-error>
              <mat-error *ngIf="signInForm.get('email').hasError('email')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Password</mat-label>
              <input
                id="password"
                matInput
                type="password"
                formControlName="password"
                #passwordField
              />
              <button
                mat-icon-button
                type="button"
                (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                matSuffix
              >
                <mat-icon
                  class="icon-size-5 inner-icon"
                  *ngIf="passwordField.type === 'password'"
                  >visibility_on</mat-icon
                >
                <mat-icon
                  class="icon-size-5 inner-icon"
                  *ngIf="passwordField.type === 'text'"
                  >visibility_off</mat-icon
                >
              </button>
              <mat-error> Password is required </mat-error>
            </mat-form-field>
            <div class="inline-flex items-end justify-between w-full mt-1.5">
              <!-- Remember Me -->
              <mat-checkbox formControlName="rememberMe">
                Remember Me
              </mat-checkbox>
              <a
                class="text-md font-medium text-primary-500 hover:underline"
                (click)="forgot()"
                >Forgot password?
              </a>
            </div>

            <!-- Submit button -->
            <button
              class="iconic-mat-button-large w-full mt-6"
              mat-flat-button
              [color]="'primary'"
              [disabled]="!signInForm.valid || loading"
              (click)="userLogin()"
            >
              <span *ngIf="signInForm.valid && !loading"> Create Account </span>
              <mat-progress-spinner
                *ngIf="loading"
                [diameter]="24"
                [mode]="'indeterminate'"
              >
              </mat-progress-spinner>
            </button>
          </form>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="registrationCompleted">
    <div
      class="bg-white min-w-1/2 flex-auto md:flex md:items-center md:justify-center sm:w-auto md:h-full py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none"
    >
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-48">
          <a href="https://iconicair.io">
            <img src="/assets/test3-logo.png" />
          </a>
        </div>

        <ng-container *ngIf="!companyExists; else showCompanySelection">
          <!-- Title -->
          <div
            class="mt-8 text-4xl font-extrabold tracking-tight leading-tight"
          >
            Create your company account
          </div>
          <div class="flex items-baseline mt-0.5 font-medium">
            <div>Is your company already an Iconic Air customer.</div>
            <a
              class="ml-1 text-primary-500 hover:underline"
              (click)="companyExists = true"
              >Select your company
            </a>
          </div>

          <!-- Sign in form -->
          <form
            class="mt-8"
            (submit)="createCompany()"
            [formGroup]="companyForm"
          >
            <!-- Email field -->
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Company Name</mat-label>
              <input
                id="email"
                matInput
                placeholder="Iconic Air"
                formControlName="name"
              />
              <mat-error *ngIf="companyForm.get('name').hasError('required')">
                Company name is required
              </mat-error>
            </mat-form-field>

            <!-- Submit button -->
            <button
              class="iconic-mat-button-large w-full mt-6"
              mat-flat-button
              [color]="'primary'"
              [disabled]="!companyForm.valid || loading"
              (click)="createCompany()"
            >
              <span *ngIf="companyForm.valid && !loading">
                Create Company
              </span>
              <mat-progress-spinner
                *ngIf="loading"
                [diameter]="24"
                [mode]="'indeterminate'"
              >
              </mat-progress-spinner>
            </button>
          </form>
        </ng-container>
        <ng-template #showCompanySelection>
          <!-- Title -->
          <div
            class="mt-8 text-4xl font-extrabold tracking-tight leading-tight"
          >
            Select Company
          </div>
          <div class="flex items-baseline mt-0.5 font-medium">
            <div>Company isn't listed?</div>
            <a
              class="ml-1 text-primary-500 hover:underline"
              (click)="companyExists = false"
              >Create
            </a>
          </div>
          <div class="h-60 flex flex-col">
            <div
              class="flex items-center justify-center"
              *ngIf="loadingCompanies && !data.companies.length"
            >
              <mat-spinner></mat-spinner>
            </div>
            <ng-container *ngIf="!loadingCompanies || data.companies.length">
              <div class="">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Company</mat-label>
                  <input
                    type="text"
                    matInput
                    [(ngModel)]="companyFilter"
                    placeholder="Iconic Air"
                  />
                </mat-form-field>
              </div>
              <div class="-mt-1 overflow-y-auto flex-auto">
                <mat-list>
                  <mat-list-item
                    *ngFor="let company of companies | filterCustomerSelection:companyFilter"
                  >
                    <div class="flex flex-auto items-center">
                      {{ company.name | titlecase }}
                    </div>
                    <div class="flex ml-auto items-center">
                      <button
                        color="primary"
                        mat-button
                        (click)="selectCompany(company)"
                      >
                        Select
                      </button>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <div
    class="bg-primary hidden relative overflow-hidden flex-auto md:flex md:items-center md:justify-center sm:w-auto py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none"
  >
    <!-- Background - @formatter:off -->
    <!-- Rings -->
    <svg
      class="absolute inset-0 pointer-events-none"
      viewBox="0 0 960 540"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMax slice"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        class="text-white opacity-25"
        fill="none"
        stroke="currentColor"
        stroke-width="100"
      >
        <circle r="234" cx="196" cy="23"></circle>
        <circle r="234" cx="790" cy="491"></circle>
      </g>
    </svg>
    <!-- Dots -->
    <svg
      class="absolute -top-16 -right-16 text-gray-100"
      viewBox="0 0 220 192"
      width="220"
      height="192"
      fill="none"
    >
      <defs>
        <pattern
          id="837c3e70-6c3a-44e6-8854-cc48c737b659"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect
        width="220"
        height="192"
        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
      ></rect>
    </svg>
    <!-- @formatter:on -->
    <!-- Content -->
    <div class="z-10 relative w-full max-w-2xl">
      <div class="text-7xl font-bold leading-none text-gray-100">
        <div>Accelerating your</div>
        <div>path to net-zero.</div>
      </div>
      <div class="mt-6 text-lg tracking-tight leading-6 text-gray-100">
        Helping the world's largest-emitting industries decarbonize faster with
        our leading sustainability tools.
      </div>
    </div>
  </div>
</div>
