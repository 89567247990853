import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ThemeModule } from './../theme/theme.module';
import { MaterialModule } from '../theme/material.module';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { Route } from './shell.service';
import { ShellComponent } from './shell/shell.component';
import { UserSnackbarModule } from '../components/user-snackbar/user-snackbar.module';
import { ReleaseDialogModule } from '../components/release-dialog/release-dialog.module';
import { MarkdownModule } from 'ngx-markdown'

@NgModule({
  declarations: [MenuComponent, FooterComponent, ShellComponent],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    RouterModule,
    ThemeModule,
    UserSnackbarModule,
    ReleaseDialogModule,
    DragDropModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: 'MarkedOptions',
        useValue: {
          gfm: true,
          breaks: true,
          pedantic: false,
          smartLists: true,
          smartypants: false,
        },
      },
    })
  ],
  providers: [Route],
})
export class ShellModule {}
