<!-- Logo -->
<div class="w-48">
  <img src="/assets/logo.svg" />
</div>

<div class="flex flex-col">
  <!-- Title -->
  <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
    Authentication Required
  </div>
  <!-- Directions -->
  <div class="flex items-baseline mt-0.5 font-medium">
    Your password is required to complete this action.
  </div>
  <div class="flex flex-col items-center justify-between w-full mt-1.5">
    <!-- Enter Password -->
    <mat-form-field appearance="outline" class="mt-4 self-center">
      <mat-label>Password</mat-label>
      <input
        name="passwordField"
        #passwordField="ngModel"
        [(ngModel)]="password"
        matInput
        [type]="showPassword ? 'text' : 'password'"
        required
      />

      <mat-icon
        matSuffix
        (click)="this.showPassword = !this.showPassword;"
        class="icon-size-5 inner-icon"
        >{{showPassword ? 'visibility_on' : 'visibility_off'}}</mat-icon
      >

      <mat-error *ngIf="passwordField.errors?.['required']"
        >A password is required.</mat-error
      >
    </mat-form-field>
    <!-- Forgot Password -->
    <a
      class="text-md font-medium text-primary-500 hover:underline mt-1.5"
      (click)="forgotPassword()"
      >Forgot password? Send a password reset e-mail.
    </a>
    <div
      class="mr-4 mt-2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
      *ngIf="status.length > 0"
    >
      {{status}}
    </div>
  </div>

  <button
    class="mt-6 align-center"
    mat-flat-button
    [color]="'primary'"
    [disabled]="!passwordField.valid"
    (click)="sendPassword(password)"
  >
    <div *ngIf="passwordField.valid">Authenticate</div>
  </button>
</div>
