import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ObservableService } from '../../../services/observable-service/observable.service';

@Component({
  selector: 'app-bulk-upload-checkbox-column',
  templateUrl: './checkbox-column.component.html',
  styleUrls: ['./checkbox-column.component.scss'],
})
export class CheckboxColumnComponent {
  public allowDelete = true;
  public params;
  constructor(private observableService: ObservableService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  checkedHandler(event) {
    const checked = event.target.checked;
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }
}
