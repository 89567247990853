<h1 mat-dialog-title class="m-2">Validation Results</h1>
<div
  class="mr-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
  role="alert"
>
  <strong class="font-bold">Data validation failed. </strong>
  <span class="block sm:inline"
    >Please fix the following {{validationErrors.length}}
    error{{(validationErrors.length > 1) ? 's': ''}} and upload the file
    again.</span
  >
</div>

<div mat-dialog-content class="m-4" #validationErrorsText>
  <ul>
    <li class="mt-2 text-base" *ngFor="let validationError of validationErrors">
      {{getErrorMessage(validationError) | emission}}
    </li>
  </ul>
</div>
<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    mat-dialog-close
    (click)="copyToClipboard(validationErrorsText)"
  >
    Copy Validation Results
  </button>
  <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
