import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-multi-factor-auth-dialog',
  templateUrl: './multi-factor-auth-dialog.component.html',
  styleUrls: ['./multi-factor-auth-dialog.component.scss'],
})
export class MultiFactorAuthDialogComponent {
  selectedMFA: any;
  loading = false;
  verificationId: string;
  showSMSInput = false;
  verificationCode = '';
  resolver: firebase.auth.MultiFactorResolver;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resolver: firebase.auth.MultiFactorResolver;
      targetUrlFragment: string;
    },
    public dialogRef: MatDialogRef<MultiFactorAuthDialogComponent>,
  ) {
    this.resolver = data.resolver;
  }

  getSMSVerification() {
    this.loading = true;
    // select phone number
    const selectedIndex = this.resolver.hints.indexOf(this.selectedMFA);
    // TODO: if less than one factor present, do not ask them to choose...

    if (
      this.resolver.hints[selectedIndex].factorId ===
      firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
    ) {
      const phoneInfoOptions: firebase.auth.PhoneInfoOptions = {
        multiFactorHint: this.resolver.hints[selectedIndex],
        session: this.resolver.session,
      };

      const recaptchaVerifier: firebase.auth.RecaptchaVerifier =
        new firebase.auth.RecaptchaVerifier('mf-auth-recaptcha-container', {
          size: 'invisible',
        });

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      // Send SMS verification code
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .then((verificationId) => {
          this.verificationId = verificationId;
          this.showSMSInput = true;
          this.loading = false;
        });
    } else {
      // Unsupported second factor. Only SMS 2FA is currently supported.
      throw 'Multi-Factor Authentication type is not supported';
    }
  }

  sendSMSVerification() {
    this.loading = true;
    const cred = firebase.auth.PhoneAuthProvider.credential(
      this.verificationId,
      this.verificationCode,
    );
    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    // Complete sign-in.
    this.resolver
      .resolveSignIn(multiFactorAssertion)
      .catch((error) => {
        if (error.code === 'auth/invalid-verification-code') {
          this.dialogRef.close();
          alert('A valid verification code is required. Please try again.');
        } else {
          throw error;
        }
      })
      .then((userCredential) => {
        setTimeout(() => {
          this.loading = false;
          this.dialogRef.close(userCredential);
        }, 500);
      });
  }
}
