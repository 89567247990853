<!-- Logo -->
<div class="w-48">
  <img src="/assets/test3-logo.png" />
</div>

<div [hidden]="showSMSInput">
  <div class="flex flex-col">
    <!-- Title -->
    <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
      Add SMS Authentication
    </div>
    <!-- Directions -->
    <div class="flex items-baseline mt-0.5 font-medium">
      Enter a phone number, and optionally, add a short description.
    </div>
    <mat-form-field appearance="outline" class="mt-4 self-center">
      <mat-label>Phone Number</mat-label>
      <input
        name="phoneNumberField"
        #phoneNumberField="ngModel"
        [(ngModel)]="phoneNumber"
        matInput
        type="text"
        required
        (change)="formatPhoneNumber()"
        (keypress)="onlyPhoneNumbers($event)"
        minlength="10"
      />

      <mat-error *ngIf="phoneNumberField.errors?.['minlength']"
        >Ten digits are required.</mat-error
      >
      <mat-error *ngIf="phoneNumberField.errors?.['required']"
        >A phone number is required.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="mt-4 self-center">
      <mat-label>Description (optional)</mat-label>
      <input
        name="descriptionField"
        #descriptionField="ngModel"
        [(ngModel)]="displayName"
        matInput
        type="text"
      />
    </mat-form-field>

    <button
      class="mt-6 align-center"
      mat-flat-button
      [color]="'primary'"
      [disabled]="!phoneNumberField.valid || !descriptionField.valid"
      (click)="registerPhoneNumber()"
    >
      <div *ngIf="phoneNumberField.valid">Add phone number</div>
    </button>
  </div>
</div>
<div [hidden]="!showSMSInput">
  <div class="flex flex-col">
    <!-- Title -->
    <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
      Enter verification code
    </div>
    <!-- Directions -->
    <div class="flex items-baseline mt-0.5 font-medium">
      An SMS text message has been sent to your phone. Please enter the
      verification code to continue.
    </div>
    <mat-form-field appearance="outline" class="mt-4 self-center">
      <mat-label>Verification Code</mat-label>
      <input
        name="verificationCodeForm"
        #verificationCodeForm="ngModel"
        [(ngModel)]="verificationCode"
        matInput
        type="text"
        required
        placeholder="Verification Code"
      />
      <mat-error *ngIf="verificationCodeForm.errors?.['required']"
        >A verification code is required.</mat-error
      >
    </mat-form-field>

    <button
      class="mt-6 align-center"
      mat-flat-button
      [color]="'primary'"
      [disabled]="!verificationCodeForm.valid || loading"
      (click)="sendVerificationCode()"
    >
      <div *ngIf="verificationCodeForm.valid">Verify</div>
      <mat-progress-spinner
        *ngIf="loading"
        [diameter]="24"
        [mode]="'indeterminate'"
      >
      </mat-progress-spinner>
    </button>
  </div>
</div>
<div id="sms-recaptcha-container"></div>
