import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@iconic-air/models';

@Pipe({
  name: 'clientsFilter',
})
export class ClientsFilterPipe implements PipeTransform {
  transform(clients: Company[], categorys: string[]) {
    return clients.filter((client) => categorys.includes(client.id as string));
  }
}
