<div
  class="flex items-center mr-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
  role="alert"
>
  <mat-icon>warning</mat-icon>
  <div class="ml-2">
    <strong class="font-bold">
      Don't leave this page or {{action}} will be interrupted
    </strong>
  </div>
</div>
<h2 class="m-2">{{message}}</h2>
<mat-progress-bar
  class="m-2"
  mode="determinate"
  [value]="progress"
></mat-progress-bar>
<div class="m-2">Time Elapsed: {{displayTime}}</div>
