<div *ngIf="loading" class="flex w-[200px]">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<app-validation-report-dialog
  *ngIf="errors?.length"
  [nonModal]="true"
  [nonModalData]="errors"
>
</app-validation-report-dialog>
<ng-container *ngIf="!errors?.length && !loading">
  <div class="items-center justify-between my-2 flex">
    <div class="title-large">{{docData?.progressMessage}}</div>
    <mat-icon
      *ngIf="docData?.fileUrl"
      (click)="download()"
      color="primary"
      class="cursor-pointer"
      >download</mat-icon
    >
  </div>

  <div class="my-2 text-large" *ngIf="errors">{{errors?.message}}</div>

  <div class="mt-4" *ngIf="docData?.validated && !docData?.completed">
    <div class="title-medium">Added or modified (to save)</div>
    <div class="flex">
      <ng-container
        *ngTemplateOutlet="card;context:{title: 'Records', value: unfiltedTotals?.records}"
      ></ng-container>
      <ng-container *ngFor="let key of dataFields">
        <ng-container
          *ngTemplateOutlet="card;context:{title: dataFieldLabels[key], value: unfiltedTotals[key]}"
        ></ng-container>
      </ng-container>
    </div>
    <div class="title-small">Card Filters (Will not impact your save)</div>
    <mat-slide-toggle [(ngModel)]="onlyAddModified" class="label-medium"
      >Only added/modified records</mat-slide-toggle
    >
    <div class="mt-2">
      <mat-form-field appearance="outline">
        <mat-label>Filters</mat-label>
        <mat-select [(ngModel)]="filters" [multiple]="true">
          <mat-select-trigger class="label-large">
            <span class="text-primary">Filters: </span>
            {{filters?.length === filtersFullList?.length ? 'All' :
            filters.length + ' Selected'}}
          </mat-select-trigger>
          <ng-container *ngFor="let filter of filtersFullList">
            <mat-option [value]="filter.value">
              <div class="label-large">{{filter.display}}</div>
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <button mat-button class="ml-2" (click)="toggleFilters()">
        <div class="label-large">
          {{(filters?.length)?'Deselect all' : 'Select all'}}
        </div>
      </button>
      <div class="title-medium">All records (with filters applied)</div>
      <div class="flex">
        <ng-container
          *ngTemplateOutlet="card;context:{title: 'Records', value: totals.records}"
        ></ng-container>
        <ng-container *ngFor="let key of dataFields">
          <ng-container
            *ngTemplateOutlet="card;context:{title: dataFieldLabels[key], value: totals[key]}"
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div
    class="flex flex-auto items-center justify-center"
    *ngIf="docData?.completed"
  >
    <ng-container
      *ngTemplateOutlet="card;context:{title: 'Created', value: docData?.saved?.inserted || 0}"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="card;context:{title: 'Updated', value: docData?.saved?.updated || 0}"
    ></ng-container>
  </div>

  <mat-progress-bar
    *ngIf="!docData?.hideProgress"
    [value]="docData?.progress"
    [color]="docData?.progress < 100 ? 'accent' : 'primary'"
  ></mat-progress-bar>

  <div class="my-2 flex justify-end">
    <button
      mat-button
      color="primary"
      *ngIf="docData?.progress === 100 && !docData?.completed && docData?.validated"
      [disabled]="disableSave"
      (click)="save()"
    >
      Save
    </button>
    <button mat-button color="primary" (click)="closeModal()">Close</button>
  </div>
</ng-container>

<ng-template
  #card
  let-title="title"
  let-value="value"
  let-includeUnits="includeUnits"
>
  <div class="flex flex-col justify-between w-full p-6">
    <div>
      <div class="flex items-center">
        <div class="label-large pr-2">{{title | emission}}</div>
      </div>
    </div>
    <div>
      <ng-container>
        <div class="flex items-start mt-2">
          <div class="label-large tracking-tight leading-none">
            {{value || 0 | number: (value < 0.01)? '1.0-5' : '1.0-2' : 'en'}}
            {{includeUnits? 'mT' : ''}}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
