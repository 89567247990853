import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iconic-air-monorepo-checkbox-column',
  templateUrl: './checkbox-column.component.html',
  styleUrls: ['./checkbox-column.component.scss']
})
export class CheckboxColumnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
