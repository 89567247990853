import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-datepicker-editor',
  templateUrl: './grid-datepicker-editor.component.html',
  styleUrls: ['./grid-datepicker-editor.component.scss']
})
export class GridDatepickerEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {
  public inputDate: Date;
  private params: any;
  @ViewChild('input', { static: false }) input: ElementRef;
  private focusedInput: number = 0;

  agInit(params: any): void {
    this.params = params;
    this.inputDate = new Date(this.params.value);
  }

  ngAfterViewInit() {
    this.focusOnInputNextTick(this.input);
  }

  private focusOnInputNextTick(input: ElementRef) {
    window.setTimeout(() => {
      input.nativeElement.focus();
    }, 0);
  }

  getValue() {
    return this.inputDate.getTime();
  }

  isPopup(): boolean {
    return true;
  }

  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (key == 9) {
      this.preventDefaultAndPropagation(event);
      this.focusOnInputNextTick(this.input);
    }
  }

  private preventDefaultAndPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
