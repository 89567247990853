import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/compat/app';
import { User } from '@iconic-air-monorepo/models';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  getAuth,
  signInWithRedirect,
  OAuthProvider,
  getRedirectResult,
  linkWithPopup,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import { Router } from '@angular/router';
import { from, lastValueFrom, Observable, Subscription } from 'rxjs';
import firebase from 'firebase/compat/app';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { MultiFactorAuthDialogComponent } from '../../components/multi-factor-auth-dialog/multi-factor-auth-dialog.component';
import { ReauthenticateDialogComponent } from '../../components/reauthenticate-dialog/reauthenticate-dialog.component';
import { SmsAuthDialogComponent } from '../../components/sms-auth-dialog/sms-auth-dialog.component';
import { CustomerDataService } from '../customer-data/customer-data.service';
import { BasicDialogComponent } from '../../components/basic-dialog/basic-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationWebService {
  userData: any; // this is the data coming from firebase auth not the firestore user document
  customerCode: string;
  userDoc: AngularFirestoreDocument<any[]>;
  userFirestore$: Observable<any>;
  userAuth: Observable<User>;
  userUid: string | undefined;
  user$: Subscription;
  userDetails$: Subscription;
  dash$: Subscription;
  rfacs$: Subscription;
  userClientUid: string;
  idToken: string | undefined = '';
  tokenExpiration: number;

  public dialogRef: MatDialogRef<unknown>;

  constructor(
    private customerData: CustomerDataService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public dialog: MatDialog,
  ) {}

  // persist login state in new browser tabs or after window is closed
  rememberUser(persistence: boolean): Promise<void> {
    return this.afAuth.setPersistence(
      persistence
        ? auth.default.auth.Auth.Persistence.LOCAL
        : auth.default.auth.Auth.Persistence.SESSION,
    );
  }

  async signInSSO(
    rememberUser: boolean = false,
    providerId: string = 'microsoft.com',
  ): Promise<void> {
    return this.rememberUser(rememberUser).then(async () => {
      const provider = new OAuthProvider(providerId);
      const auth = getAuth(this.afs.firestore.app);
      await signInWithRedirect(auth, provider);
    });
  }

  async signInSSORedirect() {
    // After returning from the redirect when your app initializes you can obtain the result
    const auth = getAuth(this.afs.firestore.app);
    try {
      const result = await getRedirectResult(auth);
      // User is signed in.
      // IdP data available in result.additionalUserInfo.profile.
      // Get the OAuth access token and ID Token
      if (result) {
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential?.accessToken;
        const idToken = credential?.idToken;

        await this.ngZone.run(async () => {
          await this.router.navigate(['/']).catch((e) => {
            throw e;
          });
        });
      }
    } catch (error) {
      // An error happened. Handle error.
      if (error.code === 'auth/account-exists-with-different-credential') {
        // Step 2.
        // User's email already exists.

        // The pending Microsoft credential.
        const pendingCred = OAuthProvider.credentialFromError(error);

        // The provider account's email address.
        const email = error.customData.email;
        // Asks the user their password.
        const password = await this.getPassword(email);
        // Get sign-in methods for this email.
        const methods = await fetchSignInMethodsForEmail(auth, email);

        // Step 3.
        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        if (methods[0] === 'password') {
          if (password?.length > 0) {
            this.afAuth
              .signInWithEmailAndPassword(email, password)
              .then((result) => {
                // Step 4a.
                if (pendingCred)
                  return result?.user?.linkWithCredential(pendingCred);
              })
              .then(async () => {
                // Microsoft account successfully linked to the existing Firebase user.
                // go to dashboard after login
                await this.ngZone.run(async () => {
                  await this.router.navigate(['dashboard']).catch((e) => {
                    throw e;
                  });
                });
              });
          } else {
            throw 'Password not entered.';
          }
        }
      } else if (
        error?.message?.includes('auth/internal-error') &&
        error?.message?.includes('FAILED_PRECONDITION')
      ) {
        this.dialog.open(BasicDialogComponent, {
          width: '450px',
          data: {
            title: 'Microsoft sign in disabled',
            text:
              'Microsoft sign-in is currently unavailable for your user. Please use another ' +
              'login method, such as Email Address and Password login, or contact your administrator to get this feature.',
            button1: 'Close',
          },
          panelClass: 'dialog-class',
        });
      } else {
        throw error;
      }
    }
  }

  addSSOToEmail(providerId: string = 'microsoft.com') {
    const provider = new OAuthProvider(providerId);
    const auth = getAuth();

    if (auth.currentUser) {
      linkWithPopup(auth.currentUser, provider);
      // .then((result) => {
      //   // Microsoft credential is linked to the current user.
      //   // IdP data available in result.additionalUserInfo.profile.

      //   // Get the OAuth access token and ID Token
      //   const credential = OAuthProvider.credentialFromResult(result);
      //   const accessToken = credential.accessToken;
      //   const idToken = credential.idToken;

      // })
    }
  }
  /**
   * @description
   * Dialogs for user multi-factor authentication
   *
   * @param resolver firebase.auth.MultiFactorResolver
   *
   * @returns A Promise that resolves to firebase.auth.UserCredential when successful
   */
  private async multiFactorAuth(
    resolver: firebase.auth.MultiFactorResolver,
  ): Promise<firebase.auth.UserCredential> {
    this.dialogRef = this.dialog.open(MultiFactorAuthDialogComponent, {
      autoFocus: false,
      data: { resolver },
      maxWidth: 450,
    });
    return await lastValueFrom(this.dialogRef.afterClosed());
  }

  /**
   * @description
   * Sign in with email, password, and set the login's persistence.
   *
   * @param email The users email address.
   * @param password The users password.
   * @param rememeberMe If enabled, the user will remain signed in after closing tab or browser window.
   *
   * @returns A Promise that resolves to firebase.auth.UserCredential when successful
   */
  signIn(
    email: string,
    password: string,
    rememberMe: boolean,
  ): Promise<firebase.auth.UserCredential | void> {
    return this.afAuth
      .setPersistence(
        rememberMe
          ? auth.default.auth.Auth.Persistence.LOCAL
          : auth.default.auth.Auth.Persistence.SESSION,
      )
      .then(() => {
        return this.afAuth
          .signInWithEmailAndPassword(email, password)
          .then((userCredential: any) => {
            // User is not enrolled with a second factor and is successfully signed in
            return userCredential;
          })
          .catch((error) => {
            if (error.code == 'auth/multi-factor-auth-required') {
              return this.multiFactorAuth(error.resolver);
            } else if (error.code == 'auth/wrong-password') {
              // Handle other errors such as wrong password.
              throw error;
            } else if (
              error?.message?.includes('auth/internal-error') &&
              error?.message?.includes('FAILED_PRECONDITION') &&
              !error?.message?.includes('trial period')
            ) {
              this.dialog.open(BasicDialogComponent, {
                width: '450px',
                data: {
                  title: 'Email sign in disabled',
                  text:
                    'Email sign-in is currently unavailable for your user. Please use another ' +
                    'login method, such as Microsoft login, or contact your administrator to get this feature.',
                  button1: 'Close',
                },
                panelClass: 'dialog-class',
              });
              return;
            } else if (
              error?.message?.includes('trial period') &&
              error?.message?.includes('FAILED_PRECONDITION')
            ) {
              this.dialog.open(BasicDialogComponent, {
                width: '450px',
                data: {
                  title: 'Trial period has ended.',
                  text:
                    'The trial period for your company has ended. Please contact Iconic Air ' +
                    'to extend trial or purchase the software.',
                  button1: 'Close',
                },
                panelClass: 'dialog-class',
              });
              return;
            } else {
              throw error;
            }
          });
      });
  }

  /**
   * @description
   * Dialog gets user password
   *
   * @returns A Promise that resolves to user's password when successful
   */
  async getPassword(email?: string): Promise<string> {
    this.dialogRef = this.dialog.open(ReauthenticateDialogComponent, {
      autoFocus: false,
      maxWidth: 450,
      data: {
        email,
      },
    });
    return await lastValueFrom(this.dialogRef.afterClosed());
  }

  /**
   * @description
   * Re-authenticates a user using a fresh credential. Use before operations such as
   * firebase.User.updatePassword or firebase.User.multiFactor.enroll that require
   * tokens from recent sign-in attempts.
   *
   * @returns A Promise that resolves to firebase.auth.UserCredential when successful
   */
  reAuthenticate(): Promise<firebase.auth.UserCredential | void> {
    return this.getPassword()
      .then((password) => {
        return firebase.auth.EmailAuthProvider.credential(
          firebase.auth().currentUser?.email as string,
          password,
        );
      })
      .then((credential) => {
        return firebase
          .auth()
          .currentUser?.reauthenticateWithCredential(credential)
          .then(function (userCredential) {
            // User successfully re-authenticated and does not require a second factor challenge.
            return userCredential;
          })
          .catch((error) => {
            if (error.code == 'auth/multi-factor-auth-required') {
              // Handle multi-factor authentication.
              return this.multiFactorAuth(error.resolver);
            } else {
              // Handle other errors.
              throw error;
            }
          });
      });
  }

  /**
   * @description
   * Enrolls a second factor as identified by the firebase.auth.MultiFactorAssertion for the current user.
   * On successful enrollment, existing Firebase sessions (refresh tokens) are revoked.
   * When a new factor is enrolled, an email notification is sent to the user’s email.
   *
   * @returns A Promise with no return value when successful
   */
  addSMSFactorAuth(): Promise<void> {
    return this.reAuthenticate().then(async () => {
      this.dialogRef = this.dialog.open(SmsAuthDialogComponent, {
        autoFocus: false,
        maxWidth: 450,
      });
      return await lastValueFrom(this.dialogRef.afterClosed());
    });
  }

  /**
   * @description
   * Unenrolls the specified second factor. To specify the factor to remove, pass the index of a
   * firebase.auth.MultiFactorInfo[]. Sessions are not revoked when the account is downgraded. An
   * email notification is likely to be sent to the user notifying them of the change. When an
   * existing factor is unenrolled, an email notification is sent to the user’s email.
   *
   * @returns A Promise with no return value when successful
   */
  removeSMSFactorAuth(selectedIndex: any): Promise<void> {
    return this.reAuthenticate().then(() => {
      const options = firebase.auth().currentUser?.multiFactor.enrolledFactors;

      if (options && options.length > 0) {
        // Present user the option to unenroll.
        return firebase
          .auth()
          .currentUser?.multiFactor.unenroll(options[selectedIndex].uid);
      }
    });
  }

  getUser(): Observable<User> {
    return from(this.afAuth.currentUser.then((user) => user));
  }

  getUserUid() {
    return this.userUid;
  }

  getUserClientId() {
    return this.userClientUid;
  }

  // Sign up with email/password
  public signUp(email: string, password: string, displayName: string): any {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result: any) => {
        // this updates the display name for the user profile that is created and stored in firebase.
        result.user?.updateProfile({
          displayName: displayName,
        });

        // this creates a document in firestore root collection of users
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
          `users/${result.user.uid}`,
        );
        const userData: User = {
          uid: result.user.uid,
          email: email,
          displayName: displayName,
          photoURL: result.user.photoURL,
        };
        userRef.set(userData, {
          merge: true,
        });

        this.sendVerificationMail();
        throw {
          message:
            'Please check your email to verify your account before signing in',
        };
      })
      .catch((error) => {
        throw error.message;
      });
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail(): any {
    // return this.afAuth.sendEmailVerification()
    // .then(() => {
    //   this.router.navigate(['login']);
    // })
  }

  // Reset Forgot password
  public forgotPassword(passwordResetEmail: string): any {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  // Sign out
  public signOut(): any {
    this.userDetails$?.unsubscribe();
    this.customerData.allZips$?.unsubscribe();
    this.user$?.unsubscribe();
    this.dash$?.unsubscribe();
    return this.router
      .navigate(['login'])
      .then(() => {
        this.afAuth.signOut();
      })
      .catch((error) => {
        throw error;
      });
  }
}
