export function inBetween(value, start, end) {
  if (!value && value !== 0) return true;
  return start <= value && end >= value;
}

export function checkLongitude(lon) {
  let valueToCheck;
  if (lon || lon === 0 || lon === '0') valueToCheck = Number(lon);
  if (!lon && lon !== 0) return true;
  return !!(isNumber(valueToCheck) && inBetween(valueToCheck, -180, 180));
}

export function checkLatitude(lat) {
  let valueToCheck;
  if (lat || lat === 0 || lat === '0') valueToCheck = Number(lat);
  if (!lat && lat !== 0) return true;
  return !!(isNumber(valueToCheck) && inBetween(valueToCheck, -90, 90));
}

export function checkYear(year) {
  if (!year && year !== 0) return true;
  const yearToCheck = JSON.parse(JSON.stringify(year))?.toString();
  const validYearFormat = new RegExp(
    '^[12]\\d{3}(?:(?:-(?:0[1-9]|1[0-2]))(?:-(?:0[1-9]|[12]\\d|3[01]))?)?$',
  );
  return validYearFormat.test(yearToCheck);
}

export function checkDoubleSpaces(text) {
  if (!text && text !== 0) return true;
  if (typeof text !== 'string') return true;
  const validSpacesFormat = new RegExp('[ ]{2,}');
  return !validSpacesFormat.test(text);
}

export function checkPhoneNumber(phoneNumber) {
  if (!phoneNumber && phoneNumber !== 0) return true;
  const validPhoneFormat = new RegExp('^\\d{3}-\\d{3}-\\d{4}$');
  return validPhoneFormat.test(phoneNumber);
}

export function isNumber(n) {
  if (!n && n !== 0) return true;
  return typeof n === 'number';
}

export function checkEmail(email) {
  if (!email && email !== 0) return true;
  const validEmailFormat = new RegExp(
    '^\\w+([\\.-]?\\w+)+@\\w+([\\.:]?\\w+)+(\\.[a-zA-Z0-9]{2,3})+$',
  );
  return validEmailFormat.test(email);
}

export function checkDate(date: string | number | Date) {
  if (!date && date !== 0) return true;
  if (typeof date === 'number')
    return new Date(date).toString() !== 'Invalid Date';
  if (date instanceof Date) return date?.toString() !== 'Invalid Date';
  const dateTest = new RegExp(
    '^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-][0-9]{4}$',
  );
  const year = date.split('/')[date.split('/').length - 1];
  if (Number(year) > new Date().getFullYear() + 30) return false;
  if (Number(year) < new Date().getFullYear() - 50) return false;
  if (dateTest.test(date)) {
    return new Date(date).toString() !== 'Invalid Date';
  }
  return false;
}

export function checkBoolean(value: string | boolean) {
  if (!value) return true;
  if (['true', 'false', 'yes', 'no'].includes(value?.toString()?.toLowerCase()))
    return true;
  return false;
}

// check to see if it is a 10 character string when excluding dashes
// then make sure it is a number as api numbers are numbers
export function checkApiNumber(value: string | number) {
  if (!value) return true;
  const stringValue = value?.toString()?.replace(/-/g, '');
  if (stringValue?.length !== 10) return false;
  if (Number(stringValue)) return true;
  return false;
}
// check to see if it is a 14 character string when excluding dashes
// then make sure it is a number as api numbers are numbers
export function checkApi14Number(value: string | number) {
  if (!value) return true;
  const stringValue = value?.toString()?.replace(/-/g, '');
  if (stringValue?.length !== 14) return false;
  if (Number(stringValue)) return true;
  return false;
}

// check to see if it is a valid reporting period
export function checkReportingPeriod(value: string | number) {
  if (!value && value !== 0 && value !== '0') return true;
  const stringValue = value?.toString();
  // checking months
  if (
    Number(stringValue) &&
    Number(stringValue) > 0 &&
    Number(stringValue) < 13
  )
    return true;
  // checking quarters
  if (['Q1', 'Q2', 'Q3', 'Q4'].includes(stringValue)) return true;
  const minYear = new Date().getFullYear() - 20,
    maxYear = new Date().getFullYear() + 1;
  // check yearly
  if (Number(stringValue) > minYear && Number(stringValue) < maxYear)
    return true;
  // nothing passed it failed
  return false;
}
