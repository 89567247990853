import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { StaticDataService } from '../services/static-data/static.data.service';
import { StaticItem } from '@iconic-air-monorepo/models';

@Injectable({
  providedIn: 'root',
})
export class StaticResolver implements Resolve<boolean> {
  constructor(
    private staticData: StaticDataService,
    private afs: AngularFirestore,
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    return forkJoin([
      //0
      this.afs.collection('static').doc('industrySegments').get(),
      //1
      this.afs.collection('static').doc('basins').get(),
      //2
      this.afs.collection('static').doc('states').get(),
      //3
      this.afs.collection('static').doc('formulas').get(),
      //4
      this.afs
        .collection('static')
        .doc('benchmarkYears')
        .collection('uploads')
        .get(),
      //5
      this.afs.collection('static').doc('templates').get(),
      //6
      this.afs.collection('static').doc('benchmarkPresets').get(),
      //7
      this.afs.collection('static').doc('ogiLdarFrequencies').get(),
      //8
      this.afs.collection('static').doc('requiredLdarFacilityKeys').get(),
      //9
      this.afs.collection('static').doc('requiredEmissionsFacilityKeys').get(),
      //10
      this.afs.collection('static').doc('standardizedLdarFacilityKeys').get(),
      //11
      this.afs
        .collection('static')
        .doc('standardizedEmissionsFacilityKeys')
        .get(),
      //12
      this.afs.collection('static').doc('requiredComponentKeys').get(),
      //13
      this.afs.collection('static').doc('standardizedComponentKeys').get(),
      //14
      this.afs.collection('static').doc('surveyTypes').get(),
      //15
      this.afs.collection('static').doc('emissionSources').get(),
      //16
      this.afs.collection('static').doc('calculations').get(),
      //17
      this.afs.collection('static').doc('cameras').get(),
      //18
      this.afs.collection('static').doc('skyConditions').get(),
      //19
      this.afs.collection('static').doc('gasTypes').get(),
      //20
      this.afs.collection('static').doc('industries').get(),
      //21
      this.afs.collection('static').doc('equipmentFields').get(),
      //22
      this.afs.collection('static').doc('repairConfMethods').get(),
      //23
      this.afs.collection('static').doc('repairMethods').get(),
      //24
      this.afs.collection('static').doc('repairStatus').get(),
      //25
      this.afs.collection('static').doc('epaWorksheetCategories').get(),
      //26
      this.afs.collection('static').doc('reportingPeriods').get(),
      //27
      this.afs.collection('static').doc('facilityTypes').get(),
      //28
      this.getEGridRegions(),
      //29
      this.afs.collection('static').doc('futureHardcodedLines').get(),
      //30
      this.afs.collection('static').doc('reportRegulationTypes').get(),
      //31
      this.afs.collection('static').doc('equipmentEmissionColumns').get(),
      //32
      this.afs.collection('static').doc('equipmentSelectorColumns').get(),
      //33
      this.afs.collection('static').doc('equipmentEmissionsFields').get(),
      //34
      this.afs.collection('static').doc('combustionEquipmentSubTypes').get(),
      //35
      this.afs.collection('static').doc('calculationMethods').get(),
      //36
      this.afs.collection('static').doc('equipmentSelectedDisplayFields').get(),
      //37
      this.afs.collection('static').doc('equipmentListColumns').get(),
      //38
      this.afs.collection('static').doc('ldarRegulations').get(),
      //39
      this.afs.collection('static').doc('productionCategories').get(),
      //40
      this.afs.collection('static').doc('componentTypes').get(),
      //41
      this.afs.collection('static').doc('rfProductionProfiles').get(),
      //42
      this.afs.collection('static').doc('rfRegulations').get(),
      //43
      this.afs.collection('static').doc('windDirections').get(),
      //44
      this.afs.collection('static').doc('auditReportHeaders').get(),
      // 45
      this.getMethaneEmissionFormulas(),
      // 46
      this.afs.collection('static').doc('carbonEmissionFormulas').get(),
      // 47
      this.afs.collection('static').doc('bulkUpload').get(),
      // 48
      this.afs.collection('static').doc('defaultDiversity').get(),
      // 49
      this.afs.collection('static').doc('mapboxApiTokens').get(),
      // 50
      this.afs.collection('static').doc('compressorOperatingModes').get(),
      // 51
      this.afs.collection('static').doc('emissionScopes').get(),
      // 52
      this.afs.collection('static').doc('templateColumnValidation').get(),
      // 53
      this.afs.collection('static').doc('templateDataValidation').get(),
      // 54
      this.afs.collection('static').doc('programTypes').get(),
      // 55
      this.afs.collection('static').doc('flareRepairMethods').get(),
      // 56
      this.afs.collection('static').doc('flareIssueTypes').get(),
      // 57
      this.afs.collection('static').doc('leakStatus').get(),
      // 58
      this.afs.collection('static').doc('surveyStatus').get(),
      // 59
      this.afs.collection('static').doc('repairConfStatus').get(),
      // 60
      this.afs
        .collection('static')
        .doc('emissionsSourcesForecastMethods')
        .get(),
      // 61
      this.afs.collection('static').doc('locationSubTypes').get(),
      // 62
      this.afs.collection('static').doc('standardizedFacilityKeys').get(),
      // 63
      this.afs.collection('static').doc('programTypeConfigs').get(),
      // 64
      this.afs.collection('static').doc('surveyProgramTypes').get(),
    ]).pipe(
      map((data: any[]) => {
        this.staticData.industrySegments = data[0].data()?.data;
        this.staticData.basins = data[1].data()?.data;
        this.staticData.states = data[2].data()?.data;
        this.staticData.formulas = data[3].data()?.data;
        // staticData benchmarkYears has multiples docs so had to add code below to loop over them.
        const benchmarkYears: any[] = [];
        data[4].docs.map((doc) => benchmarkYears.push(doc.data()));
        this.staticData.benchmarkYears = benchmarkYears?.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
        this.staticData.templates = data[5].data()?.data;
        this.staticData.presets = data[6]?.data()?.data;
        this.staticData.ldarFrequencies = data[7]?.data()?.data;
        this.staticData.requiredLdarFacilityKeys = data[8]?.data()?.data;
        this.staticData.requiredEmissionsFacilityKeys = data[9]?.data()?.data;
        this.staticData.standardizedLdarFacilityKeys = data[10]?.data()?.data;
        this.staticData.standardizedEmissionsFacilityKeys =
          data[11]?.data()?.data;
        this.staticData.requiredComponentKeys = data[12]?.data()?.data;
        this.staticData.standardizedComponentKeys = data[13]?.data()?.data;
        this.staticData.surveyTypes = data[14]?.data()?.data;
        this.staticData.scopeOneSources = data[15]?.data()?.scope1;
        this.staticData.scopeTwoSources = data[15]?.data()?.scope2;
        this.staticData.calculations = data[16]?.data()['system_calculations'];
        this.staticData.cameras = data[17]?.data()?.data;
        this.staticData.skyConditions = data[18]?.data()?.data;
        this.staticData.gasTypes = data[19]?.data()?.data;
        this.staticData.industries = data[20]?.data()?.data;
        this.staticData.emissionSourceFields = data[21]?.data();
        this.staticData.repairConfMethods = data[22].data()?.data;
        this.staticData.repairMethods = data[23].data()?.data;
        this.staticData.repairStatus = data[24].data()?.data;
        this.staticData.epaWorksheetCategories = data[25].data()?.data;
        this.staticData.reportingPeriods = data[26].data();
        this.staticData.facilityTypes = data[27].data()?.data?.sort((a, b) => {
          if (a < b) return -1;
          else if (a > b) return 1;
          return 0;
        });
        this.staticData.eGridRegions = this.processEGridRegions(data[28]);
        this.staticData.futureHardcodedLines = data[29].data()?.data;
        this.staticData.reportRegulationTypes = data[30].data()?.data;
        this.staticData.equipmentEmissionColumns = data[31].data();
        this.staticData.equipmentSelectorColumns = data[32].data();
        this.staticData.equipmentEmissionsFields = data[33].data();
        this.staticData.combustionEquipmentSubTypes = data[34].data()?.data;
        this.staticData.calculationMethods = data[35].data()?.data;
        this.staticData.equipmentSelectedDisplayFields = data[36].data();
        this.staticData.equipmentListColumns = data[37].data();
        this.staticData.ogiRegulations = data[38].data().regulations;
        this.staticData.m21Regulations = data[38].data().m21regulations;
        this.staticData.productionCategories = data[39].data()?.data;
        this.staticData.componentTypes = data[40].data()?.data;
        this.staticData.rfProductionProfiles = data[41].data()?.data;
        this.staticData.rfRegulations = data[42].data()?.data;
        this.staticData.windDirections = data[43].data()?.data;
        this.staticData.auditReportHeaders = data[44].data();
        this.staticData.methaneEmissionFormulas = data[45];
        this.staticData.carbonEmissionFormulas = data[46]?.data();
        this.staticData.bulkUpload = data[47]?.data();
        this.staticData.diversityData = data[48]?.data();
        this.staticData.mapboxApiTokens = data[49]?.data()?.data;
        if (!this.staticData.mapboxApiTokens)
          this.staticData.mapboxApiTokens = [];
        this.staticData.compressorOperatingModes = data[50]?.data()?.data;
        this.staticData.scopes = data[51]?.data()?.data;
        this.staticData.templateColumnValidation = data[52]?.data();
        this.staticData.templateDataValidation = data[53]?.data();
        this.staticData.programTypes = data[54]?.data().data;
        this.staticData.flareRepairMethods = data[55]?.data()?.data;
        this.staticData.flareIssueTypes = data[56]?.data()?.data;
        this.staticData.leakStatus = data[57]?.data()?.data;
        this.staticData.surveyStatus = data[58]?.data()?.data;
        this.staticData.repairConfStatus = data[59]?.data()?.data;
        this.staticData.emissionsSourcesForecastMethods =
          data[60]?.data()?.data;
        this.staticData.locationSubTypes = data[61]
          .data()
          ?.data?.sort((a, b) => {
            if (a < b) return -1;
            else if (a > b) return 1;
            return 0;
          });
        this.staticData.standardizedFacilityKeys = data[62]?.data()?.data;
        this.staticData.programTypeConfigs = data[63]?.data()?.data;
        this.staticData.surveyProgramTypes = data[64]?.data()?.data;
        this.setupFolders();
      }),
    );
  }

  getMethaneEmissionFormulas() {
    return this.afs
      .collection('static')
      .doc('methaneEmissionFormulas')
      .get()
      .pipe(
        mergeMap((data) => {
          const document = data?.data() as
            | { supportedProtocols: Record<any, any> }
            | { supportedProtocols: Record<any, any> };
          const queries: DocumentData = [];
          const mappedQueries: {
            protocol: string;
            version: string;
            lastUpdated: string;
            display: string;
          }[] = [];
          Object.keys(document.supportedProtocols).forEach((protocol) => {
            document.supportedProtocols[protocol].forEach((version) => {
              mappedQueries.push({
                protocol,
                version: version.version,
                lastUpdated: version.lastUpdated,
                display: version.display,
              });
              queries.push(
                this.afs
                  .collection('static')
                  .doc('methaneEmissionFormulas')
                  .collection(protocol)
                  .doc(version.version)
                  .get(),
              );
            });
          });
          return forkJoin(queries).pipe(
            map((queryData) => {
              const output: {
                [protocol: string]: {
                  [version: string]: {
                    [industrySegment: string]:
                      | {
                          [key: string]: string;
                        }
                      | string;
                    lastUpdated: string;
                    version: string;
                    display: string;
                  };
                };
              } = {};
              mappedQueries.forEach((combination, i) => {
                const currentQueryData = queryData[i] as DocumentData;
                if (!output[combination.protocol])
                  output[combination.protocol] = {} as any;
                output[combination.protocol][combination.version] =
                  currentQueryData?.data();
                output[combination.protocol][combination.version].lastUpdated =
                  combination.lastUpdated;
                output[combination.protocol][combination.version].version =
                  combination.version;
                output[combination.protocol][combination.version].display =
                  combination.display;
              });
              return output;
            }),
          );
        }),
      );
  }

  getEGridRegions() {
    return this.afs // get availableYears first
      .collection('static')
      .doc('eGridRegions')
      .get()
      .pipe(
        mergeMap((data) => {
          const { availableYears } = data?.data() as {
            availableYears: string[];
          };
          return forkJoin(
            // get eGridRegions for all years
            availableYears.map((year) => {
              return this.afs
                .collection('static')
                .doc('eGridRegions')
                .collection(year)
                .get()
                .pipe(
                  map((eGridRegions) => {
                    return { eGridRegions: eGridRegions, year };
                  }),
                );
            }),
          );
        }),
      );
  }

  processEGridRegions(data: any) {
    return data.map((yearOfEGridRegions) => {
      return {
        year: yearOfEGridRegions.year,
        eGridRegions: yearOfEGridRegions.eGridRegions.docs.map(
          (eGridRegion) => {
            return eGridRegion.data();
          },
        ),
      };
    });
  }

  private setupFolders() {
    this.createFolders(
      'Industry Segments',
      this.staticData.industrySegments,
      'shortName',
      true,
    );
    this.createFolders('Basins', this.staticData.basins, 'basinName', true);
    this.createFolders('States', this.staticData.states, 'name', true);
    this.createFolders(
      'Formulas',
      this.staticData.formulas,
      'industrySegmentShortName',
      true,
    );
    this.createFolders(
      'Benchmark Years',
      this.staticData.benchmarkYears,
      'reportingYear',
      true,
    );

    this.createFolders('templates', this.staticData.templates, 'type', true);
    //6
    this.createFolders(
      'benchmarkPresets',
      this.staticData.presets,
      'title',
      true,
    );

    //equipmentEmissionColumns = data[32].data();
    this.staticData.manageItems.folders.push({
      id: 'equipmentEmissionColumns',
      folderId: '',
      name: 'Equipment Emission Columns',
      type: 'folder',
      objectType: '',
    });

    Object.keys(this.staticData.equipmentEmissionColumns).forEach((key) => {
      this.staticData.manageItems.files.push({
        folderId: 'equipmentEmissionColumns',
        id: key,
        name: key,
        type: 'columns',
        objectType: '',
      });
    });

    //equipmentListColumns
    this.staticData.manageItems.folders.push({
      id: 'equipmentListColumns',
      folderId: '',
      name: 'Equipment List Columns',
      type: 'folder',
      objectType: '',
    });

    Object.keys(this.staticData.equipmentListColumns).forEach((key) => {
      this.staticData.manageItems.files.push({
        folderId: 'equipmentListColumns',
        id: key,
        name: key,
        type: 'columns',
        objectType: '',
      });
    });

    // equipmentSelectorColumns
    this.staticData.manageItems.folders.push({
      id: 'equipmentSelectorColumns',
      folderId: '',
      name: 'Equipment Selector Columns',
      type: 'folder',
      objectType: '',
    });

    Object.keys(this.staticData.equipmentSelectorColumns).forEach((key) => {
      this.staticData.manageItems.files.push({
        folderId: 'equipmentSelectorColumns',
        id: key,
        name: key,
        type: 'columns',
        objectType: '',
      });
    });
    // equipment folders
    this.staticData.manageItems.folders.push({
      id: 'emissionSourceFields',
      folderId: '',
      name: 'Emission Source Fields',
      type: 'folder',
      objectType: '',
    });
    Object.keys(this.staticData.emissionSourceFields).forEach((key) => {
      this.staticData.manageItems.files.push({
        folderId: 'emissionSourceFields',
        id: key,
        name: key,
        type: 'inputs',
        objectType: '',
      });
    });

    // equipment folders
    this.staticData.manageItems.folders.push({
      id: 'equipmentEmissionsFields',
      folderId: '',
      name: 'Emissions Fields',
      type: 'folder',
      objectType: '',
    });
    Object.keys(this.staticData.equipmentEmissionsFields).forEach((key) => {
      this.staticData.manageItems.files.push({
        folderId: 'equipmentEmissionsFields',
        id: key,
        name: key,
        type: 'fields',
        objectType: '',
      });
    });
    this.staticData.manageItems.folders?.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
  }

  private createFolders(
    title: string,
    items: any[],
    key: string,
    file?: boolean,
    objectType?: string,
  ) {
    const folderId = title.replace(' ', '').toLowerCase();
    this.staticData.manageItems.folders.push({
      id: folderId,
      folderId: '',
      name: title,
      type: 'folder',
      objectType: file ? (objectType ? objectType : 'file') : '',
    });

    items.forEach((item) => {
      const staticItem: StaticItem = {
        folderId: title.replace(' ', '').toLowerCase(),
        id: item[key].replace(' ', '').toLowerCase(),
        name: item[key],
        data: item,
        keys: Object.keys(item),
        type: 'file',
      };
      if (file) {
        this.staticData.manageItems.files.push(staticItem);
      } else {
        this.staticData.manageItems.folders.push(staticItem);
      }
    });
  }
}
