<div class="flex flex-col {{extraClasses.join(' ')}}">
  <div *ngIf="!chartRendering" class="flex justify-center mb-3">
    <canvas
      baseChart
      id="stacked-chart"
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [chartType]="barChartType"
    >
    </canvas>
  </div>
</div>
<!--
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
-->
