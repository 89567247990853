import { Injectable } from '@angular/core';
import versionData from '../../../environments/version.json';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { lastValueFrom } from 'rxjs';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
  ) {}
  get version(): string {
    return versionData.version;
  }

  async setUserVersion() {
    await this._afs.collection('users').doc(this._perms.userData.uid).update({
      version: this.version,
    });
    this._perms.userData.version = this.version;
  }

  async getReleaseInformation(version: string) {
    const release = (
      await lastValueFrom(this._afs.collection('releases').doc(version).get())
    ).data() as {
      videoLink?: string;
      improvements?: string[];
      fixes?: string[];
      newFeatures?: string[];
      supplementalVideoLinks?: string[];
      expires?: string | number;
    };
    return release;
  }
}
