<div
  class="w-full flex flex-col"
  [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}"
  style="
    background-size: cover;
    background-position: center 90px;
    background-repeat: no-repeat;
  "
>
  <div
    class="pt-6 flex items-center px-6 pb-7"
    id="section-header"
    *ngIf="!hideHeader"
  >
    <div class="title-large">{{screen}}</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="w-full" *ngIf="!loading && showSubscriptions">
    <div class="m-6 bg-accent-900 rounded">
      <div class="display-small p-3">Speak with an expert</div>
      <div
        class="body-text-large flex items-center"
        *ngIf="!(hasFreeConsultation && !activeSubscriptions?.length)"
      >
        <app-tooltip-icon
          *ngIf="!hasFreeConsultation"
          [tooltip]="disclaimer"
          [onlyPopup]="true"
        ></app-tooltip-icon>
        {{hasFreeConsultation && !activeSubscriptions?.length ? 'Want to talk to
        an expert?' : activeSubscriptions?.length ? 'Schedule your time with an
        expert, meetings left: ' + credits : 'Your free consultation has been
        scheduled! You can subscribe to have access to our experts.'}}
      </div>

      <div class="flex justify-between items-end p-3">
        <div class="flex justify-start items-center">
          <mat-form-field>
            <mat-label>Expert</mat-label>
            <mat-select
              [(ngModel)]="expert"
              [disabled]="showClose"
              (ngModelChange)="changeExpert()"
            >
              <mat-option
                *ngFor="let currentExpert of experts"
                [value]="currentExpert"
              >
                {{currentExpert.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container
            *ngTemplateOutlet="expertTemplate; context: {classAdd: 'hidden-sm-down'}"
          ></ng-container>
        </div>

        <ng-container
          class="hidden-sm-down"
          *ngTemplateOutlet="buttons; context: {classAdd: 'hidden-sm-down'}"
        ></ng-container>
      </div>
      <ng-container
        *ngTemplateOutlet="expertTemplate; context: {classAdd: 'hidden-md-up m-2'}"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="buttons;  context: {classAdd: 'hidden-md-up m-2'}"
      ></ng-container>
    </div>
    <div
      id="calendly-embed"
      style="min-width: 320px; height: 700px"
      *ngIf="showCalendly"
    ></div>

    <div>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="loadingCheckout"
      ></mat-progress-bar>
      <!-- Paid Plan Form -->
      <div id="checkout" style="margin: 20px 0">
        <!-- Stripe checkout will create and mount the card input field here -->
      </div>
    </div>
  </div>
</div>

<ng-template #expertTemplate let-classAdd="classAdd">
  <img
    *ngIf="expert?.avatar?.thumbnail?.url"
    [src]="expert?.avatar?.thumbnail?.url"
    [alt]="expert?.avatar?.alternativeText"
    class="w-[96px] h-[96px] rounded-full mb-2 ml-4"
    [class]="classAdd"
  />
  <img
    [class]="classAdd"
    class="cursor-pointer w-[24px] h-[24px] ml-4"
    *ngIf="expert?.linkedInUrl"
    (click)="openLink(expert?.linkedInUrl)"
    src="/assets/linkedin_logo.png"
  />
  <div [class]="classAdd" class="ml-4" *ngIf="expert?.blurb">
    <markdown [data]="expert?.blurb"></markdown>
  </div>
</ng-template>

<ng-template #buttons let-classAdd="classAdd">
  <div class="pb-2">
    <button
      [class]="classAdd"
      mat-raised-button
      class="rounded-full"
      color="primary"
      *ngIf="!showClose"
      [disabled]="loadingCheckout || (activeSubscriptions?.length > 0 && credits <= 0 && loggedIn)"
      (click)="buttonClicked()"
      [id]="activeSubscriptions?.length ? 'schedule-time-button' : hasFreeConsultation?
      'free-consultation-button' : 'subscribe-button'"
    >
      {{ activeSubscriptions?.length ? 'Schedule time' : hasFreeConsultation?
      'Get a free consultation' : 'Subscribe'}}
    </button>
    <button
      [class]="classAdd"
      mat-raised-button
      class="rounded-full"
      *ngIf="showClose"
      color="warn"
      (click)="close()"
    >
      Close
    </button>
  </div>
</ng-template>
