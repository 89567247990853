import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GasSample, ReportingFacility } from '@iconic-air/models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportingFacilityDatabaseService {
  constructor(private _http: HttpClient) {}

  public getReportingFacilities(): Observable<ReportingFacility[]> {
    return this._http.get<ReportingFacility[]>('/api/reporting-facilities');
  }

  public getReportingFacility(id: string) {
    return this._http.get<ReportingFacility>('/api/reporting-facilities/' + id);
  }

  public saveReportingFacility(rfac: ReportingFacility): Observable<any> {
    return this._http.post('/api/reporting-facilities', rfac);
  }

  public updateReportingFacility(rfac: ReportingFacility): Observable<any> {
    return this._http.put(`/api/reporting-facilities/${rfac.id}`, rfac);
  }

  public saveUpdateGasSample(id: string, gasSample: GasSample) {
    return this._http.post(`/api/reporting-facilities/${id}/gas-samples`, {
      ...gasSample,
    });
  }
}
