import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  ViewChild,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';

@Component({
  selector: 'app-sign-in-or-register-modal',
  templateUrl: './sign-in-or-register-modal.component.html',
  styleUrls: ['./sign-in-or-register-modal.component.scss'],
})
export class SignInOrRegisterModalComponent {
  @ViewChild('passwordField', { static: false }) passwordField: ElementRef;
  public loading = false;
  public signInForm: FormGroup;
  public companyForm: FormGroup;
  public register = false;
  public companyExists = true;
  public registrationCompleted = false;
  public loadingCompanies = true;
  public createdUserId = '';
  public companyFilter = '';
  public customers: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<SignInOrRegisterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthenticationWebService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.customers = this.data.customers?.length
      ? this.data.customers
      : this.customers;

    this.register = this.data.register ? true : false;
    // Create the form
    this.signInForm = new FormGroup({
      email: new FormControl(data.email ? data.email : '', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(true),
    });

    this.companyForm = new FormGroup({
      name: new FormControl('', Validators.required),
      brand: new FormControl(''),
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * User Login (register or login based on this.register)
   *
   */
  public userLogin() {
    const email = this.signInForm.get('email')?.value;
    const password = this.signInForm.get('password')?.value;
    const rememberMe = this.signInForm.get('rememberMe')?.value;
    this.loading = true;

    if (this.register) {
      // we need to register the user first
      this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          if (user.user) {
            this.createdUserId = user.user ? user.user.uid : '';
            this.afs
              .collection('users')
              .doc(this.createdUserId)
              .set({
                email: user.user.email,
                emailVerified: user.user.emailVerified,
                displayName: user.user.email?.split('@')[0],
                external: false,
                customerIds: this.customers.map((customer) => customer.id),
              })
              .then(() => {
                this.registrationCompleted = true;
                this.loading = false;
                // create customer if no id.
              })
              .catch((err) => {
                this.loading = false;
                throw err;
              });
          }
        })
        .catch((err) => {
          this.loading = false;
          throw err;
        });
    } else {
      this.auth
        .signIn(email, password, rememberMe)
        .then(() => {
          this.dialogRef.close(true);
          if (this.data.redirect) {
            this.router.navigateByUrl(this.data.redirect);
          }
        })
        .catch((error) => {
          this.dialogRef.close(false);
        });
    }
  }

  public createCompany() {
    // first create the company
    const id = this.afs.createId();
    this.afs
      .collection('customers')
      .doc(id)
      .set({
        id: id,
        permissions: {
          emissions: false,
          benchmark: true,
          ldar: false,
        },
        name: this.companyForm.get('name').value.toLowerCase(),
      })
      .then(() => {
        // then we need to modify the user to have access to that company
        this.afs
          .collection('users')
          .doc(this.createdUserId)
          .update({
            priviledges: ['view_benchmark'],
            isAdmin: true,
            customerIds: [id],
          })
          .then(() => {
            // close WITH true - so it knows where to go
            this.dialogRef.close(true);
          });
      });
  }

  public selectCompany(company: any) {
    if (company.id) {
      this.createCompanyCustomerData(
        company.id,
        company,
        company.customerExists,
      );
    } else {
      const companyId = this.afs.createId();
      this.createCompanyCustomerData(companyId, company, false);
    }

    this.dialogRef.close(true);
  }

  public createCompanyCustomerData(id: string, company: any, customerExists) {
    const batch = this.afs.firestore.batch();
    if (!company.id) {
      this.afs.collection('companies').doc(id).set({
        name: company.name?.toLowerCase(),
        id: id,
      });
    }

    if (!customerExists) {
      this.afs
        .collection('customers')
        .doc(id)
        .set({
          name: company.name?.toLowerCase(),
          id: id,
          permissions: {
            emissions: false,
            benchmark: true,
            ldar: false,
          },
        });
    }
    // edit user object
    this.afs
      .collection('users')
      .doc(this.createdUserId)
      .update({
        customerIds: [id],
        priviledges: ['view_benchmark'],
        isAdmin: company.id ? true : false,
      });
    batch.commit();
  }

  public forgot() {
    this.router.navigate(['/forgot-password']);
    this.dialogRef.close();
  }
}
