import { Component } from '@angular/core';

@Component({
  selector: 'app-beta-indicator',
  templateUrl: './beta-indicator.component.html',
  styleUrls: ['./beta-indicator.component.scss'],
})
export class BetaIndicatorComponent {
  constructor() {}
}
