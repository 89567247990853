import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emission' })
export class EmissionPipe implements PipeTransform {
  transform(value: string): string | string[] {
    if (!value || typeof value !== 'string') {
      if (Array.isArray(value)) {
        const returnValue: any[] = [];
        value?.forEach((item: string) => {
          returnValue.push(this.transform(item as unknown as string));
        });
        return returnValue;
      }
      return value;
    }

    return this.#replaceString(value);
  }

  #replaceString(value) {
    let result = value.replace(/co2/gi, 'CO₂');
    result = result.replace(/CO2/gi, 'CO₂');
    result = result.replace(/Co2/gi, 'CO₂');
    result = result.replace(/cO2/gi, 'CO₂');
    result = result.replace(/Co₂/gi, 'CO₂');
    result = result.replace(/co₂/gi, 'CO₂');
    result = result.replace(/N20/gi, 'N₂O');
    result = result.replace(/n20/gi, 'N₂O');
    result = result.replace(/n2o/gi, 'N₂O');
    result = result.replace(/N2o/gi, 'N₂O');
    result = result.replace(/N2O/gi, 'N₂O');
    result = result.replace(/N₂o/gi, 'N₂O');
    result = result.replace(/n₂o/gi, 'N₂O');
    result = result.replace(/ch4/gi, 'CH₄');
    result = result.replace(/Ch4/gi, 'CH₄');
    result = result.replace(/CH4/gi, 'CH₄');
    result = result.replace(/cH4/gi, 'CH₄');
    result = result.replace(/Ch₄/gi, 'CH₄');
    result = result.replace(/ch₄/gi, 'CH₄');
    result = result.replace(/N20/gi, 'N₂O');
    result = result.replace(/n20/gi, 'N₂O');
    result = result.replace(/ooooa/gi, 'OOOOa');
    result = result.replace(/Ooooa/gi, 'OOOOa');
    result = result.replace(/OOooa/gi, 'OOOOa');
    result = result.replace(/OOOoa/gi, 'OOOOa');
    result = result.replace(/OOOOA/gi, 'OOOOa');
    // add new ones here

    return result;
  }
}
