import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VersionService } from '../../services/version/version.service';

@Component({
  selector: 'app-release-dialog',
  templateUrl: './release-dialog.component.html',
  styleUrls: ['./release-dialog.component.scss'],
})
export class ReleaseDialogComponent implements OnInit {
  version: string;
  cleanVideoLink: SafeResourceUrl;
  improvements: string[] = [];
  fixes: string[] = [];
  newFeatures: string[] = [];
  supplementalVideoLinks: string[] = [];
  loading = true;
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ReleaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private _sanitizer: DomSanitizer,
    private _versionService: VersionService,
  ) {
    this.version = data.version;
    if (data.release) {
      this.#setVersionInformation(data.release);
    }
  }

  #setVersionInformation(release) {
    if (release?.videoLink) {
      release.videoLink = release.videoLink.replace('/share/', '/embed/');
      this.cleanVideoLink = this._sanitizer.bypassSecurityTrustResourceUrl(
        release.videoLink +
          '?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
      );
    }

    if (
      release?.improvements &&
      Array.isArray(release?.improvements) &&
      release?.improvements.length
    )
      this.improvements = release.improvements;
    if (
      release?.fixes &&
      Array.isArray(release?.fixes) &&
      release?.fixes.length
    )
      this.fixes = release.fixes;
    if (
      release?.newFeatures &&
      Array.isArray(release?.newFeatures) &&
      release?.newFeatures.length
    )
      this.newFeatures = release.newFeatures;
    if (
      release?.supplementalVideoLinks &&
      Array.isArray(release?.supplementalVideoLinks) &&
      release?.supplementalVideoLinks.length
    )
      this.supplementalVideoLinks = release.supplementalVideoLinks;
    this.loading = false;
  }

  async ngOnInit() {
    if (!this.loading) return;
    // we need to get the release notes
    const release = await this._versionService.getReleaseInformation(
      this.version,
    );

    this.#setVersionInformation(release);

    this.loading = false;
  }

  dismissModal(): void {
    this.dialogRef.close(true);
  }
}
