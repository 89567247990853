import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(text: string): string | null {
    if (text === undefined) return '';
    return text.replace(/^./, text[0].toUpperCase());
  }
}
