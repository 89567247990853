import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../theme/material.module';
import { SignUpDialogComponent } from './sign-up-dialog.component';

@NgModule({
  declarations: [SignUpDialogComponent],
  imports: [CommonModule, FormsModule, MaterialModule, ReactiveFormsModule],
  exports: [SignUpDialogComponent],
})
export class SignUpDialogModule {}
