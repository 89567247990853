import { Injectable } from '@angular/core';
import {
  SheetValidationError,
  EquipmentField,
} from '@iconic-air-monorepo/models';
import * as ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(private _perms: PermissionsWebGuard) {}

  /**
   * Will write your data to a column and return the range including the sheet name to be used for data validation
   * @param dataArray The data you want to write to the sheet
   * @param columnNumber The column number you want to store it in, 1 index
   * @param worksheet The worksheet you want to write these values to
   * @returns the range where it was written to ex. 'MySheetName'!$A$1:$A$20
   */
  writeToColumn(
    dataArray: any[],
    columnNumber: number,
    worksheet: ExcelJS.Worksheet,
  ) {
    dataArray.forEach((value, rowIndex) => {
      const cell = worksheet.getCell(rowIndex + 1, columnNumber);
      cell.value = value;
    });

    // Convert the column number to a letter
    const columnLetter = this.columnNumberToLetter(columnNumber);

    // Create the range string
    const range = `$${columnLetter}$1:$${columnLetter}$${
      dataArray.length ? dataArray.length : 1
    }`;

    return `'${worksheet.name}'!${range}`;
  }

  #getAllCombinations(arr) {
    // Function to combine the elements
    function combine(prefix: string, arr: string[]) {
      for (let i = 0; i < arr.length; i++) {
        combinations.push(`${prefix}${prefix ? ',' : ''}${arr[i]}`);
        combine(`${prefix}${prefix ? ',' : ''}${arr[i]}`, arr.slice(i + 1));
      }
    }

    const combinations: string[] = [];
    combine('', arr.sort());
    return combinations;
  }

  /**
   * Will return you the data validation for a cell for a list type
   * @param options the options you want available in the drop down
   * @param columnNumber the column you want to save the list in the sheet
   * @param worksheet the sheet that stores your options, recommended to veryHidden it
   * @param prompt the note you want the user to see when clicking into the cell
   * @param allowBlank true if you want them to be able to clear out the value
   * @param multiple true if you want them to be able to select multiple
   * @returns the data validation for the cell
   */
  getListValidation(
    options: any[],
    columnNumber: number,
    worksheet: ExcelJS.Worksheet,
    prompt?: string,
    allowBlank?: boolean,
    multiple?: boolean,
  ) {
    let optionsCopy: string[] = [];
    if (options) optionsCopy = JSON.parse(JSON.stringify(options));

    if (multiple) optionsCopy = this.#getAllCombinations(optionsCopy);
    const dataValidation: ExcelJS.DataValidation = {
      type: 'list',
      formulae: [this.writeToColumn(optionsCopy, columnNumber, worksheet)],
      prompt: this.#getPrompt(prompt),
      showErrorMessage: true,
      error: 'Value must be in the list',
      allowBlank,
      showInputMessage: !!prompt,
    };
    return dataValidation;
  }

  /**
   * Will return you the data validation for a cell for a date type
   * @param prompt the note you want the user to see when clicking into the cell
   * @param allowBlank true if you want them to be able to clear out the value
   * @param minDate the minimum date they are allowed to enter. Will default to 1/1/1990
   * @param maxDate the maximum date they are allowed to enter. Will default to three years form now
   * @returns the data validation for the cell
   */
  getDateValidation(
    prompt: string,
    allowBlank?: boolean,
    minDate: Date = new Date('1/1/1990'),
    maxDate: Date = new Date(
      new Date().getFullYear() + 3,
      new Date().getMonth(),
      new Date().getDate(),
    ),
  ) {
    const dataValidation: ExcelJS.DataValidation = {
      type: 'date',
      formulae: [minDate, maxDate],
      prompt: this.#getPrompt(prompt),
      showErrorMessage: true,
      showInputMessage: !!prompt,
      allowBlank,
      operator: 'between',
      error: `Date must be between ${minDate?.toLocaleDateString()} and ${maxDate?.toLocaleDateString()}`,
    };
    return dataValidation;
  }
  /**
   * Will return you the data validation for a cell for a number type
   * @param prompt the note you want the user to see when clicking into the cell
   * @param min the minimum number allowed (inclusive)
   * @param max the maximum number allowed (inclusive)
   * @param allowBlank true if you want them to be able to clear out the value
   * @returns the data validation for the cell
   */
  getNumberValidation(
    prompt?: string,
    min?: number,
    max?: number,
    allowBlank?: boolean,
  ) {
    const dataValidation: ExcelJS.DataValidation = {
      type: 'decimal',
      formulae: [],
      prompt: this.#getPrompt(prompt),
      showErrorMessage: true,
      allowBlank,
      showInputMessage: !!prompt,
      error: 'Must be a number',
    };
    if ((Number(min) || min?.toString() === '0') && !isNaN(Number(min))) {
      dataValidation.formulae.push(Number(min));
      dataValidation.operator = 'greaterThanOrEqual';
      dataValidation.error = `Must be a number greater than or equal to: ${min}`;
    }
    if ((Number(max) || max?.toString() === '0') && !isNaN(Number(max))) {
      dataValidation.formulae.push(Number(max));
      dataValidation.operator = 'lessThanOrEqual';
      dataValidation.error = `Must be a number less than or equal to: ${max}`;
    }
    if (dataValidation.formulae?.length === 2) {
      dataValidation.operator = 'between';
      dataValidation.error = `Must be a number between (inclusive) ${min} and ${max}`;
    }
    return dataValidation;
  }

  /**
   * Will give you basic validation that will show a prompt if passed in
   * @param prompt the note you want the user to see when clicking into the cell
   * @returns the data validation for the cell
   */
  getBasicValidation(prompt?: string): ExcelJS.DataValidation {
    return {
      showInputMessage: !!prompt,
      prompt: this.#getPrompt(prompt),
      type: 'custom',
      formulae: [],
    };
  }

  /**
   * Will add a false validation that won't let a user edit a field, will allow for a on click prompt
   * @param prompt the note you want the user to see when clicking into the cell
   * @returns the data validation for the cell
   */
  getUneditableValidation(prompt?: string): ExcelJS.DataValidation {
    return {
      showInputMessage: !!prompt,
      prompt: this.#getPrompt(prompt),
      type: 'custom',
      error: 'This field is uneditable',
      showErrorMessage: true,
      allowBlank: false,
      formulae: ['=false'],
    };
  }

  #getPrompt(prompt?: string) {
    let finalPrompt = prompt || '';
    if (finalPrompt.length > 255)
      finalPrompt = finalPrompt.substring(0, 251) + '...';
    return finalPrompt;
  }

  /**
   * returns a fully built out fill option for a solid color
   * @param color the color you want the fill to be
   * @returns the fill object
   */
  getSolidFill(color: string): ExcelJS.Fill {
    return {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: color,
      },
    };
  }

  /**
   * Creates the basic auto filter object for filtering all columns
   * @param worksheet the worksheet you want to create the auto filter for
   * @returns a auto filter object for the worksheet
   */
  getAutoFilter(worksheet: ExcelJS.Worksheet): ExcelJS.AutoFilter {
    return {
      from: { row: 1, column: 1 },
      to: { row: 1, column: worksheet.columnCount },
    };
  }

  /**
   * Converts a column number to its corresponding letter representation.
   *
   * @param {number} colNumber - The column number to convert.
   * @return {string} The letter representation of the column number.
   */
  columnNumberToLetter(colNumber: number): string {
    let dividend = colNumber;
    let columnName = '';
    let modulo;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }

    return columnName;
  }

  /**
   * Converts a column letter to its corresponding column number.
   *
   * @param {string} columnName - The column letter to convert.
   * @return {number} - The corresponding column number.
   */
  columnLetterToNumber(columnName: string): number {
    // Remove any numbers from the columnName
    columnName = columnName.replace(/\d+/g, '');

    let columnNumber = 0;
    let multiplier = 1;
    for (let i = columnName.length - 1; i >= 0; i--) {
      const charCode = columnName.charCodeAt(i) - 64; // A's charCode is 65
      columnNumber += charCode * multiplier;
      multiplier *= 26;
    }
    return columnNumber;
  }

  /**
   * Apply conditional formatting to a column based on multiple conditions.
   *
   * @param {ExcelJS.Worksheet} worksheet - The worksheet.
   * @param {string | number} column - The column to apply formatting (e.g., 'A', 'B' or 1, 2).
   * @param {Array} conditions - Array of conditions, each having a formula and color.
   * @param {number} priority - The priority of the rule.
   * @param {boolean} headerOnly - Whether to only apply formatting to the header row.
   */
  applyConditionalFormatting(
    worksheet: ExcelJS.Worksheet,
    column: string | number,
    conditions: { formula: string | string[]; color?: string }[],
    priority = 100,
    headerOnly = false,
  ) {
    // Convert column number to column letter if necessary
    let columnLetter: string = column as string;
    if (typeof column === 'number')
      columnLetter = this.columnNumberToLetter(column);

    const fullColumnAddress = `${columnLetter}${
      headerOnly ? 1 : ''
    }:${columnLetter}${headerOnly ? 1 : ''}`;
    conditions.forEach((condition) => {
      let currentFormulas: string[] = [condition.formula?.toString()];
      if (Array.isArray(condition.formula)) currentFormulas = condition.formula;
      const rules: {
        type: string;
        priority: number;
        formulae: string[];
        style?: {
          fill: { type: string; bgColor: { argb: string }; pattern: string };
        };
      }[] = [
        {
          type: 'expression',
          priority,
          formulae: currentFormulas,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: condition.color || '' },
            },
          },
        },
      ];
      // if no color passed in then they don't want a fill so remove the fill
      if (!condition.color) delete rules[0]?.style;
      currentFormulas.forEach((formula) => {
        worksheet.addConditionalFormatting({
          ref: fullColumnAddress,
          rules: [
            {
              type: 'expression',
              priority,
              formulae: [formula],
              style: {
                fill: {
                  type: 'pattern',
                  pattern: 'solid',
                  bgColor: { argb: condition.color || '' },
                },
              },
            },
          ],
        });
      });
    });
  }
  /**
   * Will give you an array of indexes of valueToFind values in a string, ignoring sections in double quotes and ensuring whole word match
   * @param input string you want indexes of
   * @param valueToFind the value you are looking for in the string
   * @returns array of indexes with valueToFind values
   */
  #getAllIndexes(input: string, valueToFind: string): number[] {
    const indexes: number[] = [];
    let inQuotes = false;

    for (let i = 0, wordIndex = 0; i < input.length; i++) {
      if (input[i] === '"') {
        inQuotes = !inQuotes; // Toggle the inQuotes flag when encountering a double quote
      }

      // Check if the current segment of the word matches valueToFind
      // and that it is not in quotes
      if (!inQuotes && input.slice(i, i + valueToFind.length) === valueToFind) {
        // Check that we're at the start of a word or after a space, and the match is followed by a space or end of the string
        if (
          (i === 0 || input[i - 1] === ' ') &&
          (i + valueToFind.length === input.length ||
            input[i + valueToFind.length] === ' ')
        )
          indexes.push(wordIndex);
      }

      // Increment wordIndex when we encounter a space
      // even if in quotes since the rest of the code splits by spaces
      if (input[i] === ' ') {
        wordIndex++;
      }
    }

    return indexes;
  }

  #findNextKeyword(currentIndex, orPositions, andPositions) {
    const nextOr = orPositions.find((index) => index > currentIndex);
    const nextAnd = andPositions.find((index) => index > currentIndex);

    // Get the minimum of the found positions (ignoring undefined values)
    const nextPosition = Math.min(...[nextOr, nextAnd].filter(Boolean));

    switch (nextPosition) {
      case nextOr:
        return 'or';
      case nextAnd:
        return 'and';
      default:
        return null; // None of the keywords were found after the current index
    }
  }

  #getNumericPart(key) {
    const match = key.match(/\d+$/); // Matches the trailing numbers in the string
    return match ? parseInt(match[0], 10) : 0;
  }

  convertToExcelFormula(
    expression: string,
    valuesToReplace: { [prefix: string]: { [key: string]: string } },
  ) {
    if (!expression) return expression;
    // replace || and && with OR and AND
    expression = this.#replaceOutsideQuotes(expression?.toString());

    const convertString = (input: string) => {
      const orPositions = this.#getAllIndexes(input, 'or'),
        andPositions = this.#getAllIndexes(input, 'and');
      const newExpression: string[] = [];
      let currentKeyword = '',
        parenthesis = 0,
        inQuotes = false;
      input.split(' ').forEach((word, index) => {
        const nextKeyword = this.#findNextKeyword(
          index,
          orPositions,
          andPositions,
        );
        if (nextKeyword !== currentKeyword && nextKeyword) {
          // close out the previous one
          if (currentKeyword) {
            newExpression.push('), ');
            parenthesis--;
          }
          // start a new one
          if (['and', 'or'].includes(nextKeyword)) {
            newExpression.push(nextKeyword + ' (');
            parenthesis++;
          }
          currentKeyword = nextKeyword;
          inQuotes = false;
        }
        if (word === '"') inQuotes = !inQuotes;
        if (!['and', 'or'].includes(word)) {
          if (word === '(' && !inQuotes) parenthesis++;
          else if (word === ')' && !inQuotes) parenthesis--;
          newExpression.push(word);
        } else if (['and', 'or'].includes(word)) {
          if (orPositions.includes(index) || andPositions.includes(index))
            newExpression.push(','); // need a comma to separate the keywords
          else newExpression.push(word);
        }
      });
      while (parenthesis > 0) {
        newExpression.push(')');
        parenthesis--;
      }
      // remove the trailing comma
      if (newExpression[newExpression.length - 1]?.trim()?.endsWith(',')) {
        newExpression[newExpression.length - 1] = newExpression[
          newExpression.length - 1
        ]
          ?.trim()
          ?.substring(
            0,
            newExpression[newExpression.length - 1]?.trim()?.length - 1,
          );
      }
      return newExpression.join(' ');
    };

    const values = this.#extractExpressions(expression);
    let result = convertString(values.result);
    let changed = true;
    // there could be multiple layers so we need to loop
    while (changed) {
      changed = false;
      const sortedKeys = Object.keys(values.expressions).sort(
        (a, b) => this.#getNumericPart(b) - this.#getNumericPart(a),
      );
      sortedKeys.forEach((key) => {
        if (result.includes(key)) {
          changed = true;
          result = result.replace(key, convertString(values.expressions[key]));
        }
      });
    }
    // now lets replace the values
    Object.keys(valuesToReplace).forEach((prefix) => {
      const valuesToAppend = Object.keys(valuesToReplace[prefix]).sort(
        (a, b) => {
          if (a?.length > b?.length) return -1;
          if (a?.length < b?.length) return 1;
          return 0;
        },
      );
      valuesToAppend.forEach((value) => {
        const regexPattern = new RegExp(prefix + '\\.' + value, 'g');
        result = result.replace(regexPattern, valuesToReplace[prefix][value]);
      });
    });
    const value = this.#replaceOutsideQuotes(result, true);
    return this.#replaceInStatementWithOrFormula(value);
  }

  #replaceOutsideQuotes(expression, postReplacements?: boolean) {
    let inQuotes = false;
    let result = '';
    let buffer = '';

    for (let i = 0; i < expression.length; i++) {
      const char = expression[i];
      if (char === '"' && (i === 0 || expression[i - 1] !== '\\')) {
        if (inQuotes) {
          // Close the quote and append buffered text
          result += '"' + buffer + '"';
          buffer = '';
        } else {
          // Apply pending replacements to result before opening quote
          if (postReplacements) result += this.#applyPostReplacements(buffer);
          else result += this.#applyReplacements(buffer);
          buffer = '';
        }
        inQuotes = !inQuotes;
      } else {
        buffer += char;
      }
    }
    // Apply replacements to the last buffered segment if not in quotes
    if (postReplacements) result += this.#applyPostReplacements(buffer);
    else result += this.#applyReplacements(buffer);
    return result;
  }

  #applyPostReplacements(text) {
    return text
      ?.replace(/(?<!')!('[\w\s]+'![A-Z]+\d+)/g, 'ISBLANK($1)')
      ?.replace(/(?<!')!([A-Z]+\d+)/g, 'ISBLANK($1)')
      ?.replace(/(?<!')!('[\w\s]+'![A-Z]+\*rowNumber\*)/g, 'ISBLANK($1)')
      ?.replace(/(?<!')!([A-Z]+\*rowNumber\*)/g, 'ISBLANK($1)')
      ?.replace(/\) /g, ')')
      ?.replace(/ \)/g, ')')
      ?.replace(/\( /g, '(')
      ?.replace(/ \(/g, '(')
      ?.replace(/ {2}/g, ' ')
      ?.replace(/ {2}/g, ' ');
  }

  #applyReplacements(text) {
    return text
      ?.replace(/\|\|/g, ' or ')
      ?.replace(/&&/g, ' and ')
      ?.replace(/\(/g, ' ( ')
      ?.replace(/\)/g, ' ) ')
      ?.replace(/!=/g, ' <> ')
      ?.replace(/==/g, ' = ')
      ?.replace(/ {2}/g, ' ')
      ?.replace(/ {2}/g, ' ');
  }

  #replaceInStatementWithOrFormula(largerStr) {
    return (
      largerStr
        // this checks for cell reference in an array
        ?.replace(
          /('[\w\s]+'![A-Z]+\*rowNumber\*) in \[(.*?)\]/g,
          (match, cellReference, valueStr) => {
            const values = valueStr
              .split(',')
              .map((s) => s.trim().slice(1, -1)); // remove quotes
            const conditions = values
              .map((value) => `${cellReference}="${value}"`)
              .join(', ');
            return `OR(${conditions})`;
          },
        )
        ?.replace(
          // this checks for a specific cell reference in an array
          /('[\w\s]+'![A-Z]+\d+) in \[(.*?)\]/g,
          (match, cellReference, valueStr) => {
            const values = valueStr
              .split(',')
              .map((s) => s.trim().slice(1, -1)); // remove quotes
            const conditions = values
              .map((value) => `${cellReference}="${value}"`)
              .join(', ');
            return `OR(${conditions})`;
          },
        )
        // this checks for a word in an array
        ?.replace(/(\w+) in \[(.*?)\]/g, (match, cellReference, valueStr) => {
          const values = valueStr?.split(',').map((s) => s.trim().slice(1, -1)); // remove quotes
          const conditions = values
            .map((value) => `${cellReference}="${value}"`)
            .join(', ');
          return `OR(${conditions})`;
        })
        // sometimes we have a hardcoded value checked against values in a cell
        ?.replace(
          /("[^"]*") in ('[\w\s]+'![A-Z]+\*rowNumber\*)/g,
          (match, valueToCheck, cellReference) => {
            return `ISNUMBER(FIND(${valueToCheck},${cellReference}))`;
          },
        )
    );
  }

  #extractExpressions(input: string) {
    let result = input;
    const expressions = {};
    let count = 1;
    let depth = 0;
    const startPos: { [index: number]: number } = {};
    let inQuotes = false;

    for (let i = 0; i < input.length; i++) {
      if (input[i] === '"') inQuotes = !inQuotes;
      if (input[i] === '(' && !inQuotes) {
        startPos[depth] = i;
        depth++;
      } else if (input[i] === ')' && !inQuotes) {
        depth--;

        const key = `expression${count}`;
        expressions[key] = input.substring(startPos[depth] + 1, i);
        result = result.replace(input.substring(startPos[depth], i + 1), key);
        input = input.replace(input.substring(startPos[depth], i + 1), key);
        count++;
        i = startPos[depth] + key.length - 1; // Adjust i to new position after replacement
      }
    }

    // Remove inner expressions from outer ones
    Object.keys(expressions).forEach((key) => {
      for (const innerKey of Object.keys(expressions)) {
        const innerExpr = `(${expressions[innerKey]})`;
        if (expressions[key].includes(innerExpr)) {
          expressions[key] = expressions[key].replace(innerExpr, innerKey);
        }
      }
    });

    return { result, expressions };
  }

  loadExcelFile(file) {
    return new Promise<ExcelJS.Workbook>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async function (event) {
        const data = event?.target?.result;
        if (!data) reject(new Error('File could not be read'));
        const workbook = new ExcelJS.Workbook();
        try {
          await workbook.xlsx.load(data as Buffer); // Load the binary data
          resolve(workbook); // Resolve the promise with the loaded workbook
        } catch (err) {
          reject(err); // Reject the promise in case of an error
        }
      };

      reader.onerror = function (event) {
        reject(new Error('File could not be read: ' + event?.target?.error));
      };

      reader.readAsArrayBuffer(file); // Read the file as binary data
    });
  }

  async readExcelWorkbook(
    file: File,
    sheetMapping: { [sheetName: string]: string },
    columnMapping: {
      [key: string]: EquipmentField[];
    },
    keysToIgnoreDefaultCheck?: { [key: string]: string[] },
    includeSheetInformation?: boolean,
    includeValidationErrors?: boolean,
  ) {
    // first add a mapping for things that are too long
    const newSheetMapping = JSON.parse(JSON.stringify(sheetMapping));
    Object.keys(newSheetMapping)?.forEach((sheetName) => {
      // have to only grab the first 30 characters since excel has a sheet name limit
      if (sheetName?.length > 30)
        newSheetMapping[sheetName.substring(0, 31)] =
          newSheetMapping[sheetName];
    });
    const workbook = await this.loadExcelFile(file);
    const data: { [key: string]: { [key: string]: any }[] } = {};
    const acceptedSheetNames = await this.getSheetNames(workbook);
    const errors: Error[] = [];
    for (const worksheet of workbook.worksheets) {
      const index = workbook.worksheets.indexOf(worksheet);
      const sheetName = worksheet.name?.startsWith('Equipment Leaks')
        ? 'Equipment Leaks'
        : worksheet?.name;
      if (!newSheetMapping[sheetName]) continue;
      // remove ignored sheets from being processed
      if (acceptedSheetNames && !acceptedSheetNames.includes(worksheet.name))
        continue;
      if (!data[newSheetMapping[sheetName]])
        data[newSheetMapping[sheetName]] = [];
      try {
        data[newSheetMapping[sheetName]] = data[
          newSheetMapping[sheetName]
        ].concat(
          await this.#readSheetWithoutBlackedOutCells(
            worksheet,
            index,
            columnMapping[newSheetMapping[sheetName]],
            workbook,
            keysToIgnoreDefaultCheck?.[newSheetMapping[sheetName]] || [],
            includeSheetInformation,
            includeValidationErrors,
          ),
        );
      } catch (err) {
        errors.push(err);
      }
    }
    if (errors.length) throw errors;

    return data;
  }

  #getConditionalRowMapping(rows: ExcelJS.Row[] | undefined) {
    const rowMapping: { [rowNumber: number]: number } = {};
    if (!rows) return rowMapping;
    let newRowNumber = 0,
      countOfErrors = 0,
      lastError = true;
    rows.forEach((row) => {
      // we need to loop through the cells and see if there are any errors
      let hasErrors = 0,
        hasValues = 0;
      row.eachCell((cell) => {
        // #REF! is an error when it is referencing a cell that doesn't exist which happens when you delete a row
        if ((cell?.result as any)?.error === '#REF!') hasErrors++;
        else hasValues++;
      });
      if (hasErrors && !hasValues) {
        // we have an error in this row meaning it was deleted
        countOfErrors++;
        lastError = true;
      } else {
        newRowNumber++;
        // no error check if last row was an error
        if (lastError && countOfErrors)
          rowMapping[newRowNumber] = countOfErrors;
        lastError = false;
      }
    });
    return rowMapping;
  }

  async getSheetNames(
    workbook: ExcelJS.Workbook,
    includeIgnoredSheetNames?: boolean,
  ) {
    return workbook?.worksheets
      ?.map((worksheet) => worksheet.name)
      ?.filter(
        (name) =>
          includeIgnoredSheetNames ||
          (!name?.toLowerCase()?.endsWith('-i') &&
            !name?.toLowerCase()?.endsWith('-ignore') &&
            !name?.toLowerCase()?.endsWith('- ignore')),
      );
  }

  createWorkbook() {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = this._perms.userData?.email
      ? this._perms.userData?.email
      : 'Unknown';
    return workbook;
  }

  addWorksheet(
    workbook: ExcelJS.Workbook,
    name: string,
    state: 'visible' | 'hidden' | 'veryHidden' = 'visible',
  ) {
    const newSheet = workbook.addWorksheet(name);
    newSheet.state = state;
    return newSheet;
  }

  async #readSheetWithoutBlackedOutCells(
    worksheet: ExcelJS.Worksheet,
    sheetIndex: number,
    columnMapping: EquipmentField[],
    workbook: ExcelJS.Workbook,
    keysToIgnoreDefaultCheck?: string[],
    includeSheetInformation?: boolean,
    includeValidationErrors?: boolean,
  ) {
    const headers: string[] = ['empty since it is 1 index'],
      headersDescription: string[] = ['empty since it is 1 index'],
      data: { [key: string]: any }[] = [];
    // we can grab the conditional formatting that is javasdcript by grabbing the hidden sheet for this sheet
    // we remove spaces and add -c-i to signify conditional and ignore
    // there is a 30 character limit in excel so that is why we use one letter and remove spaces
    const conditionalWorksheet = workbook.getWorksheet(
        worksheet.name?.replace(/ /g, '')?.substring(0, 27) + '-c-i',
      ),
      optionsWorkSheet = workbook.getWorksheet(
        worksheet.name?.replace(/ /g, '')?.substring(0, 27) + '-o-i',
      ),
      fileValidationWorkSheet = workbook.getWorksheet('file-validation-i');
    if (!conditionalWorksheet || !optionsWorkSheet)
      throw {
        title: 'Validation error',
        message:
          'Please use our templates as they have logic needed to process the data',
      };
    const fileValidation: { [key: string]: any } = {};
    if (fileValidationWorkSheet) {
      for (let i = 1; i <= fileValidationWorkSheet.rowCount; i++) {
        const row = fileValidationWorkSheet.getRow(i);
        const key = row.getCell(1).value?.valueOf()?.toString(),
          value = row.getCell(2).value?.valueOf()?.toString();
        if (key) fileValidation[key] = value;
      }
    }
    if (
      fileValidation?.maxRows &&
      Number(fileValidation?.maxRows) < worksheet.rowCount - 1
    ) {
      throw {
        title: 'Validation error',
        message: `Sheet: ${
          worksheet.name
        }, The maximum number of rows configured on this template is: ${
          Number(fileValidation?.maxRows) + 1
        }, you have: ${
          worksheet.rowCount
        }. Please repull with the appropriate max rows selected.`,
      };
    }
    // we need to mark which rows got deleted
    let hasConditional = 0,
      hasValues = 0;
    const conditionalRowMapping = this.#getConditionalRowMapping(
      conditionalWorksheet.getRows(1, conditionalWorksheet.rowCount),
    );
    const rowNumbers = Object.keys(conditionalRowMapping)?.sort(
      (a, b) => Number(a) - Number(b),
    );
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const currentData: {
        [key: string]: any;
        _errors?: SheetValidationError[];
      } = {};
      let hasNonDefaultValue = false;
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (rowNumber === 1) {
          const foundMapping = columnMapping?.find(
            (mapping) => mapping.description === cell.value?.toString(),
          );
          let header = foundMapping?.key;
          if (!header) {
            if (cell?.value?.toString()?.toLowerCase() === 'id') header = 'id';
            else header = cell?.value?.toString()?.toLowerCase();
            if (!header) header = '';
          }
          headers.push(header);
          headersDescription.push(cell?.value?.toString() || '');
          return;
        }
        const columnLetter = this.columnNumberToLetter(colNumber);
        const options = optionsWorkSheet?.getColumn(columnLetter)
          ?.values as string[];
        const foundColumn = columnMapping?.find(
          (mapping) => mapping.description === headersDescription[colNumber],
        );
        let hidden = false;
        // we need to find if we need to add anything to our row number, this is for deleted rows moving the conditional logic
        let numberToAdd = 0;
        rowNumbers.forEach((currentRowNumber) => {
          if (Number(currentRowNumber) <= rowNumber)
            numberToAdd = conditionalRowMapping[currentRowNumber];
        });
        const conditionalResult = conditionalWorksheet
            ?.getRow(rowNumber + numberToAdd)
            ?.getCell(columnLetter)?.result as unknown as boolean,
          conditionalFormula = (
            conditionalWorksheet
              ?.getRow(rowNumber + numberToAdd)
              ?.getCell(columnLetter)?.value as any
          )?.formula;
        if (conditionalFormula) hasConditional++;
        if (conditionalResult !== undefined) {
          hasValues++;
          if (conditionalResult === true) hidden = true;
        }
        if (hidden) return;
        if (cell.value && options?.length) {
          // if it has a set of options, make sure it matches because data validation is not case sensitive
          const value = options?.find(
            (v) =>
              v?.toString()?.toLowerCase() ===
              cell.value?.toString()?.toLowerCase(),
          );
          if (value === undefined && includeValidationErrors) {
            if (!currentData._errors) currentData._errors = [];
            currentData._errors.push({
              sheetName: worksheet.name,
              sheetIndex,
              labelCell: this.columnNumberToLetter(colNumber) + '1',
              label: headers[colNumber],
              currentValueCell:
                this.columnNumberToLetter(colNumber) + rowNumber?.toString(),
              currentValue: cell.value?.toString(),
              expectedValue: `However, [${cell.value}] was not in the list of options. Please pick from the dropdown.`,
            });
          }
          cell.value = value;
        }
        currentData[headers[colNumber]] = cell.formula
          ? cell.result
          : (cell.value as any);
        // convert all nulls to empty strings, we will assume empty strings are empty
        if (currentData[headers[colNumber]] === null)
          currentData[headers[colNumber]] = '';
        if (currentData[headers[colNumber]] === undefined)
          currentData[headers[colNumber]] = '';
        // don't do the default check if the key is in the list to ignore
        if (keysToIgnoreDefaultCheck?.includes(foundColumn?.key as string))
          return;
        // check if it is multiple and if so convert it into an array
        if (foundColumn?.multiple) {
          if (
            foundColumn?.multiple &&
            currentData[headers[colNumber]]?.toString()?.includes(',')
          )
            currentData[headers[colNumber]] = currentData[headers[colNumber]]
              ?.toString()
              ?.split(',')
              ?.map((value) => value?.trim());
          else if (
            foundColumn?.multiple &&
            !Array.isArray(currentData[headers[colNumber]]) &&
            currentData[headers[colNumber]]
          )
            currentData[headers[colNumber]] = [
              currentData[headers[colNumber]]?.toString()?.trim(),
            ];
        }
        if (
          includeValidationErrors &&
          foundColumn?.number &&
          foundColumn?.type === 'input' &&
          (currentData[headers[colNumber]] ||
            currentData[headers[colNumber]] === 0 ||
            currentData[headers[colNumber]] === '0')
        ) {
          // do number validations here
          if (isNaN(currentData[headers[colNumber]])) {
            if (!currentData._errors) currentData._errors = [];
            currentData._errors.push({
              sheetName: worksheet.name,
              sheetIndex,
              labelCell: this.columnNumberToLetter(colNumber) + '1',
              label: headersDescription[colNumber],
              currentValueCell:
                this.columnNumberToLetter(colNumber) + rowNumber?.toString(),
              currentValue: currentData[headers[colNumber]],
              expectedValue: `However, [${
                currentData[headers[colNumber]]
              }] is not a number.`,
            });
          }
          if (
            (foundColumn?.min || foundColumn?.min?.toString() === '0') &&
            !isNaN(Number(foundColumn?.min)) &&
            Number(currentData[headers[colNumber]]) < Number(foundColumn?.min)
          ) {
            if (!currentData._errors) currentData._errors = [];
            currentData._errors.push({
              sheetName: worksheet.name,
              sheetIndex,
              labelCell: this.columnNumberToLetter(colNumber) + '1',
              label: headersDescription[colNumber],
              currentValueCell:
                this.columnNumberToLetter(colNumber) + rowNumber?.toString(),
              currentValue: currentData[headers[colNumber]],
              expectedValue: `However the minimum value is: ${foundColumn?.min}`,
            });
          }
          if (
            (foundColumn?.max || foundColumn?.max?.toString() === '0') &&
            !isNaN(Number(foundColumn?.max)) &&
            Number(currentData[headers[colNumber]]) > Number(foundColumn?.max)
          ) {
            if (!currentData._errors) currentData._errors = [];
            currentData._errors.push({
              sheetName: worksheet.name,
              sheetIndex,
              labelCell: this.columnNumberToLetter(colNumber) + '1',
              label: headersDescription[colNumber],
              currentValueCell:
                this.columnNumberToLetter(colNumber) + rowNumber?.toString(),
              currentValue: currentData[headers[colNumber]],
              expectedValue: `However the maximum value is: ${foundColumn?.max}`,
            });
          }
        }
        // convert dates to numbers
        if (
          foundColumn?.type === 'date' &&
          currentData[headers[colNumber]] &&
          currentData[headers[colNumber]] instanceof Date
        )
          currentData[headers[colNumber]] = Number(
            currentData[headers[colNumber]],
          );

        if (
          currentData[headers[colNumber]] !== '' &&
          (!foundColumn ||
            (!foundColumn.value &&
              foundColumn.value !== 0 &&
              foundColumn.value !== false) ||
            currentData[headers[colNumber]]?.toString() !==
              foundColumn?.value?.toString())
        )
          hasNonDefaultValue = true;
      });
      if (includeSheetInformation) {
        currentData.sheetIndex = sheetIndex;
        currentData.sheetName = worksheet.name;
        currentData.rowNumber = rowNumber;
      }
      if (rowNumber > 1 && hasNonDefaultValue) data.push(currentData);
    });
    if (hasConditional && !hasValues)
      throw {
        title: 'Validation error',
        message: 'Please save the file so all conditional logic can be applied',
      };
    return data;
  }
  getFirstRowLabelValidationErrors(
    workbook: ExcelJS.Workbook,
    validLabels: string[],
  ): SheetValidationError[] | null {
    const newErrors: any[] = [];
    workbook.eachSheet((sheet) => {
      const sheetName = sheet.name;
      // skip ignored sheets
      if (
        sheetName?.toLowerCase()?.endsWith('-i') ||
        sheetName?.toLowerCase()?.endsWith('-ignore')
      )
        return;
      // we need to grab the first row and check if the column headers are in the list of valid columns
      sheet.getRow(1).eachCell({ includeEmpty: true }, (cell, colNumber) => {
        const currentValue = cell.value?.toString();
        if (!currentValue) return;
        if (!validLabels.includes(currentValue)) {
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex: 0,
            labelCell: '',
            label: currentValue,
            currentValueCell: this.columnNumberToLetter(colNumber) + '1',
            currentValue,
            expectedValue: `However, [${currentValue}] was not in the list of valid columns. Please use the latest Iconic Air Template.`,
          };

          newErrors.push(newError);
        }
      });
    });

    if (newErrors && newErrors?.length > 0) {
      return newErrors;
    } else return null;
  }

  createWorksheets(workbook: ExcelJS.Workbook, title: string) {
    const regularTab = workbook.addWorksheet(title);
    // make the sheets needed for drop downs and for conditionals
    // remove spaces and use abreviations due to excel 30 character limit
    const conditionalsTab = workbook.addWorksheet(
      title?.substring(0, 31)?.replace(/ /g, '')?.substring(0, 27) + '-c-i',
    );
    conditionalsTab.state = 'veryHidden'; // make it impossible to find
    const optionsTab = workbook.addWorksheet(
      title?.substring(0, 31)?.replace(/ /g, '')?.substring(0, 27) + '-o-i',
    );
    optionsTab.state = 'veryHidden'; // make it impossible to find
    return {
      regularTab,
      conditionalsTab,
      optionsTab,
    };
  }

  setColumns(sheet: ExcelJS.Worksheet, columns: any[], xSplit = 0, ySplit = 1) {
    sheet.columns = columns;
    sheet.views = [{ state: 'frozen', ySplit, xSplit }];
    sheet.autoFilter = this.getAutoFilter(sheet);
  }

  getNumberOfRows(
    wb: XLSX.WorkBook,
    sheetName: string,
    firstRow: number,
  ): number {
    let currentRow = firstRow;
    let currentValue;
    let numRows = 0;
    do {
      currentValue =
        wb.Sheets[sheetName]['A' + currentRow]?.v ||
        wb.Sheets[sheetName]['B' + currentRow]?.v ||
        wb.Sheets[sheetName]['C' + currentRow]?.v ||
        wb.Sheets[sheetName]['D' + currentRow]?.v ||
        wb.Sheets[sheetName]['E' + currentRow]?.v;
      currentRow++;
      if (currentValue) {
        numRows++;
      }
    } while (currentValue !== undefined);

    return numRows;
  }

  convertExcelJSToXLSX(excelJSWorkbook): Promise<XLSX.WorkBook> {
    return new Promise((resolve, reject) => {
      try {
        const xlsxWorkbook = XLSX.utils.book_new();
        excelJSWorkbook.eachSheet((sheet) => {
          const xlsxWorksheet = XLSX.utils.aoa_to_sheet(sheet.getSheetValues());
          XLSX.utils.book_append_sheet(xlsxWorkbook, xlsxWorksheet, sheet.name);
        });

        resolve(xlsxWorkbook);
      } catch (error) {
        reject(error);
      }
    });
  }

  workbookToFile(workbook: ExcelJS.Workbook, filename: string): Promise<File> {
    return new Promise((resolve, reject) => {
      try {
        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            const blob = new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const file = new File([blob], filename, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            resolve(file);
          })
          .catch(reject);
      } catch (error) {
        reject(error);
      }
    });
  }
}
