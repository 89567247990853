import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';
import { Leak, Facility, Attempt } from '@iconic-air/models';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import moment from 'moment';
// component imports
export interface Image {
  downloadURL: string;
  extType: string;
  operator: string;
  path: string;
  photoQuery: string;
}

@Component({
  selector: 'app-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss'],
})
export class MapboxComponent implements OnInit, OnChanges {
  // non media variables and arrays
  public infoArray = [];
  public jobArray = [];
  public firstComponent = '';
  componentsArray$: Observable<any[]>;
  initCustomer: string;
  componentsJson: any[];
  public currentMarkers: any[] = [];
  //mapbox variables
  public markerColor: string;
  public markerLat: number;
  public markerLong: number;
  public markerId: number;
  @Input() componentMedia: number;
  @Input() selectedLeakIndex = -1;
  @Input() selectedLeak: Leak;
  @Input() facility: Facility;
  @Input() reportingFacility: Facility;
  @Input() showAllLeakMarkers?: boolean;
  @Input() isSurveyDetailsPage?: boolean;
  @Input() coordinates: Leak[] | Facility[] = [];

  private imagesCollection: AngularFirestoreCollection<Image>;
  images$: Observable<Image[]>;
  marker = new mapboxgl.Marker();
  map: mapboxgl.Map;
  urlGeojson =
    'https://iconic-dev-public.s3.us-east-2.amazonaws.com/james-dev/mapbox_test.geojson';
  urlTif =
    'https://iconic-tifs.s3.us-east-2.amazonaws.com/equitrans_pilot_1.tif';
  style = 'mapbox://styles/mapbox/satellite-v9';

  constructor(
    public authService: AuthenticationWebService,
    public dialog: MatDialog,
  ) {}

  @HostListener('document:mapbox-leak-popup-selected', ['$event'])
  onMapBoxLeakPopupSelected(event: CustomEvent) {
    this.editLeak(
      (this.coordinates as Leak[]).find((l) => l.id === event.detail.leakId),
    );
  }

  ngOnInit() {
    // mapbox stuff
    (mapboxgl as any).accessToken =
      'pk.eyJ1IjoianBjYXJuZXMxIiwiYSI6ImNrNnFyd2J1eDA1ZXEzb3BucHlxaHpzbWcifQ.rlCACTjGs3qQH7_YHmsk6A';

    const mapOptions: any = {
      container: 'map-box',
      style: this.style,
    };

    const center: number[] = [];
    if (this.facility?.longitude) {
      const longitude =
        typeof this.facility?.longitude === 'string'
          ? parseFloat(this.facility?.longitude)
          : this.facility?.longitude;
      center.push(+longitude);
    }
    if (this.facility?.latitude) {
      const latitude =
        typeof this.facility?.latitude === 'string'
          ? parseFloat(this.facility?.latitude)
          : this.facility?.latitude;
      center.push(+latitude);
    }

    mapOptions.center = center;
    if (mapOptions.center.length) {
      mapOptions.zoom = 16;
    } else {
      mapOptions.center = [-93.24312722927598, +37.68822485309793];
      mapOptions.zoom = 2;
    }

    this.map = new mapboxgl.Map(mapOptions);
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.on('load', this.onLoad.bind(this));
  }

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.showAllLeakMarkers) {
      for (let i = this.currentMarkers.length - 1; i >= 0; i--) {
        this.currentMarkers[i].remove();
      }
      this.coordinates?.forEach((coordinate, index) => {
        if (
          !isNaN(+(coordinate as Leak)?.location?.longitude) &&
          !isNaN(+(coordinate as Leak)?.location?.latitude)
        ) {
          this.markerColor = this.getLeakPinColor(coordinate as Leak);
          const marker = new mapboxgl.Marker({ color: this.markerColor })
            .setLngLat([
              +(coordinate as Leak)?.location?.longitude,
              +(coordinate as Leak)?.location?.latitude,
            ])
            .setPopup(this.getLeakPopup(coordinate as Leak))
            .addTo(this.map);
          if (changes.selectedLeak?.currentValue !== (null || undefined)) {
            if (coordinate.id === changes.selectedLeak.currentValue.id) {
              if (!marker.getPopup().isOpen()) {
                marker.togglePopup();
              }
            }
          }

          this.currentMarkers.push(marker);
        }
      });

      return;
    } else if (this.showAllLeakMarkers === false) {
      // cleanup
      for (let i = this.currentMarkers.length - 1; i >= 0; i--) {
        this.currentMarkers[i].remove();
      }
    }
    // logic for changes in facility page to update map component based on date range picker
    if (changes.coordinates?.previousValue) {
      for (let i = this.currentMarkers.length - 1; i >= 0; i--) {
        this.currentMarkers[i].remove();
      }
    }
    if (changes.coordinates?.currentValue && this.map) {
      this.addMarkers(changes.coordinates.currentValue);
    }
    if (changes.selectedLeak?.currentValue !== (null || undefined)) {
      for (let i = this.currentMarkers.length - 1; i >= 0; i--) {
        this.currentMarkers[i].remove();
      }

      this.markerColor = this.getLeakPinColor(
        changes.selectedLeak.currentValue as Leak,
      );

      const marker = new mapboxgl.Marker({ color: this.markerColor })
        .setLngLat([
          +(changes.selectedLeak.currentValue as Leak)?.location?.longitude,
          +(changes.selectedLeak.currentValue as Leak)?.location?.latitude,
        ])
        .setPopup(this.getLeakPopup(changes.selectedLeak.currentValue as Leak))
        .addTo(this.map);
      if (!marker.getPopup().isOpen()) {
        marker.togglePopup();
      }
      this.currentMarkers.push(marker);
      // early return
      return;
    }

    // logic for selected leak row on facility survey page
    if (changes.selectedLeak?.currentValue) {
      for (let i = this.currentMarkers.length - 1; i >= 0; i--) {
        this.currentMarkers[i].remove();
      }

      this.coordinates?.forEach((coordinate, index) => {
        if (coordinate?.location) {
          this.markerColor = '';
          // runs if a leak is selected in the row, and sets active leak to grey and unactive leaks to blue.
          if (coordinate.id === changes.selectedLeak.currentValue.id) {
            this.markerColor = '#004BCACC';
          } else this.markerColor = '#777fa6';

          const marker = new mapboxgl.Marker({ color: this.markerColor })
            .setLngLat([
              +coordinate?.location?.longitude,
              +coordinate?.location?.latitude,
            ])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  "<h5 style='text-align:center'>" + coordinate?.companyId
                    ? 'Leak ID - '
                    : 'Iconic Air ID - ' +
                        (coordinate?.companyId
                          ? coordinate.companyId
                          : coordinate?.id?.substring(0, 6)) +
                        '</h5>' +
                        "<h5 style='text-align:center'>" +
                        (coordinate?.ppm
                          ? 'Leak concentration: ' + coordinate.ppm + ' ppm'
                          : '') +
                        '</h5>' +
                        "<h5 style='text-align:center'>" +
                        (coordinate?.detectionDate
                          ? 'Detection Time: ' +
                            moment(coordinate.detectionDate).format('LTS')
                          : '') +
                        '</h5>' +
                        '</div>',
                ),
            )
            .addTo(this.map);
          this.currentMarkers.push(marker);
        }
      });
    } else if (changes.selectedLeak?.currentValue === null) {
      for (let i = this.currentMarkers.length - 1; i >= 0; i--) {
        this.currentMarkers[i].remove();
      }
      // runs when a row is being deselected and should only run when there is no current selected leak so markers are all blue
      this.markerColor = '#777fa6';
      this.coordinates?.forEach((coordinate) => {
        if (coordinate?.location) {
          const marker = new mapboxgl.Marker({ color: this.markerColor })
            .setLngLat([
              +coordinate?.location?.longitude,
              +coordinate?.location?.latitude,
            ])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  "<h5 style='text-align:center'>" + coordinate?.companyId
                    ? 'Leak ID - '
                    : 'Iconic Air ID - ' +
                        (coordinate?.companyId
                          ? coordinate.companyId
                          : coordinate?.id?.substring(0, 6)) +
                        '</h5>' +
                        "<h5 style='text-align:center'>" +
                        (coordinate?.ppm
                          ? 'Leak Concentration: ' + coordinate.ppm + ' ppm'
                          : '') +
                        '</h5>' +
                        "<h5 style='text-align:center'>" +
                        (coordinate?.detectionDate
                          ? 'Detection Time: ' +
                            moment(coordinate.detectionDate).format('LTS')
                          : '') +
                        '</h5>' +
                        '</div>',
                ),
            )
            .addTo(this.map);
          this.currentMarkers.push(marker);
        }
      });
    }
  }

  onLoad() {
    // add facility overlay (Mapbox tiles url) if present
    // Note: backend function will add overlayTilesUrl to facility object once a geotiff file is uploaded
    if (this.facility?.overlayTilesUrl) {
      this.map.addSource('tiff-overlay', {
        type: 'raster',
        url: this.facility.overlayTilesUrl, // Ex. url: 'mapbox://jpcarnes1.15e33e91',
      });
      this.map.addLayer({
        id: 'Facility Overlay',
        source: 'tiff-overlay',
        type: 'raster',
        paint: { 'raster-opacity': 0.85 },
        layout: {
          visibility: 'visible',
        },
      });
    }

    if (this.coordinates && this.coordinates?.length) {
      if (this.isSurveyDetailsPage && !this.showAllLeakMarkers) {
        return;
      }
      this.addMarkers(this.coordinates);
    }
  }

  editLeak(leak: Leak) {}

  getLeakPinColor(leak: Leak) {
    // Successful repairs are colored #9499A7
    // Leaking leaks are colored #3042E4
    // Delay of repair leaks are colored #C4A94A
    // Other leaks are default color #abaab5
    if (leak?.status?.code === 'o') {
      return '#5664ff';
    } else if (leak?.status?.code === 'c') {
      return '#a9adb9';
    } else if (leak?.status?.code === 'd') {
      return '#e5c34c';
    }
    return '#abaab5';
  }

  getLatestRepairAttempt(leak: Leak) {
    // sort leaks by attempt date and return the latest attempt
    if (leak?.repair?.attempts.length > 0) {
      return leak.repair.attempts.sort(
        (a, b) =>
          new Date(b.attemptedDate).getTime() -
          new Date(a.attemptedDate).getTime(),
      )[0];
    }
    // this is a placeholder object with only the attemptedDate property
    else
      return {
        attemptedDate: new Date(leak.detectionDate).getTime(),
      } as unknown as Leak;
  }
  // leak.repair.attempts[leak.repair.attempts.length - 1].attemptedDate,
  getRepairDescription(leak: Leak) {
    if (leak?.status?.code === 'o') {
      return `Repair by: ${new Date(leak.dueDate).toDateString()}`;
    } else if (leak?.status?.code === 'c') {
      return `Repaired on: ${new Date(
        (this.getLatestRepairAttempt(leak) as Attempt)?.attemptedDate,
      ).toDateString()}`;
    } else if (leak?.status?.code === 'd') {
      return `Next shutdown: ${new Date(leak.dueDate).toDateString()}`;
    }
  }

  getLeakImageURL(leak: Leak) {
    if (leak.leakAssets?.length > 0) {
      return leak.leakAssets[0].file ?? '/assets/map-pins-empty-state.svg';
    } else {
      return '/assets/map-pins-empty-state.svg';
    }
  }

  getLeakImageTemplate(leak: Leak) {
    // 12rem is max-w-48 same as the image
    return (
      `
          <div class="flex flex-col rounded-lg bg-white drop-shadow-xl m-1 cursor-pointer" onClick="this.dispatchEvent(new CustomEvent('mapbox-leak-popup-selected', {bubbles:true, detail:{leakId: '${
            leak.id
          }'}}))">
            <div class="bg-white rounded-lg overflow-hidden relative w-48 h-48">
              <img class="w-48 h-48 object-cover" src="${this.getLeakImageURL(
                leak,
              )}" alt="leak image" onerror="this.src='/assets/map-pins-empty-state.svg'"
              >
              <div class="absolute top-2 left-2 right-2 py-1 px-2 opacity-80 text-white rounded-full" style="background-color: ${this.getLeakPinColor(
                leak,
              )};">
                  <p class='text-center capitalize'>${
                    leak?.status?.description
                  }</p>
              </div>
            </div>
            <h5 class='text-lg text-center font-semibold mt-5 mb-2'>
            ${leak.componentType.description}
            </h5>
            <h6 class='text-base font-light text-center mb-5 px-2 max-w-[12rem]'>
            ${this.getRepairDescription(leak)}
            </h6>
          </div>
            ` + '</div>'
    );
  }

  getLeakPopup(coordinate: Leak) {
    return new mapboxgl.Popup({ offset: 25, anchor: 'bottom' }) // add popups
      .setHTML(this.getLeakImageTemplate(coordinate as Leak));
  }

  addMarkers(cordinates) {
    cordinates?.forEach((coordinate, index) => {
      this.markerColor = '#777fa6';
      // Checks if it is a leak
      if ('surveyId' in coordinate) {
        // leaks
        this.markerColor = this.getLeakPinColor(coordinate as Leak);
      }
      if (coordinate?.location) {
        if (coordinate.type === 'qube') {
          // create a HTML element for each feature
          const el = document.createElement('div');
          el.className = 'qube-marker';

          // make a marker for each feature and add to the map
          const marker = new mapboxgl.Marker(el)
            .setLngLat([
              coordinate?.location?.longitude + index * 0.0001,
              +coordinate?.location?.latitude,
            ])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }).setHTML(
                "<h5 style='text-align:center'>" +
                  'Qube Sensor ID - ' +
                  coordinate?.alarm.id +
                  '</h5>' +
                  "<h5 style='text-align:center'>" +
                  'Trigger Value: ' +
                  coordinate.alarm?.triggerValue +
                  '</h5>' +
                  "<h5 style='text-align:center'>" +
                  'Max Value: ' +
                  coordinate.alarm?.maxValue +
                  '</h5>' +
                  "<h5 style='text-align:center'>" +
                  (coordinate?.alarm?.startTime
                    ? 'Detection Time: ' +
                      moment(coordinate.alarm.startTime).format('LTS')
                    : '') +
                  '</h5>' +
                  '</div>',
              ),
            )
            .addTo(this.map);
          this.currentMarkers.push(marker);
        } else {
          const marker = new mapboxgl.Marker({ color: this.markerColor })
            .setLngLat([
              +coordinate?.location?.longitude,
              +coordinate?.location?.latitude,
            ])
            .setPopup(this.getLeakPopup(coordinate))
            .addTo(this.map);
          this.currentMarkers.push(marker);
        }
      } else {
        if (coordinate.longitude && coordinate.latitude) {
          //facility
          const marker = new mapboxgl.Marker({ color: this.markerColor })
            .setLngLat([+coordinate.longitude, +coordinate.latitude])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  "<h5 style='text-align:center'>" +
                    coordinate?.displayName +
                    '</h5>' +
                    '</div>',
                ),
            )
            .addTo(this.map);
          this.currentMarkers.push(marker);
        }
      }
    });
  }
}
