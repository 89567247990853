import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, switchMap, map, filter, from } from 'rxjs';
import { AuthenticationWebService } from '../authentication/authentication-web.service';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class HttpService implements HttpInterceptor {
  constructor(
    private _auth: AuthenticationWebService,
    private afAuth: AngularFireAuth,
    private _http: HttpClient,
  ) {}

  #lastLoggedIdToken: string | undefined;

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.#refreshToken(req.url).pipe(
      switchMap(() => {
        if (req.url.indexOf('firestore') == -1 && !req.url.startsWith('http')) {
          req = req.clone({ url: environment.cloudFunctionUrl + req.url });
        }

        if (
          this._auth.idToken &&
          req.url.indexOf('vimeo') == -1 &&
          !req.url.startsWith('https://firebasestorage.googleapis.com')
        ) {
          req = req.clone({
            url: req.url,
            setHeaders: {
              idToken: this._auth.idToken ? this._auth.idToken : '',
            },
          });
          if (req.params?.get('paginate') === 'true') {
            const page = req.params.get('page')
              ? parseInt(req.params.get('page')!, 10)
              : 1;
            const perPage = req.params.get('perPage')
              ? parseInt(req.params.get('perPage')!, 10)
              : 10;
            return from(this.paginate(req, next, page, perPage));
          }
        } else if (req.url.indexOf('vimeo') == -1) {
          req = req.clone({
            url: req.url,
          });
        }

        return next.handle(req);
      }),
    );
  }

  #refreshToken(url: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.afAuth.currentUser
        .then((user) => {
          user?.getIdTokenResult().then((token) => {
            if (token?.token !== this.#lastLoggedIdToken) {
              this.#lastLoggedIdToken = token?.token;
              console.log(token?.token);
              console.log(token?.expirationTime);
            }
            this._auth.idToken = token?.token;
            observer.next();
            observer.complete();
          });
          if (!user) throw new Error('User not logged in');
        })
        .catch((error) => {
          if (
            url === '/signupUser' ||
            url?.startsWith('/contentManagementSystem')
          ) {
            observer.next();
            observer.complete();
            return;
          }
          throw error;
        });
    });
  }

  async getAllValues(
    url: string,
    queryParams?: string | any,
    perPage: number = 1000,
  ) {
    if (queryParams) {
      if (typeof queryParams === 'string') {
        url = `${url}?${queryParams ? queryParams + '&' : ''}`;
      } else {
        url = `${url}?paginate=true&`;
        Object.keys(queryParams).forEach((key) => {
          url += key + '=' + queryParams[key] + '&';
        });
      }
    } else url = `${url}?`;
    let data: any[] = [];
    const firstCallValues: {
      data: any[];
      perPage: number;
      currentPage: number;
      total: number;
    } = (await lastValueFrom(
      this._http.get(
        `${url}currentPage=1&perPage=${perPage}&includeTotal=true`,
      ),
    )) as {
      data: any[];
      perPage: number;
      currentPage: number;
      total: number;
    };

    data = data.concat(firstCallValues.data || []);
    if (firstCallValues.total > data.length)
      data = await this.#recursiveGetValues(
        data,
        url,
        perPage,
        2,
        firstCallValues.total,
      );
    return data;
  }

  async #recursiveGetValues(
    data: any[],
    url: string,
    perPage: number,
    pageNumber: number,
    total: number,
  ) {
    const results: {
      data: any[];
      perPage: number;
      currentPage: number;
      total?: number;
    } = (await lastValueFrom(
      this._http.get(
        `${url}currentPage=${pageNumber}&perPage=${perPage}&includeTotal=false`,
      ),
    )) as {
      data: any[];
      perPage: number;
      currentPage: number;
    };
    console.log('data length:', data.length);
    console.log('results length:', results.data.length);
    if (data.length + results.data?.length >= total)
      return data.concat(results.data);
    else
      return await this.#recursiveGetValues(
        data.concat(results.data),
        url,
        perPage,
        pageNumber + 1,
        total,
      );
  }

  private async paginate(
    req: HttpRequest<any>,
    next: HttpHandler,
    page: number,
    perPage: number,
  ): Promise<HttpResponse<any>> {
    const firstResponse: any = await lastValueFrom(
      this.fetchPage(req, next, page, perPage),
    );

    if (!firstResponse || !firstResponse.data?.length) {
      return new HttpResponse({ body: [] });
    }

    const allData = [...firstResponse.data];
    const total = firstResponse.total || allData.length;
    const totalPages = Math.ceil(total / perPage);
    if (totalPages <= page) {
      return new HttpResponse({ body: allData });
    }

    const remainingPages = Array.from(
      { length: totalPages - page },
      (_, i) => page + 1 + i,
    );

    // Choose strategy based on number of remaining pages
    // Use sequential for 10 or fewer remaining pages
    const useSequential = remainingPages.length <= 10;

    if (useSequential) {
      // Sequential implementation
      for (const pageNumber of remainingPages) {
        const response: any = await lastValueFrom(
          this.fetchPage(req, next, pageNumber, perPage),
        );
        if (response && response.data) {
          allData.push(...response.data);
        }
      }
    } else {
      // Concurrent implementation
      const pagePromises = remainingPages.map((pageNumber) =>
        lastValueFrom(this.fetchPage(req, next, pageNumber, perPage)),
      );
      const responses: any[] = await Promise.all(pagePromises);

      responses.forEach((response) => {
        if (response && response.data) {
          allData.push(...response.data);
        }
      });
    }

    return new HttpResponse({ body: allData });
  }

  private fetchPage(
    req: HttpRequest<any>,
    next: HttpHandler,
    page: number,
    perPage: number,
  ) {
    const modifiedReq = req.clone({
      params: req.params
        .set('page', page.toString())
        .set('perPage', perPage.toString())
        .set('paginate', true)
        .set('includeTotal', true),
    });

    return next.handle(modifiedReq).pipe(
      filter((event) => event instanceof HttpResponse), // Ignore OPTIONS requests
      map((event: HttpResponse<any>) => event.body), // Return only response bodies
    );
  }
}
