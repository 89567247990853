import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';

import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { AdminService } from '../../pages/admin/admin.service';

@Component({
  selector: 'app-chart-title-edit',
  templateUrl: './chart-title-edit.component.html',
  styleUrls: ['./chart-title-edit.component.scss'],
})
export class ChartTitleEditComponent implements OnInit, OnDestroy {
  get isAdmin() {
    return this.perms.userData?.isAdmin;
  }
  @Input() isEditMode: boolean;
  @Input() chartKey: string;
  @Input() defaultTitle: string;
  @Input() tooltipText: string;
  @Output() editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  newTitle: string;
  customerTitle: string;
  canEditCategoriesNames: boolean;
  canEditOverviewTitles: boolean;
  chartTitle$: Subscription;

  constructor(
    private perms: PermissionsWebGuard,
    private afs: AngularFirestore,
    public adminService: AdminService,
  ) {
    /*  this.adminService.getDashboardSettings().subscribe((doc) => {
      const payload = doc.payload.data();
      if (payload) {
        this.canEditCategoriesNames = payload?.canEditCategoriesNames
          ? payload?.canEditCategoriesNames
          : false;
        this.canEditOverviewTitles = payload?.canEditOverviewTitles
          ? payload?.canEditOverviewTitles
          : false;
      }
    }); */
    /* if () */
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.chartTitle$?.unsubscribe();
  }

  ngOnInit(): void {
    this.getCurrentTitle();
  }

  saveTitle(): void {
    this.editModeChange.emit(false);

    this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('chartTitles')
      .update({ [this.chartKey]: this.newTitle });
  }

  getCurrentTitle(): void {
    this.chartTitle$ = this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('chartTitles')
      .snapshotChanges()
      .subscribe((doc) => {
        const payload = doc.payload.data();
        if (payload) {
          this.customerTitle = payload[this.chartKey];
          this.customerTitle
            ? (this.newTitle = this.customerTitle)
            : (this.newTitle = this.defaultTitle);
        } else this.newTitle = this.defaultTitle;
      });
  }
}
