import { NgModule } from '@angular/core';
import { ClientsFilterPipe } from './/clients-filter.pipe';
import { FilterCustomerSelectionPipe } from '../components/sign-in-or-register-modal/filter-customer-selection.pipe';
import { TooltipDataPipe } from './tooltip-data.pipe';
import { TypeOfPipe } from './type-of.pipe';
import { SortPipe } from './sort.pipe';
import { FilterFacilityByReportingFacilityPipe } from './filter-facility-by-reporting-facility.pipe';
import { HighlightPipe } from './highlight.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
import { FacilityFilterPipe } from './facility-filter.pipe';
import { EmissionPipe } from './emission.pipe';
import { RoundPipe } from './round.pipe';
import { ProgramTypePipe } from './program-type.pipe';
import { CameraPipe } from './camera.pipe';
import { PrependItemPipe } from './prepend-item/prepend-item.pipe';
import { RoleTypePipe } from './role-type.pipe';
import { ScientificNotationPipe } from './scientific-notation.pipe';

@NgModule({
  imports: [],
  declarations: [
    ClientsFilterPipe,
    FilterCustomerSelectionPipe,
    FilterFacilityByReportingFacilityPipe,
    TooltipDataPipe,
    TypeOfPipe,
    SortPipe,
    HighlightPipe,
    SentenceCasePipe,
    FacilityFilterPipe,
    EmissionPipe,
    RoundPipe,
    ProgramTypePipe,
    CameraPipe,
    PrependItemPipe,
    RoleTypePipe,
    ScientificNotationPipe,
  ],
  exports: [
    ClientsFilterPipe,
    FilterCustomerSelectionPipe,
    FilterFacilityByReportingFacilityPipe,
    SortPipe,
    TooltipDataPipe,
    TypeOfPipe,
    HighlightPipe,
    SentenceCasePipe,
    FacilityFilterPipe,
    EmissionPipe,
    RoundPipe,
    ProgramTypePipe,
    CameraPipe,
    PrependItemPipe,
    RoleTypePipe,
    ScientificNotationPipe,
  ],
  providers: [
    EmissionPipe,
    RoundPipe,
    ProgramTypePipe,
    CameraPipe,
    ScientificNotationPipe,
  ],
})
export class PipesModule {}
