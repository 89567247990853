import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Resolve } from '@angular/router';
import { map, zip } from 'rxjs';

import { PermissionsWebGuard } from '../guards/permissions/permissions-web-guard.guard';
import { CustomerDataService } from '../services/customer-data/customer-data.service';
import { Basin, SubBasin } from '@iconic-air-monorepo/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataResolver implements Resolve<boolean> {
  constructor(
    private afs: AngularFirestore,
    private perms: PermissionsWebGuard,
    private customerData: CustomerDataService,
  ) {}
  resolve(): Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.customerData.customerRecordValueChanges = this.afs
        .doc('/customers/' + this.perms.activeCustomerId)
        .valueChanges();
      this.customerData.allZips$ = zip([
        // 0
        this.afs
          .doc('/customers/' + this.perms.activeCustomerId)
          .snapshotChanges()
          .pipe(
            map((valueData: any) => {
              this.customerData.customerRecord = valueData.payload.data();
              return valueData.payload.data();
            }),
          ),
        // 1
        this.afs
          .doc('static/basins')
          .snapshotChanges()
          .pipe(
            map((valueData: any) => {
              const subBasins: { [basinFullName: string]: SubBasin[] } = {};
              valueData.payload.data().data.forEach((basin: Basin) => {
                subBasins[basin.basinFullName] = basin.subBasins?.map(
                  (subBasin) => {
                    return {
                      ...subBasin,
                      subBasinFullNameMapped:
                        basin?.basinNumber + ' - ' + subBasin?.subBasinFullName,
                    };
                  },
                );
              });
              return subBasins;
            }),
          ),
        // 2
        this.afs
          .doc('/companies/' + this.perms.activeCustomerId)
          .snapshotChanges()
          .pipe(
            map((companyData: any) => {
              if (companyData.payload.exists) {
                this.customerData.companyRecord = companyData.payload.data();
                return companyData.payload.data();
              }
            }),
          ),
        // 3
        this.afs
          .collection(
            '/customers/' + this.perms.activeCustomerId + '/companyValues',
          )
          .snapshotChanges()
          .pipe(
            map((valueData: any[]) => {
              valueData.forEach((v) => {
                this.customerData.companyValues[v.payload.doc.id] =
                  v.payload.doc.data();
              });
              return valueData;
            }),
          ),
        // 4
        this.afs
          .collection(
            '/customers/' +
              this.perms.activeCustomerId +
              '/additionalCategories',
          )
          .snapshotChanges()
          .pipe(
            map((valueData: any[]) => {
              valueData.forEach((v) => {
                const categoryExists =
                  this.customerData.additionalCategories.findIndex((val) => {
                    return val.id === v.payload.doc.id;
                  });
                if (categoryExists === -1) {
                  this.customerData.additionalCategories.push({
                    id: v.payload.doc.id,
                    ...v.payload.doc.data(),
                  });
                } else {
                  this.customerData.additionalCategories[categoryExists] = {
                    id: v.payload.doc.id,
                    ...v.payload.doc.data(),
                  };
                }
              });

              return valueData;
            }),
          ),
      ]).subscribe((data) => {
        let subBasins: SubBasin[] = [];
        this.customerData.customerRecord?.basins?.forEach((basin) => {
          if (data[1][basin]) subBasins = subBasins.concat(data[1][basin]);
        });
        this.customerData.subBasins = subBasins;
        resolve(true);
      });
    });
  }
}
