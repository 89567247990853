<div
  class="flex items-start"
  [ngClass]="{ 'hidden': !ids || ids.length === 0 }"
>
  <div class="mt-2">
    <div *ngIf="minimized" class="flex justify-end">
      <button (click)="toggleMinimize()">
        <mat-icon>{{!minimized ? 'unfold_less': 'unfold_more'}}</mat-icon>
      </button>
    </div>
    <ng-container *ngFor="let id of ids">
      <ng-container
        *ngTemplateOutlet="snackbar; context: {snackbar: snackbars[id], id: id}"
      ></ng-container>
    </ng-container>
  </div>

  <button *ngIf="!minimized" (click)="toggleMinimize()">
    <mat-icon>{{!minimized ? 'unfold_less': 'unfold_more'}}</mat-icon>
  </button>
</div>

<ng-template #snackbar let-snackbar="snackbar" let-id="id">
  <div
    (click)="openModal(id, snackbar)"
    *ngIf="minimized && (snackbar?.showProgress || snackbar?.errored || snackbar?.icon)"
  >
    <div
      *ngIf="minimized && (snackbar?.showProgress || snackbar?.errored || snackbar?.icon)"
      class="mt-2 flex"
    >
      <div class="spinner-container" (click)="download(id, $event)">
        <mat-progress-spinner
          *ngIf="snackbar?.showProgress"
          [value]="snackbar?.progress"
          [color]="snackbar?.progress >= 100 ? 'primary' : snackbar?.errored ? 'warn' : 'accent'"
          diameter="25"
        >
        </mat-progress-spinner>
        <mat-icon
          [matTooltip]="snackbar?.message"
          *ngIf="snackbar?.icon && !snackbar?.errored"
          class="spinner-icon"
        >
          {{snackbar?.icon}}
        </mat-icon>
        <mat-icon
          *ngIf="snackbar?.errored"
          class="text-red-700 spinner-icon"
          [matTooltip]="snackbar?.error"
        >
          error</mat-icon
        >
      </div>
      <button class="text-red-700" (click)="close(id, $event)">
        <mat-icon class="text-sm mb-2 h-2.5 min-h-0">close</mat-icon>
      </button>
    </div>
    <h4 *ngIf="snackbar?.createdDate" class="text-xs mt-1 w-7">
      {{ snackbar?.createdDatePretty }}
    </h4>
  </div>
  <div (click)="openModal(id, snackbar)" *ngIf="!minimized">
    <div class="flex items-center justify-between mt-2 mb-1">
      <h4 *ngIf="snackbar?.createdDate" class="text-xs mr-1">
        {{ snackbar?.createdDatePretty }}
      </h4>
      <mat-icon
        *ngIf="snackbar?.errored"
        class="text-red-700"
        [matTooltip]="snackbar?.showProgress ? 'Errored at ' + snackbar?.progress + '%' : ''"
      >
        error</mat-icon
      >
      <span style="overflow-wrap: anywhere" class="pr-2">
        {{ snackbar?.message }}</span
      >
      <a *ngIf="snackbar?.downloadLink" (click)="download(id, $event)" download
        >Export</a
      >
      <a *ngIf="snackbar?.route" (click)="navigate(id, $event)">Open</a>
      <button class="text-red-700" (click)="close(id, $event)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-progress-bar
      *ngIf="snackbar?.showProgress && !snackbar?.errored"
      [value]="snackbar?.progress"
      [color]="snackbar?.progress >= 100 ? 'accent' : snackbar?.errored ? 'warn' : 'primary'"
    >
    </mat-progress-bar>
    <div
      class="mt-1"
      *ngIf="!snackbar?.errored && snackbar?.showProgress && snackbar?.showDuration && snackbar?.progress < 100"
    >
      {{snackbar.duration}}
    </div>
    <div
      style="overflow-wrap: anywhere"
      class="text-red-700 mt-1"
      *ngIf="snackbar?.errored"
      [matTooltip]="snackbar?.error !== cleanError(snackbar?.error) ? snackbar?.error : ''"
    >
      {{ cleanError(snackbar?.error) }}
    </div>
  </div>
</ng-template>
