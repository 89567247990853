import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, forkJoin } from 'rxjs';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import * as ExcelJS from 'exceljs';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { take, lastValueFrom } from 'rxjs';
import { Facility, Leak, Survey, LeakAsset } from '@iconic-air/models';
import { titleCase } from 'title-case';
import { PdfBuilderService } from './pdf-builder.services';
import { CameraPipe } from '../../pipes/camera.pipe';
import { ProgramTypePipe } from '../../pipes/program-type.pipe';
import { TitleCasePipe } from '@angular/common';
import { FacilitiesDatabaseService } from '../../services-database/facilities-service/facilities-database.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CreateLdarReportService {
  get customers() {
    return this.perms.customers;
  }
  users: any[] = [];
  survey: Observable<Survey>;
  filteredLeakAssets: LeakAsset[];
  reportLabels = {
    information: 'Survey Information',
    leakTitle: 'Leak Details',
    repairTitle: 'Repair History',
    normal: 'Normal',
    visual: 'Visual',
    summary: 'Summary Snapshot',
    facilityName: 'LOCATION NAME',
    plantAddress: 'ADDRESS OF PLANT',
    city: 'CITY',
    zipCode: 'ZIP CODE',
    inspectionStart: 'INSPECTION START DATE',
    inspectionEnd: 'INSPECTION END DATE',
    leaksDetected: 'LEAKS DETECTED',
    notes: 'NOTES',
    gpsLocation: 'GPS LOCATION',
    dueDate: 'DUE DATE',
    deviceUsed: 'DEVICE USED',
    componentType: 'COMPONENT TYPE',
    componentSize: 'COMPONENT SIZE',
    ldarTag: 'LDAR TAG',
    description: 'DESCRIPTION',
    leakId: 'LEAK ID',
    repairDescription: 'LAST REPAIR ATTEMPT',
    repairStatus: 'REPAIR STATUS',
    facilityAuth: 'FACILITY AUTHORIZATION',
    operatorExp: 'OPERATOR EXPERIENCE',
    deviations: 'DEVIATIONS FROM MONITORING PLAN',
    ambientTemp: 'AMBIENT TEMPERATURE',
    skyConditions: 'SKY CONDITIONS',
    maxWindSpeed: 'MAX WIND SPEED',
    monitoringDevice: 'MONITORING DEVICE',
    calibGasType: 'CALIBRATION GAS TYPE',
    flowRate: 'FLOW RATE',
    distance: 'DISTANCE',
    operatorName: 'OPERATOR NAME',
    leakQuantification: 'LEAK QUANTIFICATION',
    leakDetectionMethod: 'LEAK DETECTION METHOD',
    leakDetectionDate: 'LEAK DETECTION DATE',
    leakStatus: 'STATUS',
    dateOfRepair: 'DATE OF REPAIR',
    repairMethod: 'REPAIR METHOD',
    resurveyDate: 'RESURVEY DATE',
    resurveyMethod: 'RESURVEY METHOD',
  };

  constructor(
    private afs: AngularFirestore,
    private _http: HttpClient,
    private perms: PermissionsWebGuard,
    private pdfBuilderService: PdfBuilderService,
    private cameraPipe: CameraPipe,
    private programTypePipe: ProgramTypePipe,
    private titleCasePipe: TitleCasePipe,
    private _facilityDatabaseService: FacilitiesDatabaseService,
  ) {
    this.afs
      .collection('users')
      .get()
      .subscribe((users) => {
        if (users.docs) {
          this.users = users.docs;
        }
      });
  }

  generateLeakReport(
    fileType: string,
    selectedFacilities: string[],
    fromDate?: number,
    toDate?: number,
    selectedReportType?: string,
  ) {
    return this._http.post(
      '/api/reports/leaks/' + fileType,
      {
        fileType,
        selectedFacilities,
        fromDate,
        toDate,
        selectedReportType,
      },
      {
        responseType: 'blob',
      },
    );
  }

  getData(surveyId, facilityId): Observable<any> {
    const surveyResponse = this.afs
      .collection<Survey>('customers')
      .doc(this.customers[0].id)
      .collection('surveys')
      .doc(surveyId)
      .valueChanges()
      .pipe(take(1));
    const facilityResponse = this._facilityDatabaseService
      .getFacilityObservable(facilityId)
      .pipe(take(1));
    return forkJoin([surveyResponse, facilityResponse]);
  }

  // takes survey that is passed in and builds a data dump for that survey in excel then downloads to user in browser. Code taken from reports.component.ts
  public async createWorkbook(
    surveys: Survey[],
    fromDate?: number,
    toDate?: number,
  ) {
    return new Promise(async (resolve, reject) => {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Me';
      const surveyTab: any = workbook.addWorksheet('Survey Data', {
        properties: { tabColor: { argb: 'F0F1F6' } },
      });
      surveyTab.columns = [
        { header: 'Survey Id', key: 'surveyId', width: 25 },
        { header: 'Basin', key: 'basin', width: 20 },
        { header: 'Business Area', key: 'businessArea', width: 20 },
        { header: 'Location Name', key: 'facilityName', width: 20 },
        {
          header: 'Survey Start Date',
          key: 'surveyStartDate',
          width: 20,
          style: { numFmt: 'mm/dd/yyyy' },
        },
        {
          header: 'Survey End Date',
          key: 'surveyEndDate',
          width: 20,
          style: { numFmt: 'mm/dd/yyyy' },
        },
        { header: 'Camera', key: 'camera', width: 20 },
        { header: 'Program Type', key: 'programType', width: 20 },
        { header: 'No. of Leaks', key: 'numberOfLeaks', width: 20 },
        { header: 'Survey Type', key: 'type', width: 20 },
      ] as ExcelJS.Column[];

      const leakAndRepairTab: any = workbook.addWorksheet(
        'Leak and Repair Data',
        {
          properties: { tabColor: { argb: 'G0F1F6' } },
        },
      );
      leakAndRepairTab.columns = [
        { header: 'Survey Id', key: 'surveyId', width: 25 },
        { header: 'Leak Id', key: 'leakId', width: 32 },
        { header: 'Basin', key: 'basin', width: 20 },
        { header: 'Business Area', key: 'businessArea', width: 20 },
        { header: 'Location Name', key: 'facilityName', width: 20 },
        { header: 'Company Id', key: 'companyId', width: 20 },
        { header: 'Leak Type', key: 'type', width: 20 },
        { header: 'Leak Status', key: 'leakStatus', width: 20 },
        {
          header: 'Detection Date',
          key: 'detectionDate',
          width: 20,
          style: { numFmt: 'mm/dd/yyyy' },
        },
        { header: 'Next Action', key: 'nextAction', width: 20 },
        {
          header: 'Next Action Date',
          key: 'dueDate',
          width: 20,
          style: { numFmt: 'mm/dd/yyyy' },
        },
        { header: 'Component Type', key: 'componentType', width: 25 },
        { header: 'Repair Status', key: 'repairStatus', width: 20 },
        {
          header: 'Last Repair Attempt',
          key: 'lastRepairAttempt',
          width: 20,
          style: { numFmt: 'mm/dd/yyyy' },
        },
        { header: 'Latitude', key: 'latitude', width: 20 },
        { header: 'Longitude', key: 'longitude', width: 20 },
        { header: 'Leak Description', key: 'description', width: 20 },
      ] as ExcelJS.Column[];

      const assetsTab: any = workbook.addWorksheet('Leak Assets');
      assetsTab.columns = [
        { header: 'Survey ID', key: 'surveyId', width: 30, note: null },
        { header: 'Leak ID', key: 'leakId', width: 30, note: null },
        {
          header: 'Survey Start Date',
          key: 'surveyStartDate',
          width: 30,
          note: null,
        },
        { header: 'Asset Link', key: 'assetLink', width: 200, note: null },
      ];

      const unformattedLeaks: any[] = [];
      const formattedLeaks: any[] = [];

      let leaksCounter = 0;
      const surveyIds: any[] = [];

      const from = fromDate || moment('1/1/1900');
      const to = toDate || moment('12/31/3000');

      const facilities: Facility[] = await lastValueFrom(
        this._facilityDatabaseService.getFacilitiesByKey(
          {
            includes: ['locationSurveyWindows', 'id', 'locationSurveyConfig'],
          },
          true,
          undefined,
          true,
        ),
      );

      surveys
        .filter((survey) =>
          moment(survey.inspectionStartDate).isBetween(
            moment(from),
            moment(to),
            'd',
            '[]',
          ),
        )
        .forEach((survey: Survey) => {
          let programType = '';
          const facility = facilities.find((f) => f.id === survey.facilityId);
          if (facility && facility.locationSurveyConfig && survey.type) {
            if (facility.locationSurveyConfig?.[survey.type]) {
              if (
                facility.locationSurveyConfig?.[survey.type].programTypes &&
                Array.isArray(
                  facility.locationSurveyConfig?.[survey.type].programTypes,
                )
              ) {
                programType = facility.locationSurveyConfig?.[
                  survey.type
                ].programTypes
                  ?.map((type) => this.programTypePipe.transform(type))
                  .join(', ') as string;
              } else if (
                facility.locationSurveyConfig?.[survey.type].programTypes
              ) {
                programType = this.programTypePipe.transform(
                  facility.locationSurveyConfig[
                    survey.type
                  ].programTypes?.toString() as string,
                );
              }
            }
          }
          if (survey.leaks) {
            leaksCounter += survey.leaks.length;
            surveyTab.addRow({
              surveyId: survey.surveyId,
              basin: survey.basin,
              businessArea: survey.businessArea,
              facilityName: survey.facilityName,
              surveyStartDate: moment(survey.inspectionStartDate).toDate(), // changed to toDate() becauase exceljs only recognizes javascript dates when settings the column type as a "Date" in excel
              surveyEndDate: moment(survey.inspectionEndDate).toDate(), // same comment as above, don't change. date style formatting is handled in header settings
              camera: this.cameraPipe.transform(survey.camera),
              programType: programType,
              numberOfLeaks: survey?.leaks.length,
              type: this.programTypePipe.transform(survey?.type || ''),
            });

            survey.leaks.forEach((leak, index) => {
              if (leak) {
                unformattedLeaks.push(leak);
                if (!surveyIds.includes(leak.surveyId)) {
                  surveyIds.push(leak.surveyId);
                }

                leakAndRepairTab.addRow({
                  surveyId: survey.surveyId,
                  leakId: leak.id,
                  companyId: leak.companyId,
                  basin: survey.basin,
                  businessArea: survey?.businessArea,
                  facilityName: survey.facilityName,
                  type: this.programTypePipe.transform(leak?.type || ''),
                  detectionDate: leak?.detectionDate
                    ? moment(leak.detectionDate).toDate()
                    : '', // changed to toDate() becauase exceljs only recognizes javascript dates when settings the column type as a "Date" in excel
                  nextAction: leak?.status?.code !== 'c' ? leak.nextAction : '',
                  dueDate:
                    leak?.status?.code !== 'c' && leak.dueDate
                      ? moment(leak.dueDate).toDate()
                      : '', // same comment as above, don't change. date style formatting is handled in header settings
                  camera: this.cameraPipe.transform(survey.camera),
                  componentType: leak.componentType?.description,
                  leakStatus: leak.status?.description,
                  repairStatus:
                    leak.repair?.attempts?.[leak.repair.attempts.length - 1]
                      ?.status?.description,
                  lastRepairAttempt: leak.repair?.attempts?.[
                    leak.repair.attempts.length - 1
                  ]?.attemptedDate
                    ? moment(
                        leak.repair?.attempts[leak.repair.attempts.length - 1]
                          ?.attemptedDate,
                      ).toDate()
                    : '',
                  latitude:
                    !leak?.location?.latitude ||
                    (leak?.location?.latitude &&
                      isNaN(+leak?.location?.latitude))
                      ? ''
                      : +leak?.location?.latitude,
                  longitude:
                    !leak?.location?.longitude ||
                    (leak?.location?.longitude &&
                      isNaN(+leak?.location?.longitude))
                      ? ''
                      : +leak?.location?.longitude,
                  description: leak?.description,
                  delayOfRepair:
                    leak.repair?.attempts?.[leak.repair.attempts.length - 1]
                      ?.status.code === 'd'
                      ? 'TRUE'
                      : 'FALSE',
                });
              }
            });
          }
        });

      unformattedLeaks.sort((a, b) => {
        return moment(a.detectionDate).valueOf() >
          moment(b.detectionDate).valueOf()
          ? 1
          : -1;
      });

      unformattedLeaks.forEach((leak) => {
        if (leak.leakAssets && leak.leakAssets?.length > 0) {
          leak.leakAssets.forEach((asset) => {
            formattedLeaks.push({
              surveyId: leak.surveyId,
              leakId: leak.id,
              surveyStartDate: moment(leak.detectionDate).format('MM/DD/YYYY'),
              assetLink: asset.file,
            });
          });
        }
      });

      formattedLeaks.forEach((leak) => {
        assetsTab.addRow(leak);
      });

      assetsTab.getColumn('assetLink').eachCell((cell, rowNumber) => {
        cell.value = {
          text: cell.value,
          hyperlink: cell.value,
          tooltip: 'Click to view asset',
        };
      });

      leakAndRepairTab.columns.forEach((column, i) => {
        column.width = 20;
        column.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          if (rowNumber == 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'eeece1',
              },
            };
            cell.font = { bold: true };
          }
        });
      });
      surveyTab.columns.forEach((column, i) => {
        column.width = 20;
        column.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          if (rowNumber == 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'eeece1',
              },
            };
            cell.font = { bold: true };
          }
        });
      });

      assetsTab.columns.forEach((column, i) => {
        column.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
            wrapText: true,
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          if (rowNumber == 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'eeece1',
              },
            };
            cell.font = { bold: true };
          }
        });
      });

      const facNames: string[] = [];
      let fileName = '';
      surveys.map((survey: Survey) => facNames.push(survey.facilityName));
      if (facNames.length > 1) {
        fileName = 'ldar-survey-data-export';
      } else {
        fileName = surveys[0].facilityName + '-' + 'survey-data';
      }

      workbook.xlsx.writeBuffer().then((buffer) => {
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        const blob = new Blob([buffer], { type: fileType });
        saveAs(blob, fileName, fileExtension);
        resolve(false); // resolve sets value for isLoading report
      });
    });
  }

  // takes survey that is passed in and builds a pdf then downloads to user in browser. Code taken from reports.component.ts
  createPdf(
    surveys: Survey[],
    facility: Facility,
    emails?: string[],
    startDate?,
    endDate?,
  ) {
    return new Promise((resolve, reject) => {
      // removes any videos from leak assets array so that only images go in the pdf
      let facilityPhoto: any;
      surveys.forEach((survey: Survey) => {
        survey.leaks.forEach((leak: Leak) => {
          if (survey.facilityPhoto) {
            facilityPhoto = [survey.facilityPhoto];
          }

          if (leak.leakAssets) {
            const length = leak.leakAssets?.length;
            const filtered = leak.leakAssets.filter(
              (asset: LeakAsset) => asset.type == 'image',
            );
            leak.leakAssets = filtered;
            console.log(
              `Filtered leak assets down from ${length} to ${filtered.length}`,
            );
          }
        });
      });

      const docDefinition: any = {
        page: function (currentPage: any) {
          return currentPage;
        },
        headerTextDynamic: [''],
        pageSize: 'LEGAL',
        pageMargins: [40, 120, 40, 40],
        header: function (currentPage: number) {
          if (currentPage > 1) {
            return [
              {
                margin: [40, 30, 0, 30],
                table: {
                  widths: ['50%', '50%'],
                  heights: [40],
                  body: [
                    [
                      {
                        text: 'LDAR Survey Report',
                        style: 'leftHeader',
                      },
                      {
                        text: `${facility.facilityName}\n${moment(
                          startDate,
                        ).format('MMMM Do YYYY')} - ${moment(endDate).format(
                          'MMMM Do YYYY',
                        )}`,
                        style: 'rightHeader',
                      },
                    ],
                  ],
                },
                layout: 'noBorders',
              },
            ];
          }
        },
        footer: function (currentPage: number) {
          if (currentPage > 1) {
            const adjustedCurrentPage = currentPage - 1;
            return [
              {
                table: {
                  widths: ['10%', '90%'],
                  heights: [40],
                  margin: [0, 10, 0, 0],
                  body: [
                    [
                      {
                        text: adjustedCurrentPage.toString(),
                        style: 'leftFooter',
                      },
                      { text: '', style: 'filledHeader' },
                    ],
                  ],
                },
                layout: 'noBorders',
              },
            ];
          }
        },
        content: [],
        styles: {
          leftHeader: {
            fontSize: 12,
            bold: true,
            alignment: 'left',
          },
          rightHeader: {
            fontSize: 12,
            bold: true,
          },
          pageHeader: {
            color: '#1a286f',
            fillColor: 'white',
            fontSize: 24,
            fontWeight: 800,
            bold: true,
            margin: [0, 0, 12, 0],
          },
          leftFooter: {
            color: '#1a286f',
            bold: true,
            alignment: 'right',
          },
          rowText: {
            color: 'lightslategray',
            alignment: 'center',
          },
          rowValue: {
            color: 'black',
            alignment: 'center',
          },
          dataHeaderText: {
            color: '#1a286f',
            fontSize: '14',
            margin: [0, 0, 0, 5],
            bold: true,
          },
          titlePageLarge: {
            color: '#1a286f',
            bold: true,
            fontSize: '40',
            alignment: 'center',
          },
          titlePageSubHeader: {
            color: 'lightslategray',
            bold: true,
            fontSize: '14',
            alignment: 'center',
          },
          titlePageSubHeaderText: {
            color: 'black',
            bold: true,
            fontSize: '14',
            alignment: 'center',
          },
        },
      };

      docDefinition.content.push(
        this.buildContent(
          docDefinition.headerTextDynamic,
          surveys,
          facility,
          facilityPhoto,
          startDate,
          endDate,
        ),
      );

      // first if checks to see if emails are present - if so then that means they are generating report from send email dialog
      if (emails) {
        pdfMake.createPdf(docDefinition).getBlob((blob) => {
          resolve(blob);
        });
      } else {
        // this else means they are in export dialog and want to download the survey to their computer
        if (surveys.length > 1) {
          pdfMake
            .createPdf(docDefinition)
            .download(facility.facilityName + '-' + 'surveys');
          resolve(false);
        } else {
          pdfMake
            .createPdf(docDefinition)
            .download(facility.facilityName + '-' + surveys[0].surveyId);
          resolve(false);
        }
      }
    });
  }

  // function that builds all content for the pdf generated for the survey. Is fired in createPdf() - code pulled from reports.component.ts
  buildContent(
    headers: string[],
    surveys: Survey[],
    facility: Facility,
    facilityPhoto: any,
    startDate?,
    endDate?,
  ) {
    let finalSurvey = false;
    const content: any[] = [];

    content.push(this.buildTitlePage(facility, startDate, endDate));

    surveys.forEach((survey: Survey, index) => {
      headers.push(`SURVEY ${index + 1} SUMMARY`);

      if (index === surveys.length - 1) {
        finalSurvey = true;
      }

      const surveyIndex = index + 1;

      content.push(
        this.buildTable(facility, survey, facilityPhoto, surveyIndex),
      );
      if (survey.leaks !== undefined) {
        survey?.leaks.forEach((leak, index) => {
          let finalLeak = false;
          if (index === 0) {
            headers.push(`LEAK ${index + 1}: ${leak.id}`);
          } else {
            headers.push(`LEAK ${index + 1} of Survey ${surveyIndex}`);
          }
          leak.device = survey.camera;
          if (index == survey.leaks.length - 1) {
            finalLeak = true;
          }
          content.push(
            this.buildLeaksPage(
              leak,
              finalSurvey,
              finalLeak,
              index,
              surveyIndex,
              survey,
            ),
          );
        });
      }
    });

    return content;
  }

  // function that builds the title page for the pdf generated for the survey. Is fired in createPdf() - code pulled from reports.component.ts
  buildTitlePage(facility: Facility, startDate?, endDate?) {
    return [
      {
        table: {
          widths: ['100%'],
          heights: [300, 70, 10, 10, 150],
          body: [
            [{ text: '', style: 'leftHeader' }],
            [
              {
                text: facility.facilityName,
                style: 'titlePageLarge',
              },
            ],
            [{ text: 'LDAR Survey Report', style: 'titlePageSubHeader' }],
            [
              {
                text: this.customers[0].name?.toUpperCase(),
                style: 'titlePageSubHeaderText',
              },
            ],
            [
              {
                text: `${moment(startDate).format('MMMM Do YYYY')} - ${moment(
                  endDate,
                ).format('MMMM Do YYYY')}`,
                style: 'titlePageSubHeaderText',
              },
            ],
          ],
        },
        layout: 'noBorders',
        pageBreak: 'after',
      },
    ];
  }

  // function that builds the table for the pdf generated for the survey. Is fired in createPdf() - code pulled from reports.component.ts
  buildTable(
    facility: Facility,
    survey: Survey,
    facilityPhoto: any,
    surveyIndex: number,
  ) {
    let surveyCamera = '-';
    if (survey.camera) surveyCamera = survey.camera;

    let surveyCameraSerialNumber = '-';
    if (survey.camera)
      surveyCameraSerialNumber = survey.cameraSerialNumber || '-';
    let displayName = '';
    if (survey.userId) {
      const userData = this.users.find(
        (user) => user.data().uid === survey.userId,
      );
      if (userData) {
        if (userData.data().displayName) {
          displayName = userData.data().displayName;
        } else if (userData.data().userName) {
          displayName = userData.data().userName;
        }
      }
    }
    let facilityPhotoTitle;
    let facilityPhotoSegment;
    if (facilityPhoto) {
      facilityPhotoTitle = {
        text: 'Location photo',
        style: 'dataHeaderText',
        margin: [0, 20, 0, 10],
      };
      facilityPhotoSegment = {
        image: facilityPhoto,
        width: 150,
        /*  height: 250, */
        pageBreak: 'after',
      };
    } else {
      facilityPhotoSegment = {
        text: '',
        pageBreak: 'after',
      };
    }
    return [
      {
        text: `SURVEY ${surveyIndex}`,
        style: 'pageHeader',
      },
      {
        text: this.reportLabels.information,
        style: 'dataHeaderText',
      },
      {
        table: {
          widths: ['50%', '50%'],
          heights: [30],
          margin: [0, 0, 40, 20],
          body: [
            [
              { text: this.reportLabels.facilityName, style: 'rowText' },
              {
                text: facility.facilityName,
                style: 'rowValue',
              },
            ],
            // Permit Type and Number
            [
              { text: this.reportLabels.facilityAuth, style: 'rowText' },
              { text: '-', style: 'rowValue' },
            ],
            [
              { text: this.reportLabels.operatorName, style: 'rowText' },
              { text: titleCase(displayName), style: 'rowValue' },
            ],
            // Operator Experience
            [
              { text: this.reportLabels.operatorExp, style: 'rowText' },
              { text: '-', style: 'rowValue' },
            ],
            [
              {
                text: this.reportLabels.inspectionStart,
                style: 'rowText',
              },
              {
                text: moment(survey.inspectionStartDate).format(
                  'MMMM Do YYYY, h:mm a',
                ),
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.inspectionEnd, style: 'rowText' },
              {
                text: moment(survey.inspectionEndDate).format(
                  'MMMM Do YYYY, h:mm a',
                ),
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.leaksDetected, style: 'rowText' },
              { text: this.getLeaksForSurvey(survey), style: 'rowValue' },
            ],
            [
              { text: this.reportLabels.deviations, style: 'rowText' },
              {
                text: survey.deviations ? survey.deviations : '-',
                style: 'rowValue',
              },
            ],
          ],
        },

        layout: {
          hLineColor: 'lightslategray',
          vLineColor: 'lightslategray',
        },
      },
      { text: '  ', style: 'dataHeaderText' },

      {
        table: {
          widths: ['50%', '50%'],
          heights: [30],
          margin: [0, 0, 40, 20],
          body: [
            [
              { text: this.reportLabels.ambientTemp, style: 'rowText' },
              {
                text: survey.ambientTemp
                  ? `${survey.ambientTemp} degrees F`
                  : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.skyConditions, style: 'rowText' },
              {
                text: survey.skyCondition ? survey.skyCondition : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.maxWindSpeed, style: 'rowText' },
              {
                text: survey.windSpeedMax
                  ? survey.windSpeedMax
                  : survey.windSpeed
                  ? survey.windSpeed
                  : '-',
                style: 'rowValue',
              },
            ],
          ],
        },
        layout: {
          hLineColor: 'lightslategray',
          vLineColor: 'lightslategray',
        },
        style: { margin: [10, 0, 0, 0] },
      },
      { text: '  ', style: 'dataHeaderText' },
      {
        table: {
          widths: ['50%', '50%'],
          heights: [30],
          margin: [0, 0, 40, 20],
          body: [
            [
              {
                text: this.reportLabels.monitoringDevice,
                style: 'rowText',
              },
              {
                text: `${this.cameraPipe.transform(
                  surveyCamera,
                )} \n Serial #: ${surveyCameraSerialNumber}`,
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.calibGasType, style: 'rowText' },
              {
                text: survey.calibGasType?.description
                  ? survey.calibGasType?.description
                  : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.flowRate, style: 'rowText' },
              {
                text: survey.flowRate ? `${survey.flowRate} L/min` : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.distance, style: 'rowText' },
              {
                text: survey.distance ? `${survey.distance} ft` : '-',
                style: 'rowValue',
              },
            ],
          ],
        },
        layout: {
          hLineColor: 'lightslategray',
          vLineColor: 'lightslategray',
        },
        style: { margin: [10, 10, 10, 10] },
      },
      facilityPhotoTitle,
      facilityPhotoSegment,
    ];
  }

  // function that builds the leaks pages for the pdf generated for the survey. Is fired in createPdf() - code pulled from reports.component.ts
  buildLeaksPage(
    leak: Leak,
    finalSurvey,
    finalLeak: boolean,
    index: number,
    surveyIndex: number,
    survey: Survey,
  ) {
    let finalAsset = false;
    let firstAsset = true;

    const repairBody: any[] = [
      [
        { text: this.reportLabels.dateOfRepair, style: 'rowText' },
        { text: this.reportLabels.repairMethod, style: 'rowText' },
        { text: this.reportLabels.repairStatus, style: 'rowText' },
        { text: this.reportLabels.resurveyMethod, style: 'rowText' },
        { text: this.reportLabels.notes, style: 'rowText' },
      ],
    ];
    if (leak?.repair?.attempts.length > 0) {
      leak?.repair?.attempts?.forEach((attempt) => {
        if (attempt) {
          repairBody.push([
            {
              text: attempt.attemptedDate
                ? moment(attempt.attemptedDate).format('MMMM Do YYYY')
                : '-',
              style: 'rowValue',
            },

            {
              text: attempt.method ? titleCase(attempt.method) : '-',
              style: 'rowValue',
            },

            {
              text: attempt?.status?.description
                ? titleCase(attempt.status.description)
                : '-',
              style: 'rowValue',
            },

            {
              text: attempt.confirmationMethod
                ? toTitleCase(attempt.confirmationMethod)
                : '-',
              style: 'rowValue',
            },

            {
              text: attempt?.notes ? attempt?.notes : '-',
              style: 'rowValue',
            },
          ]);
        }
      });
    } else {
      repairBody.push([
        {
          text: '-',
          style: 'rowValue',
        },
        {
          text: '-',
          style: 'rowValue',
        },

        {
          text: '-',
          style: 'rowValue',
        },

        {
          text: '-',
          style: 'rowValue',
        },

        {
          text: 'No Attempts Have Been Made',
          style: 'rowValue',
        },
      ]);
    }

    const leaksPageContent: any[] = [
      {
        text:
          index + 1 === 1
            ? `LEAK ${index + 1}: ${leak.id}`
            : `LEAK ${index + 1} of SURVEY ${surveyIndex}`,
        style: 'pageHeader',
      },
      {
        text: `${this.reportLabels.leakTitle}`,
        style: 'dataHeaderText',
      },
      {
        table: {
          widths: ['50%', '50%'],
          heights: [30],
          margin: [0, 0, 40, 20],
          body: [
            [
              { text: this.reportLabels.facilityName, style: 'rowText' },
              {
                text: survey.facilityName,
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.componentType, style: 'rowText' },
              {
                text: leak?.componentType?.description
                  ? titleCase(leak?.componentType?.description)
                  : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels?.description, style: 'rowText' },
              {
                text: leak?.description ? titleCase(leak?.description) : '-',
                style: 'rowValue',
                noWrap: false,
              },
            ],
            [
              { text: this.reportLabels.leakQuantification, style: 'rowText' },
              {
                text: leak?.ppm ? leak?.ppm : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.leakDetectionMethod, style: 'rowText' },
              {
                text: leak?.type ? leak?.type.toUpperCase() : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.leakDetectionDate, style: 'rowText' },
              {
                text: leak.detectionDate
                  ? moment(leak.detectionDate).format('MMMM Do YYYY')
                  : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.leakStatus, style: 'rowText' },
              {
                text: leak?.status?.description
                  ? toTitleCase(leak?.status?.description)
                  : leak.repair.attempts.length > 0
                  ? toTitleCase(
                      leak.repair.attempts[leak.repair.attempts.length - 1]
                        .status?.description,
                    )
                  : 'Leaking',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.componentSize, style: 'rowText' },
              {
                text: leak?.componentSize ? `${leak?.componentSize} in` : '-',
                style: 'rowValue',
              },
            ],
            [
              { text: this.reportLabels.gpsLocation, style: 'rowText' },
              {
                text:
                  leak?.location?.latitude && leak?.location?.longitude
                    ? leak?.location?.latitude +
                      ', ' +
                      leak?.location?.longitude
                    : '-',
                style: 'rowValue',
              },
            ],
          ],
        },
        layout: {
          hLineColor: 'lightslategray',
          vLineColor: 'lightslategray',
        },
        pageBreak: '',
      },
      // creates repair info table
      {
        text: this.reportLabels.repairTitle,
        style: 'dataHeaderText',
        margin: [0, 20, 0, 10],
      },
      {
        table: {
          margin: [0, 0, 0, 20],
          body: repairBody,
        },
        layout: {
          hLineColor: 'lightslategray',
          vLineColor: 'lightslategray',
        },
        pageBreak:
          finalLeak && leak?.leakAssets?.length === 0
            ? 'after'
            : leak?.leakAssets?.length > 0
            ? ''
            : 'after',
      },
    ];

    if (leak.leakAssets?.length > 0) {
      leak.leakAssets?.forEach((asset, index) => {
        if (index === leak.leakAssets.length - 1) {
          finalAsset = true;
        }
        index === 0 ? (firstAsset = true) : (firstAsset = false);
        leaksPageContent.push(
          this.buildImageContent(
            asset,
            finalSurvey,
            finalLeak,
            finalAsset,
            firstAsset,
          ),
        );
      });
    }
    return leaksPageContent;
  }

  // function that builds the image for each leak for the pdf generated for the survey. Is fired in createPdf() - code pulled from reports.component.ts
  buildImageContent(
    asset,
    finalSurvey,
    finalLeak,
    finalAsset: boolean,
    firstAsset: boolean,
  ) {
    const imageContent = [
      {
        text: firstAsset ? 'Photo(s)' : '',
        margin: [0, 10, 0, 10],
        style: 'dataHeaderText',
      },
      {
        image: asset.file,
        width: 150,
        /*  height: 250, */
        pageBreak: '',
      },
    ];
    if (finalSurvey && finalLeak && finalAsset) {
      imageContent[1].pageBreak = '';
    } else if (finalLeak && finalAsset) {
      imageContent[1].pageBreak = 'after';
    } else if (finalAsset) {
      imageContent[1].pageBreak = 'after';
    } else if (!asset.file) {
      imageContent[1].pageBreak = 'after';
    }

    return imageContent;
  }

  // function that gets the leaks for surveys and is called in the buildTablePage function
  getLeaksForSurvey(survey: { leaks?: any[] }) {
    if (survey?.leaks !== undefined) {
      return survey.leaks.length.toString();
    } else {
      return 'None';
    }
  }

  /**
   * Hydrates images by converting them from image URIs to base64encoded data uris.
   * Returns all surveys after hydrating.
   */
  async hydrateImagesWithDataUri(surveys: Survey[]) {
    for (let i = 0; i < surveys.length; i++) {
      const survey: Survey = surveys[i];
      if (survey.facilityPhoto) {
        let facilityPhoto: string;
        if (Array.isArray(survey.facilityPhoto)) {
          console.log(`Facility Photo was an array: ${survey.facilityPhoto}`);
          facilityPhoto = survey.facilityPhoto[0];
        } else {
          facilityPhoto = survey.facilityPhoto as string;
        }
        const dataUri = await this.pdfBuilderService.createDataUriFromImageUrl(
          facilityPhoto,
        );
        survey.facilityPhoto = dataUri;
      }

      for (let j = 0; j < survey.leaks.length; j++) {
        const leak = survey.leaks[j];
        if (!leak.leakAssets) {
          continue;
        }

        for (let k = 0; k < leak.leakAssets?.length; k++) {
          const asset = leak.leakAssets[k];
          if (asset.type === 'image') {
            const dataUri =
              await this.pdfBuilderService.createDataUriFromImageUrl(
                asset.file,
              );
            asset.file = dataUri;
          }
        }
      }
    }

    return Promise.resolve(surveys);
  }
}

function toTitleCase(str: string) {
  if (str && str.length > 0) {
    if (Array.isArray(str)) {
      const titledArray: any = str.forEach((s) => {
        return s.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      });
      if (str.length > 1) {
        str = titledArray.join(', ');
      } else {
        str = str[0];
      }
      return str;
    } else {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  } else {
    return str;
  }
}

function toProgramCase(programs: string[]) {}
