import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Subscription } from '@iconic-air/models';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  #url = '/payment';
  constructor(private _http: HttpClient, private _perms: PermissionsWebGuard) {}

  async checkSubscriptions() {
    if (!this._perms.activeCustomerId) return [] as Subscription[];
    return (await lastValueFrom(
      this._http.get(`${this.#url}/subscriptions`),
    )) as Subscription[];
  }

  async createCheckoutSession(returnUrl: string) {
    const stripe = await loadStripe(environment.stripePublishableKey);
    if (!stripe) throw new Error('Stripe not loaded');
    const { clientSecret } = (await lastValueFrom(
      this._http.post(`${this.#url}/createCheckoutSession`, {
        returnUrl,
      }),
    )) as { clientSecret: string };
    return stripe.initEmbeddedCheckout({
      clientSecret,
    });
  }
}
