<div class="day-edit" #matWrapper (keydown)="onKeyDown($event)">
  <mat-form-field appearance="outline">
    <mat-select panelClass="ag-custom-component-popup" #matSelect placeholder="State" [(ngModel)]="value" (selectionChange)="changeValue($event)" >
      <ng-container *ngFor="let state of states">
        <mat-option [value]="state">
          {{state | uppercase}}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
