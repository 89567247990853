<header
  [ngClass]="{ 'transformed': isScrolled || isHeaderClosed }"
  class="text-white text-titleMd p-4 bg-error"
>
  <div class="flex justify-between items-center">
    <div class="flex items-center">
      {{(firebaseProjectAlias?.projectName) ? 'You are currently using the' :
      ''}} {{firebaseProjectAlias?.projectName | uppercase}} You are currently
      acting as {{ isAdmin ? 'an ADMIN' : 'a USER' }} for {{activeCustomer?.name
      | uppercase}}.
      <p
        *ngIf="timeLeft && timeLeft.days >=0 && timeLeft.hours >= 0"
        class="pl-1"
      >
        Your sandbox trial period expires in {{(timeLeft.days !== 0) ?
        (timeLeft.days + " days " + timeLeft.hours + " hours") : (timeLeft.hours
        + " hours")}}.
      </p>
      <p
        *ngIf="timeLeft && (timeLeft.days < 0 || timeLeft.hours < 0)"
        class="pl-1"
      >
        Your sandbox trial period expired {{(timeLeft.days !== 0) ?
        ((timeLeft.days * -1) + " days " + (timeLeft.hours * -1) + " hours") :
        ((timeLeft.hours * -1) + " hours")}} ago.
      </p>
      <a
        *ngIf="isInternal && !hidden"
        class="text-white ml-2 hover:underline"
        [routerLink]="['/internal-admin/customers']"
        >Change</a
      >
    </div>

    <button><mat-icon (click)="closeHeader()">close</mat-icon></button>
  </div>
</header>

<div class="flex flex-auto w-full h-full bg-gray-100">
  <div
    class="mobile-hidden-backdrop"
    [ngClass]="{'mobile': mobile}"
    (click)="mobile = false"
  ></div>
  <app-menu
    [ngClass]="{'mini': mini, 'mobile': mobile}"
    [mobile]="mobile"
    [isScrolled]="isScrolled"
    [isHeaderClosed]="isHeaderClosed"
    class="bg-tertiary-120"
  >
  </app-menu>
  <div
    class="left-absolute"
    (click)="toggleNavigation()"
    [ngClass]="{'mini': mini, 'scrolled': isScrolled || isHeaderClosed}"
    *ngIf="!hideCarrot"
  >
    <mat-icon>chevron_left</mat-icon>
  </div>
  <div
    id="main"
    class="flex flex-col flex-auto min-w-0 overflow-y-auto scroll-container"
    [ngClass]="{ 'transformed': isScrolled || isHeaderClosed }"
    (scroll)="onScroll()"
  >
    <div
      class="flex justify-between items-center p-4 h-15 text-white visible bg-tertiary-120 md:hidden"
    >
      <div class="h-10 flex justify-start items-center">
        <img class="h-8" src="./assets/logo-white.svg" />
      </div>

      <button mat-icon-button (click)="toggleMobile()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <!-- Content -->
    <div #scrollContainer class="flex flex-col flex-auto">
      <div class="w-full flex" *ngIf="showReleaseBanner">
        <div
          class="w-full m-4 bg-green-100 border-solid border-2 border-green-600 p-2 flex rounded"
        >
          <div class="p-2">
            <mat-icon class="text-green-600">rocket_launch</mat-icon>
          </div>
          <div>
            <h2 class="text-green-600 py-1">
              Welcome to the newest version of the platform
            </h2>
            <h3 class="text-base py-1">
              There are a lot of improvements. Check out the full list
              <a (click)="openReleaseDialog()">here</a>. We would love to hear
              your <a (click)="contactSupport()">feedback</a>!
            </h3>
          </div>
        </div>
      </div>
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
          Otherwise, layout changes won't be registered and the view won't be updated! -->
      <div *ngIf="!(!timeLeft || !(timeLeft.days < 0 || timeLeft.hours < 0))">
        Your trial has ended. Please reach out to your iconic air to extend or
        purchase.
      </div>
      <router-outlet
        *ngIf="(!timeLeft || !(timeLeft.days < 0 || timeLeft.hours < 0)) || isInternal"
      ></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <app-user-snackbar></app-user-snackbar>
</div>
