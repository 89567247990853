import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Facility } from '@iconic-air/models';
import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import { FacilityFilterPipe } from '../../pipes/facility-filter.pipe';

@Component({
  selector: 'app-location-select',
  templateUrl: './facility-select.component.html',
  styleUrls: ['./facility-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FacilitySelectComponent,
      multi: true,
    },
    FacilityFilterPipe,
  ],
})
export class FacilitySelectComponent
  implements ControlValueAccessor, AfterViewInit, OnInit
{
  @ViewChild('searchedInput', { static: true }) searchedInput: any;
  @ViewChild('matSelect', { static: true }) select: any;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @Input() disableScroll: boolean;
  @Input() facilities: Facility[] = [];
  @Input() multiple: boolean;
  @Input() fullObj: boolean;
  @Input() selectedFacility: string[] | string;
  @Input() classes = 'w-full';
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() appearance = 'outline';
  @Output() selectedFacilityChange: EventEmitter<string | string[]> =
    new EventEmitter();
  @ViewChild('matSelect')
  selectOption: MatSelect;
  @ViewChild('matWrapper')
  selectWrapper: ElementRef;
  value: string; // Ex. CA
  isDisabled = false;
  facilityNameFilter = '';
  @ViewChildren(MatOption)
  options: QueryList<MatOption>;
  private onChangeCallback: any = () => undefined;
  private onTouchedCallback: any = () => undefined;
  private selectedFacilitiesGospel: string[] | string;
  private facilityListGospel: Facility[] = [];
  hasBeenOpened = false;

  constructor(readonly sd: ScrollDispatcher, private cd: ChangeDetectorRef) {
    if (this.multiple) {
      this.selectedFacility = [];
    }
  }

  selectPanelOpened(opened: boolean) {
    if (opened) {
      this.hasBeenOpened = true;
      this.searchedInput?.nativeElement?.focus();
    }
  }

  ngOnInit() {
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.code === 'Space') event.stopPropagation();
      else {
        // Call original _handleKeydown for other keys
        MatSelect.prototype._handleKeydown.call(this.select, event);
      }
    };
  }

  ngAfterViewInit(): void {
    this.facilityListGospel = JSON.parse(JSON.stringify(this.facilities));
    this.facilityListGospel = this.facilityListGospel.sort((a, b) => {
      if (a.facilityName && b.facilityName) {
        if (a.facilityName.toLowerCase() < b?.facilityName.toLowerCase())
          return -1;
        else if (a.facilityName.toLowerCase() > b.facilityName.toLowerCase())
          return 1;
        return 0;
      } else {
        if (a.facilityName.toLowerCase() < b?.facilityName.toLowerCase())
          return -1;
        else if (a.facilityName.toLowerCase() > b.facilityName.toLowerCase())
          return 1;
        return 0;
      }
    });
    this.selectedFacilitiesGospel = JSON.parse(
      JSON.stringify(this.selectedFacility),
    );
    this.selectOption.optionSelectionChanges.subscribe((change) => {
      if (change.isUserInput) {
        if (Array.isArray(this.selectedFacilitiesGospel)) {
          if (!change.source.selected) {
            const index = this.selectedFacilitiesGospel.findIndex((id) => {
              return id === change.source.value;
            });
            if (index >= 0) {
              this.selectedFacilitiesGospel.splice(index, 1);
            }
          }

          if (change.source.selected) {
            const index = this.selectedFacilitiesGospel.findIndex((id) => {
              return id === change.source.value;
            });
            if (index === -1) {
              this.selectedFacilitiesGospel.push(change.source.value);
            }
          }

          this.selectedFacility = JSON.parse(
            JSON.stringify(this.selectedFacilitiesGospel),
          );
        }
        this.selectedFacilityChange.emit(this.selectedFacility);
        this.onChangeCallback(this.selectedFacility);
      }
    });
  }

  onSelect(event: MatOptionSelectionChange) {
    this.onChangeCallback(event.source.value);
    this.selectedFacilityChange.emit(event.source.value);
  }

  writeValue(value: string | string[]) {
    this.selectedFacility = value;
  }

  // Implement ControlValueAccessor registerOnChange.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // Implement ControlValueAccessor registerOnTouched.
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // Propagate enable/disable to the form control.
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public focus() {
    this.selectOption._elementRef.nativeElement.focus();
  }

  selectAllFacilities(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedFacilitiesGospel = JSON.parse(
        JSON.stringify(this.facilityListGospel.map((a) => a.id)),
      );
      this.selectedFacility = JSON.parse(
        JSON.stringify(this.selectedFacilitiesGospel),
      );
    } else {
      this.selectedFacility = [];
      this.selectedFacilitiesGospel = [];
    }
    this.onChangeCallback(this.selectedFacility);
    this.selectedFacilityChange.emit(this.selectedFacility);
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
