import { Component } from '@angular/core';

@Component({
  selector: 'app-new-indicator',
  templateUrl: './new-indicator.component.html',
  styleUrls: ['./new-indicator.component.scss'],
})
export class NewIndicatorComponent {
  constructor() {}
}
