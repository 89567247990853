import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Address } from '@iconic-air-monorepo/models';
import { StaticDataService } from '../static-data/static.data.service';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  currentToken = 0;
  constructor(private http: HttpClient, private _static: StaticDataService) {}
  async getLatitudeLongitude(address): Promise<{
    latitude: number | null;
    longitude: number | null;
    county: string | null;
  }> {
    const location: {
      latitude: number | null;
      longitude: number | null;
      county: string | null;
    } = {
      latitude: null,
      longitude: null,
      county: null,
    };
    if (
      address.addressStreet &&
      address.addressCity &&
      address.addressState &&
      address.addressZip
    ) {
      const value: {
        features: {
          text: string;
          place_type: string[];
          address?: string;
          center: [number, number];
          context: { id: string; text: string }[];
          properties: { short_code: string };
        }[];
      } = (await this.mapboxApiAddress(address)) as {
        features: {
          text: string;
          place_type: string[];
          address?: string;
          center: [number, number];
          context: { id: string; text: string }[];
          properties: { short_code: string };
        }[];
      };
      if (value?.features?.length) {
        location.longitude = value?.features?.[0]?.center?.[0];
        location.latitude = value?.features?.[0]?.center?.[1];
        const countyFound = value?.features?.[0]?.context?.find((context) =>
          context.id.includes('district'),
        );
        if (countyFound) {
          location.county = countyFound.text?.replace('County', '')?.trim();
        }
      }
    }
    return location;
  }

  async getAddress(
    latitude: number,
    longitude: number,
  ): Promise<{ address: Address; county: string | null }> {
    const address: Address = {
      addressStreet: null,
      addressCity: null,
      addressZip: null,
      addressState: null,
    };
    let county: string | null = null;
    if (latitude && longitude) {
      const value: {
        features: {
          text: string;
          place_type: string[];
          address?: string;
          properties: { short_code: string };
        }[];
      } = (await this.mapboxApiLatLong(longitude, latitude)) as {
        features: {
          text: string;
          place_type: string[];
          address?: string;
          properties: { short_code: string };
        }[];
      };
      if (value.features?.length) {
        value.features.forEach((feature) => {
          if (
            feature?.place_type?.indexOf('address') > -1 &&
            feature.address &&
            feature.text
          ) {
            address.addressStreet = feature.address + ' ' + feature.text;
          } else if (feature?.place_type?.indexOf('postcode') > -1) {
            address.addressZip = feature.text;
          } else if (feature?.place_type?.indexOf('place') > -1)
            address.addressCity = feature.text;
          else if (feature?.place_type?.indexOf('region') > -1) {
            const state = feature.properties.short_code;
            address.addressState = state
              ?.split('-')
              [state.split('-').length - 1]?.toUpperCase();
          } else if (feature?.place_type?.indexOf('district') > -1)
            county = feature.text?.replace('County', '')?.trim();
        });
      }
    }
    return { address, county };
  }

  private async mapboxApiLatLong(longitude, latitude) {
    let values;
    const currentToken = JSON.parse(JSON.stringify(this.currentToken));
    try {
      // try to give a random buffer so as to not make all the calls at once.
      await this.wait(5 * Math.random());
      values = await lastValueFrom(
        this.http.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${this._static.mapboxApiTokens[currentToken]}&types=region,postcode,address,place,district`,
        ),
      );
    } catch (err) {
      if (err.status === 429) {
        if (this.currentToken === currentToken) {
          this.currentToken++;
          if (this.currentToken >= this._static.mapboxApiTokens.length)
            this.currentToken = 0;
        }
      }
      // add a small random wait to prevent endless locking of the page
      await this.wait(50 * Math.random());
      values = await this.mapboxApiLatLong(latitude, longitude);
    }
    return values;
  }
  private async mapboxApiAddress(address: Address, attemptNumber?: number) {
    if (!attemptNumber) attemptNumber = 0;
    let values;
    const currentToken = JSON.parse(JSON.stringify(this.currentToken));
    try {
      values = await lastValueFrom(
        this.http.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${address.addressStreet?.replace(
            /#/g,
            '',
          )}, ${address.addressCity?.replace(/#/g, '')}, ${
            address.addressState
          } ${address.addressZip}.json?access_token=${
            this._static.mapboxApiTokens[currentToken]
          }&types=address`,
        ),
      );
    } catch (err) {
      if (attemptNumber > 10) throw err;
      if (err.status === 429) {
        if (this.currentToken === currentToken) {
          this.currentToken++;
          if (this.currentToken >= this._static.mapboxApiTokens.length)
            this.currentToken = 0;
        }
      }
      // add a small random wait to prevent endless locking of the page
      await this.wait(50 * Math.random());
      values = await this.mapboxApiAddress(address, attemptNumber + 1);
    }
    return values;
  }

  private wait(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, milliseconds);
    });
  }
}
