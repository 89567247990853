import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-true-false-column',
  templateUrl: './true-false-column.component.html',
  styleUrls: ['./true-false-column.component.scss'],
})
export class TrueFalseColumnComponent implements AgRendererComponent {
  public params;
  private gridApi: GridApi;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.gridApi = params.api;
  }

  refresh(params) {
    return true;
  }
}
