<mat-form-field
  [appearance]="inputAppearance"
  [class]="inputClass"
  floatLabel="never"
>
  <mat-label *ngIf="label">{{(required) ? 'State *': 'State'}}</mat-label>
  <mat-select
    panelClass="ag-custom-component-popup"
    #matSelect
    [(ngModel)]="value"
    [disabled]="disabled"
    (selectionChange)="changeValue($event)"
  >
    <mat-select-trigger>
      {{(!value)? 'Select State' : valueDisplay}}
    </mat-select-trigger>
    <form novalidate class="mat-filter">
      <input
        matInput
        [(ngModel)]="stateFilter"
        [ngModelOptions]="{standalone: true}"
        filterBasinszclass="mat-filter-input"
        placeholder="Search..."
      />
    </form>
    <ng-container *ngFor="let state of states">
      <mat-option
        [value]="state.abbreviation"
        (onSelectionChange)="onSelect($event, state.abbreviation)"
        [ngClass]="{'hidden': stateFilter && state.name.toLowerCase().indexOf(stateFilter.toLowerCase()) === -1 && state.abbreviation.toLowerCase().indexOf(stateFilter.toLowerCase()) === -1}"
      >
        <span
          [innerHtml]="state.name | uppercase | highlight:stateFilter"
        ></span>
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
