import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'iconic-air-monorepo-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent {
  public hover = false;

  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input() text!: string;

  /**
   * This provides finer control on the content to be visible on the tooltip
   * This template will be injected in McToolTipRenderer directive in the consumer template
   * <ng-template #template>
   *  content.....
   * </ng-template>
   */
  @Input() contentTemplate!: TemplateRef<any>;
  @Input() data!: any;
  @Input() index!: number;
  public mouseHasLeftTheBuilding = new EventEmitter();
  @HostListener('mouseenter')
  show() {
    this.hover = true;
  }

  /**
   * This method will be called when mouse goes out of the host element
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener('mouseleave')
  hide() {
    this.hover = false;
    this.mouseHasLeftTheBuilding.emit(true);
  }
}
