import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  constructor(private _http: HttpClient) {}

  async downloadFile(downloadUrl: string, method: 'GET' | 'POST' = 'POST') {
    await this.downloadFileWithCustomName(downloadUrl, '', method);
  }

  async downloadFileBlob(downloadUrl: string, method: 'GET' | 'POST' = 'POST') {
    let response;
    if (method === 'POST')
      response = await lastValueFrom(
        this._http.post(
          '/api/download-file',
          {
            fileUrl: downloadUrl,
          },
          {
            observe: 'response',
            responseType: 'blob',
          },
        ),
      );
    else if (method === 'GET')
      response = await lastValueFrom(
        this._http.get(downloadUrl, {
          observe: 'response',
          responseType: 'blob',
        }),
      );
    return response;
  }

  async downloadFileWithCustomName(
    downloadUrl: string,
    newName: string,
    method: 'GET' | 'POST' = 'POST',
  ) {
    const response = await this.downloadFileBlob(downloadUrl, method);
    if (!response.body) return;

    let fileName = newName;
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition && !newName) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    const url = URL.createObjectURL(response.body);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = newName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); // Clean up
  }
}
