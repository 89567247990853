import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facility-upload-error-completed',
  templateUrl: './facility-upload-error-completed.component.html',
  styleUrls: ['./facility-upload-error-completed.component.scss']
})
export class FacilityUploadErrorCompletedComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<FacilityUploadErrorCompletedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public gotoFacilities = (): void => {
    this.router.navigate(['/facilities']).then(() => {
      this.dialogRef.close();
    });
  };

  public close = () => {
    this.dialogRef.close();
  };
}
