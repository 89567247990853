import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BenchmarkService {
  constructor(private ngZone: NgZone) {}
  public data: any = {};
  public filteredData: any[] = [];
  private other = 'Other';
  public dataFaster(
    selectedIntensity,
    years,
    selectedYear,
    basins,
    selectedBasins,
    selectAllBasins,
    companies,
    selectedCompanies,
    selectAllCompanies,
    industrySegments,
    selectedIndSegs,
    selectAllSegments,
    selectedHighLevels,
    selectAllHighLevels,
    highLevels,
    sourceType,
    remove,
    skipSmartFilter?,
  ) {
    const feed = this.ngZone.runOutsideAngular(() => {
      let total = 0;
      // eslint-disable-next-line
      let allowBasinOther = false;
      const benchmarkData: any[] = [];
      const sourceTypeData: any[] = [];
      const companyData: any[] = [];
      const indSegChartData: any[] = [];
      const indSegChartLabels: any[] = [];
      const indSegChartColors: any[] = [];
      const basinChartData: any[] = [];
      const basinChartLabels: any[] = [];
      const basinChartColors: any[] = [];
      const benchmarkChartLabels: any[] = [];
      const benchmarkChartData: any[] = [];
      const emittingCompanyLabels: any[] = [];
      const emittingCompanyData: any[] = [];
      const emittingCompanyConstants: any[] = [];
      const methaneData: any[] = [];
      const methanePieData: any[] = [];
      const methanePieLabels: any[] = [];
      const basinData: any[] = [];
      const indSegData: any[] = [];
      const emittingCompanyPagecount = 0;
      let companies: any[] = [];
      let availableCompanies: any[] = [];
      let availableHighLevels: any[] = [];
      let availableBasins: any[] = [];
      let availableIndSegs: any[] = [];

      const data = remove ? this.filteredData : this.data;
      data[selectedYear]?.forEach((metrics) => {
        if (selectedYear.indexOf(metrics.reportingYear) >= 0) {
          const benchmarkExisting = benchmarkData.findIndex((pd) => {
            return pd.label === metrics.reportingYear.toString();
          });

          const basinExisting = basinData.findIndex((pd) => {
            return metrics.reportingFacility.reportingFacilityBasin
              ? pd.label === metrics.reportingFacility.reportingFacilityBasin
              : pd.label === this.other;
          });

          const indSegExisting = indSegData.findIndex((pd) => {
            return (
              metrics.reportingFacility
                .reportingFacilityIndustrySegmentLongName === pd.label
            );
          });

          const compExisting = companyData.findIndex((pd) => {
            return metrics.reportingFacility.parentCompanyName
              ? pd.label ===
                  metrics.reportingFacility.parentCompanyName?.toUpperCase()
              : pd.label === this.other;
          });

          const existing = companies.find((cc) => {
            if (metrics.reportingFacility.parentCompanyName) {
              return (
                cc.toLowerCase() ===
                metrics.reportingFacility.parentCompanyName.toLowerCase()
              );
            } else {
              return cc.toLowerCase() === this.other.toLowerCase();
            }
          });

          if (!existing) {
            if (metrics.reportingFacility.parentCompanyName) {
              companies.push(metrics.reportingFacility.parentCompanyName);
            } else {
              companies.push(this.other);
            }
          }

          if (!skipSmartFilter) {
            const filtered = this.smartFilter(
              metrics,
              selectedCompanies,
              selectAllCompanies,
              availableCompanies,
              availableHighLevels,
              highLevels,
              selectedHighLevels,
              selectAllSegments,
              availableIndSegs,
              selectedIndSegs,
              industrySegments,
              availableBasins,
              selectedBasins,
              basins,
              allowBasinOther,
              selectAllBasins,
            );

            availableCompanies = filtered.availableCompanies;
            availableHighLevels = filtered.availableHighLevels;
            availableIndSegs = filtered.availableIndSegs;
            availableBasins = filtered.availableBasins;
            allowBasinOther = filtered.allowBasinOther;
          }
          if (
            (selectedCompanies.indexOf(
              metrics.reportingFacility.parentCompanyName
                ? metrics.reportingFacility.parentCompanyName
                : this.other,
            ) >= 0 ||
              selectAllCompanies) &&
            (this.getSelectedSegments(
              availableIndSegs.length ? availableIndSegs : industrySegments,
              selectedIndSegs,
            ).indexOf(
              metrics.reportingFacility
                .reportingFacilityIndustrySegmentLongName,
            ) >= 0 ||
              selectAllSegments) &&
            (this.getSelectedBasins(
              availableBasins.length ? availableBasins : basins,
              selectedBasins,
            ).indexOf(
              metrics.reportingFacility?.reportingFacilityBasin?.replace(
                / *\([^)]*\) */g,
                '',
              ),
            ) >= 0 ||
              (metrics.reportingFacility?.reportingFacilityBasin ===
                undefined &&
                selectedBasins.indexOf(this.other) >= 0) ||
              selectAllBasins)
          ) {
            const benchmarkExisting = benchmarkData.findIndex((pd) => {
              return pd.label === metrics.reportingYear.toString();
            });

            const basinExisting = basinData.findIndex((pd) => {
              return metrics.reportingFacility.reportingFacilityBasin
                ? pd.label === metrics.reportingFacility.reportingFacilityBasin
                : pd.label === this.other;
            });

            const indSegExisting = indSegData.findIndex((pd) => {
              return (
                metrics.reportingFacility
                  .reportingFacilityIndustrySegmentLongName === pd.label
              );
            });

            const compExisting = companyData.findIndex((pd) => {
              return metrics.reportingFacility.parentCompanyName
                ? pd.label ===
                    metrics.reportingFacility.parentCompanyName?.toUpperCase()
                : pd.label === this.other;
            });

            let parentValue = 0;
            let parentCo2 = 0,
              parentCh4 = 0,
              parentN20 = 0;
            metrics.reportingFacility?.emissions?.forEach((metric) => {
              if (selectedHighLevels.indexOf(metric.highLevel) >= 0) {
                if (!sourceType || sourceType === metric.sourceType) {
                  let value = 0;
                  let co2 = 0,
                    ch4 = 0,
                    n20 = 0;

                  const methaneExisting = sourceTypeData.findIndex((pd) => {
                    return pd.sourceType === metric.sourceType;
                  });

                  if (selectedIntensity === 'CO2e (AR5)') {
                    // add co2
                    if (metric.co2 && !isNaN(metric.co2)) {
                      co2 += parseFloat(metric.co2);
                    }
                    if (metric.ch4 && !isNaN(metric.ch4)) {
                      ch4 += parseFloat(metric.ch4);
                    }
                    if (metric.n20 && !isNaN(metric.n20)) {
                      n20 += parseFloat(metric.n20);
                    }
                  }

                  if (selectedIntensity === 'CO2') {
                    if (metric.co2 && !isNaN(metric.co2)) {
                      value += parseFloat(metric.co2);
                    }
                  }

                  if (selectedIntensity === 'CH4') {
                    if (metric.ch4 && !isNaN(metric.ch4)) {
                      value += parseFloat(metric.ch4);
                    }
                  }

                  if (selectedIntensity === 'N20') {
                    if (metric.n20 && !isNaN(metric.n20)) {
                      value += parseFloat(metric.n20);
                    }
                  }

                  // co2e multipliers
                  if (selectedIntensity === 'CO2e (AR5)') {
                    value = co2 * 1 + ch4 * 28 + n20 * 265;
                  }
                  total += value;
                  parentValue += value;
                  parentCo2 += co2;
                  parentCh4 += ch4;
                  parentN20 += n20;

                  if (methaneExisting === -1) {
                    sourceTypeData.push({
                      sourceType: metric.sourceType,
                      highLevel: metric.highLevel,
                      value: value,
                    });
                  } else {
                    // add to highlevel;
                    sourceTypeData[methaneExisting].value += value;
                  }
                }
              }
            });
            if (parentValue) {
              if (benchmarkExisting === -1) {
                benchmarkData.push({
                  label: metrics.reportingYear.toString(),
                  value: parentValue,
                });
              } else {
                // add to highlevel;
                benchmarkData[benchmarkExisting].value += parentValue;
              }
              if (basinExisting === -1) {
                const currentBasin = basins.find(
                  (basin) =>
                    basin.basinFullName.replace(/ *\([^)]*\) */g, '') ===
                    metrics.reportingFacility.reportingFacilityBasin?.replace(
                      / *\([^)]*\) */g,
                      '',
                    ),
                );
                basinData.push({
                  label: metrics.reportingFacility.reportingFacilityBasin
                    ? metrics.reportingFacility.reportingFacilityBasin
                    : this.other,
                  value: parentValue,
                  color: currentBasin ? currentBasin.color : this.getColor(),
                });
              } else {
                // add to highlevel;
                basinData[basinExisting].value += parentValue;
              }
              if (indSegExisting === -1) {
                const segment = industrySegments.find(
                  (seg) =>
                    seg.longName.toLowerCase() ===
                    metrics.reportingFacility.reportingFacilityIndustrySegmentLongName.toLowerCase(),
                );
                indSegData.push({
                  label:
                    metrics.reportingFacility
                      .reportingFacilityIndustrySegmentLongName,
                  value: parentValue,
                  color: segment ? segment.color : this.getColor(),
                });
              } else {
                // add to highlevel;
                indSegData[indSegExisting].value += parentValue;
              }
            }

            if (
              selectedCompanies.length === companies.length ||
              selectedCompanies.indexOf(
                metrics.reportingFacility.parentCompanyName,
              ) >= 0
            ) {
              if (compExisting === -1) {
                companyData.push({
                  color: this.getColor(),
                  label: metrics.reportingFacility.parentCompanyName
                    ? metrics.reportingFacility.parentCompanyName?.toUpperCase()
                    : this.other,
                  value: {
                    co2:
                      selectedIntensity === 'CO2e (AR5)'
                        ? parentCo2
                        : selectedIntensity === 'CO2'
                        ? parentValue
                        : 0,
                    ch4:
                      selectedIntensity === 'CO2e (AR5)'
                        ? parentCh4
                        : selectedIntensity === 'CH4'
                        ? parentValue
                        : 0,
                    n20:
                      selectedIntensity === 'CO2e (AR5)'
                        ? parentN20
                        : selectedIntensity === 'N20'
                        ? parentValue
                        : 0,
                  },
                  intensityNumbers: {
                    reportingFacilityGasProdWells: metrics.reportingFacility
                      .reportingFacilityGasProdWells
                      ? metrics.reportingFacility.reportingFacilityGasProdWells
                      : 0,
                    reportingFacilityGasSales: metrics.reportingFacility
                      .reportingFacilityGasSales
                      ? metrics.reportingFacility.reportingFacilityGasSales
                      : 0,
                    reportingFacilityOilSales: metrics.reportingFacility
                      .reportingFacilityOilSales
                      ? metrics.reportingFacility.reportingFacilityOilSales
                      : 0,
                    reportingFacilityGasTransportedGB: metrics.reportingFacility
                      .reportingFacilityGasTransportedGB
                      ? metrics.reportingFacility
                          .reportingFacilityGasTransportedGB
                      : 0,
                    reportingFacilityHcLiqTrans: metrics.reportingFacility
                      .reportingFacilityHcLiqTrans
                      ? metrics.reportingFacility.reportingFacilityHcLiqTrans
                      : 0,
                    sumProductMoleFraction: metrics.reportingFacility
                      .sumProductMoleFraction
                      ? metrics.reportingFacility.sumProductMoleFraction
                      : 0,
                    reportingFacilityWellProducingEoy: metrics.reportingFacility
                      .reportingFacilityWellProducingEoy
                      ? metrics.reportingFacility
                          .reportingFacilityWellProducingEoy
                      : 0,
                  },
                });
              } else {
                // add to highlevel;
                companyData[compExisting].value.co2 +=
                  selectedIntensity === 'CO2e (AR5)'
                    ? parentCo2
                    : selectedIntensity === 'CO2'
                    ? parentValue
                    : 0;
                companyData[compExisting].value.ch4 +=
                  selectedIntensity === 'CO2e (AR5)'
                    ? parentCh4
                    : selectedIntensity === 'CH4'
                    ? parentValue
                    : 0;
                companyData[compExisting].value.n20 +=
                  selectedIntensity === 'CO2e (AR5)'
                    ? parentN20
                    : selectedIntensity === 'N20'
                    ? parentValue
                    : 0;

                // add intensity numbers
                if (
                  metrics.reportingFacility.reportingFacilityGasProdWells &&
                  !isNaN(
                    metrics.reportingFacility.reportingFacilityGasProdWells,
                  )
                ) {
                  companyData[
                    compExisting
                  ].intensityNumbers.reportingFacilityGasProdWells +=
                    metrics.reportingFacility.reportingFacilityGasProdWells;
                }

                if (
                  metrics.reportingFacility.reportingFacilityGasSales &&
                  !isNaN(metrics.reportingFacility.reportingFacilityGasSales)
                ) {
                  companyData[
                    compExisting
                  ].intensityNumbers.reportingFacilityGasSales +=
                    metrics.reportingFacility.reportingFacilityGasSales;
                }

                if (
                  metrics.reportingFacility.reportingFacilityOilSales &&
                  !isNaN(metrics.reportingFacility.reportingFacilityOilSales)
                ) {
                  companyData[
                    compExisting
                  ].intensityNumbers.reportingFacilityOilSales +=
                    metrics.reportingFacility.reportingFacilityOilSales;
                }

                if (
                  metrics.reportingFacility.reportingFacilityGasTransportedGB &&
                  !isNaN(
                    metrics.reportingFacility.reportingFacilityGasTransportedGB,
                  )
                ) {
                  companyData[
                    compExisting
                  ].intensityNumbers.reportingFacilityGasTransportedGB +=
                    metrics.reportingFacility.reportingFacilityGasTransportedGB;
                }

                if (
                  metrics.reportingFacility.reportingFacilityHcLiqTrans &&
                  !isNaN(metrics.reportingFacility.reportingFacilityHcLiqTrans)
                ) {
                  companyData[
                    compExisting
                  ].intensityNumbers.reportingFacilityHcLiqTrans +=
                    metrics.reportingFacility.reportingFacilityHcLiqTrans;
                }

                if (
                  metrics.reportingFacility.reportingFacilityWellProducingEoy &&
                  !isNaN(
                    metrics.reportingFacility.reportingFacilityWellProducingEoy,
                  )
                ) {
                  companyData[
                    compExisting
                  ].intensityNumbers.reportingFacilityWellProducingEoy +=
                    metrics.reportingFacility.reportingFacilityWellProducingEoy;
                }

                if (
                  metrics.reportingFacility.sumProductMoleFraction &&
                  !isNaN(metrics.reportingFacility.sumProductMoleFraction)
                ) {
                  companyData[
                    compExisting
                  ].intensityNumbers.sumProductMoleFraction +=
                    metrics.reportingFacility.sumProductMoleFraction;
                }
              }
            }
          }
        }

        availableCompanies = availableCompanies.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });

        availableBasins = availableBasins.sort((a, b) => {
          if (a.basinFullName < b.basinFullName) return -1;
          if (a.basinFullName > b.basinFullName) return 1;
          return 0;
        });

        availableIndSegs = availableIndSegs.sort((a, b) => {
          if (a.shortName < b.shortName) return -1;
          if (a.shortName > b.shortName) return 1;
          return 0;
        });

        availableHighLevels = availableHighLevels.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      });

      companies = companies.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      availableCompanies = availableCompanies.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      availableBasins = availableBasins.sort((a, b) => {
        if (a.basinFullName < b.basinFullName) return -1;
        if (a.basinFullName > b.basinFullName) return 1;
        return 0;
      });

      availableIndSegs = availableIndSegs.sort((a, b) => {
        if (a.shortName < b.shortName) return -1;
        if (a.shortName > b.shortName) return 1;
        return 0;
      });

      availableHighLevels = availableHighLevels.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      return {
        total: total,
        benchmarkData: benchmarkData,
        sourceTypeData: sourceTypeData,
        companyData: companyData,
        indSegChartData: indSegChartData,
        indSegChartLabels: indSegChartLabels,
        indSegChartColors: indSegChartColors,
        basinChartData: basinChartData,
        basinChartLabels: basinChartLabels,
        basinChartColors: basinChartColors,
        benchmarkChartLabels: benchmarkChartLabels,
        benchmarkChartData: benchmarkChartData,
        emittingCompanyLabels: emittingCompanyLabels,
        emittingCompanyData: emittingCompanyData,
        emittingCompanyConstants: emittingCompanyConstants,
        methaneData: methaneData,
        methanePieData: methanePieData,
        methanePieLabels: methanePieLabels,
        basinData: basinData,
        indSegData: indSegData,
        emittingCompanyPagecount: emittingCompanyPagecount,
        companies: companies,
        availableCompanies: availableCompanies,
        availableHighLevels: availableHighLevels,
        availableBasins: availableBasins,
        availableIndSegs: availableIndSegs,
        allowBasinOther: allowBasinOther,
      };
    });

    return feed;
  }

  private getColor(): string {
    const randomColor = () => {
      const r = Math.floor(Math.random() * 175);
      const g = Math.floor(Math.random() * 125);
      const b = Math.floor(Math.random() * 255) + 100;
      return 'rgba(' + r + ',' + g + ',' + b + ', 0.9)';
    };
    return randomColor();
  }

  private getSelectedBasins(basins, selectedBasins) {
    return basins
      .filter((bb) => selectedBasins.indexOf(bb.id) >= 0)
      .map((bb) => bb?.basinFullName?.replace(/ *\([^)]*\) */g, ''));
  }

  private getSelectedSegments(industrySegments, selectedSegments) {
    return industrySegments
      .filter((segment) => selectedSegments.indexOf(segment.id) >= 0)
      .map((ss) => ss.longName);
  }

  private smartFilter(
    metrics,
    selectedCompanies,
    selectAllCompanies,
    availableCompanies,
    availableHighLevels,
    highLevels,
    selectedHighLevels,
    selectAllSegments,
    availableIndSegs,
    selectedIndSegs,
    industrySegments,
    availableBasins,
    selectedBasins,
    basins,
    allowBasinOther,
    selectAllBasins,
  ) {
    const highlevel = this.filterHighLevels(
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    );
    availableHighLevels = highlevel.availableHighLevels;
    const segment = this.filterSegments(
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    );
    availableIndSegs = segment.availableIndSegs;
    const basin = this.filterBasins(
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    );

    availableBasins = basin.availableBasins;
    const company = this.filterCompanies(
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    );
    availableCompanies = company.availableCompanies;

    const ids = availableIndSegs.map((o) => o.id);
    availableIndSegs = availableIndSegs.filter(
      ({ id }, index) => !ids.includes(id, index + 1),
    );

    const basinIds = availableBasins.map((o) => o.id);
    availableBasins = availableBasins.filter(
      ({ id }, index) => !basinIds.includes(id, index + 1),
    );

    availableHighLevels = availableHighLevels.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });

    availableCompanies = availableCompanies.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    return {
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    };
  }

  private filterCompanies(
    metrics,
    selectedCompanies,
    selectAllCompanies,
    availableCompanies,
    availableHighLevels,
    highLevels,
    selectedHighLevels,
    selectAllSegments,
    availableIndSegs,
    selectedIndSegs,
    industrySegments,
    availableBasins,
    selectedBasins,
    basins,
    allowBasinOther,
    selectAllBasins,
  ) {
    if (
      (metrics.highLevels
        .filter((a) => availableHighLevels.indexOf(a) >= 0)
        .filter((a) => selectedHighLevels.indexOf(a) >= 0).length ||
        selectedHighLevels) &&
      this.getSelectedSegments(
        availableIndSegs.length ? availableIndSegs : industrySegments,
        selectedIndSegs,
      ).indexOf(
        metrics.reportingFacility.reportingFacilityIndustrySegmentLongName,
      ) >= 0 &&
      (this.getSelectedBasins(
        availableBasins.length ? availableBasins : basins,
        selectedBasins,
      ).indexOf(
        metrics.reportingFacility?.reportingFacilityBasin?.replace(
          / *\([^)]*\) */g,
          '',
        ),
      ) >= 0 ||
        (metrics.reportingFacility?.reportingFacilityBasin === undefined &&
          selectedBasins.indexOf(this.other) >= 0))
    ) {
      const existing = availableCompanies.find((cc) => {
        if (metrics.reportingFacility.parentCompanyName) {
          return (
            cc.toLowerCase() ===
            metrics.reportingFacility.parentCompanyName.toLowerCase()
          );
        } else {
          return cc.toLowerCase() === this.other.toLowerCase();
        }
      });

      if (!existing) {
        if (metrics.reportingFacility.parentCompanyName) {
          availableCompanies.push(metrics.reportingFacility.parentCompanyName);
        } else {
          availableCompanies.push(this.other);
        }

        //every time we push a new company, we need to filter the highlevels, segments, basins
        const hl = this.filterHighLevels(
          metrics,
          selectedCompanies,
          selectAllCompanies,
          availableCompanies,
          availableHighLevels,
          highLevels,
          selectedHighLevels,
          selectAllSegments,
          availableIndSegs,
          selectedIndSegs,
          industrySegments,
          availableBasins,
          selectedBasins,
          basins,
          allowBasinOther,
          selectAllBasins,
        );
        availableHighLevels = availableHighLevels.concat(
          hl.availableHighLevels,
        );
        const seg = this.filterSegments(
          metrics,
          selectedCompanies,
          selectAllCompanies,
          availableCompanies,
          availableHighLevels,
          highLevels,
          selectedHighLevels,
          selectAllSegments,
          availableIndSegs,
          selectedIndSegs,
          industrySegments,
          availableBasins,
          selectedBasins,
          basins,
          allowBasinOther,
          selectAllBasins,
        );
        availableIndSegs = availableIndSegs.concat(seg.availableIndSegs);
        const basin = this.filterBasins(
          metrics,
          selectedCompanies,
          selectAllCompanies,
          availableCompanies,
          availableHighLevels,
          highLevels,
          selectedHighLevels,
          selectAllSegments,
          availableIndSegs,
          selectedIndSegs,
          industrySegments,
          availableBasins,
          selectedBasins,
          basins,
          allowBasinOther,
          selectAllBasins,
        );
        availableBasins = availableBasins.concat(basin.availableBasins);
      }
    }
    const ids = availableIndSegs.map((o) => o.id);
    availableIndSegs = availableIndSegs.filter(
      ({ id }, index) => !ids.includes(id, index + 1),
    );

    const basinIds = availableBasins.map((o) => o.id);
    availableBasins = availableBasins.filter(
      ({ id }, index) => !basinIds.includes(id, index + 1),
    );

    availableHighLevels = availableHighLevels.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });

    return {
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    };
  }

  private filterHighLevels(
    metrics,
    selectedCompanies,
    selectAllCompanies,
    availableCompanies,
    availableHighLevels,
    highLevels,
    selectedHighLevels,
    selectAllSegments,
    availableIndSegs,
    selectedIndSegs,
    industrySegments,
    availableBasins,
    selectedBasins,
    basins,
    allowBasinOther,
    selectAllBasins,
  ) {
    // find available highLevels
    if (
      (selectedCompanies.indexOf(
        metrics.reportingFacility.parentCompanyName
          ? metrics.reportingFacility.parentCompanyName
          : this.other,
      ) >= 0 ||
        (selectAllCompanies &&
          availableCompanies.length <= selectedCompanies.length) ||
        !selectedCompanies.length) &&
      (this.getSelectedSegments(
        availableIndSegs.length ? availableIndSegs : industrySegments,
        selectedIndSegs,
      ).indexOf(
        metrics.reportingFacility.reportingFacilityIndustrySegmentLongName,
      ) >= 0 ||
        (selectAllSegments &&
          availableIndSegs.length <= selectedIndSegs.length) ||
        !selectedIndSegs.length ||
        selectedIndSegs === industrySegments.length) &&
      (this.getSelectedBasins(
        availableBasins.length ? availableBasins : basins,
        selectedBasins,
      ).indexOf(
        metrics.reportingFacility?.reportingFacilityBasin?.replace(
          / *\([^)]*\) */g,
          '',
        ),
      ) >= 0 ||
        (metrics.reportingFacility?.reportingFacilityBasin === undefined &&
          selectedBasins.indexOf(this.other) >= 0) ||
        (selectAllBasins && availableBasins.length <= selectedBasins.length) ||
        !selectedBasins.length)
    ) {
      metrics.highLevels.forEach((high) => {
        const existingAvailableHighLevels = availableHighLevels.find(
          (available) => {
            return available === high;
          },
        );

        if (!existingAvailableHighLevels) {
          availableHighLevels.push(high);

          //every time we push a highlevel, we need to filter the rest
          const comps = this.filterCompanies(
            metrics,
            selectedCompanies,
            selectAllCompanies,
            availableCompanies,
            availableHighLevels,
            highLevels,
            selectedHighLevels,
            selectAllSegments,
            availableIndSegs,
            selectedIndSegs,
            industrySegments,
            availableBasins,
            selectedBasins,
            basins,
            allowBasinOther,
            selectAllBasins,
          );
          availableCompanies = availableCompanies.concat(
            comps.availableCompanies,
          );
          const seg = this.filterSegments(
            metrics,
            selectedCompanies,
            selectAllCompanies,
            availableCompanies,
            availableHighLevels,
            highLevels,
            selectedHighLevels,
            selectAllSegments,
            availableIndSegs,
            selectedIndSegs,
            industrySegments,
            availableBasins,
            selectedBasins,
            basins,
            allowBasinOther,
            selectAllBasins,
          );
          availableIndSegs = availableIndSegs.concat(seg.availableIndSegs);

          const basin = this.filterBasins(
            metrics,
            selectedCompanies,
            selectAllCompanies,
            availableCompanies,
            availableHighLevels,
            highLevels,
            selectedHighLevels,
            selectAllSegments,
            availableIndSegs,
            selectedIndSegs,
            industrySegments,
            availableBasins,
            selectedBasins,
            basins,
            allowBasinOther,
            selectAllBasins,
          );
          availableBasins = availableBasins.concat(basin.availableBasins);
        }
      });
    }
    const ids = availableIndSegs.map((o) => o.id);
    availableIndSegs = availableIndSegs.filter(
      ({ id }, index) => !ids.includes(id, index + 1),
    );

    const basinIds = availableBasins.map((o) => o.id);
    availableBasins = availableBasins.filter(
      ({ id }, index) => !basinIds.includes(id, index + 1),
    );

    availableCompanies = availableCompanies.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    return {
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    };
  }

  private filterSegments(
    metrics,
    selectedCompanies,
    selectAllCompanies,
    availableCompanies,
    availableHighLevels,
    highLevels,
    selectedHighLevels,
    selectAllSegments,
    availableIndSegs,
    selectedIndSegs,
    industrySegments,
    availableBasins,
    selectedBasins,
    basins,
    allowBasinOther,
    selectAllBasins,
  ) {
    if (
      (selectedCompanies.indexOf(
        metrics.reportingFacility.parentCompanyName
          ? metrics.reportingFacility.parentCompanyName
          : this.other,
      ) >= 0 ||
        (selectAllCompanies &&
          availableCompanies.length <= selectedCompanies.length) ||
        !selectedCompanies.length) &&
      (metrics.highLevels
        .filter((a) => availableHighLevels.indexOf(a) >= 0)
        .filter((a) => selectedHighLevels.indexOf(a) >= 0).length ||
        selectedHighLevels) &&
      (this.getSelectedBasins(
        availableBasins.length ? availableBasins : basins,
        selectedBasins,
      ).indexOf(
        metrics.reportingFacility?.reportingFacilityBasin?.replace(
          / *\([^)]*\) */g,
          '',
        ),
      ) >= 0 ||
        (metrics.reportingFacility?.reportingFacilityBasin === undefined &&
          selectedBasins.indexOf(this.other) >= 0) ||
        (selectAllBasins && availableBasins.length <= selectedBasins.length) ||
        !selectedBasins.length)
    ) {
      const existingAvailableIndSeg = availableIndSegs.findIndex(
        (available) => {
          return (
            available.longName ===
            metrics.reportingFacility.reportingFacilityIndustrySegmentLongName
          );
        },
      );

      if (existingAvailableIndSeg === -1) {
        const segment = industrySegments.find((seg) => {
          return (
            seg.longName ===
            metrics.reportingFacility.reportingFacilityIndustrySegmentLongName
          );
        });

        if (segment) {
          availableIndSegs.push(segment);

          //every time we push a highlevel, we need to filter the rest
          const hl = this.filterCompanies(
            metrics,
            selectedCompanies,
            selectAllCompanies,
            availableCompanies,
            availableHighLevels,
            highLevels,
            selectedHighLevels,
            selectAllSegments,
            availableIndSegs,
            selectedIndSegs,
            industrySegments,
            availableBasins,
            selectedBasins,
            basins,
            allowBasinOther,
            selectAllBasins,
          );
          availableHighLevels = availableHighLevels.concat(
            hl.availableHighLevels,
          );
          const seg = this.filterHighLevels(
            metrics,
            selectedCompanies,
            selectAllCompanies,
            availableCompanies,
            availableHighLevels,
            highLevels,
            selectedHighLevels,
            selectAllSegments,
            availableIndSegs,
            selectedIndSegs,
            industrySegments,
            availableBasins,
            selectedBasins,
            basins,
            allowBasinOther,
            selectAllBasins,
          );
          availableIndSegs = availableIndSegs.concat(seg.availableIndSegs);
          const basin = this.filterBasins(
            metrics,
            selectedCompanies,
            selectAllCompanies,
            availableCompanies,
            availableHighLevels,
            highLevels,
            selectedHighLevels,
            selectAllSegments,
            availableIndSegs,
            selectedIndSegs,
            industrySegments,
            availableBasins,
            selectedBasins,
            basins,
            allowBasinOther,
            selectAllBasins,
          );
          availableBasins = availableBasins.concat(basin.availableBasins);
        }
      }
    }
    const ids = availableBasins.map((o) => o.id);
    availableBasins = availableBasins.filter(
      ({ id }, index) => !ids.includes(id, index + 1),
    );

    availableHighLevels = availableHighLevels.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });

    availableCompanies = availableCompanies.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });

    return {
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    };
  }

  private filterBasins(
    metrics,
    selectedCompanies,
    selectAllCompanies,
    availableCompanies,
    availableHighLevels,
    highLevels,
    selectedHighLevels,
    selectAllSegments,
    availableIndSegs,
    selectedIndSegs,
    industrySegments,
    availableBasins,
    selectedBasins,
    basins,
    allowBasinOther,
    selectAllBasins,
  ) {
    let newOne = false;
    if (
      (selectedCompanies.indexOf(
        metrics.reportingFacility.parentCompanyName
          ? metrics.reportingFacility.parentCompanyName
          : this.other,
      ) >= 0 ||
        (selectAllCompanies &&
          availableCompanies.length <= selectedCompanies.length) ||
        !selectedCompanies.length) &&
      (metrics.highLevels
        .filter((a) => availableHighLevels.indexOf(a) >= 0)
        .filter((a) => selectedHighLevels.indexOf(a) >= 0).length ||
        selectedHighLevels) &&
      (this.getSelectedSegments(
        availableIndSegs.length ? availableIndSegs : industrySegments,
        selectedIndSegs,
      ).indexOf(
        metrics.reportingFacility.reportingFacilityIndustrySegmentLongName,
      ) >= 0 ||
        (selectAllSegments &&
          availableIndSegs.length <= selectedIndSegs.length) ||
        !selectedIndSegs.length ||
        selectedIndSegs === industrySegments.length)
    ) {
      if (metrics.reportingFacility.reportingFacilityBasin) {
        const existingAvailableBasin = availableBasins.findIndex(
          (available) => {
            return (
              available.basinFullName.replace(/ *\([^)]*\) */g, '') ===
              metrics.reportingFacility.reportingFacilityBasin.replace(
                / *\([^)]*\) */g,
                '',
              )
            );
          },
        );

        if (existingAvailableBasin === -1) {
          const existingBasin = basins.find((bb) => {
            return (
              bb.basinFullName.replace(/ *\([^)]*\) */g, '') ===
              metrics.reportingFacility.reportingFacilityBasin.replace(
                / *\([^)]*\) */g,
                '',
              )
            );
          });

          if (existingBasin) {
            availableBasins.push(existingBasin);
            newOne = true;
          }
        }
      } else {
        allowBasinOther = true;
        newOne = true;
      }
    }

    if (newOne) {
      //every time we push a highlevel, we need to filter the rest
      const comps = this.filterCompanies(
        metrics,
        selectedCompanies,
        selectAllCompanies,
        availableCompanies,
        availableHighLevels,
        highLevels,
        selectedHighLevels,
        selectAllSegments,
        availableIndSegs,
        selectedIndSegs,
        industrySegments,
        availableBasins,
        selectedBasins,
        basins,
        allowBasinOther,
        selectAllBasins,
      );

      availableCompanies = availableCompanies.concat(comps.availableCompanies);
      const hl = this.filterHighLevels(
        metrics,
        selectedCompanies,
        selectAllCompanies,
        availableCompanies,
        availableHighLevels,
        highLevels,
        selectedHighLevels,
        selectAllSegments,
        availableIndSegs,
        selectedIndSegs,
        industrySegments,
        availableBasins,
        selectedBasins,
        basins,
        allowBasinOther,
        selectAllBasins,
      );
      availableHighLevels = availableHighLevels.concat(hl.availableHighLevels);

      const seg = this.filterSegments(
        metrics,
        selectedCompanies,
        selectAllCompanies,
        availableCompanies,
        availableHighLevels,
        highLevels,
        selectedHighLevels,
        selectAllSegments,
        availableIndSegs,
        selectedIndSegs,
        industrySegments,
        availableBasins,
        selectedBasins,
        basins,
        allowBasinOther,
        selectAllBasins,
      );
      availableIndSegs = availableIndSegs.concat(seg.availableIndSegs);
    }

    const ids = availableIndSegs.map((o) => o.id);
    availableIndSegs = availableIndSegs.filter(
      ({ id }, index) => !ids.includes(id, index + 1),
    );

    availableHighLevels = availableHighLevels.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });

    availableCompanies = availableCompanies.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    return {
      metrics,
      selectedCompanies,
      selectAllCompanies,
      availableCompanies,
      availableHighLevels,
      highLevels,
      selectedHighLevels,
      selectAllSegments,
      availableIndSegs,
      selectedIndSegs,
      industrySegments,
      availableBasins,
      selectedBasins,
      basins,
      allowBasinOther,
      selectAllBasins,
    };
  }
}
