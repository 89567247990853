import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { Upload } from '@iconic-air/models';
import { map, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadsDatabaseService {
  uploads$: Subject<Upload[]> = new Subject();
  uploads: Upload[] = [];

  constructor(
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
  ) {
    this.getUploads();
  }

  getUploads(): Observable<any> {
    const uploads$ = this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('uploads', (ref) => ref.orderBy('createdAt', 'desc'))
      .snapshotChanges()
      .pipe(
        map((data: any) => {
          return data.map((a) => {
            return {
              id: a.payload.doc.id,
              ...a.payload.doc.data(),
            };
          });
        }),
      );
    return uploads$;
  }

  async createUpload(upload: Upload): Promise<any> {
    const uploadId = this._afs.createId();
    const setObject: Upload = {
      ...upload,
      id: uploadId,
    };

    try {
      await this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('uploads')
        .doc(uploadId)
        .set(setObject);
      return setObject;
    } catch (e) {
      console.error(e);
      return { error: 'Error creating upload.' };
    }
  }
}
