<div class="flex flex-col w-full p-4">
  <div
    class="flex items-center justify-around bg-primary bg-opacity-15 text-white rounded-md"
  >
    <div class="w-1/5 text-white flex justify-center items-center">
      <span class="material-icons icon-size-14"> support_agent </span>
    </div>
    <div class="flex flex-col w-4/5">
      <div class="text-3xl mt-3">Text or Call Support</div>
      <div class="text-lg mb-3 mt-2">
        Prefer to text or call? Reach us at
        <a class="text-white" href="tel:3044914224"><b>(304) 491-4224</b></a>
      </div>
    </div>
  </div>
  <div class="flex flex-col mt-6">
    <div class="text-3xl">Email Support</div>
    <div class="text-lg mt-2">
      Prefer to send an email? Type your message below and click "Email Support"
    </div>
    <div class="mt-2" [formGroup]="supportForm">
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Email support</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          formControlName="comment"
          placeholder="Ex. Have a feature suggestion or found a bug? Let us know here..."
        ></textarea>
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div class="flex mt-2">
      <div class="w-3/4"></div>
      <button
        class="w-1/4"
        mat-raised-button
        [disabled]="!supportForm.valid"
        color="primary"
        (click)="sendEmail()"
      >
        Email Support
      </button>
    </div>
  </div>
</div>
