import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ChartType,
  ChartOptions,
  ChartDataSets,
  ChartTooltipItem,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Label } from 'ng2-charts';
import { BenchmarkService } from '../../pages/analytics/benchmark/benchmark.service';
import { StaticDataService } from '../../services/static-data/static.data.service';

@Component({
  selector: 'app-chart-bar-stacked',
  templateUrl: './chart-bar-stacked.component.html',
  styleUrls: ['./chart-bar-stacked.component.scss'],
})
export class ChartBarStackedComponent {
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      mode: 'index',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      bodyFontColor: 'rgba(0, 0, 0, 1)',
      borderColor: 'rgba(218, 220, 232, 1)',
      borderWidth: 1,
      titleFontColor: 'rgba(0, 0, 0, 1)',
      bodyFontFamily: 'Roobert-Regular',
      xPadding: 18,
      yPadding: 18,
      titleFontSize: 18,
      bodyFontSize: 16,
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: '',
            fontFamily: 'Roobert-Regular',
          },
          ticks: {
            fontFamily: 'Roobert-Regular',
          },
        },
      ],
      yAxes: [
        {
          position: 'left',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: '',
            fontFamily: 'Roobert-Regular',
          },
          ticks: {
            fontFamily: 'Roobert-Regular',
          },
        },
      ],
    },
    legend: {
      labels: {
        fontFamily: 'Roobert-Regular',
      },
    },
  };

  @Input() public barChartLabels: Label[] = [];
  @Input() public showTotal: string;
  @Input() public extraClasses: string[] = [];
  @Input() public barChartData: ChartDataSets[] = [];
  @Input() public selectedIntensity: string;
  @Input() public hoverOverBench = false;
  @Input() public selectedYear;
  @Input() public selectedSegments = [];
  @Input() public selectedBasins = [];
  @Input() public selectedHighLevels = [];
  @Input() public selectedChartType;
  @Input() public xAxisLabel: string;
  public chartRendering = false;
  public yAxisLabel: string;
  public unitLabel: string;
  public localSelectedChartType: string;
  get basins() {
    return this.staticData.basins;
  }
  get industrySegments() {
    return this.staticData.industrySegments;
  }

  /*  public barChartOptions: ChartOptions; */
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  @ViewChild(BaseChartDirective, { static: false })
  chartInstance: BaseChartDirective;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedIntensity?.currentValue) {
      this.selectedIntensity = changes.selectedIntensity?.currentValue;
    }
    if (
      changes.selectedChartType &&
      changes.selectedChartType?.currentValue !== this.localSelectedChartType
    ) {
      this.localSelectedChartType = changes.selectedChartType?.currentValue;
      this.updateAxis(changes.selectedChartType?.currentValue);
    }
  }

  constructor(
    private benchmarkService: BenchmarkService,
    private staticData: StaticDataService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public updateAxis(selectedChartType) {
    this.chartRendering = true;
    this.cdRef.detectChanges();
    if (selectedChartType === 'emissions' || !selectedChartType) {
      this.unitLabel = 'mT';
      if (this.selectedIntensity === 'CO2e (AR5)') {
        this.yAxisLabel = 'mT CO2e';
      } else this.yAxisLabel = 'mT';
    } else {
      if (this.selectedIntensity === 'CO2e (AR5)') {
        this.yAxisLabel = 'GHG Intensity (kg CO2e/BOE)';
        this.unitLabel = 'kg CO2e/BOE';
      } else {
        this.yAxisLabel = `${this.selectedIntensity} Intensity (%)`;
        this.unitLabel = '%';
      }
    }

    // bars
    this.barChartOptions.tooltips.callbacks = {
      beforeBody: (items, data) => {
        const label: string[] = [];
        let title = '';
        let total = 0;

        if (this.selectedIntensity === 'CO2e (AR5)') {
          if (this.localSelectedChartType === 'emissions') {
            items.forEach((item) => {
              total += parseFloat(item.value);
              title = item.xLabel.toString();
            });
            label.push(
              `${parseFloat(total.toFixed(3)).toLocaleString()} ${'mT CO2e'}`,
            );
            label.push(' ');
          }
        }

        return label;
      },
      afterBody: (items, data) => {
        const label: string[] = [];
        let title = '';

        items.forEach((item) => {
          title = item.xLabel.toString();
        });

        const feed = this.benchmarkService.dataFaster(
          this.selectedIntensity,
          [this.selectedYear],
          this.selectedYear,
          this.basins,
          this.selectedBasins,
          false,
          [title.toLowerCase()],
          [title.toLowerCase()],
          false,
          this.industrySegments,
          this.selectedSegments.map((i) => i.id),
          false,
          this.selectedHighLevels,
          false,
          this.staticData.emissionSourcesLabels,
          false,
          false,
        );

        label.push(' ');
        label.push('Basins:');
        label.push();
        feed.basinData?.forEach((bb) => {
          label.push(
            bb.label +
              ': ' +
              parseFloat(bb.value.toFixed(3)).toLocaleString() +
              ' ' +
              'mT',
          );
        });

        label.push(' ');
        label.push('Segments:');
        feed.indSegData?.forEach((seg) => {
          label.push(
            seg.label +
              ': ' +
              parseFloat(seg.value.toFixed(3)).toLocaleString() +
              ' ' +
              'mT',
          );
        });

        return label;
      },
      label: (item: ChartTooltipItem) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        if (vm.localSelectedChartType === 'emissions') {
          return `${parseFloat(
            parseFloat(item.yLabel as string).toFixed(3),
          ).toLocaleString()} mT ${
            vm.barChartData[item.datasetIndex as number].label
          }`;
        } else
          return `${parseFloat(
            parseFloat(item.yLabel as string).toFixed(3),
          ).toLocaleString()} ${this.unitLabel}`;
      },
    };
    this.barChartOptions.scales.xAxes[0].scaleLabel.labelString =
      this.xAxisLabel;
    this.barChartOptions.scales.yAxes[0].scaleLabel.labelString =
      this.yAxisLabel;

    this.barChartOptions.scales.xAxes[0].scaleLabel.fontSize = 16;
    this.barChartOptions.scales.xAxes[0].scaleLabel.fontStyle = 'bold';
    this.barChartOptions.scales.xAxes[0].scaleLabel.fontColor = '#000000';
    this.barChartOptions.scales.yAxes[0].scaleLabel.fontSize = 16;
    this.barChartOptions.scales.yAxes[0].scaleLabel.fontStyle = 'bold';
    this.barChartOptions.scales.yAxes[0].scaleLabel.fontColor = '#000000';

    // left in for now in case we need to add back
    this.barChartOptions.scales.yAxes[0].ticks = {
      callback: (value: number, index, values) => {
        return value.toLocaleString() + (this.unitLabel === '%' ? '%' : '');
      },
    };

    this.chartRendering = false;
    this.cdRef.detectChanges();
  }
}
