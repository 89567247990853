import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-support-dialog',
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.scss'],
})
export class ContactSupportDialogComponent implements OnInit {
  get userData() {
    return this.perms.userData;
  }
  public supportForm: FormGroup;

  private _errors;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    private perms: PermissionsWebGuard,
    @Inject(MAT_DIALOG_DATA) public data: any | any[],
  ) {
    // empty
    this._errors = data?.errors;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.supportForm = new FormGroup({
      comment: new FormControl(
        this.data?.comments || null,
        Validators.required,
      ),
    });
  }

  sendEmail(): void {
    this.afs
      .collection('emailReports')
      .add({
        to: 'support@iconicair.io',
        message: {
          subject: `SUPPORT REQUEST Customer ${this.userData.customerIds[0]}`,
          html: `
          <h2>This message is from: <b>${this.userData.email} (${
            this.userData.uid
          })</b></h2>
          <h2>Their support issue is: <b>${this.supportForm
            .get('comment')
            .value.trim()}</b></h2>${
            this._errors?.length
              ? '<h2> With errors: <b>' +
                JSON.stringify(this._errors) +
                '</b></h2>'
              : ''
          }
          <h2>The route they were on was: <b>${this.router.url}</b></h2>
        `,
        },
      })
      .then((empty) => {
        this.dialog.closeAll();
        this.snackBar.open(
          'Email sent. Someone from our support team will reach out shortly!',
          'Close',
          { duration: 3500 },
        );
      });
  }
}
