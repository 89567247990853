import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prependItem',
})
export class PrependItemPipe implements PipeTransform {
  transform(
    items: any[],
    selectedItem: any,
    key: string,
    originalList: any[],
  ): any[] {
    if (!items || !selectedItem || !key || Array.isArray(selectedItem))
      return items;

    // Check if the selected item is already in the list based on the key
    const isPresent = items.some(
      (item) => item[key] === selectedItem || item[key] === selectedItem[key],
    );

    const selectedItemFull = originalList?.find(
      (item) => item[key] === selectedItem || item[key] === selectedItem[key],
    );

    if (selectedItemFull) selectedItem = selectedItemFull;

    // If not present, append the selected item to the list
    if (!isPresent) return [selectedItem, ...items];
    else {
      // reorder it to the front
      const index = items.findIndex(
        (item) => item[key] === selectedItem || item[key] === selectedItem[key],
      );
      return [
        selectedItem,
        ...items.slice(0, index),
        ...items.slice(index + 1),
      ];
    }
  }
}
