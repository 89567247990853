import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scientificNotation',
})
export class ScientificNotationPipe implements PipeTransform {
  transform(value: number, digits: number = 2): string {
    if (value == null || value === 0) return '0';

    const expForm = Number(value)?.toExponential(digits); // e.g., "1.1e-5"
    const [coefficient, exponent] = expForm.split('e'); // Split into coefficient and exponent

    return `${coefficient} × 10${this.toSuperscript(exponent)}`;
  }

  private toSuperscript(exp: string): string {
    const superscriptMap: { [key: string]: string } = {
      '-': '⁻',
      '0': '⁰',
      '1': '¹',
      '2': '²',
      '3': '³',
      '4': '⁴',
      '5': '⁵',
      '6': '⁶',
      '7': '⁷',
      '8': '⁸',
      '9': '⁹',
    };

    return exp
      .split('')
      .map((char) => superscriptMap[char] || char)
      .join('');
  }
}
