import { Pipe, PipeTransform } from '@angular/core';
import { Facility } from '@iconic-air/models';

@Pipe({
  name: 'filterFacilityByReportingFacility',
})
export class FilterFacilityByReportingFacilityPipe implements PipeTransform {
  transform(value: Facility[], reportingFacilityId: string): any {
    if (!value?.length) {
      return value;
    }

    return value?.filter((fac: Facility) => {
      return fac.reportingFacilityId === reportingFacilityId;
    });
  }
}
