import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'round' })
export class RoundPipe implements PipeTransform {
  /**
   * Transforms a given value into a rounded number.
   *
   * @param {string} value - The value to be transformed.
   * @return {string} The transformed value.
   */
  transform(value: number): number {
    if (!value || !Number(value)) return value;
    let returnValue = 0,
      decimalPlaces = -1;
    while (returnValue === 0) {
      decimalPlaces++;
      const multipler = Math.pow(10, decimalPlaces);
      returnValue = Math.round(Number(value) * multipler) / multipler;
    }
    return returnValue;
  }
}
