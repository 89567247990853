import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationReportDialogComponent } from './validation-report-dialog.component';
import { MaterialModule } from '../../theme/material.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [ValidationReportDialogComponent],
  imports: [CommonModule, MaterialModule, PipesModule],
  exports: [ValidationReportDialogComponent],
})
export class ValidationReportDialogModule {}
