import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  constructor(private _http: HttpClient) {}

  async checkIfSignedIn() {
    try {
      // wait half a second for auth to load.
      await new Promise((resolve) => setTimeout(resolve, 500));
      await lastValueFrom(this._http.get('/signupUser'));
    } catch (error) {
      if (['You are already signed up'].includes(error?.error?.message))
        return true;
    }
    return false;
  }

  async generateCode(email: string, screen: string) {
    await lastValueFrom(this._http.post('/signupUser', { email, screen }));
  }

  async submitCode(
    email: string,
    code: string,
    displayName: string,
    password: string,
    screen: string,
  ) {
    return (await lastValueFrom(
      this._http.put('/signupUser', {
        email,
        code,
        displayName,
        password,
        screen,
      }),
    )) as {
      message: string;
      userPassword: string;
    };
  }
}
