import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressStatusDialogComponent } from '../../components/progress-status-dialog/progress-status-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ProgressStatusDialogService {
  private dialogRefs: MatDialogRef<ProgressStatusDialogComponent>[] = [];

  constructor(private dialog: MatDialog) {}

  open(action: string, initialMessage: string): ProgressStatusDialogComponent {
    const dialogRef = this.dialog.open(ProgressStatusDialogComponent, {
      data: { action, startTime: Number(new Date()) },
      disableClose: true,
    }) as MatDialogRef<ProgressStatusDialogComponent>;

    dialogRef.componentInstance?.changeMessageProgress(initialMessage, 0);
    this.dialogRefs.push(dialogRef);

    dialogRef.afterClosed().subscribe(() => {
      const index = this.dialogRefs.indexOf(dialogRef);
      if (index !== -1) {
        clearInterval(dialogRef.componentInstance.intervalId); // Clear the interval when the dialog closes
        this.dialogRefs.splice(index, 1);
      }
    });

    return dialogRef.componentInstance;
  }

  close(dialogComponent: ProgressStatusDialogComponent) {
    const dialogRef = this.dialogRefs.find(
      (ref) => ref.componentInstance === dialogComponent,
    );
    if (dialogRef) {
      dialogRef.close();
    }
  }

  closeAll() {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  }
}
