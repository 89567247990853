import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';

@Component({
  selector: 'app-reauthenticate-dialog',
  templateUrl: './reauthenticate-dialog.component.html',
  styleUrls: ['./reauthenticate-dialog.component.scss'],
})
export class ReauthenticateDialogComponent {
  password = '';
  showPassword = false;
  email;
  status = '';
  constructor(
    public dialogRef: MatDialogRef<ReauthenticateDialogComponent>,
    public router: Router,
    private auth: AuthenticationWebService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.email = data.email;
  }

  sendPassword(password: string) {
    this.dialogRef.close(password);
  }

  forgotPassword() {
    this.auth
      .forgotPassword(this.email)
      .then(() => {
        this.status = 'Email sent to: ' + this.email;
      })
      .catch((error) => {
        throw error;
      });
  }
}
