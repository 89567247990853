import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tooltipData'
})
export class TooltipDataPipe implements PipeTransform {
  transform(func: any, ...args: any): unknown {
    return func(...args);
  }
}
