export const lineChartColors = [
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },

  // Fix later just repeats for now

  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },

  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },

  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },

  // Fix later just repeats for now

  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },

  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },

  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
  // Grape
  {
    fill: true,
    backgroundColor: 'rgba(111, 45, 168, 0.7)',
    borderColor: 'rgba(111, 45, 168, 1)',
    pointBackgroundColor: 'rgba(111, 45, 168, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(111, 45, 168, 1)',
    pointHoverBorderColor: 'rgba(111, 45, 168, 0.8)',
  },
  // Tertiary 60
  {
    fill: true,
    backgroundColor: 'rgba(0, 149, 247, 0.7)',
    borderColor: 'rgba(0, 149, 247, 1)',
    pointBackgroundColor: 'rgba(0, 149, 247, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 149, 247, 1)',
    pointHoverBorderColor: 'rgba(0, 149, 247, 0.8)',
  },
  // Secondary 30
  {
    fill: true,
    backgroundColor: 'rgba(0, 78, 91, 0.7)',
    borderColor: 'rgba(0, 78, 91, 1)',
    pointBackgroundColor: 'rgba(0, 78, 91, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 78, 91, 1)',
    pointHoverBorderColor: 'rgba(0, 78, 91, 0.8)',
  },
  // Gold
  {
    fill: true,
    backgroundColor: 'rgba(185, 137, 3, 0.7)',
    borderColor: 'rgba(185, 137, 3, 1)',
    pointBackgroundColor: 'rgba(185, 137, 3, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(185, 137, 3, 1)',
    pointHoverBorderColor: 'rgba(185, 137, 3, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(154, 24, 83, 0.7)',
    borderColor: 'rgba(154, 24, 83, 1)',
    pointBackgroundColor: 'rgba(154, 24, 83, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(154, 24, 83, 1)',
    pointHoverBorderColor: 'rgba(154, 24, 83, 0.8)',
  },
  // Red
  {
    fill: true,
    backgroundColor: 'rgba(250, 78, 86, 0.7)',
    borderColor: 'rgba(250, 78, 86, 1)',
    pointBackgroundColor: 'rgba(250, 78, 86, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(250, 78, 86, 1)',
    pointHoverBorderColor: 'rgba(250, 78, 86, 0.8)',
  },
  // Maroon
  {
    fill: true,
    backgroundColor: 'rgba(82, 4, 8, 0.7)',
    borderColor: 'rgba(82, 4, 8, 1)',
    pointBackgroundColor: 'rgba(82, 4, 8, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(82, 4, 8, 1)',
    pointHoverBorderColor: 'rgba(82, 4, 8, 0.8)',
  },
  // Green
  {
    fill: true,
    backgroundColor: 'rgba(40, 138, 40, 0.7)',
    borderColor: 'rgba(40, 138, 40, 1)',
    pointBackgroundColor: 'rgba(40, 138, 40, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(40, 138, 40, 1)',
    pointHoverBorderColor: 'rgba(40, 138, 40, 0.8)',
  },
  // Eggplant
  {
    fill: true,
    backgroundColor: 'rgba(136, 45, 210, 0.7)',
    borderColor: 'rgba(136, 45, 210, 1)',
    pointBackgroundColor: 'rgba(136, 45, 210, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(26, 40, 111, 1)',
    pointHoverBorderColor: 'rgba(26, 40, 111, 0.8)',
  },
  // Primary
  {
    fill: true,
    backgroundColor: 'rgba(0, 13, 164, 0.7)',
    borderColor: 'rgba(0, 13, 164, 1)',
    pointBackgroundColor: 'rgba(0, 13, 164, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 13, 164, 1)',
    pointHoverBorderColor: 'rgba(0, 13, 164, 0.8)',
  },
  // Magenta
  {
    fill: true,
    backgroundColor: 'rgba(238, 83, 152, 0.7)',
    borderColor: 'rgba(238, 83, 152, 1)',
    pointBackgroundColor: 'rgba(238, 83, 152, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(238, 83, 152, 1)',
    pointHoverBorderColor: 'rgba(238, 83, 152, 0.8)',
  },
  // Tertiary 20
  {
    fill: true,
    backgroundColor: 'rgba(0, 50, 88, 0.7)',
    borderColor: 'rgba(0, 50, 88, 1)',
    pointBackgroundColor: 'rgba(0, 50, 88, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(0, 50, 88, 1)',
    pointHoverBorderColor: 'rgba(0, 50, 88, 0.8)',
  },
  // Orange
  {
    fill: true,
    backgroundColor: 'rgba(236, 113, 19, 0.7)',
    borderColor: 'rgba(236, 113, 19, 1)',
    pointBackgroundColor: 'rgba(236, 113, 19, 1)',
    borderWidth: 1,
    pointBorderWidth: 0,
    pointHoverBackgroundColor: 'rgba(236, 113, 19, 1)',
    pointHoverBorderColor: 'rgba(236, 113, 19, 0.8)',
  },
];
