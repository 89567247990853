import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-integration-connected',
  templateUrl: './integration-connected.component.html',
  styleUrls: ['./integration-connected.component.scss'],
})
export class IntegrationConnectedComponent implements AgRendererComponent {
  params;
  gridApi: GridApi;
  connected = false;
  setup = false;
  label = 'Connected'
  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.connected = params.value?.includes('connected') ? true : false;
    this.setup = params.value?.includes('setup') ? true : false;
    if (this.connected && this.setup) {
      this.label = 'Connected'
    } else if (!this.setup) {
      this.label = 'Needs setup'
    } else {
      this.label = 'Connection failed'
    }
    this.gridApi = params.api;
  }
  
  refresh(params) {
    return true;
  }
}
