import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PermissionsWebGuard } from '../../../guards/permissions/permissions-web-guard.guard';
import { map } from 'rxjs';

@Injectable()
export class AdminEmissionsService {
  constructor(
    private perms: PermissionsWebGuard,
    private afs: AngularFirestore,
  ) {}

  public getProviers() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('providers')
      .snapshotChanges();
  }

  public getCalcMethods() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('calculationMethods')
      .snapshotChanges();
  }

  public setCalcMethods(calculationMethods) {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('calculationMethods')
      .set({ calculationMethods }, { merge: true });
  }

  public setFrameworks(frameworks) {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('calculationMethods')
      .set({ frameworks }, { merge: true });
  }

  public getIndustrySegments() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .get()
      .pipe(
        map((data) => {
          const document: any = data?.data();
          return document.industrySegments;
        }),
      );
  }

  public async setIntensityFunctions(intensityFunctions) {
    try {
      await this.afs
        .collection('customers')
        .doc(this.perms.activeCustomerId)
        .collection('companyValues')
        .doc('intensityFunctions')
        .update(intensityFunctions);
    } catch (e) {
      if (e?.message?.includes('No document to update')) {
        await this.afs
          .collection('customers')
          .doc(this.perms.activeCustomerId)
          .collection('companyValues')
          .doc('intensityFunctions')
          .set(intensityFunctions);
      } else {
        throw e;
      }
    }
    return;
  }

  public getReportingPeriods() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('reportingPeriods')
      .get();
  }
}
