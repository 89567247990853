import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss'],
})
export class ChartLineComponent {
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          position: 'left',
          ticks: {
            beginAtZero: true,
            callback: (value: number) => {
              return value.toLocaleString();
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  @Input() public lineChartLabels: Label[] = [];
  @Input() public showTotal: string;
  @Input() public extraClasses: string[] = [];
  @Input() public lineChartData: ChartDataSets[] = [];
  @Input() public xAxisLabel: string;
  @Input() public yAxisLabel: string;
  @Input() public selectedIntensity: string;
  @Input() public hoverOverBench = false;
  @Input() public selectedYear;
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
}
