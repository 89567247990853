<div class="flex-auto flex flex-col">
  <div class="flex items-center flex-col md:flex-row">
    <div *ngIf="!facility?.gasSamples?.length">
      No gas samples exist yet for {{ facility?.facilityName}}
    </div>
  </div>
  <ng-container *ngIf="loading">
    <div class="flex items-center justify-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div *ngIf="facility?.gasSamples">
      <table
        mat-table
        [dataSource]="facility?.gasSamples"
        class="w-full table-row-{{i}}"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            {{element.name | titlecase}}
          </td>
        </ng-container>
        <ng-container matColumnDef="sampleDate">
          <th mat-header-cell *matHeaderCellDef>Sample Date</th>
          <td mat-cell *matCellDef="let element">
            {{element.sampleDate | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="effectiveDate">
          <th mat-header-cell *matHeaderCellDef>Effective Date</th>
          <td mat-cell *matCellDef="let element">
            {{element.effectiveDate | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="co2">
          <th mat-header-cell *matHeaderCellDef>{{'CO2' | emission}}</th>
          <td mat-cell *matCellDef="let element">
            {{element.co2 | number:'1.3-3'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="ch4">
          <th mat-header-cell *matHeaderCellDef>{{'CH4' | emission}}</th>
          <td mat-cell *matCellDef="let element">
            {{element.ch4 | number:'1.3-3'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>Edit Sample</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <button
              mat-button
              class="flex items-center p-2 rounded-3xl text-center text-primary cursor-pointer"
              color="primary"
              (click)="addGasSample($event, false, element);"
            >
              Edit
            </button>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="gasSampleColumns"
          class="text-lg"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: gasSampleColumns;"
          class="text-lg hover:bg-primary-500"
        ></tr>
      </table>
    </div>
  </ng-container>
</div>
