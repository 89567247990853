import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../../components/error-dialog/error-dialog.component';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private errors: any[] = [];
  private ref: MatDialogRef<ErrorDialogComponent> | null = null;
  constructor(
    private zone: NgZone,
    private injector: Injector,
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private perms: PermissionsWebGuard,
  ) {}

  public handleError(error: any) {
    let errorToProcess = error;
    if (error?.rejection) errorToProcess = error.rejection;
    // support array of errors
    if (Array.isArray(errorToProcess))
      errorToProcess.forEach((currentError) =>
        this._processError(currentError),
      );
    else this._processError(errorToProcess);
  }

  /**
   * function will process an error and add it if needed to the database and clean up some errors
   * @param error error that was thrown
   */
  private _processError(error: any) {
    const compError: any = {};
    const router = this.injector.get(Router);
    const zone = this.injector.get(NgZone);
    compError.message = error.message || error.toString();

    if (compError.message.includes('Missing or insufficient permissions.')) {
      console.warn('Missing or insufficient permissions was thrown.');
      return;
    }
    compError.title = error?.title || '';
    if (window.location.href.indexOf('localhost') === -1) {
      this.afs.collection('errors').add({
        timestamp: Date.now(),
        date: new Date().toISOString(),
        ...compError,
        user: this.perms?.userData?.uid ? this.perms?.userData?.uid : '',
        email: this.perms?.userData?.email ? this.perms?.userData?.email : '',
        customer: this.perms?.userData?.customerIds?.length
          ? this.perms.activeCustomerId
          : '',
        page: window.location.href,
      });
    }
    if (error.toString()?.includes('wrong-password')) {
      compError.message =
        'Invalid username or password. Please re-enter and try again.';
    }

    if (error.toString().includes('too-many-requests')) {
      compError.message =
        'Sign in attempts limit has been reached and your account has been temporarily disabled. Please reset password to continue or try again later.';
    }
    const httpResponse =
      error instanceof HttpErrorResponse
        ? true
        : error?.message
            ?.toString()
            ?.startsWith('Uncaught (in promise): HttpErrorResponse: ')
        ? true
        : false;
    if (httpResponse) {
      let errorToCheck = error;
      let logout = true;
      // if it is uncaught like this it means it comes from a sub api and not
      // the main api, therefore we don't need to logout the user
      if (
        error?.message
          ?.toString()
          ?.startsWith('Uncaught (in promise): HttpErrorResponse: ')
      ) {
        logout = false;
        errorToCheck = JSON.parse(
          error?.message?.replace(
            'Uncaught (in promise): HttpErrorResponse: ',
            '',
          ),
        );
      }
      if (errorToCheck?.error && errorToCheck?.error?.message) {
        compError.message = errorToCheck.error.message;
      } else if (typeof errorToCheck?.error === 'string') {
        compError.message = errorToCheck.error;
      }

      compError.code =
        errorToCheck?.status !== undefined
          ? errorToCheck?.status?.toString()
          : undefined;

      if (errorToCheck?.status === 401 && logout) {
        compError.message = 'Session Timed Out';
        zone.run(() => {
          router.navigate(['/login']);
        });
      } else if (errorToCheck?.status === 503 || errorToCheck?.status === 504) {
        compError.message = 'Service is Currently Unavailable';
      }
    }

    if (
      error?.message?.includes('auth/internal-error') &&
      error?.message?.includes('FAILED_PRECONDITION')
    ) {
      return;
    }
    this.addError(compError);
    console.error(error);
  }

  public addError = (err: any): void => {
    this.errors.push(err);
    this.onErrorAdded();
  };

  private onErrorAdded = (): void => {
    if (this.ref) {
      this.ref.componentInstance.data = this.errors;
    } else {
      this.zone.run(() => {
        const unauthorized = this.errors.find((err: any) => {
          return err.code === '401';
        });
        if (unauthorized) {
          // close any remaining dialogs when logged out
          this.dialog.closeAll();
        }
        this.ref = this.dialog.open(ErrorDialogComponent, {
          data: this.errors,
          panelClass: 'dialog-class',
        });

        this.ref.afterClosed().subscribe(() => {
          this.errors = [];
          this.ref = null;
        });
      });
    }
  };
}
