import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-yes-no-column',
  templateUrl: './yes-no-column.component.html',
  styleUrls: ['./yes-no-column.component.scss'],
})
export class YesNoColumnComponent implements AgRendererComponent {
  public params;
  private gridApi: GridApi;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.gridApi = params.api;
  }

  refresh() {
    return true;
  }
}
