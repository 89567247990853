import { Injectable } from '@angular/core';
import { ValidationReportDialogComponent } from '../../components/validation-report-dialog/validation-report-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SheetValidationError } from '@iconic-air-monorepo/models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import firebase from 'firebase/compat/app';
import {
  checkApi14Number,
  checkApiNumber,
  checkBoolean,
  checkDate,
  checkEmail,
  checkLatitude,
  checkLongitude,
  checkPhoneNumber,
  checkReportingPeriod,
  checkYear,
} from './utils';

@Injectable({
  providedIn: 'root',
})
export class ValidationReportDialogService {
  private dialogRefs: MatDialogRef<ValidationReportDialogComponent>[] = [];

  constructor(
    private _dialog: MatDialog,
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
  ) {}

  /**
   * Opens a validation report dialog component.
   *
   * @param {SheetValidationError[]} errorLog - The array of sheet validation errors.
   * @param {string} [fileName] - The name of the file.
   * @param {string} [format] - The format of the file.
   * @param {string} [reportingFacility] - The reporting facility.
   * @param {string} [errorDocument='emissionsImport'] - The document in the errors collection to save to.
   * @return {ValidationReportDialogComponent} The instance of the validation report dialog component.
   */
  open(
    errorLog: SheetValidationError[],
    fileName?: string,
    format?: string,
    reportingFacility?: string,
    errorDocument = 'emissionsImport',
  ): ValidationReportDialogComponent {
    const dialogRef = this._dialog.open(ValidationReportDialogComponent, {
      data: { errorLog },
      disableClose: true,
    }) as MatDialogRef<ValidationReportDialogComponent>;

    this.dialogRefs.push(dialogRef);

    dialogRef.afterClosed().subscribe(() => {
      const index = this.dialogRefs.indexOf(dialogRef);
      if (index !== -1) this.dialogRefs.splice(index, 1);
    });
    if (fileName || format || reportingFacility) {
      const data = {
        fileName,
        uploadDate: Date.now(),
        reportingFacility,
        uploadedBy: this._perms.userData.email,
        format,
        errorLog,
      };
      data.errorLog.forEach((error) => {
        if (error?.error === undefined) delete error.error;
      });
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('errors')
        .doc(errorDocument)
        .set(
          {
            log: firebase.firestore.FieldValue.arrayUnion(data),
          },
          { merge: true },
        );
    }
    return dialogRef.componentInstance;
  }

  close(dialogComponent: ValidationReportDialogComponent) {
    const dialogRef = this.dialogRefs.find(
      (ref) => ref.componentInstance === dialogComponent,
    );
    if (dialogRef) {
      dialogRef.close();
    }
  }

  closeAll() {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  }

  checkTypeErrors(
    field: { key: string; type: string; min?: number; max?: number },
    data: any,
  ) {
    const messages: string[] = [];
    switch (field.type) {
      case 'longitude':
        if (!checkLongitude(data[field.key])) {
          messages.push(`${field.key} is not a valid longitude`);
        } else if (data[field.key]) data[field.key] = Number(data[field.key]);
        break;
      case 'latitude':
        if (!checkLatitude(data[field.key])) {
          messages.push(`${field.key} is not a valid latitude`);
        } else if (data[field.key]) data[field.key] = Number(data[field.key]);
        break;
      case 'email':
        if (!checkEmail(data[field.key])) {
          messages.push(`${field.key} is not a valid email`);
        }
        break;
      case 'year':
        if (!checkYear(data[field.key])) {
          messages.push(`${field.key} is not a valid year`);
        }
        break;
      case 'phone':
        if (!checkPhoneNumber(data[field.key])) {
          messages.push(`${field.key} is not a valid phone number`);
        }
        break;
      case 'date':
        if (!checkDate(data[field.key])) {
          messages.push(
            `${field.key} is not a valid date, please use the following format: MM/DD/YYYY. Note: Date can only be in the future 30 years or in the past 50 years.`,
          );
        }
        break;
      case 'boolean':
        if (!checkBoolean(data[field.key])) {
          messages.push(
            `${field.key} is not a valid boolean, please use one of the following 'true', 'false', 'yes', 'no'`,
          );
        }
        break;
      case 'apiNumber':
        if (!checkApiNumber(data[field.key])) {
          messages.push(
            `${field.key} is not a valid api number, it should be a 10 character number (leeding 0's might be needed) and dashes do not count to the 10 characters but are allowed.`,
          );
        }
        break;
      case 'api14Number':
        if (!checkApi14Number(data[field.key])) {
          messages.push(
            `${field.key} is not a valid api number, it should be a 14 character number (leeding 0's might be needed) and dashes do not count to the 14 characters but are allowed.`,
          );
        }
        break;
      case 'reportingPeriod':
        if (!checkReportingPeriod(data[field.key])) {
          messages.push(
            `${field.key} is not a valid reporting period, ${
              data[field.key]
            } has to either be a date, year, quarter (Q1, etc) or first or second for semi annual.`,
          );
        }
        break;
      case 'number':
        if (
          (field.min || field?.min?.toString() === '0') &&
          !isNaN(Number(field.min)) &&
          data[field.key] < Number(field?.min)
        )
          messages.push(
            `${field.key} is not a valid number, it has to be greater than or equal to ${field.min}.`,
          );
        if (
          (field.max || field?.max?.toString() === '0') &&
          !isNaN(Number(field.max)) &&
          data[field.key] > Number(field?.max)
        )
          messages.push(
            `${field.key} is not a valid number, it has to be less than or equal to ${field.max}.`,
          );
        break;
    }
    return messages;
  }

  createError(
    sheetIndex: number,
    sheetName: string,
    labelCell: string,
    label: string,
    currentValue,
    currentValueCell,
    expectedValue,
    error?: string,
  ) {
    return {
      sheetIndex,
      sheetName,
      labelCell,
      label,
      currentValue,
      currentValueCell,
      expectedValue,
      error,
    } as SheetValidationError;
  }
}
