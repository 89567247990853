import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'facilityFilter',
})
export class FacilityFilterPipe implements PipeTransform {
  public transform(data: any[], filter: string): any[] {
    if (!data || !data.length) {
      return data;
    }
    if (!filter) {
      return data;
    }

    const filteredData: any = data.filter((item: any) => {
      return (
        item &&
        item.facilityName &&
        item.facilityName.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      );
    });

    return filteredData;
  }
}
