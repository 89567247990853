export interface DataStreamFilter {
  // TODO: add setting the rest of filter options by expanding interface.
  selectedIntensity?: string;
  //selectedYear: number; // Not sure if I can load most recent year if most recent year isn't current year
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataStreamService {
  setDataStreamFilterSubject = new Subject<DataStreamFilter>(); // reset DataStream filters and set with new one
  lastFilterUsedBehaviorSubject = new BehaviorSubject<DataStreamFilter | void>(
    undefined,
  ); // last filter used

  setFilters(dataStreamFilter: DataStreamFilter) {
    this.setDataStreamFilterSubject.next(dataStreamFilter);
  }

  filterUsed(filterUsed?: DataStreamFilter) {
    if (filterUsed) {
      this.lastFilterUsedBehaviorSubject.next(filterUsed);
    }

    // TODO: Fix this... make sure that if filterUsed is undefined, that something is still passed.
    // this is necessary to that forecast is disabled if any filters are used....
    // come back to this once most of the functionality is built out...
  }
}
