import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCustomerSelection'
})
export class FilterCustomerSelectionPipe implements PipeTransform {
  transform(companies: any[], searchValue: string): unknown {
    return searchValue && companies.length
      ? companies.filter((comp: any) => {
          return (
            comp?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) >= 0
          );
        })
      : companies;
  }

}
