import { Injectable } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObservableService {
  private events: { [key: string]: Subject<any> } = {};

  public subscribe(event: string): Subject<any> {
    if (!this.events[event]) {
      this.events[event] = new Subject<any>();
    }
    return this.events[event];
  }

  public publish(event: string, value: any) {
    if (!this.events[event]) {
      this.events[event] = new Subject<any>();
    }
    this.events[event].next(value);
  }

  public async publishAndAwait(
    publishEvent: string,
    value: any,
    subscribeEvent: string,
  ) {
    const subscribedEvent = this.subscribe(subscribeEvent);
    this.publish(publishEvent, value);
    return await firstValueFrom(subscribedEvent);
  }

  public async awaitEvent(event: string): Promise<any> {
    if (!this.events[event]) {
      this.events[event] = new Subject<any>();
    }
    return firstValueFrom(this.events[event]);
  }
}
