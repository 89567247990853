<div class="flex flex-col">
  <h1 mat-dialog-title>{{ title ? title : 'Error'}}</h1>
</div>
<div mat-dialog-content class="py-1">
  <div *ngFor="let d of locData;" class="dialog-row">
    <div *ngIf="d.code && !d.messageLong" class="p-x-sm p-y">
      {{(d.count) ? d.count + ' times: ': ''}}
      <span>{{d.code}}: </span>
      {{d.message | emission}}
    </div>
    <div *ngIf="(!d.code || d.messageLong) && d.message" class="p-x-sm p-y">
      {{(d.count) ? d.count + ' times: ': ''}}
      <span class="message">{{d.message | emission}}</span>
    </div>
  </div>
  <div class="flex justify-end" *ngIf="hasLongMessage">
    <span class="self-center">Show more information</span>
    <mat-slide-toggle class="pl-2" [(ngModel)]="showLong"></mat-slide-toggle>
  </div>
  <ng-container *ngIf="hasLongMessage && showLong">
    <ng-container *ngFor="let d of locData">
      <div *ngIf="d.messageLong" class="dialog-row">
        <div *ngIf="d.code" class="p-x-sm p-y w-min italic text-sm">
          <span>{{d.code}}: </span>
          {{d.messageLong | emission}}
        </div>
        <div *ngIf="!d.code && d.messageLong" class="p-x-sm p-y italic text-sm">
          <span class="message">{{d.messageLong | emission}}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions class="flex justify-end">
  <button
    *ngIf="contactSupportEnabled"
    mat-flat-button
    class="flex flex-row items-center small-clear-filters rounded-3xl mt-1 h-12 force-animate-refresh"
    (click)="openContactDialog()"
  >
    Contact Support
  </button>
  <button
    mat-flat-button
    mat-dialog-close
    id="closeError"
    class="flex flex-row items-center small-clear-filters rounded-3xl mt-1 h-12 force-animate-refresh"
  >
    {{buttonText}}
  </button>
</div>
