<div
  class="menu-wrapper bg-tertiary-120"
  [ngClass]="{ 'transformed': isScrolled || isHeaderClosed, 'mobile': mobile }"
>
  <div class="border-r border-solid border-accent menu">
    <div class="logo pt-8 pl-8" routerLink="/">
      <img src="./assets/logo-white.svg" />
    </div>
    <!-- <div class="top-icon" routerLink="/">
      <img src="./assets/icon.svg" />
    </div> -->
    <div class="menu-items">
      <div
        class="menu-item-wrapper"
        *ngFor="let navItem of navLinks; let i = index"
      >
        <ng-container *ngIf="navItem.children?.length">
          <div class="nav-title text-tertiary-700">
            {{ navItem.title | uppercase }}
          </div>
          <div class="nav-items">
            <ng-container *ngFor="let link of navItem.children">
              <a
                class="nav-item flex"
                [routerLink]="link.path"
                [ngClass]="{'active': isActive(link)}"
              >
                {{ link.label }}
                <app-new-indicator
                  class="pl-2"
                  *ngIf="link.indicator === 'new'"
                ></app-new-indicator>
                <app-beta-indicator
                  class="pl-2"
                  *ngIf="link.indicator === 'beta'"
                ></app-beta-indicator>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="lower-nav">
      <!-- <div class="w-full">
        <a
          class="text-lg text-white font-medium tracking-tight leading-8 flex items-center m-2 p-2 rounded"
          [routerLink]="['/tutorials']"
          [ngClass]="{'active': isActive({label: 'tutorials'})}"
        >
          <mat-icon class="mr-1.5 sm-icon">menu_book</mat-icon>
          <h6>Tutorial Videos</h6>
        </a>
      </div> -->
      <div class="py-4 px-2 flex justify-around items-center text-white">
        <div>
          <mat-icon class="mr-2 mt-1 cursor-pointer" [matMenuTriggerFor]="menu"
            >person_2</mat-icon
          >
        </div>       
        <div *ngIf="hasChat">
          <mat-icon class="mr-2 mt-1 cursor-pointer" (click)="onChatClick()"
            >chat</mat-icon
          >
        </div>
        <div>
          <mat-icon
            class="mr-2 mt-1 cursor-pointer"
            (click)="openContactDialog()"
            >contact_support</mat-icon
          >
        </div>
        <div *ngIf="isAdmin || internal">
          <mat-icon
            class="mr-2 mt-1 cursor-pointer"
            [matMenuTriggerFor]="settingMenu"
            >settings</mat-icon
          >
        </div>
        <!-- <a [matMenuTriggerFor]="menu" class="text-white">
          <div class="flex items-center">
            <h6 class="text-ellipsis" *ngIf="user">{{user.email}}</h6>
          </div>
          <mat-icon class="upper sm-icon">expand_more</mat-icon>
        </a>  -->
        <mat-menu #settingMenu="matMenu" class="user-menu">
          <button
            mat-menu-item
            [routerLink]="['/internal-admin']"
            *ngIf="internal"
          >
            <mat-icon>admin_panel_settings</mat-icon>
            <span>Internal</span>
          </button>

          <button mat-menu-item [routerLink]="['/admin']" *ngIf="isAdmin">
            <mat-icon>admin_panel_settings</mat-icon>
            <span>Admin</span>
          </button>
        </mat-menu>

        <mat-menu #menu="matMenu" class="user-menu">
          <button mat-menu-item [routerLink]="['/profile']">
            <mat-icon>person_2</mat-icon>
            <span>Profile</span>
          </button>

          <mat-divider></mat-divider>
          <button mat-menu-item (click)="signOut()">
            <mat-icon>login</mat-icon>
            <span>Sign Out</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
