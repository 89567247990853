import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Customer, EmissionsCategory, SubBasin } from '@iconic-air/models';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataService {
  public allZips$: Subscription;
  public customerRecord: Customer;
  public companyRecord: any;
  public companyValues: any = {};
  public additionalCategories: EmissionsCategory[] = [];
  public subBasins: SubBasin[] = [];
  public customerRecordValueChanges: Observable<any>;

  get hasPermissions() {
    let hasPermissions = false;
    Object.keys(this.customerRecord?.permissions || {}).forEach((key) => {
      if (this.customerRecord.permissions[key]) hasPermissions = true;
    });

    return hasPermissions;
  }

  constructor(private _afs: AngularFirestore) {}

  async markFreeConsultation(url: string) {
    await this._afs.collection('customers').doc(this.customerRecord.id).update({
      freeConsultation: url,
    });
    this.customerRecord.freeConsultation = url;
  }

  async markCreditsUsed(url: string, type: string) {
    const creditsUsed = this.customerRecord.creditsUsed || [];
    creditsUsed.push({ url, date: Date.now(), type });
    await this._afs.collection('customers').doc(this.customerRecord.id).update({
      creditsUsed,
    });
    this.customerRecord.creditsUsed = creditsUsed;
  }
}
