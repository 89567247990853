import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lowerCase, startCase } from 'lodash';
import { Subscription } from 'rxjs';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { CustomerDataService } from '../../services/customer-data/customer-data.service';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { PaymentService } from '../../services/stripe/payment.service';
import * as models from '@iconic-air/models';
import { StaticDataService } from '../../services/static-data/static.data.service';
import { SignUpDialogComponent } from '../../components/sign-up-dialog/sign-up-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContentManagementSystemService } from '../../services/content-management-system/content-management-system.service';

// Declare Calendly as an external global object
declare let Calendly: any;

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnDestroy, OnInit {
  expert: models.Author;
  subscriptions: models.Subscription[] = [];
  loading = true;
  loadingCheckout = false;
  checkoutSession?: StripeEmbeddedCheckout;
  #screen: string;
  #screenSubscription: Subscription;
  #listener;
  showCalendly: boolean;
  #subscriptionSettings: {
    disclaimer: string;
  };
  #queryParamsSubscription: Subscription;
  #queryParams: models.BasicObject = {};
  #lastQueryParams: models.BasicObject = {};
  experts: models.Author[] = [];
  showClose: boolean;
  @Input() hideHeader: boolean;
  @Output() hideEverything: EventEmitter<boolean> = new EventEmitter(false);
  get showSubscriptions() {
    return (
      this._customerDataService.customerRecord?.showSubscriptions ||
      !this.loggedIn
    );
  }
  get disclaimer() {
    return this.#subscriptionSettings?.disclaimer;
  }
  get activeSubscriptions() {
    return this.subscriptions?.filter((value) => value.status === 'active');
  }
  get screen() {
    return startCase(lowerCase(this.#screen));
  }
  get hasFreeConsultation() {
    return !this._customerDataService.customerRecord?.freeConsultation;
  }
  get loggedIn() {
    return !!this._perms.activeCustomerId;
  }

  get backgroundImage() {
    return `assets/demo/blurred-${this.#screen}.png`;
  }

  get subscription() {
    return this.activeSubscriptions?.[0];
  }

  get credits() {
    const used = (
      this._customerDataService.customerRecord?.creditsUsed || []
    )?.filter(
      (value) =>
        (this.subscription?.items?.[0]?.interval === 'month' &&
          value.date >=
            this.activeSubscriptions?.[0]?.currentPeriodStart * 1000 &&
          value.date <=
            this.activeSubscriptions?.[0]?.currentPeriodEnd * 1000) ||
        (this.subscription?.items?.[0]?.interval === 'year' &&
          this.#getStartDate() <= value.date &&
          this.#getEndDate() >= value.date),
    )?.length;
    return used > 2 ? 0 : 2 - used;
  }
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _perms: PermissionsWebGuard,
    private _customerDataService: CustomerDataService,
    private _paymentService: PaymentService,
    private _staticDataService: StaticDataService,
    private _dialog: MatDialog,
    private _contentManagementSystemService: ContentManagementSystemService,
    private _cd: ChangeDetectorRef,
  ) {
    // if we don't need to show subscriptions exit out
    if (!this.showSubscriptions) return;
    this.#screenSubscription = this._route.params.subscribe((params) => {
      this.#screen = params.screen;
    });
    this.#queryParamsSubscription = this._route.queryParams?.subscribe(
      (params) => {
        this.#lastQueryParams = { ...this.#queryParams };
        this.#queryParams = params;
        this.#checkExpertQueryParam();
        if (!params.expert) this.#handleButtonLogic();
      },
    );
    this.#listener = window.addEventListener('message', (e) => {
      if (e?.origin?.includes('calendly.com') && e?.data?.payload?.event?.uri) {
        if (this.activeSubscriptions?.length)
          this._customerDataService.markCreditsUsed(
            e?.data?.payload?.event?.uri,
            'meeting',
          );
        else {
          // they setup their free consultation, set it to scheduled
          this._customerDataService.markFreeConsultation(
            e?.data?.payload?.event?.uri,
          );
        }
      }
    });
  }

  #checkExpertQueryParam() {
    if (this.#queryParams?.expert) {
      const expert = this.experts?.find(
        (value) =>
          value.name?.toLowerCase() ===
          this.#queryParams?.expert?.toLowerCase(),
      );
      if (expert) {
        this.expert = expert;
        this._cd.detectChanges();
        this.#handleButtonLogic();
      }
    }
  }

  #recursiveClick(id: string, count = 0) {
    if (count > 5) return;
    count += 1;
    const element = document.getElementById(id);
    if (element) return element.click();
    setTimeout(() => {
      this.#recursiveClick(id, count);
    }, 100);
  }

  #getStartDate() {
    // the current period start date
    const now = new Date();
    let startDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      new Date(this.subscription?.currentPeriodStart * 1000).getDate(),
    );

    if (startDate > now) {
      startDate.setMonth(startDate.getMonth() - 1); // Subtract one month
    }

    return Number(startDate);
  }

  #getEndDate() {
    // the current period end date
    const now = new Date();
    let endDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      new Date(this.subscription?.currentPeriodEnd * 1000).getDate(),
    );

    if (endDate < now) {
      endDate.setMonth(endDate.getMonth() + 1); // Add one month
    }

    return Number(endDate);
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }

  changeExpert() {
    this._router.navigate(['./'], {
      queryParams: {
        ...this.#queryParams,
        expert: this.expert?.name,
      },
      relativeTo: this._route,
    });
  }

  async ngOnInit() {
    this.loading = true;
    try {
      if (!this.showSubscriptions) return;
      this.#subscriptionSettings = await this._staticDataService.getData(
        'subscriptionSettings',
      );
      this.experts = (
        await this._contentManagementSystemService.getExperts()
      )?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      this.subscriptions = await this._paymentService.checkSubscriptions();
      this.#checkExpertQueryParam();
    } finally {
      this.loading = false;
    }
  }

  async #handleButtonLogic() {
    const fieldsToCheck = [
      'freeConsultation',
      'consultation',
      'signUp',
      'subscribe',
    ];
    let clickButton = false,
      step;
    for (const field of fieldsToCheck) {
      if (!this.#lastQueryParams[field] && this.#queryParams[field]) {
        clickButton = true;
        step = field;
      }
    }
    if (!clickButton) return;
    const button = this.#currentButton();
    if (button !== step)
      throw new Error(
        'You currently need to ' + button + ', ' + step + ' is not available.',
      );
    if (!this.loggedIn) {
      this._dialog.open(SignUpDialogComponent, {
        data: {
          screen: 'signup',
          signInUser: true,
          redirect: '/landing' + window.location.href?.split('/landing')?.[1],
          reload: true,
          reason:
            'schedule a free consultation with ' + this.expert?.name ||
            'an expert',
        },
      });
      return;
    }
    this.hideEverything.emit(true);
    this.showClose = true;
    if (
      (this.hasFreeConsultation && !this.activeSubscriptions?.length) ||
      this.activeSubscriptions?.length
    )
      this.openCalendly();
    else this.openCheckoutSession();
  }

  #currentButton() {
    if (this.hasFreeConsultation && !this.activeSubscriptions?.length)
      return 'freeConsultation';
    if (!this.hasFreeConsultation && !this.activeSubscriptions?.length)
      return 'subscribe';
    return 'consultation';
  }

  async buttonClicked() {
    const queryParams: {
      freeConsultation?: boolean;
      consultation?: boolean;
      signUp?: boolean;
      subscribe?: boolean;
    } = {
      ...this.#queryParams,
    };
    const button = this.#currentButton();
    queryParams[button] = true;
    this._router.navigate(['./'], { queryParams, relativeTo: this._route });
  }

  async openCheckoutSession() {
    this.loadingCheckout = true;
    try {
      this.checkoutSession = await this._paymentService.createCheckoutSession(
        window.location.href,
      );
      if (!this.showClose) {
        this.checkoutSession = undefined;
        return;
      }
      this.checkoutSession.mount('#checkout');
    } finally {
      this.loadingCheckout = false;
    }
  }

  ngOnDestroy() {
    this.#screenSubscription?.unsubscribe();
    this.#listener?.remove();
    this.checkoutSession?.destroy();
    this.checkoutSession = undefined;
    this.#queryParamsSubscription?.unsubscribe();
  }

  close() {
    const params = {};
    if (this.#queryParams?.expert) params['expert'] = this.#queryParams?.expert;
    this.showCalendly = false;
    this.checkoutSession?.destroy();
    this.checkoutSession = undefined;
    this.showClose = false;
    this.hideEverything.emit(false);
    this._router.navigate(['./'], {
      queryParams: params,
      relativeTo: this._route,
    });
  }

  async #recursiveFindElement(id: string) {
    const element = document.getElementById(id);
    if (element) return element;
    await new Promise((resolve) => setTimeout(resolve, 10));
    return this.#recursiveFindElement(id);
  }

  async openCalendly() {
    if (!this.expert) {
      this.showClose = false;
      this.hideEverything.emit(false);
      const button = this.#currentButton();
      const queryParams = { ...this.#queryParams };
      delete queryParams[button];
      this._router.navigate(['./'], { queryParams, relativeTo: this._route });
      throw new Error('No expert selected');
    }
    this.showCalendly = true;
    const parentElement = await this.#recursiveFindElement('calendly-embed');
    let url = this.expert?.freeConsultationUrl;
    if (this.activeSubscriptions?.length)
      url = this.expert?.paidConsultationUrl;
    // Initialize the Calendly inline embed after the view is initialized
    Calendly.initInlineWidget({
      url, // Replace with your Calendly URL
      parentElement,
      prefill: {
        email: this._perms.email,
        name: this._perms.userData?.displayName,
      },
      utm: {},
    });
  }
}
