import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/compat/app';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';

@Component({
  selector: 'app-sms-auth-dialog',
  templateUrl: './sms-auth-dialog.component.html',
  styleUrls: ['./sms-auth-dialog.component.scss'],
})
export class SmsAuthDialogComponent {
  private afUser: firebase.User;
  private verificationId: string;
  private dbFormatPhoneNumber = ''; // required format +14445556666
  phoneNumber = '';
  displayName = '';
  showSMSInput = false;
  verificationCode: string;
  loading = false;
  recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  constructor(
    public dialogRef: MatDialogRef<SmsAuthDialogComponent>,
    private auth: AuthenticationWebService,
  ) {
    this.auth.getUser().subscribe((data: any) => {
      this.afUser = data;
    });
  }

  formatPhoneNumber() {
    const phoneNumberString = this.phoneNumber;
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      // prepare data to send to database
      this.dbFormatPhoneNumber = ['+1', match[1], match[2], match[3]].join('');
      // format phone number for UI
      this.phoneNumber = ['(', match[1], ') ', match[2], '-', match[3]].join(
        '',
      );
    } else {
      // no match, clean up data
      this.phoneNumber = cleaned;
    }
  }

  //only valid phone numbers will be added
  onlyPhoneNumbers(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = event.key;
    const cleanPhoneNumber = ('' + this.phoneNumber).replace(/\D/g, '');

    if (event.key != 'Backspace' && !pattern.test(inputChar)) {
      event.preventDefault();
    } else if (cleanPhoneNumber.length >= 10) {
      event.preventDefault();
    }
  }

  public registerPhoneNumber() {
    const recaptchaContainer = 'sms-recaptcha-container';
    const recaptchaOptions = { size: 'invisible' };

    this.afUser.multiFactor
      .getSession()
      .then((multiFactorSession) => {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: this.dbFormatPhoneNumber,
          session: multiFactorSession,
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        if (this.recaptchaVerifier?.type === 'recaptcha') {
          this.recaptchaVerifier.clear();
        } else {
          this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            recaptchaContainer,
            recaptchaOptions,
          );
        }

        // Send SMS verification code.
        return phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
          .catch((error) => {
            if (error.code === 'auth/invalid-phone-number') {
              this.dialogRef.close();
              throw 'A valid phone number is required. Please try again.';
            } else {
              throw error;
            }
          });
      })
      .then((verificationId) => {
        this.verificationId = verificationId;
        this.showSMSInput = true;
      });
  }

  sendVerificationCode() {
    // Ask user for the verification code.
    const cred = firebase.auth.PhoneAuthProvider.credential(
      this.verificationId,
      this.verificationCode,
    );
    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    this.afUser.multiFactor
      .enroll(multiFactorAssertion, this.displayName)
      .catch((error) => {
        if (error.code === 'auth/invalid-verification-code') {
          this.dialogRef.close();
          throw 'A valid verification code is required. Please try again.';
        } else {
          throw error;
        }
      })
      .then(() => {
        this.dialogRef.close();
      });
  }
}
