import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { SheetValidationError, Upload } from '@iconic-air/models';
import { DownloadFileService } from '../../services/download-file/download-file.service';
import { lowerCase, startCase } from 'lodash';
import { DataUploadService } from '../../services/data-upload/data-upload.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements OnInit {
  #docData: Upload;
  disableSave: boolean;
  get docData() {
    return this.#docData;
  }

  get docDataStringified() {
    return JSON.stringify(this.docData, null, 2);
  }
  set docData(value) {
    if (value?.error) {
      this.errors = JSON.parse(JSON.stringify(value?.error));
      try {
        this.errors = JSON.parse(this.errors);
      } catch (e) {}
      if (
        value?.error?.toString()?.includes('sheetName') ||
        value?.error?.toString()?.includes('sheet')
      ) {
        const errors: SheetValidationError[] = [];
        if (Array.isArray(this.errors)) {
          this.errors?.forEach((error) => {
            try {
              error = JSON.parse(error);
              errors.push(error);
            } catch (e) {
              Object.keys(error).forEach((key) => {
                errors.push(error[key]);
              });
            }
          });
        } else {
          let returnOut = false;
          Object.keys(this.errors).forEach((key, index) => {
            if (returnOut) return;
            if (key?.toString() === index?.toString())
              errors.push(this.errors[key]);
            else {
              returnOut = true;
              errors.push(this.errors);
            }
          });
        }
        this.errors = errors;
      } else if (Array.isArray(this.errors)) {
        let returnValue = '';
        this.errors?.map((error) => {
          if (error?.message) returnValue += error?.message;
          else if (error?.error) returnValue += error?.error;
          else returnValue += JSON.stringify(error);
        });
        this.errors = returnValue;
      }
    }
    this.#docData = value;

    this.filtersFullList = Array.from(
      new Set(
        Object.keys(this.docData?.data?.savedValues || {}).concat(
          Object.keys(this.docData?.data?.nonSavedValues || {}),
        ),
      ),
    )?.map((key) => {
      return {
        display: startCase(lowerCase(key)),
        value: key,
      };
    });
    const dataFields: string[] = [];
    Object.keys(this.docData?.data?.savedValues || {}).forEach((key) => {
      const fields = Object.keys(
        this.docData?.data?.savedValues[key] || {},
      )?.filter((field) => field !== 'records');
      dataFields.push(...fields);
    });
    Object.keys(this.docData?.data?.nonSavedValues || {}).forEach((key) => {
      const fields = Object.keys(
        this.docData?.data?.nonSavedValues[key] || {},
      )?.filter((field) => field !== 'records');
      dataFields.push(...fields);
    });
    this.dataFields = Array.from(new Set(dataFields)).sort() || [];
    this.dataFieldLabels = {};
    this.dataFields.forEach((field) => {
      this.dataFieldLabels[field] = `${field} ${
        ['co2', 'ch4', 'n2o'].includes(field?.toLowerCase()) ? '(tonnes)' : ''
      }`;
    });
    this.filters = this.filtersFullList?.map((option) => {
      return option.value;
    });
    this.errorIsArray = Array.isArray(this.errors);
    console.log('errors', this.errors);
    console.log('errorIsArray', this.errorIsArray);
  }
  errorIsArray = false;
  errors;
  #docSubscription$: Subscription;
  filters: string[] = [];
  filtersFullList: { value: string; display: string }[] = [];
  onlyAddModified = false;
  get unfiltedTotals() {
    return this.#getTotals(false);
  }
  get totals() {
    return this.#getTotals(true);
  }

  get totalKeys() {
    return Object.keys(this.totals);
  }

  dataFields: string[] = [];
  dataFieldLabels: { [key: string]: string } = {};
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Upload,
    private _dialogRef: MatDialogRef<UploadDialogComponent>,
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
    private _downloadFile: DownloadFileService,
    private _dataUploadService: DataUploadService,
  ) {
    this.docData = data;
    if (Object.keys(data || {}).length > 1) this.loading = false;
  }

  download() {
    this._downloadFile.downloadFileWithCustomName(
      this.docData.fileUrl,
      this.docData.fileName,
    );
  }

  #getTotals(applyFilters: boolean) {
    const returnValue = {};
    Object.keys(this.#docData.data?.savedValues).forEach((key) => {
      if (!this.filters.includes(key) && applyFilters) return;
      Object.keys(this.#docData.data?.savedValues[key]).forEach((key2) => {
        if (!returnValue[key2]) returnValue[key2] = 0;
        returnValue[key2] += this.#docData.data?.savedValues[key][key2];
      });
    });
    if (!this.onlyAddModified && applyFilters) {
      Object.keys(this.#docData.data?.nonSavedValues).forEach((key) => {
        if (!this.filters.includes(key)) return;
        Object.keys(this.#docData.data?.nonSavedValues[key]).forEach((key2) => {
          if (!returnValue[key2]) returnValue[key2] = 0;
          returnValue[key2] += this.#docData.data?.nonSavedValues[key][key2];
        });
      });
    }
    return returnValue;
  }

  toggleFilters() {
    if (this.filters.length > 0) this.filters = [];
    else
      this.filters = this.filtersFullList?.map((option) => {
        return option.value;
      });
  }

  async save() {
    this.disableSave = true;
    setTimeout(() => {
      this.disableSave = false;
    }, 15000);
    await this._dataUploadService.saveFile(
      this.docData.id,
      this.docData.fileType,
    );
  }

  closeModal() {
    this._dialogRef.close();
  }

  ngOnDestroy() {
    this.#docSubscription$?.unsubscribe();
  }

  ngOnInit() {
    this.#docSubscription$ = this._afs
      .collection('users')
      .doc(this._perms.userData.uid)
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('uploads')
      .doc(this.data.id)
      .valueChanges()
      .subscribe((data: any) => {
        this.loading = false;
        this.docData = data;
      });
  }
}
