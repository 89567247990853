import * as XLSX from 'xlsx';
import { SheetValidationError } from '@iconic-air/models';

function getTemplateValidationErrors(
  wb: XLSX.WorkBook,
  sheetName: string,
  sheetIndex: number,
  columns: string[],
  requiredColumns: string[],
): SheetValidationError[] | null {
  const columnsToLoop = Object.keys(wb.Sheets[sheetName]).filter(
    (value) =>
      value
        .substring(0, value.length - 1)
        .replace(new RegExp('[0-9]', 'g'), '') +
        '1' ===
      value,
  );
  const requiredColumnsFound: string[] = [];

  const newErrors: SheetValidationError[] = [];
  columnsToLoop.forEach((currentColumn) => {
    const currentValueCell = currentColumn;
    const currentValue = wb.Sheets[sheetName][currentValueCell]?.w;
    if (
      columns.indexOf(currentValue) === -1 &&
      currentValue?.toString()?.toLowerCase() !== 'legend'
    ) {
      const newError: SheetValidationError = {
        sheetName,
        sheetIndex,
        labelCell: '',
        label: '',
        currentValueCell,
        currentValue,
        expectedValue:
          'This is not a supported column, please use the latest Iconic Air Template. To download a new copy, close this and download it above the upload file button.',
      };
      newErrors.push(newError);
    } else if (
      requiredColumns.indexOf(currentValue) > -1 &&
      requiredColumnsFound.indexOf(currentValue) === -1
    ) {
      requiredColumnsFound.push(currentValue);
    }
  });

  if (requiredColumns.length !== requiredColumnsFound.length) {
    requiredColumns.forEach((column) => {
      if (requiredColumnsFound.indexOf(column) === -1) {
        newErrors.push({
          sheetName,
          sheetIndex,
          labelCell: '',
          label: '',
          currentValueCell: '',
          currentValue: '',
          expectedValue: `${column} is required and was not found, please use the latest Iconic Air Template. To download a new copy, close this and download it above the upload file button.`,
        });
      }
    });
  }
  if (newErrors && newErrors?.length > 0) {
    return newErrors;
  } else return null;
}

export function validateTemplate(
  wb: XLSX.WorkBook,
  columns: string[],
  requiredColumns: string[],
): null | SheetValidationError[] {
  let validationErrors: SheetValidationError[] = [];
  const maxSheetsProcessed = 1; // only one sheet in ldar ingest template

  Object.keys(wb.Sheets).forEach((sheet, sheetIndex) => {
    if (wb.SheetNames[sheetIndex]?.includes('-ignore')) return;
    if (sheetIndex < maxSheetsProcessed) {
      const newValidationErrors = getTemplateValidationErrors(
        wb,
        sheet,
        sheetIndex,
        columns,
        requiredColumns,
      );
      if (newValidationErrors !== null) {
        validationErrors = [...validationErrors, ...newValidationErrors];
      }
    } else {
      validationErrors.push({
        sheetName: '',
        sheetIndex,
        labelCell: '',
        label: '',
        currentValueCell: '',
        currentValue: '',
        expectedValue: `You cannot have more then one sheet`,
      });
    }
  });

  return validationErrors.length === 0 ? null : validationErrors;
}
