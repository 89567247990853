import { Injectable } from '@angular/core';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { Facility, GasSample, LocationSurveyConfig } from '@iconic-air/models';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StaticDataService } from '../../services/static-data/static.data.service';
import { DateService } from '../../services/date/date.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesDatabaseService {
  get programTypeConfigs() {
    return this._staticDataService.programTypeConfigs;
  }
  #facilityChanges: { [id: string]: BehaviorSubject<Facility> } = {};
  constructor(
    private _perms: PermissionsWebGuard,
    private _http: HttpClient,
    private _staticDataService: StaticDataService,
    private _dateService: DateService,
    private _afs: AngularFirestore,
  ) {}

  async deactivateFacility(id: string) {
    const facility = await this.editFacilityFields(id, {
      active: false,
      inactiveDate: this._dateService.momentToUtc(new Date()),
    });
    return facility;
  }

  async activateFacility(id: string) {
    const result = await this.editFacilityFields(id, {
      active: true,
      activeDate: Date.now(),
    });
    return result;
  }

  getFacilitiesByReportingFacility(reportingFacilityId) {
    return this._http.get('/api/facilities', {
      params: {
        reportingFacilityId,
      },
    }) as Observable<Facility[]>;
  }

  getFacilities(
    isLdar?: boolean,
    includeInactive?: boolean,
    includeFs?: boolean,
    includeFsMap?: boolean,
  ): Observable<{ data: Facility[] }> {
    let route = '/api/facilities';
    if (isLdar) {
      route += route.indexOf('?') === -1 ? '?' : '';
      route += 'ldar=true';
    }

    if (includeInactive) {
      route += route.indexOf('?') === -1 ? '?' : '&';
      route += 'includeInactive=true';
    }

    if (includeFs) {
      route += route.indexOf('?') === -1 ? '?' : '&';
      route += 'includeFs=true';
    }

    if (includeFsMap) {
      route += route.indexOf('?') === -1 ? '?' : '&';
      route += 'includeFsMap=true';
    }
    return this._http.get(route) as Observable<{ data: Facility[] }>;
  }

  async getFacilityColumnOptions() {
    return (await lastValueFrom(
      this._http.get('/api/facilityColumnOptions'),
    )) as string[];
  }

  getFacilitiesByKey(
    keys: { includes: string[]; excludes?: string[] },
    isLdar?: boolean,
    includeInactive?: boolean,
    includeFs?: boolean,
    includeFsMap?: boolean,
  ): Observable<any> {
    let route = '/api/facilities';
    if (isLdar) {
      route += route.indexOf('?') === -1 ? '?' : '';
      route += 'ldar=true';
    }

    if (includeInactive) {
      route += route.indexOf('?') === -1 ? '?' : '&';
      route += 'includeInactive=true';
    }

    if (includeFs) {
      route += route.indexOf('?') === -1 ? '?' : '&';
      route += 'includeFs=true';
    }

    if (includeFsMap) {
      route += route.indexOf('?') === -1 ? '?' : '&';
      route += 'includeFsMap=true';
    }
    return this._http.post(route, keys);
  }

  async getFacility(id: string) {
    const facility = (await lastValueFrom(
      this._http.get('/api/facility/' + id + '?includeFs=true'),
    )) as Facility;
    if (this.#facilityChanges[id]) this.#facilityChanges[id].next(facility);
    else this.#facilityChanges[id] = new BehaviorSubject(facility);
    return this.#facilityChanges[id];
  }

  getFirestoreFacility(id: string) {
    return this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('facilities')
      .doc(id)
      .valueChanges();
  }

  getFacilitiesForBasin(selectedBasin): Observable<Facility[]> {
    return this._http.get<Facility[]>(
      '/api/basins/' + selectedBasin + '/facilities?ldar=true',
    );
  }

  getFacilityObservable(id: string) {
    return this._http.get('/api/facility/' + id) as Observable<Facility>;
  }

  // gets all facilites that exist in a customer instance for that company.
  // convert to valueChanges but ensure you update create facility
  getCompanyFacilities() {
    return this._http.get('/api/facilities') as Observable<{
      data: Facility[];
    }>;
  }

  async editFacility(
    model,
    existing: boolean,
    existingFacilityInfo?: Facility,
    bulkUpload?: boolean,
  ) {
    const facility: Facility = {
      facilityName: model.facilityName,
      facilityType: model.facilityType?.toLowerCase(),
      reportingFacilityId: model.reportingFacilityId,
      addressStreet: model.addressStreet,
      addressCity: model.addressCity,
      addressState: model.addressState,
      addressZip: model.addressZip,
      isLdar: model.isLdar,
      id: model.id,
      lastUpdatedUser: this._perms.userData.uid,
      updatedAt: this._dateService.momentToUtc(new Date()),
      locationSurveyConfig: model.locationSurveyConfig,
      locationSurveyConfigUpdatedAt: this._dateService.momentToUtc(new Date()),
      active:
        model.active === true || model.active === false ? model.active : true,
    };
    if (existing && existingFacilityInfo) {
      facility.createdAt = existingFacilityInfo.createdAt;
    } else {
      facility.createdAt = this._dateService.momentToUtc(new Date());
    }

    const optionalKeys = [
      'county',
      'pointOfContact',
      'pointOfContactEmail',
      'pointOfContactPhone',
      'comments',
      'longitude',
      'latitude',
      'permitType',
      'programTypes',
      'businessArea',
      'softwareId',
      'locationSubType',
      'surveyType',
      'formationType',
      'ownershipPercentage',
      'previousOwner',
      'facilityOwner',
      'dateAcquired',
      'accountingId',
      'ldarId',
    ];
    optionalKeys.forEach((key) => {
      if (key === 'businessArea') {
        facility[key] = model[key]?.toString() || '';
      } else {
        if (model[key]) facility[key] = model[key];
      }
    });

    const optionalDateKeys = this.getOptionalDateKeys();

    optionalDateKeys.forEach((key) => {
      if (model[key]) facility[key] = this._dateService.momentToUtc(model[key]);
    });
    if (facility.isLdar) {
      if (model.avoProgramTypes || model.ogiProgramTypes) {
        let config: LocationSurveyConfig = {};

        if (existing && existingFacilityInfo?.locationSurveyConfig)
          config = JSON.parse(
            JSON.stringify(existingFacilityInfo?.locationSurveyConfig),
          );

        ['avo', 'ogi']?.forEach((surveyType) => {
          if (facility?.surveyType?.includes(surveyType)) {
            if (!existingFacilityInfo?.locationSurveyConfig?.[surveyType]) {
              const blankValue = {};
              blankValue[surveyType] = {
                frequency: '',
                overrideFrequency: false,
                programTypes: [],
              };
              Object.assign(config, blankValue);
            }

            config[surveyType].programTypes =
              model?.[`${surveyType}ProgramTypes`].map((type) =>
                type?.toLowerCase(),
              ) || [];
            if (
              model?.[
                `override${surveyType
                  .substring(0, 1)
                  .toUpperCase()}${surveyType.substring(1)}Frequency`
              ]
            ) {
              config[surveyType].overrideFrequency = true;
              config[surveyType].frequency =
                model[
                  `override${surveyType
                    .substring(0, 1)
                    .toUpperCase()}${surveyType.substring(1)}Frequency`
                ];
            }
          }
        });

        facility.locationSurveyConfig = config;
      }

      if (model.permitType) {
        facility.permitType = model.permitType;
      }
    }

    if (bulkUpload) {
      return facility;
    } else {
      await lastValueFrom(
        this._http.put('/api/facilities', {
          data: [facility],
        }),
      );

      if (this.#facilityChanges[model.facilityId])
        this.#facilityChanges[model.facilityId].next(facility);
    }

    return { companyId: this._perms.activeCustomerId };
  }

  async editFacilityFields(facilityId: string, model) {
    const facility = {
      id: facilityId,
      ...model,
    };
    await lastValueFrom(
      this._http.put('/api/facilities', {
        data: [facility],
      }),
    );
    if (this.#facilityChanges[facilityId])
      this.#facilityChanges[facilityId].next(facility as unknown as Facility);
    return facility;
  }

  async editFacilityContacts(facility) {
    await lastValueFrom(
      this._http.put('/api/facilities', {
        data: [facility],
      }),
    );
    if (this.#facilityChanges[facility.id]) {
      const value = {
        ...this.#facilityChanges[facility.id].value,
        ...facility,
      };
      this.#facilityChanges[facility.id].next(value as unknown as Facility);
    }
  }

  public saveUpdateGasSample(
    rfid: string,
    facilityId: string,
    gasSample: GasSample,
  ) {
    return this._http.post(
      `/api/reporting-facilities/${rfid}/facilities/${facilityId}/gas-samples`,
      {
        ...gasSample,
      },
    );
  }

  getFacilitiesForCustomer(
    customerId: string,
    includes?: string[],
    excludes?: string[],
  ) {
    return this._http.post('/api/facilities-by-customer', {
      customerId,
      includes,
      excludes,
    }) as Observable<Facility[]>;
  }

  getFacilitiesPairedDown(isLdar: boolean, includes?: string[]) {
    const hasIncludes = includes !== undefined;
    if (!hasIncludes)
      includes = [
        'id',
        'facilityName',
        'facilityType',
        'displayName',
        'addressState',
        'addressCity',
        'addressZip',
        'addressStreet',
        'basin',
        'reportingFacilityName',
        'reportingFacilityId',
        'reportingFacilityIndustrySegmentLongName',
      ];
    if (isLdar) {
      if (!hasIncludes && includes)
        includes = includes.concat([
          'nextSurveyWindowStart',
          'nextSurveyWindowEnd',
          'outstandingActionItems',
          'nextActionItemDate',
          'outstandingActionItems',
          'lastSurveyDate',
          'numberOfDors',
          'locationSurveyConfig',
          'locationSurveyWindows',
        ]);
    }
    return this.getFacilitiesByKey(
      { includes: includes as string[] },
      isLdar,
      undefined,
      isLdar,
    ) as Observable<Facility[]>;
  }

  getOptionalDateKeys() {
    return ['dateOfSale', 'activeDate', 'inactiveDate', 'dateAcquired'];
  }
}
