import { HostListener, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  private resizeSubject = new Subject<void>();

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizeSubject.next();
  }

  getResizeEvent() {
    return this.resizeSubject.asObservable();
  }

  getCurrentScreenSize() {
    return window.innerWidth;
  }
}
