import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoComponent } from './demo.component';
import { MaterialModule } from '../../theme/material.module';
import { TooltipIconModule } from '../../components/tooltip-icon/tooltip-icon.module';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { SignUpDialogModule } from '../../components/sign-up-dialog/sign-up-dialog.module';

@NgModule({
  declarations: [DemoComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TooltipIconModule,
    FormsModule,
    SignUpDialogModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          breaks: true,
        },
      },
    }),
  ],
  exports: [DemoComponent],
})
export class DemoModule {}
