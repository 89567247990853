import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { CustomTooltipComponent } from '../components/custom-tooltip/custom-tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[iconicAirMonorepoTooltip]',
})
export class TooltipRendererDirective {
  /**
   * This will be used to show tooltip or not
   * This can be used to show the tooltip conditionally
   */
  @Input() showToolTip = true;

  //If this is specified then specified text will be showin in the tooltip
  @Input(`iconicAirMonorepoTooltip`) text!: string;

  //If this is specified then specified template will be rendered in the tooltip
  @Input() contentTemplate!: TemplateRef<any>;
  @Input() data!: any;
  @Input() index!: number;
  @Input() originX!: 'center' | 'end' | 'start';
  @Input() originY!: 'bottom' | 'center' | 'top';
  @Input() overlayX!: 'center' | 'end' | 'start';
  @Input() overlayY!: 'bottom' | 'center' | 'top';
  @Input() offsetY = 0;
  private tooltipRef!: ComponentRef<CustomTooltipComponent>;
  private _overlayRef!: OverlayRef;
  private toolSub$!: Subscription;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
  ) {}

  /**
   * Init life cycle event handler
   */
  ngOnInit() {
    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        {
          originX: this.originX ? this.originX : 'end',
          originY: this.originY ? this.originY : 'top',
          overlayX: this.overlayX ? this.overlayX : 'start',
          overlayY: this.overlayY ? this.overlayY : 'top',
          offsetY: this.offsetY ? this.offsetY : 5,
        },
      ]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  /**
   * This method will be called whenever mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the tooltip by instantiating the McToolTipComponent and attaching to the overlay
   */
  @HostListener('mouseenter')
  show() {
    //attach the component if it has not already attached to the overlay
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this.tooltipRef = this._overlayRef.attach(
        new ComponentPortal(CustomTooltipComponent),
      );
      this.tooltipRef.instance.text = this.text;
      this.tooltipRef.instance.contentTemplate = this.contentTemplate;
      this.tooltipRef.instance.data = this.data;
      this.tooltipRef.instance.index = this.index;

      this.toolSub$ =
        this.tooltipRef.instance.mouseHasLeftTheBuilding.subscribe(
          (hasLeft?: boolean) => {
            if (hasLeft) {
              this.closeToolTip();
            }
          },
        );
    }
  }

  /**
   * This method will be called when mouse goes out of the host element
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener('mouseleave')
  hide() {
    setTimeout(() => {
      if (!this.tooltipRef.instance.hover) {
        this.closeToolTip();
      }
    }, 1);
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   * It will be needed in case when app is navigating to different page
   * and user is still seeing the tooltip; In that case we do not want to hang around the
   * tooltip after the page [on which tooltip visible] is destroyed
   */
  ngOnDestroy() {
    this.closeToolTip();
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this.toolSub$?.unsubscribe();
    }
  }
}
