import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { TooltipRendererDirective } from './directives/tooltip-renderer.directive';
import { CheckboxColumnComponent } from './components/checkbox-column/checkbox-column.component';
import { MatTableResponsiveDirective } from './directives/mat-responsive-table.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CustomTooltipComponent,
    TooltipRendererDirective,
    CheckboxColumnComponent,
    MatTableResponsiveDirective
  ],
  exports: [
    CustomTooltipComponent,
    TooltipRendererDirective,
    CheckboxColumnComponent,
    MatTableResponsiveDirective
  ]
})
export class UiModule {}
