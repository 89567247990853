<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{data.title ? data.title : 'Are you sure?'}}</h1>
  <div mat-dialog-content>
    <p>{{data.message}}</p>
  </div>
  <div mat-dialog-actions class="b-t dialog-actions">
    <button mat-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon> Cancel
    </button>
    <button mat-button (click)="deleteAndClose()">
      <ng-container *ngIf="data.confirmIcon; else noButtonIcon">
        <mat-icon>{{data.confirmIcon}}</mat-icon>
      </ng-container>
      <ng-template #noButtonIcon>
        <mat-icon>delete</mat-icon>
      </ng-template>
      <ng-container *ngIf="data.confirmText; else noButtonText">
        {{data.confirmText}}
      </ng-container>
      <ng-template #noButtonText>
        Yes, {{data.confirmMessage ? data.confirmMessage : 'delete'}}
      </ng-template>
    </button>
  </div>
</div>
