import { Router } from '@angular/router';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartType, ChartOptions, ChartData, ChartTooltipItem } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';

import { BenchmarkService } from '../../pages/analytics/benchmark/benchmark.service';
import { StaticDataService } from '../../services/static-data/static.data.service';

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.scss'],
})
export class ChartPieComponent implements OnInit, OnChanges {
  @Input() centerLabel: string;
  @Input() showTotal: string;
  @Input() extraClasses: string[] = [];
  @Input() doughnutChartLabels: Label[] = [];
  @Input() doughnutChartData: MultiDataSet = [];
  @Input() doughnutChartColors = [];
  @Input() selectedBasins: any[] = [];
  @Input() selectedSegments: any[] = [];
  @Input() selectedHighLevels: any[] = [];
  @Input() selectedCompanies: any[] = [];
  @Input() selectedYear;
  @Input() selectedIntensity;
  @Input() filteredAttribute = '';
  @Input() canClickDetails = false;
  @Input() plugins: any[];
  @Input() benchmark = false;
  @Input() showTooltip = true;
  @Input() replaceToolTipSuffix = '';
  @Output() clickDetails: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('chart', { static: true })
  public _chart;

  public activePieCategory;
  public originalLabels = [];
  public originalColors = [];
  public showPieMore = false;
  public availableBySegments = ['emissions source'];
  public selectedBySegment = this.availableBySegments[0];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartPlugins: any = [];
  public total = 0;

  // when upgrading to chart.js 3 - this has changed to cutout: 40
  public ng2options: ChartOptions = {
    cutoutPercentage: 88,
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
      labels: {
        fontColor: '#777FA6',
      },
    },
    tooltips: {
      enabled: false,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      bodyFontColor: 'rgba(0, 0, 0, 1)',
      borderColor: 'rgba(0, 0, 0, 1)',
      borderWidth: 1,
      titleFontSize: 13,
      bodyFontSize: 13,
      titleFontColor: 'rgba(0, 0, 0, 1)',
      callbacks: {
        label: (item: ChartTooltipItem, data: ChartData) => {
          if (data.datasets?.length && data.datasets[0].data) {
            return (data.datasets[0]?.data[item.index as number] as number)
              .toFixed(3)
              .indexOf('0.00') >= 0
              ? (
                  data.datasets[0].data[item.index as number] as number
                ).toString()
              : this.getCommaNumber(
                  data.datasets[0].data[item.index as number] as number,
                );
          }

          return '';
        },
        title: (tooltipItem: ChartTooltipItem[], data: ChartData) => {
          if (data?.labels) {
            return data?.labels[tooltipItem[0]?.index as number] as string;
          }
          return '';
        },
      },
      custom: (tooltipModel) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        if (!this.showTooltip) {
          return;
        }
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        let tooltipLabel = '';
        // chart is emissions and is now checking for if it's set to CO2e or any other emissions category.
        if (this.selectedIntensity === 'CO2e (AR5)') {
          tooltipLabel = 'mT CO2e';
        } else {
          tooltipLabel = 'mT';
        }
        if (this.replaceToolTipSuffix !== '') {
          tooltipLabel = this.replaceToolTipSuffix;
        }

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.classList.add('bg-white');
          tooltipEl.classList.add('border');
          tooltipEl.classList.add('border-solid');
          tooltipEl.classList.add('border-black');
          tooltipEl.classList.add('p-2');
          tooltipEl.classList.add('md:p-3');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table class="w-full"></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '<thead>';

          titleLines.forEach((tt) => {
            innerHtml +=
              '<tr><th class="text-left"><b>' + tt + '</ b></th></tr>';
          });
          innerHtml += '</thead><tbody>';

          let tooltipLabel = '';
          // chart is emissions and is now checking for if it's set to CO2e or any other emissions category.
          if (vm.selectedIntensity === 'CO2e (AR5)') {
            tooltipLabel = 'mT CO2e';
          } else {
            tooltipLabel = 'mT';
          }
          if (this.replaceToolTipSuffix !== '') {
            tooltipLabel = this.replaceToolTipSuffix;
          }

          bodyLines.forEach((body, i) => {
            const colors = tooltipModel.labelColors[i];
            const style = 'color: '; // background:' + colors.backgroundColor;
            // style += '; border-color:' + colors.borderColor;
            // style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml +=
              '<tr><td>' + span + body + ' ' + tooltipLabel + '</td></tr>';
          });

          const titleStrings: string[] = [];
          titleLines.forEach((seg) => {
            titleStrings.push(seg);
          });

          const selectedTitleIds: string[] = [];

          titleStrings.forEach((title) => {
            if (this.filteredAttribute === 'segments') {
              const segment = vm.industrySegments.find(
                (ss) => ss.longName == title,
              );
              if (segment) {
                selectedTitleIds.push(segment.id);
              }
            }
            if (this.filteredAttribute === 'basins') {
              const basin = vm.basins.find(
                (bb) =>
                  bb.basinFullName.replace(/ *\([^)]*\) */g, '') ==
                  title.replace(/ *\([^)]*\) */g, ''),
              );
              if (basin) {
                selectedTitleIds.push(basin.id);
              }
            }
          });

          if (this.benchmark) {
            const feed = vm.benchmarkService.dataFaster(
              vm.selectedIntensity,
              [vm.selectedYear],
              vm.selectedYear,
              vm.basins,
              this.filteredAttribute === 'basins'
                ? selectedTitleIds
                : vm.selectedBasins,
              false,
              vm.selectedCompanies,
              vm.selectedCompanies,
              false,
              vm.industrySegments,
              this.filteredAttribute === 'segments'
                ? selectedTitleIds
                : vm.selectedSegments.map((a) => a.id),
              false,
              this.filteredAttribute === 'source'
                ? titleStrings
                : vm.selectedHighLevels,
              false,
              vm.staticData.emissionSourcesLabels,
              '',
              false,
              true,
            );

            let co2 = 0;
            let ch4 = 0;
            let n20 = 0;
            let total = 0;

            feed.companyData.forEach((company) => {
              co2 += company.value.co2;
              ch4 += company.value.ch4;
              n20 += company.value.n20;
              total +=
                company.value.co2 +
                company.value.ch4 * 28 +
                company.value.n20 * 265;
            });

            const companyData = feed.companyData.sort((a, b) => {
              let aValue = 0;
              let bValue = 0;
              if (this.selectedIntensity === 'CO2e (AR5)') {
                aValue +=
                  a.value.co2 * 1 + a.value.ch4 * 28 + a.value.n20 * 265;
                bValue +=
                  b.value.co2 * 1 + b.value.ch4 * 28 + b.value.n20 * 265;
              } else if (this.selectedIntensity === 'CO2') {
                aValue += a.value.co2;
                bValue += b.value.co2;
              } else if (this.selectedIntensity === 'CH4') {
                aValue += a.value.ch4;
                bValue += b.value.ch4;
              } else if (this.selectedIntensity === 'N20') {
                aValue += a.value.n20;
                bValue += b.value.n20;
              }

              if (aValue > bValue) return -1;
              if (aValue < bValue) return 1;
              return 0;
            });

            if (vm.selectedIntensity === 'CO2e (AR5)') {
              innerHtml += '<tr class="py-2 block"><td></td></tr>';
              // uncomment if you want to show this total
              // but it will always be the same as feed.total from body lines

              // innerHtml += '<tr><td><span>' + span + total + ' ' + tooltipLabel + '</span></td></tr>';
            }
            if (vm.selectedIntensity === 'CO2e (AR5)') {
              co2 = co2 * 1;
              ch4 = ch4 * 28;
              n20 = n20 * 265;
            }

            const co2span =
              '<span class="flex-auto mr-2"">CO2:</span><span>' +
              parseFloat(co2.toFixed(3)).toLocaleString() +
              ' ' +
              tooltipLabel +
              '</span>';

            innerHtml += '<tr><td class="flex">' + co2span + '</td></tr>';

            const ch4span =
              '<span class="flex-auto mr-2"">CH4:</span><span>' +
              parseFloat(ch4.toFixed(3)).toLocaleString() +
              ' ' +
              tooltipLabel +
              '</span>';

            innerHtml += '<tr><td class="flex">' + ch4span + '</td></tr>';

            const n20span =
              '<span class="flex-auto mr-2"">N20:</span><span>' +
              parseFloat(n20.toFixed(3)).toLocaleString() +
              ' ' +
              tooltipLabel +
              '</span>';

            innerHtml += '<tr><td class="flex">' + n20span + '</td></tr>';

            if (this.filteredAttribute !== 'companies') {
              innerHtml += '<tr class="py-2 block"><td></td></tr>';
              innerHtml +=
                '<tr class="py-2 block"><td><b>Top ' +
                (companyData?.length > 4 ? '5' : companyData?.length) +
                ' ' +
                (companyData?.length > 1 || companyData.length === 0
                  ? 'Companies'
                  : 'Company') +
                '</b></td></tr>';

              companyData.splice(0, 5).forEach((company) => {
                let innerTotal = 0;
                if (this.selectedIntensity === 'CO2e (AR5)') {
                  innerTotal +=
                    company.value.co2 +
                    company.value.ch4 * 28 +
                    company.value.n20 * 265;
                } else {
                  innerTotal +=
                    company.value.co2 + company.value.ch4 + company.value.n20;
                }

                const companySpan =
                  '<span class="flex-auto mr-2"">' +
                  company.label +
                  '</span><span>' +
                  parseFloat(innerTotal.toFixed(3)).toLocaleString() +
                  ' ' +
                  tooltipLabel +
                  '</span>';

                innerHtml +=
                  '<tr><td class="flex">' + companySpan + '</td></tr>';
              });
            }
          }

          innerHtml += '</tbody>';

          const tableRoot: any = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        const position = vm._chart.nativeElement.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding =
          tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
      },
    },
  };
  public doughnutChartLegend = true;
  get basins() {
    return this.staticData.basins;
  }
  get industrySegments() {
    return this.staticData.industrySegments;
  }

  constructor(
    private staticData: StaticDataService,
    private benchmarkService: BenchmarkService,
    private sanitizer: DomSanitizer,
    public router: Router,
  ) {}

  /**
   * On Changes
   */
  ngOnInit() {
    this.calcTotal();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    const doughnutChartPlugins = [
      {
        beforeDraw: (chart: any): void => {
          if (vm.showTotal && parseFloat(vm.showTotal)) {
            const ctx = chart.ctx;
            const txt1 = vm.centerLabel;
            let txt2 = '';
            let txtCount = '';

            let total = 0;
            chart.tooltip._data.datasets[0].data.forEach((dd) => {
              total += dd;
            });
            let thousands = false;

            const txtFormat: string[] = parseFloat(total.toFixed(3))
              .toLocaleString()
              .split(',');
            if (txtFormat.length) {
              if (txtFormat.length === 1) {
                txt2 = txtFormat[0];
              } else {
                if (txtFormat.length >= 5) {
                  txtFormat.forEach((t, i) => {
                    if (i < txtFormat.length - 4) {
                      txt2 += t + ',';
                    }
                  });
                  txtCount = 'B';
                } else {
                  if (txtFormat.length === 4) {
                    txt2 = txtFormat[0] + '.' + txtFormat[1];
                    txtCount = 'B';
                  }
                  if (txtFormat.length === 3) {
                    txt2 = txtFormat[0] + '.' + txtFormat[1];
                    txtCount = 'M';
                  } else if (txtFormat.length === 2) {
                    thousands = true;
                    txt2 = txtFormat[0] + ',' + txtFormat[1];
                    txtCount = '';
                  }
                }
              }
            }
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            ctx.font = thousands ? '20px "Roboto"' : '36px "Roboto"';

            // Draw text in center
            ctx.fillStyle = '#060D32';

            ctx.fillText(txt2, centerX, centerY + 15);
            ctx.font = '16px "Roboto"';
            ctx.fillStyle = '#8C87A6';
            ctx.fillText(
              txt1,
              centerX - (ctx.measureText(txtCount).width - 15) / 2,
              centerY - 15,
            );
            ctx.fillStyle = '#060D32';
            ctx.fillText(
              txtCount,
              centerX + (ctx.measureText(txt2).width + 60) / 2,
              centerY + 20,
            );
          }
        },
      },
    ];

    this.plugins?.forEach((plugin) => {
      doughnutChartPlugins.push(plugin);
    });
    this.doughnutChartPlugins = doughnutChartPlugins;
  }

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.doughnutChartData?.isFirstChange()) {
      this.calcTotal();
    }
  }

  public getCommaNumber(n: number) {
    return parseFloat(n.toFixed(3)).toLocaleString();
  }

  public getTooltipData = (data?: any) => {
    if (this.showTooltip) {
      let tooltipLabel = '';
      // chart is emissions and is now checking for if it's set to CO2e or any other emissions category.
      if (this.selectedIntensity === 'CO2e (AR5)') {
        tooltipLabel = 'mT CO2e';
      } else {
        tooltipLabel = 'mT';
      }
      if (this.replaceToolTipSuffix !== '') {
        tooltipLabel = this.replaceToolTipSuffix;
      }

      let html = '<table class="w-full">';
      html += '<thead>';
      html += '<tr><th class="text-left"><b>' + data.text + '</ b></th></tr>';
      html += '</thead><tbody>';
      html +=
        '<tr><th class="text-left"><b>' +
        this.doughnutChartData[data.index].toLocaleString() +
        ' ' +
        tooltipLabel +
        '</ b></th></tr>';
      if (this.benchmark) {
        const ids: string[] = [];
        if (this.filteredAttribute === 'basins') {
          const basin = this.basins.find((bb) => {
            return (
              bb.basinFullName.replace(/ *\([^)]*\) */g, '') ===
              data.text.replace(/ *\([^)]*\) */g, '')
            );
          });

          if (basin) {
            ids.push(basin.id);
          }
        }
        if (this.filteredAttribute === 'segments') {
          const segment = this.industrySegments.find((seg) => {
            return seg.longName === data.text;
          });

          if (segment) {
            ids.push(segment.id);
          }
        }
        const feed = this.benchmarkService.dataFaster(
          this.selectedIntensity,
          [this.selectedYear],
          this.selectedYear,
          this.basins,
          this.filteredAttribute === 'basins' ? ids : this.selectedBasins,
          false,
          this.selectedCompanies,
          this.selectedCompanies,
          false,
          this.industrySegments,
          this.filteredAttribute === 'segments'
            ? ids
            : this.selectedSegments.map((a) => a.id),
          false,
          this.filteredAttribute === 'source'
            ? [data.text]
            : this.selectedHighLevels,
          false,
          this.staticData.emissionSourcesLabels,
          '',
          false,
          true,
        );

        let co2 = 0;
        let ch4 = 0;
        let n20 = 0;
        let total = 0;

        feed.companyData.forEach((company) => {
          co2 += company.value.co2;
          ch4 += company.value.ch4;
          n20 += company.value.n20;
          total +=
            company.value.co2 +
            company.value.ch4 * 28 +
            company.value.n20 * 265;
        });

        const companyData = feed.companyData.sort((a, b) => {
          let aValue = 0;
          let bValue = 0;
          if (this.selectedIntensity === 'CO2e (AR5)') {
            aValue += a.value.co2 * 1 + a.value.ch4 * 28 + a.value.n20 * 265;
            bValue += b.value.co2 * 1 + b.value.ch4 * 28 + b.value.n20 * 265;
          } else if (this.selectedIntensity === 'CO2') {
            aValue += a.value.co2;
            bValue += b.value.co2;
          } else if (this.selectedIntensity === 'CH4') {
            aValue += a.value.ch4;
            bValue += b.value.ch4;
          } else if (this.selectedIntensity === 'N20') {
            aValue += a.value.n20;
            bValue += b.value.n20;
          }

          if (aValue > bValue) return -1;
          if (aValue < bValue) return 1;
          return 0;
        });

        if (this.selectedIntensity === 'CO2e (AR5)') {
          const span = '<span></span>';
          html +=
            '<tr><td>' +
            span +
            parseFloat(total.toFixed(3)).toLocaleString() +
            ' ' +
            tooltipLabel +
            '</td></tr>';
          html += '<tr class="py-2 block"><td></td></tr>';
        }

        if (this.selectedIntensity === 'CO2e (AR5)') {
          co2 = co2 * 1;
          ch4 = ch4 * 28;
          n20 = n20 * 265;
        }

        const co2span =
          '<span class="flex-auto mr-2"">CO2:</span><span>' +
          parseFloat(co2.toFixed(3)).toLocaleString() +
          ' ' +
          tooltipLabel +
          '</span>';

        html += '<tr><td class="flex text-md">' + co2span + '</td></tr>';

        const ch4span =
          '<span class="flex-auto mr-2"">CH4:</span><span>' +
          parseFloat(ch4.toFixed(3)).toLocaleString() +
          ' ' +
          tooltipLabel +
          '</span>';

        html += '<tr><td class="flex text-md">' + ch4span + '</td></tr>';

        const n20span =
          '<span class="flex-auto mr-2"">N20:</span><span>' +
          parseFloat(n20.toFixed(3)).toLocaleString() +
          ' ' +
          tooltipLabel +
          '</span>';

        html += '<tr><td class="flex text-md">' + n20span + '</td></tr>';

        if (this.filteredAttribute !== 'companies') {
          html += '<tr class="py-2 block"><td></td></tr>';
          html +=
            '<tr class="py-2 block"><td><b>Top ' +
            (companyData?.length > 4 ? '5' : companyData?.length) +
            ' ' +
            (companyData?.length > 1 || companyData.length === 0
              ? 'Companies'
              : 'Company') +
            '</b></td></tr>';

          companyData.splice(0, 5).forEach((company) => {
            let innerTotal = 0;
            if (this.selectedIntensity === 'CO2e (AR5)') {
              innerTotal +=
                company.value.co2 +
                company.value.ch4 * 28 +
                company.value.n20 * 265;
            } else {
              innerTotal +=
                company.value.co2 + company.value.ch4 + company.value.n20;
            }

            const companySpan =
              '<span class="flex-auto mr-2 text-md">' +
              company.label +
              '</span><span class="text-md">' +
              parseFloat(innerTotal.toFixed(3)).toLocaleString() +
              ' ' +
              tooltipLabel +
              '</span>';

            html += '<tr><td class="flex">' + companySpan + '</td></tr>';
          });
        }
      }

      html += '</tbody>';
      html += '</table>';

      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  };

  public goToChartDetails(label: string) {
    if (!this.canClickDetails) {
      return;
    }

    this.clickDetails.emit(label);
  }

  private calcTotal() {
    this.total = 0;
    this.doughnutChartData.forEach((data: any) => {
      // looks if data array is > 1 because it is on overview pie charts, but for benchmarking it's always just lenght = 1 for data.
      if (data.length > 1) {
        data.forEach((vv) => {
          this.total += vv;
        });
      } else {
        this.total += data;
      }
    });
  }
}
