import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { lastValueFrom } from 'rxjs';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { ReportingPeriodType, ReportingPeriod } from '@iconic-air/models';

@Injectable({
  providedIn: 'root',
})
export class ReportingPeriodService {
  constructor(
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
  ) {}

  async getReportingPeriodYears() {
    const yearDocs = await lastValueFrom(
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('reportingPeriods')
        .get(),
    );
    return yearDocs.docs.map((doc) => {
      return doc.id;
    });
  }

  async getReportingPeriods(year: string) {
    const rpDoc: { [emissionSource: string]: ReportingPeriod } = (
      await lastValueFrom(
        this._afs
          .collection('customers')
          .doc(this._perms.activeCustomerId)
          .collection('reportingPeriods')
          .doc(year?.toString())
          .get(),
      )
    ).data() as { [emissionSource: string]: ReportingPeriod };

    // delete framework part of reporting periods
    if (Object.keys(rpDoc || {})?.includes('frameworks'))
      delete rpDoc.frameworks;
    return rpDoc;
  }

  async getAllFrameworks() {
    const frameworks: string[] = [],
      frameworkVersions: string[] = [];
    (
      await lastValueFrom(
        this._afs
          .collection('customers')
          .doc(this._perms.activeCustomerId)
          .collection('reportingPeriods')
          .get(),
      )
    ).docs?.forEach((doc) => {
      const data = doc.data();
      data?.frameworks?.forEach((framework) => {
        if (!frameworks.includes(framework.framework)) {
          frameworks.push(framework.framework);
        }
        framework?.versions?.forEach((version) => {
          if (
            !frameworkVersions.includes(framework.framework + ' | ' + version)
          ) {
            frameworkVersions.push(framework.framework + ' | ' + version);
          }
        });
      });
    });
    return { frameworks, frameworkVersions };
  }

  async getFrameworks(year: string) {
    const rpDoc = (
      await lastValueFrom(
        this._afs
          .collection('customers')
          .doc(this._perms.activeCustomerId)
          .collection('reportingPeriods')
          .doc(year?.toString())
          .get(),
      )
    ).data();
    return rpDoc?.frameworks as {
      framework: string;
      reportingVersion: string;
      versions: string[];
    }[];
  }

  async getFrameworkVersions(year: string) {
    const rpDoc = (
      await lastValueFrom(
        this._afs
          .collection('customers')
          .doc(this._perms.activeCustomerId)
          .collection('reportingPeriods')
          .doc(year?.toString())
          .get(),
      )
    ).data();
    const frameworkVersions: string[] = [];
    rpDoc?.frameworks?.forEach((framework) => {
      framework?.versions?.forEach((version) => {
        if (
          !frameworkVersions.includes(framework.framework + ' | ' + version)
        ) {
          frameworkVersions.push(framework.framework + ' | ' + version);
        }
      });
    });
    return frameworkVersions;
  }

  async getAllUniqueReportingPeriodKeys() {
    // set the reporting period and use that to set the bulk upload values
    const uniqueKeys: string[] = [
      ...((await this.getAllReportingPeriods()) as any[]).reduce((acc, obj) => {
        Object.keys(obj).forEach((key) => {
          if (!['frameworks', 'id'].includes(key)) acc.add(key);
        });
        return acc;
      }, new Set()),
    ];
    return uniqueKeys;
  }

  async getAllReportingPeriods() {
    const rpDoc: { [emissionSource: string]: ReportingPeriod }[] = (
      await lastValueFrom(
        this._afs
          .collection('customers')
          .doc(this._perms.activeCustomerId)
          .collection('reportingPeriods')
          .get(),
      )
    ).docs?.map((doc) => {
      const data = doc.data();
      if (Object.keys(data || {})?.includes('frameworks'))
        delete data.frameworks;
      return { ...data, id: doc.id };
    }) as unknown as { [emissionSource: string]: ReportingPeriod }[];
    return rpDoc;
  }

  getReportingPeriodType(reportingPeriod: string): keyof ReportingPeriodType {
    if (['Q1', 'Q2', 'Q3', 'Q4'].includes(reportingPeriod)) return 'quarterly';
    else if (
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].includes(
        reportingPeriod?.toString(),
      )
    )
      return 'monthly';
    else if (Number(reportingPeriod) > 1900 && Number(reportingPeriod) < 2100)
      return 'annual';
    throw new Error('Invalid reporting period');
  }

  startOfPeriod(reportingPeriod: string, year: string) {
    const reportingPeriodType = this.getReportingPeriodType(reportingPeriod);
    switch (reportingPeriodType) {
      case 'quarterly':
        return new Date(
          Number(year),
          (Number(reportingPeriod.substring(1)) - 1) * 3,
          1,
        );
      case 'monthly':
        return new Date(Number(year), Number(reportingPeriod) - 1, 1);
      case 'annual':
        return new Date(Number(year), 0, 1);
    }
  }

  async getAllReportingPeriodsByYear() {
    const reportingPeriods: {
      [year: string]: { [emissionSource: string]: ReportingPeriod };
    } = {};
    const docs = await lastValueFrom(
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('reportingPeriods')
        .get(),
    );
    docs.forEach((doc) => {
      const data = doc.data();
      if (Object.keys(data || {})?.includes('frameworks'))
        delete data.frameworks;
      if (Object.keys(reportingPeriods || {})?.includes('id'))
        delete reportingPeriods.id;
      reportingPeriods[doc.id] = data;
    });
    return reportingPeriods;
  }
}
