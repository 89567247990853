import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ArticleSimple,
  Category,
  Article,
  WebinarNoUrl,
  Webinar,
  Author,
} from '@iconic-air/models';
import { lastValueFrom } from 'rxjs';
import { DownloadFileService } from '../download-file/download-file.service';

@Injectable({
  providedIn: 'root',
})
export class ContentManagementSystemService {
  #url = '/contentManagementSystem';

  constructor(
    private _http: HttpClient,
    private _downloadFileService: DownloadFileService,
  ) {}

  async getExperts() {
    return (await lastValueFrom(
      this._http.get<any>(`${this.#url}/expert`),
    )) as Author[];
  }

  async getInitialLoad() {
    return (await lastValueFrom(this._http.get<any>(`${this.#url}`))) as {
      featuredArticles: ArticleSimple[];
      categories: Category[];
    };
  }

  async getCategory(id: number) {
    return (await lastValueFrom(
      this._http.get<any>(`${this.#url}/category/${id}`),
    )) as Category;
  }

  async getArticle(id: number, screen: 'landing' | 'subcategory') {
    return (await lastValueFrom(
      this._http.get<any>(`${this.#url}/article/${id}`, {
        params: { screen },
      }),
    )) as Article;
  }

  async getWebinars() {
    return (await lastValueFrom(
      this._http.get<any>(`${this.#url}/webinar`),
    )) as WebinarNoUrl[];
  }

  async getWebinar(id: number) {
    return (await lastValueFrom(
      this._http.get<any>(`${this.#url}/webinar/${id}`),
    )) as Webinar;
  }

  async getArticles(categoryId: number, subcategoryId: number) {
    return (await lastValueFrom(
      this._http.get<any>(
        `${this.#url}/category/${categoryId}/subcategory/${subcategoryId}`,
      ),
    )) as ArticleSimple[];
  }

  async downloadFile(
    article: ArticleSimple | Article,
    screen: 'article' | 'landing' | 'subcategory',
  ) {
    return await this._downloadFileService.downloadFileWithCustomName(
      `/contentManagementSystem/article/${article.id}/file/${article.file?.id}?screen=${screen}`,
      article.file?.name || article.title,
      'GET',
    );
  }
}
