import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(
    text: string,
    highlight: string,
    tag?: string,
    returnString = false,
  ): any {
    if (!text || !highlight) {
      return text;
    }

    let replaceText: string = '<span class="highlighted">$1</span>';

    if (tag) {
      replaceText = `<${tag}>$1</${tag}>`;
    }

    let regex = '(?![^<>]*>)(';

    highlight.split('').forEach((char, i, arr) => {
      regex += char;

      if (i !== arr.length - 1) {
        // need to account for the possibility of HTML tags within the search text
        regex += '(?:<.*?>)?';
      }
    });

    regex += ')';

    const ret = text.replace(new RegExp(regex, 'gi'), replaceText);

    return returnString ? ret : this.sanitizer.bypassSecurityTrustHtml(ret);
  }
}
