import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camera' })
export class CameraPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return value;
    }

    return this.#replaceString(value);
  }

  #replaceString(value) {
    let result = value;

    if (value.toLowerCase().includes('flir gf'))
      result = value.replace(/flir gf/gi, 'FLIR GF');
    if (value.toLowerCase().includes('flir gfx'))
      result = value.replace(/flir gfx/gi, 'FLIR GFX');
    if (value.toLowerCase().includes('opgal eyecgas'))
      result = value.replace(/opgal eyecgas/gi, 'Opgal EyeCGas');
    if (value.toLowerCase().includes('op gal eye c gas'))
      result = value.replace(/opgal eyecgas/gi, 'Opgal EyeCGas');
    if (value.toLowerCase().includes('ppm'))
      result = value.replace(/ppm/gi, 'PPM');

    return result;
  }
}
