import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { StaticDataService } from '../../services/static-data/static.data.service';
import { State } from '@iconic-air-monorepo/models';

@Component({
  selector: 'app-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StateSelectComponent,
      multi: true,
    },
  ],
})
export class StateSelectComponent implements ControlValueAccessor {
  get states() {
    return this.staticService.states;
  }
  @Input() formControlName: string;
  @Input() required = true;
  @Input() disabled = false;
  @Output() changeEvent = new EventEmitter<string>();
  value: string; // Ex. CA
  get valueDisplay() {
    if (this.states) {
      const state = this.states.find((state: State) => {
        return this.value === state.abbreviation;
      });
      if (state) {
        return state.name;
      }
    }
    return '';
  } // Ex. California
  @ViewChild('matSelect')
  selectOption: MatSelect;
  @ViewChild('matWrapper')
  selectWrapper: ElementRef;
  stateFilter = '';
  @Input() inputAppearance = '';
  @Input() inputClass = '';
  @Input() label = true;
  public isDisabled = false;
  private onChangeCallback: any = () => undefined;
  private onTouchedCallback: any = () => undefined;

  constructor(private staticService: StaticDataService) {}

  public changeValue(val: MatSelectChange): void {
    this.value = val.value;
    this.changeEvent.emit(this.value);
    this.onChangeCallback(this.value);
  }

  public onSelect(event: MatOptionSelectionChange, selectedCode: string) {
    this.onChangeCallback(selectedCode);
    this.changeEvent.emit(selectedCode);
  }

  public writeValue(value: string) {
    let stateFound: State;
    if (this.states) {
      const state = this.states.find((state: State) => {
        return value === state.abbreviation;
      });
      if (state) {
        stateFound = state;
      }
    }

    if (stateFound) {
      this.value = stateFound.abbreviation;
    } else {
      this.value = '';
    }
  }

  // Implement ControlValueAccessor registerOnChange.
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // Implement ControlValueAccessor registerOnTouched.
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // Propagate enable/disable to the form control.
  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public focus() {
    this.selectOption._elementRef.nativeElement.focus();
  }
}
