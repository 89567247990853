import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  utcToLocaleDate(utcTimestamp: number | Date) {
    // if its too small it is probably an excel date, so convert it
    if (isNumber(utcTimestamp) && utcTimestamp < 100000)
      utcTimestamp = utcTimestamp * 86400000 - 2209075200000;
    const date = new Date(utcTimestamp);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
    );
  }
  localeDateTimeToUtc(date: string | number | Date | { seconds: number }) {
    if (!date) return date;
    if (isNumber(date)) {
      // if its too small it is probably an excel date, so convert it
      if (date < 100000) date = date * 86400000 - 2209075200000;
      date = new Date(date);
    } else if (typeof date === 'string') date = new Date(date);
    else if ('seconds' in date) date = new Date(date.seconds * 1000);
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getMilliseconds(),
      ),
    );
  }

  localeDateToUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
  }

  convertToDateTime(
    date: string | number | Date | { seconds: number },
    convertToUtc?: boolean,
  ) {
    if (!date) return date;
    if (isNumber(date)) {
      // if its too small it is probably an excel date, so convert it
      if (date < 100000) date = date * 86400000 - 2209075200000;
      date = new Date(date);
    } else if (typeof date === 'string') date = new Date(date);
    else if ('seconds' in date) date = new Date(date.seconds * 1000);
    if (convertToUtc) date = this.localeDateToUtc(date as Date);
    return date;
  }

  displayDatetime(date: string | number | Date | { seconds: number }) {
    return this.convertToDateTime(date)?.toLocaleString();
  }
  monthsAgo(dateInMillis: number) {
    const givenDate = new Date(dateInMillis);
    const currentDate = new Date();

    // Get year and month for both the current date and the given date
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-indexed (January = 0)

    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth(); // 0-indexed (January = 0)

    // Calculate the difference in years and months
    const yearDiff = currentYear - givenYear;
    const monthDiff = currentMonth - givenMonth;

    // Calculate total months difference
    const totalMonthsAgo = yearDiff * 12 + monthDiff;

    return totalMonthsAgo;
  }

  convertToDate(
    date: string | number | Date | { seconds: number },
    convertToUtc?: boolean,
  ) {
    if (!date) return date;
    if (isNumber(date)) {
      // if its too small it is probably an excel date, so convert it
      if (date < 100000) date = date * 86400000 - 2209075200000;
      date = new Date(date);
    } else if (typeof date === 'string') date = new Date(date);
    else if ('seconds' in date) date = new Date(date.seconds * 1000);
    if (convertToUtc) {
      const finalDate = this.convertToDateTime(date, convertToUtc) as Date;
      const year = finalDate?.getUTCFullYear();
      const month = ('0' + (finalDate?.getUTCMonth() + 1)).slice(-2);
      const day = ('0' + finalDate?.getUTCDate()).slice(-2);
      return `${year}-${month}-${day}`;
    }
    return `${date?.getFullYear()}-${('0' + (date?.getMonth() + 1)).slice(
      -2,
    )}-${('0' + date?.getDate()).slice(-2)}`;
  }

  momentToUtc(date: string | number | Date | { seconds: number }) {
    return moment(date).utc().toDate();
  }
}
