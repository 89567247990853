import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFirestoreModule,
  USE_EMULATOR as FIRESTORE_EMULATOR,
  USE_EMULATOR as FUNCTIONS_EMULATOR,
} from '@angular/fire/compat/firestore';
import {
  AngularFireAuthModule,
  PERSISTENCE,
  USE_EMULATOR as AUTH_EMULATOR,
} from '@angular/fire/compat/auth';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import {
  AngularFireStorageModule,
  AngularFireStorage,
} from '@angular/fire/compat/storage';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProfileComponent } from './pages/profile/profile.component';
import { ShellModule } from './shell/shell.module';
import { MaterialModule } from './theme/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { ErrorHandlerService } from './services/error/error-handler.service';
import { AuthenticationWebService } from './services/authentication/authentication-web.service';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { HttpService } from './services/http/http.service';
import { environment } from '../environments/environment';
import { LoadingGridComponentComponent } from './components/loading-grid-component/loading-grid-component.component';
import { AreYouSureComponent } from './components/are-you-sure/are-you-sure.component';
import { StateEditorComponent } from './components/state-editor/state-editor.component';
import { CreateLdarReportService } from './services/reporting/create-ldar-report.service';
import { MediaWatcherService } from './services/media-watcher/media-watcher.service';
import { AgGridModule } from 'ag-grid-angular';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { MultiFactorAuthDialogComponent } from './components/multi-factor-auth-dialog/multi-factor-auth-dialog.component';
import { ReauthenticateDialogComponent } from './components/reauthenticate-dialog/reauthenticate-dialog.component';
import { SmsAuthDialogComponent } from './components/sms-auth-dialog/sms-auth-dialog.component';
import { ContactSupportDialogComponent } from './components/contact-support-dialog/contact-support-dialog.component';
import { InviteComponent } from './pages/auth/invite/invite.component';
import { AdminEmissionsService } from './pages/admin/emissions/admin-emissions.service';
import { IconSnackBarComponent } from './components/icon-snack-bar/icon-snack-bar.component';
import { BasicDialogComponent } from './components/basic-dialog/basic-dialog.component';
import { PipesModule } from './pipes/pipes.module';
import { DataValidationComponent } from './components/data-validation/data-validation.component';
import { DomService } from './services/dom/dom.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    SignInComponent,
    ErrorDialogComponent,
    LoadingGridComponentComponent,
    AreYouSureComponent,
    StateEditorComponent,
    MultiFactorAuthDialogComponent,
    ReauthenticateDialogComponent,
    SmsAuthDialogComponent,
    ContactSupportDialogComponent,
    InviteComponent,
    IconSnackBarComponent,
    BasicDialogComponent,
    DataValidationComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    CommonModule,
    ShellModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    RouterModule,
    MonacoEditorModule.forRoot(),
    AgGridModule.withComponents([]),
    PipesModule,
    ScrollingModule,
  ],
  providers: [
    DatePipe,
    TitleCasePipe,
    DomService,
    { provide: PERSISTENCE, useValue: 'local' },
    AngularFireStorage,
    AngularFireAuthGuard,
    AuthenticationWebService,
    HttpService,
    ErrorHandlerService,
    CreateLdarReportService,
    MediaWatcherService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpService,
      multi: true,
    },
    AdminEmissionsService,
    //{
    //  provide: AUTH_EMULATOR,
    //  useValue: environment.production ? undefined : ['http://localhost:9099'],
    //},
    //{
    //  provide: FIRESTORE_EMULATOR,
    //  useValue: environment.production ? undefined : ['http://localhost:8081'],
    //},
    //{
    //  provide: FUNCTIONS_EMULATOR,
    //  useValue: environment.production ? undefined : ['http://localhost:5001'],
    //},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
