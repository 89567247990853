import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'programType' })
export class ProgramTypePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return value;
    }

    return this.#replaceString(value);
  }

  #replaceString(value) {
    let result = value;

    if (value.toLowerCase().includes('oooo'))
      result = value.replace(/oooo/gi, 'OOOO');
    if (value.toLowerCase().includes('nrsp'))
      result = value.replace(/nrsp/gi, 'NRSP');
    if (value.toLowerCase().includes('nmed'))
      result = value.replace(/nmed/gi, 'NMED');
    if (value.toLowerCase().includes('pa gp-5a/gp5'))
      result = value.replace(/pa gp-5a\/gp5/gi, 'PA GP-5A/GP5');
    if (value.toLowerCase().includes('pa exemption'))
      result = value.replace(/pa exemption/gi, 'PA Exemption');
    if (value.toLowerCase().includes('pa existing source rule'))
      result = value.replace(
        /pa existing source rule/gi,
        'PA Existing Source Rule',
      );
    if (value.toLowerCase().includes('voluntary'))
      result = value.replace(/voluntary/gi, 'Voluntary');
    if (value.toLowerCase().includes('ogi'))
      result = value.replace(/ogi/gi, 'OGI');
    if (value.toLowerCase().includes('awp'))
      result = value.replace(/awp/gi, 'AWP');
    if (value.toLowerCase().includes('avo'))
      result = value.replace(/avo/gi, 'AVO');
    if (value.toLowerCase().includes('flare'))
      result = value.replace(/flare/gi, 'Flare');

    return result;
  }
}
