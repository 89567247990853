import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '@iconic-air-monorepo/models';

@Pipe({
  name: 'roleType'
})
export class RoleTypePipe implements PipeTransform {
  transform(args: Role[], value: string): Role[] {
    return args.filter((arg) => {
      return arg.roleType === value;
    });
  }
}
