<mat-form-field
  class="{{classes}}"
  matTooltip="{{ multiple ? (selectedFacility?.length ? selectedFacility?.length + ' Selected' : '') : ''  | titlecase}}"
  matTooltipPosition="below"
  [appearance]="appearance"
>
  <mat-label *ngIf="hasBeenOpened">
    <ng-container *ngIf="!multiple">Location</ng-container>
    <ng-container *ngIf="multiple">Locations</ng-container>
  </mat-label>
  
  <mat-label matTextPrefix *ngIf="!hasBeenOpened">
    <ng-container *ngIf="!multiple && selectedFacility">
      <span class="text-primary">Location: </span>
      <span *ngFor="let facility of facilities">
        <span *ngIf="facility.id === selectedFacility">{{facility.facilityName}}</span>
      </span>
    </ng-container>
    <ng-container *ngIf="multiple && selectedFacility?.length">
      <span class="text-primary">Location: </span>
      <span>{{selectedFacility?.length >= facilities?.length ? 'All' : selectedFacility?.length + ' Selected'}}</span>
    </ng-container>
  </mat-label>
  <mat-select
    [disabled]="disabled"
    #matSelect
    [(ngModel)]="selectedFacility"
    [multiple]="multiple"
    (openedChange)="selectPanelOpened($event)"
  >
    <mat-select-trigger *ngIf="multiple">
      <ng-container *ngIf="loading">
        <mat-progress-spinner
          *ngIf="loading"
          [diameter]="18"
          [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
      </ng-container>
      <ng-container *ngIf="!loading">
        <span class="text-primary">Location: </span>
        {{selectedFacility?.length >= facilities?.length ? 'All' :
        selectedFacility?.length + ' Selected'}}
      </ng-container>
    </mat-select-trigger>
    <div novalidate class="mat-filter">
      <input
        #searchedInput
        matInput
        [(ngModel)]="facilityNameFilter"
        [ngModelOptions]="{standalone: true}"
        filterBasinszclass="mat-filter-input"
        placeholder="Search..."
      />
    </div>
    <ng-container *ngIf="disableScroll">
      <div class="example-margin" ripple *ngIf="multiple">
        <mat-checkbox
          [checked]="selectedFacility?.length >= facilities?.length"
          (change)="selectAllFacilities($event)"
          *ngIf="!facilityNameFilter || ['all'].includes(facilityNameFilter.toLowerCase())"
        >
          {{'All' | titlecase }}</mat-checkbox
        >
        <h1
          *ngIf="facilityNameFilter && !['all'].includes(facilityNameFilter.toLowerCase())"
        >
          {{'Search Results...' | titlecase}}
        </h1>
      </div>
      <ng-container *ngFor="let facility of facilities; trackBy: trackByFn">
        <mat-option
          [value]="fullObj ? facility : facility.id"
          *ngIf="!facilityNameFilter || facility?.facilityName?.toLowerCase()?.includes(facilityNameFilter?.toLowerCase())"
        >
          <span
            [innerHtml]="facility.facilityName | highlight:facilityNameFilter"
          ></span>
        </mat-option>
      </ng-container>
    </ng-container>
    <cdk-virtual-scroll-viewport
      *ngIf="!disableScroll"
      minBufferPx="200"
      maxBufferPx="400"
      itemSize="50"
      [style.height.px]="5 * 48"
      class="relative"
    >
      <div class="example-margin" ripple *ngIf="multiple">
        <mat-checkbox
          [checked]="selectedFacility?.length >= facilities?.length"
          (change)="selectAllFacilities($event)"
          *ngIf="!facilityNameFilter || ['all'].indexOf(facilityNameFilter.toLowerCase()) > -1"
        >
          {{'All' | titlecase }}</mat-checkbox
        >
        <h1
          *ngIf="facilityNameFilter && ['all'].indexOf(facilityNameFilter.toLowerCase()) === -1"
        >
          {{'Search Results...' | titlecase}}
        </h1>
      </div>
      <mat-option
        *cdkVirtualFor="let facility of facilities | facilityFilter: facilityNameFilter | sort:'asc':'facilityName' | prependItem: selectedFacility : 'id' : facilities; trackBy: trackByFn"
        [value]="fullObj ? facility : facility.id"
        [ngClass]="{'hidden': facility?.active === false || (facilityNameFilter && facility?.facilityName?.toLowerCase().indexOf(facilityNameFilter.toLowerCase()) === -1)}"
      >
        <span
          [innerHtml]="facility.facilityName | highlight:facilityNameFilter"
        ></span>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-select>
</mat-form-field>
