import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
} from '@angular/core';
import { FormulaTooltipComponent } from './formula-tooltip.component';

@Directive({
  selector: '[appFormulaTooltip]',
})
export class FormulaTooltipDirective {
  // this can be used to show the tooltip conditionally
  @Input() showTooltip: boolean;

  // this can be used to show a specific text in the tooltip
  @Input() tooltipText: string;

  // this will show the corresponding temlate in the tooltip
  @Input() tooltipTemplate: TemplateRef<any>;

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
  ) {}

  ngOnInit() {
    if (!this.showTooltip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -5,
        },
      ]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }
  @HostListener('mouseenter')
  show() {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<FormulaTooltipComponent> =
        this._overlayRef.attach(new ComponentPortal(FormulaTooltipComponent));
      tooltipRef.instance.text = this.tooltipText;
      tooltipRef.instance.contentTemplate = this.tooltipTemplate;
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
