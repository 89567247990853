<div class="flex flex-1 {{extraClasses.join(' ')}} flex-col lg:flex-row">
  <div
    class="flex items-center justify-center mb-3 flex-1 mx-auto w-full lg:w-3/5"
  >
    <canvas
      baseChart
      #chart
      #partsBaseChart="base-chart"
      [data]="doughnutChartData"
      [colors]="doughnutChartColors"
      [labels]="doughnutChartLabels"
      [options]="ng2options"
      [chartType]="doughnutChartType"
      [plugins]="doughnutChartPlugins"
      [legend]="doughnutChartLegend"
    >
    </canvas>
  </div>
  <div
    *ngIf="partsBaseChart?.chart?.legend?.legendItems"
    class="legend w-full legend overflow-y-auto p-4 scroll-shadow flex-1 ignore-drag"
  >
    <ul class="custom-legend-list">
      <ng-container
        *ngFor="let item of partsBaseChart.chart.legend.legendItems; let i = index"
      >
        <li
          class="py-1 flex flex-auto items-center relative"
          [ngClass]="{'cursor-pointer': canClickDetails}"
          (click)="goToChartDetails(item.text)"
        >
          <span
            class="custom-legend-item flex flex-auto items-center"
            iconicAirMonorepoTooltip
            [contentTemplate]="megaFeedTooltip"
            [data]="item"
            [showTooltip]="false"
            [ngStyle]="{'border-color': item.fillStyle.replace('0.9', '0.3')}"
          >
            <span class="slice-color flex items-center">
              <span
                class="internal"
                [ngStyle]="{'background': item.fillStyle}"
              ></span>
            </span>
            <span class="slice-title flex flex-auto items-center">
              <span
                class="flex-auto mr-2 flex items-center"
                [ngClass]="{'mr-18': canClickDetails}"
              >
                {{ item.text }}
              </span>
              <span class="ml-auto flex items-center" #percWidth
                >{{ (doughnutChartData[item.index] / total) | percent:'1.0-3'
                }}</span
              >
            </span>
          </span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<ng-template #megaFeedTooltip let-data="data">
  <div
    class="flex flex-col bg-white shadow-md border border-solid border-black p-4 md:p-6 min-w-100"
    [ngClass]="{'hidden': !showTooltip}"
  >
    <div [innerHtml]="getTooltipData | tooltipData : data"></div>
  </div>
</ng-template>
