import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SignupService } from '../../services/signup/signup.service';

@Injectable({
  providedIn: 'root',
})
export class LandingGuard implements CanActivate {
  constructor(private _signupService: SignupService, private _router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve) => {
      // this will redirect them if they are signed in
      const signedIn = await this._signupService.checkIfSignedIn();
      const routeParams = ['../knowledge-hub'];
      const additionalParams = state?.url
        ?.replace('/landing', '')
        ?.split('?')?.[0]
        ?.split('/')
        ?.filter((value) => value);
      if (additionalParams?.toString()) routeParams.push(...additionalParams);

      if (signedIn)
        this._router.navigate(routeParams, {
          queryParams: route.queryParams,
        });
      resolve(true);
    });
  }
}
