import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-photo-preview-modal',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss'],
})
export class PhotoPreviewModalComponent {
  downloadURL: string;
  latitude: string;
  longitude: string;
  id: string;
  companyId: string;
  componentType: string;
  componentSize: string;
  description: string;
  ppm: number;

  constructor(
    private dialogRef: MatDialogRef<PhotoPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.id = data.id;
    this.companyId = data.companyId;
    this.downloadURL = data.downloadURL;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.componentSize = data.componentSize;
    this.componentType = data.componentType;
    this.description = data.description;
    this.ppm = data.ppm;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
