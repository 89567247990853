import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';
import { SignupService } from '../../services/signup/signup.service';

@Component({
  selector: 'app-sign-up-dialog',
  templateUrl: './sign-up-dialog.component.html',
  styleUrls: ['./sign-up-dialog.component.scss'],
})
export class SignUpDialogComponent implements OnInit {
  signupForm: FormGroup;
  loading = false;
  step: number = 0;
  get url() {
    const regex = /https?:\/\/[^\/]+(\/.*)/;
    const url = window.location.href.match(regex)?.[1] || '';
    return url;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      signInUser?: boolean;
      email?: string;
      redirect?: string;
      screen: string;
      reload?: boolean;
      reason?: string;
    },
    public dialogRef: MatDialogRef<SignUpDialogComponent>,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private auth: AuthenticationWebService,
    private _signupService: SignupService,
  ) {
    if (!this.data)
      this.data = {
        screen: 'signup',
      };
    if (!this.data?.screen) this.data.screen = 'signup';
    // Create the form
    this.signupForm = new FormGroup({
      email: new FormControl(this.data?.email ? this?.data?.email : '', [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  ngOnInit(): void {}

  async signupUser() {
    try {
      this.loading = true;
      if (this.step === 0) {
        const email = this.signupForm.get('email')?.value;
        await this._signupService.generateCode(email, this.data.screen);
        // the code is sent
        this._snackBar.open('Code sent, please check your email', 'close', {
          duration: 3000,
        });
        this.step = 1;
        const formGroupInput: {
          email: FormControl;
          code: FormControl;
          displayName?: FormControl;
          password?: FormControl;
        } = {
          email: new FormControl(email, [
            Validators.required,
            Validators.email,
          ]),
          code: new FormControl('', [Validators.required]),
          displayName: new FormControl('', [Validators.required]),
          password: new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(30),
            Validators.pattern(
              new RegExp(
                '(?=.*[~`!@#$%^&*()_?{}<,.>+=])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9~`!@#$%^&*()_?{}<,.>+=]{1,}',
              ),
            ),
          ]),
        };
        if (this.data.screen !== 'wec') {
          delete formGroupInput.displayName;
          delete formGroupInput.password;
        }
        this.signupForm = new FormGroup(formGroupInput);
        this.signupForm.controls.email.disable();
      } else if (this.step === 1) {
        const email = this.signupForm.get('email')?.value;
        const code = this.signupForm.get('code')?.value;
        const displayName = this.signupForm.get('displayName')?.value;
        const password = this.signupForm.get('password')?.value;

        const { userPassword } = await this._signupService.submitCode(
          email,
          code,
          displayName,
          password,
          this.data.screen,
        );
        if (this.data.signInUser) {
          this._snackBar.open('User created, logging you in...', undefined, {
            duration: 3000,
          });
          await this.auth.signIn(email, password || userPassword, true);
          if (this.data.redirect)
            this._router.navigateByUrl(this.data.redirect, {});
          else this._router.navigate(['/']);
          if (this.data.reload) window.location.reload();
        } else {
          if (this.data.redirect)
            this._router.navigateByUrl(this.data.redirect);
          if (this.data.reload) window.location.reload();
          this._snackBar.open('User created, please login', 'close', {
            duration: 3000,
          });
        }
        this.dialogRef.close(true);
      }
    } catch (err) {
      if (
        err?.error?.message ===
        'The requesting user already exists in our system.'
      ) {
        this._router.navigateByUrl(
          `/login?redirectUrl=${this.url}&email=${
            this.signupForm.get('email')?.value
          }`,
        );
        this.dialogRef.close(true);
        return;
      }
      throw err;
    } finally {
      this.loading = false;
    }
  }
}
