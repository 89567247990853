import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-facility-action-column',
  templateUrl: './facility-action-column.component.html',
  styleUrls: ['./facility-action-column.component.scss'],
})
export class FacilityActionColumnComponent implements AgRendererComponent {
  public allowDelete = true;
  private params;
  private gridApi: GridApi;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.gridApi = params.api;
    this.allowDelete = !params.data.alreadySaved;
  }

  refresh(params) {
    this.allowDelete = !params.value;
    return true;
  }

  public deleteRow() {
    this.gridApi.applyTransaction({ remove: [this.params.node.data] });
    this.gridApi.refreshCells();
  }
}
