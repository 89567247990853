import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-formula-tooltip',
  templateUrl: './formula-tooltip.component.html',
  styleUrls: ['./formula-tooltip.component.scss'],
})
export class FormulaTooltipComponent implements OnInit {
  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input() text: string;

  /**
   * This provides finer control on the content to be visible on the tooltip
   * This template will be injected in ToolTipRenderer directive in the consumer template
   * <ng-template #template>
   *  content.....
   * </ng-template>
   */
  @Input() contentTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
