import { Component, Input } from '@angular/core';
import { BasicDialogComponent } from '../basic-dialog/basic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EmissionPipe } from '../../pipes/emission.pipe';

@Component({
  selector: 'app-tooltip-icon',
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss'],
})
export class TooltipIconComponent {
  @Input() tooltip: string;
  @Input() onlyPopup: boolean;

  constructor(
    private _dialog: MatDialog,
    private _emissionPipe: EmissionPipe,
  ) {}

  get tooltipText() {
    if (this.tooltip?.length > 200)
      return this._emissionPipe
        .transform(this.tooltip.substring(0, 200) + '...')
        ?.toString();
    return this._emissionPipe.transform(this.tooltip)?.toString();
  }

  openText() {
    this._dialog.open(BasicDialogComponent, {
      data: {
        text: this._emissionPipe.transform(this.tooltip)?.toString(),
      },
    });
  }
}
