import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrackFocus]',
})
export class TrackFocusDirective {
  @HostBinding('class.my-focused-element') isFocused: boolean;

  @HostListener('focus', ['$event']) onFocus(e) {
    this.isFocused = true;
  }
  @HostListener('blur', ['$event']) onblur(e) {
    this.isFocused = false;
  }
}
