import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  text: string | string[];
  icon?: string;
  button1?: string;
  button1ReturnValue?: any;
  button2?: string;
  button2ReturnValue?: any;
}

@Component({
  selector: 'app-basic-dialog',
  templateUrl: 'basic-dialog.component.html',
})
export class BasicDialogComponent {
  isArray: boolean;
  constructor(
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    if (!Array.isArray(this.data?.text) && this.data?.text?.includes('\n'))
      this.data.text = this.data.text.split('\n');
    if (Array.isArray(this.data?.text)) this.isArray = true;
  }

  onButton1Click(): void {
    this.dialogRef.close(this.data.button1ReturnValue);
  }

  onButton2Click(): void {
    this.dialogRef.close(this.data.button2ReturnValue);
  }
}

/*
 // Example code shows how to use BasicDialogComponent in your component.
 // Note that buttons are optional.

 import { BasicDialogComponent, DialogData } from './basic-dialog.component';

 ...snip...

  openDialog(): void {
    const dialogRef = this.dialog.open(BasicDialogComponent, {
      width: '250px',
      data: {
        title: 'My Dialog Title',
        text: 'My Dialog Text',
        icon: 'info',
        button1: 'No Thanks',
        button1ReturnValue: 'Button 1 was clicked',
        button2: 'Ok',
        button2ReturnValue: 'Button 2 was clicked'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed. Return value:', result);
    });
  }
*/
