<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <p *ngIf="data.message">{{data.message}}</p>
    <ng-container *ngIf="data.messages">
      <p *ngFor="let message of data.messages">{{message}}</p>
    </ng-container>
  </div>
  <div mat-dialog-actions class="b-t dialog-actions">
    <button mat-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon> Close
    </button>
  </div>
</div>
