import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-grid-component',
  templateUrl: './loading-grid-component.component.html',
  styleUrls: ['./loading-grid-component.component.scss']
})
export class LoadingGridComponentComponent {
  private params: any;

  public agInit(params): void {
    this.params = params;
  }
}
