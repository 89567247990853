import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererParams } from 'ag-grid-community';
import { AreYouSureComponent } from '../../are-you-sure/are-you-sure.component';

@Component({
  selector: 'app-delete-column',
  templateUrl: './delete-column.component.html',
  styleUrls: ['./delete-column.component.scss'],
})
export class DeleteColumnComponent {
  public allowDelete = true;
  public params;

  constructor(private dialog: MatDialog) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.allowDelete = !params.data.alreadySaved;
  }

  refresh(params) {
    this.allowDelete = !params.value;
    return true;
  }

  public deleteRow(event) {
    event?.stopPropagation();
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        message: 'Are you sure you want to remove this record?',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((closed) => {
      if (closed) {
        this.params.setValue(true);
      }
    });
  }
}
