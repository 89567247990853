import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-status-dialog',
  templateUrl: './progress-status-dialog.component.html',
  styleUrls: ['./progress-status-dialog.component.scss'],
})
export class ProgressStatusDialogComponent {
  message: string;
  progress: number;
  displayTime: string;
  intervalId: NodeJS.Timeout;
  startTime: number;

  action = 'saving'; // Default action

  constructor(
    public dialogRef: MatDialogRef<ProgressStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.action = data?.action || this.action;
    this.startTime = data.startTime || Date.now();

    this.intervalId = setInterval(() => {
      this.displayTime = this.getDisplayTime();
    }, 500);
  }

  getDisplayTime() {
    const delta = (Number(new Date()) - this.startTime) / 1000;
    let minutes = Math.floor(delta / 60).toString();
    if (minutes.length === 1) minutes = '0' + minutes;
    let seconds = (Math.floor(delta) % 60).toString();
    if (seconds.length === 1) seconds = '0' + seconds;
    return minutes + ':' + seconds;
  }

  changeMessageProgress(message: string, progress: number) {
    this.message = message;
    this.progress = progress;
  }

  changeProgress(progress: number) {
    this.progress = progress;
  }

  changeMessage(message: string) {
    this.message = message;
  }
}
