<div class="flex flex-col h-full {{extraClasses.join(' ')}}">
  <div class="flex justify-center mb-3">
    <canvas
      baseChart
      id="line-chart"
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [colors]="lineChartColors"
      [legend]="lineChartLegend"
      chartType="line"
      [plugins]="lineChartPlugins"
    >
    </canvas>
  </div>
</div>
