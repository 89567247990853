import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SheetValidationError } from '@iconic-air-monorepo/models';

import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-validation-report-dialog',
  templateUrl: './validation-report-dialog.component.html',
  styleUrls: ['./validation-report-dialog.component.scss'],
})
export class ValidationReportDialogComponent implements OnInit {
  public validationErrors: SheetValidationError[];
  // Sheet named "January" has a "Reporting Period" in cell B2 of "2", but was expected to have "1".

  @Input() nonModal: boolean;
  @Input() nonModalData;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar,
  ) {
    this.validationErrors = data.errorLog || [];
  }

  ngOnInit() {
    if (this.nonModal) this.validationErrors = this.nonModalData;
  }

  // TODO: Once Material Clipboard supports html text copying switch back (execCommand is depricated).
  public copyToClipboard(value: HTMLElement): void {
    const str: string = value.innerHTML || '';
    function listener(e) {
      e.clipboardData.setData('text/html', str);
      e.clipboardData.setData('text/plain', str);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    this.openSnackBar('Copied Validation Results', 'Close', {
      duration: 3500,
    });
  }

  getErrorMessage(validationError: SheetValidationError) {
    if (
      !validationError.label &&
      !validationError.currentValue &&
      !validationError.currentValueCell &&
      (validationError.expectedValue || validationError.error)
    ) {
      return validationError.expectedValue || validationError.error;
    } else if (validationError.label === 'duplicates') {
      return `Duplicate data was identified in the ingest template at ${validationError.currentValueCell}. Please verifty that this data does not already exist.`;
    } else if (validationError.label === 'invalid facility') {
      return `An invalid location name was identified in the ingest template at ${validationError.currentValueCell} for location ${validationError.currentValue}. Please verify this facility exists.`;
    } else
      return `The "${validationError.sheetName}" sheet has a "${validationError.label}" (${validationError.currentValueCell}) value of [${validationError.currentValue}]. ${validationError.expectedValue}`;
  }

  openSnackBar(message: string, action: string, duration: any) {
    this._snackBar.open(message, action, duration);
  }
}
