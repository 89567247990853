import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../theme/material.module';
import { UploadDialogComponent } from './upload-dialog.component';
import { PipesModule } from '../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { ValidationReportDialogModule } from '../validation-report-dialog/validation-report-dialog.module';

@NgModule({
  declarations: [UploadDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    ValidationReportDialogModule,
  ],
  exports: [UploadDialogComponent],
})
export class UploadDialogModule {}
