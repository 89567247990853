import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Facility, GasSample } from '@iconic-air/models';
import { GasSampleComponent } from '../../../../components/gas-sample/gas-sample.component';
import { PermissionsWebGuard } from '../../../../guards/permissions/permissions-web-guard.guard';

@Component({
  selector: 'app-location-gas-samples',
  templateUrl: './location-gas-samples.component.html',
  styleUrls: ['./location-gas-samples.component.scss'],
})
export class LocationGasSamplesComponent {
  isAdmin = false;
  gasSampleColumns: string[] = [
    'name',
    'sampleDate',
    'effectiveDate',
    'co2',
    'ch4',
    'edit',
  ];
  @Input() facility: Facility;
  loading = false;
  constructor(private perms: PermissionsWebGuard, private dialog: MatDialog) {
    this.isAdmin = this.perms.userData.isAdmin;
  }

  addGasSample(
    event,
    isNewSample: boolean,
    sample?: GasSample,
    index?: number,
  ) {
    event.stopPropagation();
    const data = sample ? sample : null;

    const gasSampleDialogRef = this.dialog.open(GasSampleComponent, {
      data: {
        gasSample: data,
        reportingFacilityId: this.facility.reportingFacilityId,
        facilityId: this.facility.id,
        isNewSample: isNewSample,
      },
    });

    gasSampleDialogRef
      .afterClosed()
      .subscribe((data: { gasSample: GasSample; isNewSample: boolean }) => {
        if (!data) return;
        // not every facility has a gasSample so initialize the empty array here.
        if (!this.facility.gasSamples) {
          this.facility.gasSamples = [];
        }

        if (isNewSample) {
          this.facility.gasSamples.push(data.gasSample);
        } else if (index !== undefined && index > -1)
          this.facility.gasSamples[index] = data.gasSample;

        // we need to make a new array to trigger change detection
        this.facility.gasSamples = [...this.facility.gasSamples]?.sort(
          (a, b) => {
            return (
              Number(new Date(b.sampleDate)) - Number(new Date(a.sampleDate))
            );
          },
        );
      });
  }
}
