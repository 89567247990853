import { DatePipe, Location, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, ValueSetterParams } from 'ag-grid-community';
import { saveAs } from 'file-saver';
import { firstValueFrom, lastValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import * as moment from 'moment';
import { StaticDataService } from '../../services/static-data/static.data.service';
import { ActionColumnComponent } from './action-column/action-column.component';
import { LoadingGridComponentComponent } from '../loading-grid-component/loading-grid-component.component';
import { UploadErrorCompletedComponent } from '../upload-error-completed/upload-error-completed.component';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import {
  SheetValidationError,
  BulkUpload,
  BulkUploadColumn,
  SubBasin,
} from '@iconic-air/models';
import { FileValidationDialogComponent } from './file-validation-dialog/file-validation-dialog.component';
import { isNumber, kebabCase, startCase } from 'lodash';
import { CustomerDataService } from '../../services/customer-data/customer-data.service';
import {
  checkApi14Number,
  checkApiNumber,
  checkBoolean,
  checkDate,
  checkDoubleSpaces,
  checkEmail,
  checkLatitude,
  checkLongitude,
  checkPhoneNumber,
  checkReportingPeriod,
  checkYear,
} from './file-validation-dialog/validate-data-type';
import { ObservableService } from '../../services/observable-service/observable.service';
import { MapboxService } from '../../services/mapbox/mapbox.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { CheckboxColumnComponent } from './checkbox-column/checkbox-column.component';
import { ExcelService } from '../../services/excel/excel.service';
import { ProgressStatusDialogService } from '../../services/progress-status-dialog/progress-status-dialog.service';
import { ValidationReportDialogService } from '../../services/validation-report-dialog/validation-report-dialog.service';
import { DateService } from '../../services/date/date.service';
import { FacilitiesDatabaseService } from '../../services-database/facilities-service/facilities-database.service';
import { HttpClient } from '@angular/common/http';
import { SaveService } from '../../services/save/save.service';
import { HttpService } from '../../services/http/http.service';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
  providers: [TitleCasePipe, DatePipe],
})
export class BulkUploadComponent implements OnDestroy {
  @ViewChild('agGrid') agGrid!: AgGridAngular;
  @ViewChild('stepper') stepper: MatStepper;
  file;
  loading = true;
  loadingValues = false;
  // save variables
  saving = false;
  savingStartTime: number;
  reportingYearForm: FormGroup;
  reportingYears: string[] = [];
  private _route: string;
  get facilityTypes(): string[] {
    return this._staticData.facilityTypes;
  }

  get standardizedKeys(): string[] {
    return this._staticData.standardizedFacilityKeys;
  }

  get programTypeConfigs() {
    return this._staticData.programTypeConfigs;
  }

  private _binaryStream: any;

  customerCollection: { [key: string]: any[] } = {};
  existingData: any[];

  columnDefs: ColDef[] = [];
  fileForm = new FormGroup({
    fileName: new FormControl('', Validators.required),
  });
  hideUpload = false;
  fileLoading = false;
  fileUploaded = false;
  fileUploadId: string;
  gridOptions: GridOptions = {
    onCellEditingStarted: () => {
      const elements: HTMLElement[] = document.getElementsByClassName(
        'ag-picker-field-wrapper',
      ) as unknown as HTMLElement[];
      if (elements && elements.length) elements[0]?.click();
    },
    getRowStyle: (params: any) => {
      if (params.node.data.alreadySaved) {
        return { background: '#dddddd' };
      }
    },
    getRowNodeId: (data) => {
      return data.componentId;
    },
    loadingOverlayComponentFramework: LoadingGridComponentComponent,
    floatingFilter: true,
    animateRows: true,
    defaultColDef: {
      filter: 'text',
      sortable: true,
      resizable: true,
    },
    frameworkComponents: {
      actionColumn: ActionColumnComponent,
      checkboxRenderer: CheckboxColumnComponent,
    },
  };
  valuesToSet: { [key: string]: string } = {};
  isAdminPage = false;
  labels: string[] = [];
  overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 2px solid #444;">No Data</span>';

  bulkUploadValues: BulkUpload;

  massEdit: boolean;

  // Subjects
  private unsubscribeSubject = new Subject();
  private csvData: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private perms: PermissionsWebGuard,
    private router: Router,
    private customerData: CustomerDataService,
    private observableService: ObservableService,
    private mapboxService: MapboxService,
    private storage: AngularFireStorage,
    private _location: Location,
    private _excelService: ExcelService,
    private _progressStatusDialog: ProgressStatusDialogService,
    private _staticData: StaticDataService,
    private _validationReportingDialog: ValidationReportDialogService,
    private _dateService: DateService,
    private _facilitiesDatabaseService: FacilitiesDatabaseService,
    private _http: HttpClient,
    private _httpService: HttpService,
    private _saveService: SaveService,
  ) {
    this.fileUploadId = this.afs.createId();
    this.reportingYearForm = new FormGroup({
      reportingYear: new FormControl('', Validators.required),
    });
    this.activatedRoute.parent?.url?.subscribe((parentUrl) => {
      let route: string = parentUrl.map((urlPart) => urlPart.path).join('/');
      this.activatedRoute.url.subscribe((url) => {
        // add our current spot to get the full route
        route += '/' + url.map((urlPart) => urlPart.path).join('/');
        this._route = route;
        this.getBulkUpload(route);
      });
    });

    this.observableService
      .subscribe('bulkUpload-deleteRow')
      .subscribe((value) => {
        if (value) {
          // find the index of what to delete
          const data: any[] = [];
          this.agGrid.api.forEachNode((node: any) => {
            data.push(node.data);
          });
          const index = data.indexOf(value);
          if (index > -1) {
            data.splice(index, 1);
            this.csvData = data;
            this.loadGrid();
          }
        }
      });

    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(({ isAdminPage }) => {
        this.isAdminPage = isAdminPage;
      });
    setTimeout(async () => {
      this.reportingYears = await this._getReportingYears();

      const latestReportingYear =
        this.reportingYears[this.reportingYears.length - 1];

      this.reportingYearForm.patchValue({ reportingYear: latestReportingYear });
    });
  }

  private async _getReportingYears(): Promise<string[]> {
    const newReportingYears: string[] = [];

    const reportingPeriodDocs = await lastValueFrom(
      this.afs
        .collection('customers')
        .doc(this.perms.activeCustomerId)
        .collection('reportingPeriods')
        .get(),
    );

    reportingPeriodDocs.forEach((year) => {
      newReportingYears.push(year.id?.toString());
    });

    return newReportingYears;
  }

  private getBulkUpload(route: string) {
    this.loading = true;
    try {
      const bulkUpload: { [key: string]: BulkUpload } = {
        ...this._staticData.bulkUpload,
      };
      Object.keys(bulkUpload).forEach((bulkUploadKey) => {
        const currentBulkUpload = JSON.parse(
          JSON.stringify(bulkUpload[bulkUploadKey]),
        );
        if (currentBulkUpload?.route?.indexOf(route) > -1) {
          this.bulkUploadValues = currentBulkUpload;
          this.processBulkUpload();
        } else if (Array.isArray(currentBulkUpload?.route)) {
          // loop through the routes and see if it finds it.
          currentBulkUpload?.route?.forEach((currentRoute) => {
            if (currentRoute.indexOf(route) > -1) {
              this.bulkUploadValues = currentBulkUpload;
              this.bulkUploadValues.route = currentRoute;
              this.processBulkUpload();
            }
          });
        }
      });
    } finally {
      this.loading = false;
    }
  }

  private processBulkUpload() {
    this.bulkUploadValues?.columns?.sort((a, b) => {
      if (a.required && !b.required) return -1;
      if (!a.required && b.required) return 1;
      return 0;
    });
    // load all the options.
    this.bulkUploadValues?.columns?.forEach((column) => {
      this.optionData(column);
    });
    this.bulkUploadValues?.valuesToCopy?.forEach((copyValue) => {
      this.optionData(copyValue as any);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.complete();
  }

  private getRandomColor() {
    const letters = 'BCDEF'.split('');
    let color = '';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  async downloadExample(downloadValues?: boolean) {
    if (this.loadingValues) return;
    // we need to get the current values
    const workbook = new ExcelJS.Workbook();
    workbook.creator = this.perms.userData?.email
      ? this.perms.userData?.email
      : 'Unknown';
    const templateTab: any = workbook.addWorksheet(
      this.bulkUploadValues.downloadName,
    );
    const optionsTab = workbook.addWorksheet('options-ignore');
    optionsTab.state = 'veryHidden'; // make it impossible to find
    let currentOptionColumn = 1;
    const headerColumns = [
      { header: 'legend', key: 'legend', width: 12, note: null },
    ] as any;
    const colorMapping: {
      [key: string]: { color: string; legendText: string };
    } = { legend: { color: 'ff5c59', legendText: 'Required Field' } };
    let xLock = 1;
    if (downloadValues) {
      xLock++;
      headerColumns.push({
        header: 'id',
        key: 'id',
        width: 20,
        note: 'Record unique identifier',
      });
    }
    const optionColumns = {};
    this.bulkUploadValues.columns.forEach((column) => {
      if (column.required) xLock++;
      if (column.options) optionColumns[column.key] = column;
      // create a random color for things that have a validator
      if (column.required && column?.validator?.key) {
        colorMapping[column.key] = {
          color: this.getRandomColor(),
          legendText: `required if: ${
            column.validator.key
          } is in this list: ${column.validator.values?.toString()}`,
        };
        headerColumns[0].width = 50;
      }
      headerColumns.push({
        header: column.description ? column.description : column.key,
        type: column.type,
        key: column.key,
        width: 20,
        note: column.note,
      });
    });

    templateTab.columns = headerColumns;
    templateTab.autoFilter = this._excelService.getAutoFilter(templateTab);

    let values;
    if (downloadValues) {
      this.loadingValues = true;
      values = await this._getData();
      this.loadingValues = false;
      if (!values?.length)
        throw 'No existing data, please download the example sheet and add data.';
    }
    const docs = values?.map((doc) => {
      const returnedDoc: any = {};
      headerColumns.forEach((column) => {
        if (doc[column.key] || doc[column.key] == 0) {
          if (
            optionColumns[column.key] &&
            (optionColumns[column.key]?.options?.option?.startsWith(
              'backend.',
            ) ||
              optionColumns[column.key]?.options?.option?.startsWith(
                'customerCollection.',
              ) ||
              optionColumns[column.key]?.options?.option?.startsWith(
                'staticCollection.',
              )) &&
            optionColumns[column.key]?.options?.value
          ) {
            const collection = optionColumns[column.key]?.options?.option
              ?.replace('customerCollection.', '')
              ?.replace('backend.', '')
              ?.replace('staticCollection.', '');
            let key = column.key;
            optionColumns[column.key]?.options?.additionalSaves?.forEach(
              (additionalSave) => {
                if (
                  additionalSave?.overrideValue &&
                  additionalSave?.key === column.key
                )
                  key = additionalSave?.value;
              },
            );
            let foundValue;
            if (
              optionColumns[column.key]?.options?.option?.startsWith(
                'customerCollection.',
              ) ||
              optionColumns[column.key]?.options?.option?.startsWith('backend.')
            )
              foundValue = this.customerCollection[collection]?.find(
                (row) =>
                  row[key]?.toString()?.toLowerCase() ===
                  doc[column.key]?.toString()?.toLowerCase(),
              );
            if (
              optionColumns[column.key]?.options?.option?.startsWith(
                'staticCollection.',
              )
            )
              foundValue = this._staticData[collection]?.find(
                (row) =>
                  row[key]?.toString()?.toLowerCase() ===
                  doc[column.key]?.toString()?.toLowerCase(),
              );

            // see if it is a valid option, if not delete it
            if (
              foundValue &&
              foundValue[optionColumns[column.key]?.options?.value]
            )
              doc[column.key] =
                foundValue[optionColumns[column.key]?.options?.value];
            else if (optionColumns[column.key]?.type !== 'api14Number')
              delete doc[column.key];
          }
          returnedDoc[column.key] = doc[column.key];
          if (Array.isArray(returnedDoc[column.key]))
            returnedDoc[column.key] = returnedDoc[column.key]?.toString();
          if (isNumber(returnedDoc[column.key]) && column.type === 'date') {
            const date = new Date(returnedDoc[column.key]);
            returnedDoc[column.key] = `${
              date?.getUTCMonth() + 1
            }/${date?.getUTCDate()}/${date?.getUTCFullYear()}`;
          }
        }
        if (column.key === 'overrideOgiFrequency') {
          if (doc?.locationSurveyConfig?.ogi?.overrideFrequency) {
            returnedDoc[column.key] = doc?.locationSurveyConfig?.ogi?.frequency;
          }
        }
        if (column.key === 'overrideAvoFrequency') {
          if (doc?.locationSurveyConfig?.avo?.overrideFrequency) {
            returnedDoc[column.key] = doc?.locationSurveyConfig?.avo?.frequency;
          }
        }

        if (column.key === 'avoProgramTypes') {
          if (doc?.locationSurveyConfig?.avo?.programTypes)
            returnedDoc[column.key] =
              doc?.locationSurveyConfig?.avo?.programTypes?.toString();
        }

        if (column.key === 'ogiProgramTypes') {
          if (doc?.locationSurveyConfig?.ogi?.programTypes)
            returnedDoc[column.key] =
              doc?.locationSurveyConfig?.ogi?.programTypes?.toString();
        }
      });
      return returnedDoc;
    });

    let index = 0;
    Object.keys(colorMapping).forEach((key) => {
      let currentRow = { legend: colorMapping[key].legendText };
      if (docs?.length && index < docs?.length) {
        currentRow = { ...docs[index], legend: colorMapping[key].legendText };
        index++;
      }
      templateTab.addRow(currentRow);
    });

    for (let i = index; i < docs?.length; i++) {
      templateTab.addRow(docs[i]);
    }
    if (!downloadValues) {
      for (let i = index; i < 500; i++) {
        templateTab.addRow({});
      }
    }

    templateTab.columns.forEach((column, i) => {
      const foundColumn = this.bulkUploadValues.columns.find(
        (currentColumn) => currentColumn.key === column._key,
      );
      let addColumn = false;
      const headerColumn = headerColumns.find((col) => col.key === column._key);
      // loop through the cells and add notes and validations
      column.eachCell(
        { includeEmpty: true },
        (cell: ExcelJS.Cell, rowNumber) => {
          if (rowNumber == 1)
            cell.dataValidation = this._excelService.getUneditableValidation(
              headerColumn?.note,
            );
          else {
            if (optionColumns && optionColumns[headerColumn?.key]) {
              if (foundColumn?.multiple) {
                addColumn = true;
                cell.dataValidation = this._excelService.getListValidation(
                  this.optionData(optionColumns[headerColumn.key], true),
                  currentOptionColumn,
                  optionsTab,
                  headerColumn.note,
                  !foundColumn?.required,
                  true,
                );
              } else {
                addColumn = true;
                cell.dataValidation = this._excelService.getListValidation(
                  this.optionData(optionColumns[headerColumn.key], true),
                  currentOptionColumn,
                  optionsTab,
                  headerColumn.note,
                  !foundColumn?.required,
                );
              }
            } else {
              // default values if not a special type
              cell.dataValidation = this._excelService.getBasicValidation(
                headerColumn?.note,
              );
              if (foundColumn?.type === 'date')
                cell.dataValidation = this._excelService.getDateValidation(
                  headerColumn?.note,
                  !foundColumn?.required,
                );
              else if (foundColumn?.type === 'number') {
                cell.dataValidation = this._excelService.getNumberValidation(
                  headerColumn?.note,
                  foundColumn?.min,
                  foundColumn?.max,
                  !foundColumn?.required,
                );
              }
            }
            if (column._key === 'id')
              cell.dataValidation = this._excelService.getUneditableValidation(
                headerColumn?.note,
              );
          }
        },
      );
      if (addColumn) currentOptionColumn++;
      if (
        (foundColumn &&
          (foundColumn.required || colorMapping[foundColumn.key])) ||
        column._key === 'id'
      ) {
        column.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
          if (rowNumber == 1) {
            cell.fill = this._excelService.getSolidFill(
              colorMapping[foundColumn?.key as string]?.color
                ? colorMapping[foundColumn?.key as string]?.color
                : 'ff5c59',
            );
            cell.font = { bold: true };
          }
        });
      } else if (column._key === 'legend') {
        column.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
          if (rowNumber == 1)
            cell.fill = this._excelService.getSolidFill('777fa6');
          else if (rowNumber == 2) {
            cell.fill = this._excelService.getSolidFill('ff5c59');
            cell.font = { bold: true };
          } else if (rowNumber < 2 + Object.keys(colorMapping).length)
            cell.fill = this._excelService.getSolidFill(
              colorMapping[Object.keys(colorMapping)[rowNumber - 2]].color,
            );
        });
      }
    });

    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(
      blob,
      `Iconic Air ${this.bulkUploadValues?.downloadName} Ingest Template.xlsx`,
      fileExtension,
    );
  }

  private getEditable(params): boolean {
    return !params.node.data.alreadySaved;
  }

  getRowData(): any[] {
    const newData: any[] = [];
    if (this.csvData?.length) {
      let currentRow = 1;
      this.csvData.forEach((row) => {
        const newObject = {
          rowNumberForViewing: currentRow,
        };
        (newObject[this.bulkUploadValues.idKey] = row[
          this.bulkUploadValues.idKey
        ]
          ? row[this.bulkUploadValues.idKey]
          : row.id
          ? row.id
          : this.afs.createId()),
          currentRow++;

        for (const key of Object.keys(row)) {
          // on a delete we don't want to override the row number
          // also don't want to bring over the legend values
          if (['rowNumberForViewing', 'legend'].indexOf(key) === -1) {
            newObject[key] = row[key];
            const foundColumn = this.bulkUploadValues.columns.find((column) => {
              const columnKey = column.description
                ? column.description
                : column.key;
              return columnKey === key;
            });
            if (foundColumn?.type === 'date') {
              if (Number(row[key])) row[key] = Number(row[key]);
              const excelDate = row[key];
              if (isNumber(row[key])) {
                // we need to convert from a number to a date
                const unixTimestamp = excelDate * 86400000 - 2209075200000;
                const date = moment(new Date(unixTimestamp));
                // date.add(date.utcOffset() * -1, 'minute');
                const dateWithNewFormat = date.format('MM/DD/YYYY');
                newObject[key] = dateWithNewFormat;
              }
            }
            if (foundColumn && foundColumn.options && row[key]) {
              const foundValue = this.optionData(foundColumn, true).find(
                (data) =>
                  data?.toString()?.toLowerCase() ===
                  row[key]?.toString()?.toLowerCase(),
              );
              if (foundValue) newObject[key] = foundValue;
            }
          }
        }
        newData.push(newObject);
      });
    }

    return newData;
  }

  loadGrid = (): void => {
    // Set grid
    this.agGrid.api.setRowData([]);
    this.agGrid.api.setRowData(this.getRowData());
  };

  navigateBack = (): void => {
    const parentRoute = this._route?.toString()?.split('/');
    parentRoute.pop();
    if (window.history.length > 1) this._location.back();
    else this.router.navigate([parentRoute.join('/')]);
  };

  async uploadFile(event: any) {
    const errorLog = await this.validateFileUpload(event);
    if (errorLog === undefined) {
      this.onFileClear(event);
      throw 'Upload cancelled. Please try again.';
    } else if (errorLog === null || errorLog.length === 0) {
      this.onFileChange(event);
    } else if (errorLog?.length > 0) {
      this.onFileClear(event);
      this._validationReportingDialog.open(errorLog);
    }
    return;
  }

  validateFileUpload = async (
    event: any,
  ): Promise<SheetValidationError[] | null | undefined> => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      file: event.target.files[0],
      columns: this.bulkUploadValues.columns,
      collection: this.bulkUploadValues.collection,
      reportingYear: this.reportingYearForm?.controls?.reportingYear?.value,
      massEdit: this.massEdit,
      api: this.bulkUploadValues.api,
      apiGetRoute: this.bulkUploadValues.apiGetRoute,
      apiPaginate: this.bulkUploadValues.apiPaginate,
      apiPaginateCount: this.bulkUploadValues.apiPaginateCount,
      apiGetQueryParams: this.bulkUploadValues.apiGetQueryParams,
      yearInPath: this.bulkUploadValues.yearInPath,
    };

    dialogConfig.disableClose = false;

    const dialogClosed$: Observable<any> = this.dialog
      .open(FileValidationDialogComponent, dialogConfig)
      .afterClosed();

    const errorLog: SheetValidationError[] = await firstValueFrom(
      dialogClosed$,
    );
    return errorLog;
  };

  onFileChange(event: any): void {
    const target: DataTransfer = <DataTransfer>event.target;
    this.fileForm.get('fileName')?.setValue(target.files[0].name);
    this.file = target.files[0];
    this.fileLoading = true;

    // Read file
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (event: any) => {
      // Create workbook
      this._binaryStream = event.target.result;
      const workBook: XLSX.WorkBook = XLSX.read(this._binaryStream, {
        type: 'binary',
      });

      // Select first sheet
      const workSheetName: string = workBook.SheetNames[0];
      const workSheet: XLSX.WorkSheet = workBook.Sheets[workSheetName];
      let excelOptions = {};
      if (this.massEdit) excelOptions = { defval: '' };
      const data: any[] = XLSX.utils.sheet_to_json(workSheet, excelOptions);
      // Get unique keys
      const keys: string[] = [];
      let mappedSubBasins;
      let lowerCasedSubBasins;
      this.csvData = [];
      for (const row of data) {
        let emptyRow = true;
        for (const key of Object.keys(row)) {
          if (key === 'legend') continue;
          if (row[key] !== '') emptyRow = false;
          const foundColumn = this.bulkUploadValues.columns.find((column) =>
            column.description ? column.description : column.key === key,
          );
          // make sure they match the casing that is in static
          if (foundColumn?.type === 'subBasin') {
            if (!mappedSubBasins) {
              mappedSubBasins = this.customerData.subBasins.map(
                (subBasin: SubBasin) => subBasin.subBasinFullNameMapped,
              );
              lowerCasedSubBasins = mappedSubBasins.map((name) =>
                name?.toLowerCase(),
              );
            }
            const index = lowerCasedSubBasins.indexOf(row[key]);
            if (index > -1) row[key] = mappedSubBasins[index];
          } else if (foundColumn?.type === 'boolean') {
            if (['no', 'false'].includes(row[key]?.toString()?.toLowerCase()))
              row[key] = false;
            else if (
              ['yes', 'true'].includes(row[key]?.toString()?.toLowerCase())
            )
              row[key] = true;
          }
          // Only add unique keys
          if (keys.indexOf(key) === -1) {
            keys.push(key);
          }
        }
        if (!emptyRow) this.csvData.push(row);
      }
      // Build labels
      this.labels = keys;
      this.loadGrid();
      this.fileUploaded = true;

      this.fileLoading = false;
    };
  }

  onFileClear(event): void {
    this.fileUploaded = false;
    // this.stepper.reset();
    if (event?.target) event.target.value = null;
  }

  async validateData(data, column: BulkUploadColumn, uniqueValues) {
    const messages: string[] = [];
    const key = column.key;
    if (column?.required) {
      let errored = true;

      if (column?.validator?.key && column?.validator?.values?.length) {
        const requiredKeyValues = column.validator.values.map((value) => {
          let returnValue = value;
          if (typeof returnValue === 'string' && returnValue?.toLowerCase())
            returnValue = returnValue?.toLowerCase();
          return returnValue;
        });
        if (column?.validator?.includes) {
          for (const value of requiredKeyValues) {
            if (!data[column.validator.key]?.toString().includes(value)) {
              errored = false;
              break;
            }
          }
        } else if (
          !requiredKeyValues.includes(data[column.validator.key]?.toString())
        ) {
          errored = false;
          if (data[column.key] || data[column.key] === 0) {
            messages.push(
              `${column.key} is only applicable if ${
                column.validator.key
              } has a value in this list ${requiredKeyValues.toString()}`,
            );
          }
        }
      }
      if (
        data[column.key] ||
        data[column.key] === 0 ||
        data[column.key] === false
      )
        errored = false;
      if (errored && !column?.validator?.key)
        messages.push(column.key + ' is required.');
      else if (errored && column?.validator?.key) {
        messages.push(
          `${column.key} is required if ${column.validator.key} is ${
            data[column.validator.key]
          }, please provide a value`,
        );
      }
    }
    switch (column.type) {
      case 'longitude':
        if (!checkLongitude(data[key])) {
          messages.push(`${key} is not a valid longitude`);
        } else if (data[key]) data[key] = Number(data[key]);
        break;
      case 'latitude':
        if (!checkLatitude(data[key])) {
          messages.push(`${key} is not a valid latitude`);
        } else if (data[key]) data[key] = Number(data[key]);
        break;
      case 'email':
        if (!checkEmail(data[key])) {
          messages.push(`${key} is not a valid email`);
        }
        break;
      case 'year':
        if (!checkYear(data[key])) {
          messages.push(`${key} is not a valid year`);
        }
        break;
      case 'phone':
        if (!checkPhoneNumber(data[key])) {
          messages.push(`${key} is not a valid phone number`);
        }
        break;
      case 'date':
        if (!checkDate(data[key])) {
          messages.push(
            `${key} is not a valid date, please use the following format: MM/DD/YYYY. Note: Date can only be in the future 30 years or in the past 50 years.`,
          );
        }
        break;
      case 'boolean':
        if (!checkBoolean(data[key])) {
          messages.push(
            `${key} is not a valid boolean, please use one of the following 'true', 'false', 'yes', 'no'`,
          );
        }
        break;
      case 'apiNumber':
        if (!checkApiNumber(data[key])) {
          messages.push(
            `${key} is not a valid api number, it should be a 10 character number (leeding 0's might be needed) and dashes do not count to the 10 characters but are allowed.`,
          );
        }
        break;
      case 'api14Number':
        if (!checkApi14Number(data[key])) {
          messages.push(
            `${key} is not a valid api number, it should be a 14 character number (leeding 0's might be needed) and dashes do not count to the 14 characters but are allowed.`,
          );
        }
        break;
      case 'reportingPeriod':
        if (!checkReportingPeriod(data[key])) {
          messages.push(
            `${key} is not a valid reporting period, ${data[key]} has to either be a date, year, quarter (Q1, etc) or first or second for semi annual.`,
          );
        }
        break;
      case 'number':
        if (
          (column.min || column?.min?.toString() === '0') &&
          !isNaN(Number(column.min)) &&
          data[key] < Number(column?.min)
        )
          messages.push(
            `${key} is not a valid number, it has to be greater than or equal to ${column.min}.`,
          );
        if (
          (column.max || column?.max?.toString() === '0') &&
          !isNaN(Number(column.max)) &&
          data[key] > Number(column?.max)
        )
          messages.push(
            `${key} is not a valid number, it has to be less than or equal to ${column.max}.`,
          );
        break;
    }
    if (!checkDoubleSpaces(data[key])) {
      messages.push(`${key} cannot have double spaces`);
    }
    if (column.unique) {
      if (!this.existingData) {
        this.existingData = await this._getData();
      }
      const found = this.existingData?.find(
        (currentExistingData) =>
          currentExistingData[column.key] &&
          data[key]?.toString()?.toLowerCase() &&
          currentExistingData[column.key]?.toString()?.toLowerCase() ===
            data[key]?.toString()?.toLowerCase() &&
          data?.id?.toString() !== currentExistingData?.id?.toString(),
      );
      if (found) {
        messages.push(
          `${key} already exists in the database, you cannot have two of the same value`,
        );
      }
      if (!uniqueValues[key]) uniqueValues[key] = [];
      if (
        data[key]?.toString()?.toLowerCase() &&
        uniqueValues[key].indexOf(data[key]?.toString()?.toLowerCase()) > -1
      ) {
        messages.push(
          `${key} is a duplicate of a previous value, you cannot have two of the same value`,
        );
      }
      uniqueValues[key].push(data[key]?.toString()?.toLowerCase());
    }
    return messages.join('. ');
  }

  // will update the uploaded file to have the data the user edited.
  private async _updateExcelSheet(data) {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(this._binaryStream);
    const worksheet = workbook.getWorksheet(1);
    const keys: string[] = [];
    if (!worksheet) {
      throw 'Could not find work sheet.';
    }
    for (let h = 0; h < worksheet.columnCount; h++) {
      const key = worksheet.getCell(1, h + 1).value?.toString() as string;
      keys.push(key);
    }
    for (let i = 1; i < worksheet.rowCount; i++) {
      const row: any[] = [];
      keys.forEach((key) => {
        if (!data[i - 1]) row.push('');
        else row.push(data[i - 1][key]);
      });
      worksheet.getRow(i + 1).values = row;
    }
    const updatedBuffer = await workbook.xlsx.writeBuffer();
    return updatedBuffer;
  }

  getDisplayTime() {
    const delta = (Number(new Date()) - this.savingStartTime) / 1000;
    let minutes = Math.floor(delta / 60).toString();
    if (minutes.length === 1) minutes = '0' + minutes;
    else if (minutes.length === 0) minutes = '00';
    let seconds = (Math.floor(delta) % 60).toString();
    if (seconds.length === 1) seconds = '0' + seconds;
    else if (seconds.length === 0) seconds = '00';
    return minutes + ':' + seconds;
  }

  private async _getData() {
    let values;

    if (this.bulkUploadValues.api) {
      const apiUrl = `${this.bulkUploadValues.apiGetRoute}${
        this.bulkUploadValues.yearInPath
          ? `/${this.reportingYearForm?.controls?.reportingYear?.value}`
          : ''
      }`;

      if (!apiUrl) {
        throw 'Iconic air did not format this url correctly, please reach out to them to fix this.';
      }
      if (this.bulkUploadValues.apiPaginate)
        values = await this._httpService.getAllValues(
          apiUrl,
          this.bulkUploadValues.apiGetQueryParams,
          this.bulkUploadValues.apiPaginateCount,
        );
      else values = await lastValueFrom(this._http.get(apiUrl));
    } else {
      values = (
        await lastValueFrom(
          this.afs
            .collection('customers')
            .doc(this.perms.activeCustomerId)
            .collection(this.bulkUploadValues.collection)
            .get(),
        )
      )?.docs?.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    }

    const dateColumns = this.bulkUploadValues.columns?.filter(
      (column) => column.type === 'date',
    );
    const booleanColumns = this.bulkUploadValues.columns?.filter(
      (column) => column.type === 'boolean',
    );
    const docs = values?.map((docData) => {
      if (this.bulkUploadValues.convertAddress && docData?.address) {
        const address = JSON.parse(JSON.stringify(docData?.address));
        delete docData.address;
        Object.keys(address).forEach((key) => {
          docData[key] = address[key];
        });
      }
      dateColumns?.forEach((column) => {
        if (docData[column.key]) {
          docData[column.key] = this._dateService.convertToDateTime(
            docData[column.key],
          );
        }
      });

      booleanColumns?.forEach((column) => {
        if (docData[column.key]) {
          if (
            ['no', 'false'].includes(
              docData[column.key]?.toString()?.toLowerCase(),
            )
          ) {
            docData[column.key] = false;
          } else if (
            ['yes', 'true'].includes(
              docData[column.key]?.toString()?.toLowerCase(),
            )
          ) {
            docData[column.key] = true;
          }
        }
      });
      return { ...docData };
    });
    return docs;
  }

  convertRawRow(rawRow): { [key: string]: any } {
    const returnValue = JSON.parse(JSON.stringify(rawRow));
    this.bulkUploadValues.columns?.forEach((column) => {
      if (Object.keys(rawRow).includes(column.description)) {
        delete returnValue[column.description];
        returnValue[column.key] = rawRow[column.description];
      }
      if (column.type === 'date' && returnValue[column.key]) {
        returnValue[column.key] = this._dateService.convertToDateTime(
          returnValue[column.key],
        );
      }

      if (column.type === 'boolean' && returnValue[column.key]) {
        if (
          ['no', 'false'].includes(
            returnValue[column.key]?.toString()?.toLowerCase(),
          )
        ) {
          returnValue[column.key] = false;
        } else if (
          ['yes', 'true'].includes(
            returnValue[column.key]?.toString()?.toLowerCase(),
          )
        ) {
          returnValue[column.key] = true;
        }
      }
    });
    return returnValue;
  }

  async save() {
    this.saving = true;
    this.savingStartTime = Number(new Date());
    const progressDialog = this._progressStatusDialog.open(
      'saving',
      'Loading ' + this.bulkUploadValues.downloadName + ' Data',
    );
    // progress will be based on this
    // 5% for loading data
    // 20% for validating
    // 75% for saving
    // make sure to clear focus so that it saves whatever they were editing.
    this.agGrid.api.clearFocusedCell();
    try {
      const rowData: any[] = [];
      const data: any = [];

      const newErrors: string[] = [];
      const uniqueValues: { [key: string]: any[] } = {};
      // add rows
      const totalRows = this.agGrid.api.getDisplayedRowCount();
      progressDialog.changeMessageProgress(
        'Loading ' + this.bulkUploadValues.downloadName + ' Data.',
        0,
      );
      this.agGrid.api.forEachNode((node: any) => {
        data.push(node.data);
      });
      progressDialog.changeMessageProgress(
        'Updating ' + this.bulkUploadValues.downloadName + ' File.',
        2.5,
      );
      const file = await this._updateExcelSheet(data);
      // validate data
      progressDialog.changeMessageProgress(
        'Validating ' + this.bulkUploadValues.downloadName + ' Data',
        5,
      );
      let docs;
      if (this.massEdit) {
        // grab the data
        docs = await this._getData();
      }
      for (let i = 0; i < data.length; i++) {
        const currentRowRaw = data[i];
        if (currentRowRaw.alreadySaved) return;
        // we need to convert the row to have the keys instead of the descriptions
        const currentRow = this.convertRawRow(currentRowRaw);
        const newDoc: {
          [key: string]:
            | number
            | string
            | boolean
            | { address: string; city: string; state: string; zip: string };
        } = {};

        const keys = Object.keys(currentRow);
        for (let h = 0; h < keys.length; h++) {
          const key = keys[h];
          const foundColumn: BulkUploadColumn =
            this.bulkUploadValues.columns.find(
              (column) => column.key === key,
            ) as BulkUploadColumn;
          if (foundColumn) {
            if (
              foundColumn?.multiple &&
              currentRow[key]?.toString()?.includes(',')
            ) {
              currentRow[key] = currentRow[key]
                ?.toString()
                ?.split(',')
                ?.map((value) => value?.trim());
            } else if (
              foundColumn?.multiple &&
              !Array.isArray(currentRow[key]) &&
              currentRow[key]
            ) {
              currentRow[key] = [currentRow[key]?.toString()?.trim()];
            }
            // validate the value
            const errorMessage = await this.validateData(
              currentRow,
              foundColumn,
              uniqueValues,
            );
            if (errorMessage) newErrors.push(`row ${i + 1}: ${errorMessage}`);
            newDoc[key] = currentRow[key];
            if (
              typeof newDoc[key] === 'string' &&
              !foundColumn?.options &&
              ['state', 'subBasin'].indexOf(foundColumn?.type) === -1 &&
              key !== 'id' &&
              key !== 'facilityName'
            )
              newDoc[key] = newDoc[key].toString().toLowerCase().trim();
            if (foundColumn?.duplicateKey?.length) {
              foundColumn.duplicateKey.forEach((duplicateKey) => {
                newDoc[duplicateKey.key] = duplicateKey?.toLowerCase
                  ? currentRow[key]?.toLowerCase()
                  : currentRow[key];
              });
            }
            let foundValue;
            if (
              (foundColumn?.options as any)?.additionalSaves?.length &&
              newDoc[key]
            ) {
              const optionsObject: {
                option: string;
                value: string;
                additionalSaves: any;
              } = foundColumn.options as any;
              const collectionName = optionsObject.option.split('.')[1];
              if (
                (foundColumn?.options as any)?.option?.startsWith('backend.')
              ) {
                // check to see if we have already grabbed it.
                if (!this.customerCollection[collectionName]) {
                  const results: any[] = (await lastValueFrom(
                    this._http.get('/api/' + kebabCase(collectionName)),
                  )) as any[];
                  this.customerCollection[collectionName] = results;
                }

                // now we need to find that specific value
                foundValue = this.customerCollection[collectionName].find(
                  (doc) =>
                    doc[optionsObject?.value]?.toString()?.toLowerCase() ===
                      newDoc[key]?.toString()?.toLowerCase() ||
                    (foundColumn?.type === 'api14Number' &&
                      collectionName === 'wells' &&
                      newDoc[key]
                        ?.toString()
                        ?.toLowerCase()
                        ?.substring(0, 10) ===
                        doc?.wellId
                          ?.toString()
                          ?.toLowerCase()
                          ?.substring(0, 10)),
                );
              }
              if (
                (foundColumn?.options as any)?.option?.startsWith(
                  'customerCollection.',
                )
              ) {
                // check to see if we have already grabbed it.
                if (!this.customerCollection[collectionName]) {
                  const results = await lastValueFrom(
                    this.afs
                      .collection('customers')
                      .doc(this.perms.activeCustomerId)
                      .collection(collectionName)
                      .get(),
                  );
                  this.customerCollection[collectionName] = results.docs.map(
                    (doc) => {
                      const newDoc = { ...doc.data() };
                      newDoc.id = doc.id;
                      return newDoc;
                    },
                  );
                }
                // now we need to find that specific value
                foundValue = this.customerCollection[collectionName].find(
                  (doc) =>
                    doc[optionsObject?.value]?.toString()?.toLowerCase() ===
                      newDoc[key]?.toString()?.toLowerCase() ||
                    (foundColumn?.type === 'api14Number' &&
                      collectionName === 'wells' &&
                      newDoc[key]
                        ?.toString()
                        ?.toLowerCase()
                        ?.substring(0, 10) ===
                        doc?.wellId
                          ?.toString()
                          ?.toLowerCase()
                          ?.substring(0, 10)),
                );
              }
              if (
                (foundColumn?.options as any)?.option?.startsWith(
                  'staticCollection.',
                )
              )
                foundValue = this._staticData[collectionName]?.find(
                  (row) =>
                    row[optionsObject?.value]?.toString()?.toLowerCase() ===
                    newDoc[key]?.toString()?.toLowerCase(),
                );
              for (let j = 0; j < optionsObject.additionalSaves.length; j++) {
                const additionalSave = optionsObject.additionalSaves[j];
                // do not override value user supplied (i.e lat long)
                if (newDoc[additionalSave.key] && !additionalSave.overrideValue)
                  continue;
                if (
                  (foundColumn?.options as any)?.option?.startsWith(
                    'backend.',
                  ) ||
                  (foundColumn?.options as any)?.option?.startsWith(
                    'customerCollection.',
                  ) ||
                  (foundColumn?.options as any)?.option?.startsWith(
                    'staticCollection.',
                  )
                ) {
                  if (foundValue) {
                    let valueToSet;
                    // allow for drilling into objects (i.e. address.state)
                    if (additionalSave.value.includes('.')) {
                      const drillDown = additionalSave.value.split('.');
                      let currentValue = JSON.parse(JSON.stringify(foundValue));
                      drillDown?.forEach((drillKey) => {
                        if (currentValue && currentValue[drillKey])
                          currentValue = currentValue[drillKey];
                        else currentValue = null;
                      });
                      valueToSet = currentValue;
                    } else valueToSet = foundValue[additionalSave.value];
                    if (additionalSave.value === 'copyEntireDocument')
                      valueToSet = foundValue;
                    if (valueToSet) newDoc[additionalSave.key] = valueToSet;
                  }
                }
              }
            }
            // convert date stuff
            if (newDoc[key] && foundColumn?.type === 'date') {
              newDoc[key] = moment(newDoc[key] as string, 'MM/DD/YYYY')
                .toDate()
                .getTime();
            }
            // convert into numbers
            if (
              ['number', 'latitude', 'longitude'].indexOf(foundColumn.type) > -1
            ) {
              if (newDoc[key] || newDoc[key]?.toString()?.trim() === '0')
                newDoc[key] = Number(newDoc[key]);
            }
            // convert api numbers
            if (
              newDoc[key] &&
              ['apiNumber', 'api14Number'].includes(foundColumn?.type)
            ) {
              newDoc[key] = Number(newDoc[key]?.toString()?.replace(/-/g, ''));
            }
          } else if (
            ['rowNumberForViewing', this.bulkUploadValues.idKey].indexOf(
              key,
            ) === -1
          ) {
            // this should never happen since the validation passed but just in case it is here
            if (!(this.massEdit && key === 'id'))
              newErrors.push(`row ${i + 1}: ${key} is not a supported column`);
          }
          if (key === this.bulkUploadValues.idKey) {
            if (!newDoc[key]) newDoc[key] = currentRow[key];
            if (!newDoc.id) newDoc.id = newDoc[key];
          }
        }
        const state = newDoc?.state;
        if (this.bulkUploadValues.convertAddress) {
          newDoc.address = {
            address: newDoc.address ? newDoc.address.toString() : '',
            city: newDoc.city ? newDoc.city.toString() : '',
            state: newDoc.state ? newDoc.state.toString().toUpperCase() : '',
            zip: newDoc.zip ? newDoc.zip.toString() : '',
          };
          delete newDoc.state;
          delete newDoc.city;
          delete newDoc.zip;
        }
        if (this.bulkUploadValues.convertAddressToLatLong) {
          const location = await this.mapboxService.getLatitudeLongitude(
            newDoc.address,
          );
          Object.keys(location).forEach((key) => {
            if (location[key] && !newDoc[key]) newDoc[key] = location[key];
          });
        }
        if (
          this.bulkUploadValues.convertLatLongToAddress ||
          this.bulkUploadValues.convertLatLongToCounty
        ) {
          if (newDoc.latitude && newDoc.longitude) {
            const location = await this.mapboxService.getAddress(
              newDoc.latitude as number,
              newDoc.longitude as number,
            );
            if (this.bulkUploadValues.convertLatLongToAddress) {
              Object.keys(location.address)?.forEach((key) => {
                // only override state and zipCode everything else leave it as user inputed.
                if (['state', 'zip'].includes(key) || !newDoc?.address[key])
                  newDoc.address[key] = location.address[key];
              });
            }
            // do not override it if it is supplied.
            if (!newDoc.county) newDoc.county = location.county as string;
          }
        }
        if (this.bulkUploadValues?.valuesToHardCode) {
          // add the hardcoded values
          Object.keys(this.bulkUploadValues.valuesToHardCode).forEach(
            (hardCodeKey) => {
              if (
                !newDoc[hardCodeKey] &&
                this.bulkUploadValues?.valuesToHardCode?.[hardCodeKey]
              )
                newDoc[hardCodeKey] =
                  this.bulkUploadValues.valuesToHardCode[hardCodeKey];
            },
          );
        }
        if (this.bulkUploadValues?.createSubBasin && newDoc.formationType) {
          // create sub basin from county, state, formation
          const searchString = `${newDoc?.county}, ${state}`?.toLowerCase();
          const mappedSubBasins = this.customerData.subBasins.map(
            (subBasin: SubBasin) => subBasin.subBasinFullNameMapped,
          );
          let index = -1;
          mappedSubBasins.forEach((subBasin, i) => {
            if (index > -1) return;
            if (subBasin?.toLowerCase()?.includes(searchString)) {
              const formationType = newDoc.formationType?.toString();
              index = i;
              newDoc.subBasinId =
                subBasin +
                ' - ' +
                formationType?.substring(0, 1)?.toUpperCase() +
                formationType?.substring(1).toLowerCase();
            }
          });
        }
        // grab extra information if needed
        if (this.bulkUploadValues?.valuesToCopy?.length) {
          for (const valueToCopy of this.bulkUploadValues.valuesToCopy) {
            if (
              valueToCopy?.options?.option?.startsWith('customerCollection.') ||
              valueToCopy?.options?.option?.startsWith('backend.')
            ) {
              const collection = valueToCopy?.options?.option?.split('.')[1];
              // we can assume the data is loaded since we load on init.
              const foundValue = this.customerCollection[collection]?.find(
                (doc) =>
                  doc[valueToCopy?.options?.value] ===
                  newDoc[valueToCopy?.options?.rowKey],
              );
              if (
                foundValue &&
                foundValue[valueToCopy?.value] &&
                (!newDoc[valueToCopy?.key] || valueToCopy?.overrideValue)
              )
                newDoc[valueToCopy?.key] = foundValue[valueToCopy?.value];
            }
          }
        }
        // add companyId to all data
        newDoc.companyId = this.perms.activeCustomerId;
        newDoc.lastUpdatedUser = this.perms.userData.uid;
        let push = true;
        // check to see if it changed. if it didn't, don't add it to be saved
        const foundValue = docs?.find(
          (doc) => doc.id?.toString() === newDoc.id?.toString(),
        );
        let existingRecord;
        if (foundValue)
          existingRecord = JSON.parse(
            JSON.stringify(
              docs?.find((doc) => doc.id?.toString() === newDoc.id?.toString()),
            ),
          );
        if (this.massEdit) {
          if (!foundValue)
            throw 'could not find value for row: ' + (i + 2)?.toString();

          if (!existingRecord) push = false; // could not find the record
          const columnsRemoved: string[] = [];
          const newDocCopy = JSON.parse(JSON.stringify(newDoc));
          // lowercase everything for the difference check
          Object.keys(existingRecord)?.forEach((key) => {
            if (existingRecord[key]) {
              if (
                typeof existingRecord[key] === 'string' &&
                existingRecord[key]
              )
                existingRecord[key] = existingRecord[key]?.toLowerCase();
              if (typeof newDocCopy[key] === 'string' && newDocCopy[key])
                newDocCopy[key] = newDocCopy[key]?.toLowerCase();
              if (!Object.keys(newDocCopy).includes(key))
                columnsRemoved.push(key);
            }
          });
          columnsRemoved.forEach((key) => {
            delete existingRecord[key];
          });
          const changes = this._diff(newDocCopy, existingRecord);
          const keysToDelete = ['user', 'url', 'createdAt', 'fileName'];
          keysToDelete.forEach((key) => {
            delete changes[key];
          });
          if (!Object.keys(changes)?.length) push = false; // no changes so continue.
        }

        if (this._route.includes('locations')) {
          const newFacilityDoc =
            await this._facilitiesDatabaseService.editFacility(
              newDoc,
              true,
              existingRecord,
              true,
            );

          if (push) rowData.push(newFacilityDoc);
        } else {
          if (push) {
            if (this.bulkUploadValues.yearInPath)
              newDoc.year =
                this.reportingYearForm?.getRawValue()?.reportingYear;
            rowData.push(newDoc);
          }
        }

        if (i % 10 === 0)
          progressDialog.changeMessageProgress(
            'Validating ' +
              this.bulkUploadValues.downloadName +
              ' Data, ' +
              rowData.length +
              ' of ' +
              totalRows +
              ' Done',
            5 + (i / totalRows) * 20,
          );
      }
      progressDialog.changeMessageProgress(
        'Saving ' + this.bulkUploadValues.downloadName + ' Data',
        25,
      );
      let facilityCurrentBatchIndex = 0;
      let facilityCurrentBatchCount = 0;

      const facilityBatches = [this.afs.firestore.batch()];
      if (rowData.length && !newErrors.length) {
        // Set user permissions for new facilities
        if (
          !this.massEdit &&
          rowData.length &&
          this.bulkUploadValues.collection === 'facilities'
        ) {
          rowData.forEach((doc) => {
            const id = doc[this.bulkUploadValues.idKey];
            facilityBatches[facilityCurrentBatchIndex].set(
              this.afs
                .collection(
                  `users/${this.perms.userData.uid}/customers/${this.perms.activeCustomerId}/facilities`,
                )
                .doc(id).ref,
              {
                facilityId: id,
              },
            );

            facilityCurrentBatchCount++;
            if (facilityCurrentBatchCount === 500) {
              facilityCurrentBatchCount = 0;
              facilityCurrentBatchIndex++;
              facilityBatches.push(this.afs.firestore.batch());
            }
          });

          await Promise.all(facilityBatches.map((batch) => batch.commit()));
        }

        if (this.bulkUploadValues.apiPostRoute) {
          this._progressStatusDialog.close(progressDialog);
          await this._saveService.saveApi(
            file as Buffer,
            this.file.name,
            `bulkUpload/${this.bulkUploadValues.downloadName.replace(
              / /g,
              '',
            )}`,
            'bulkUpload',
            this.bulkUploadValues.downloadName,
            '',
            rowData,
            this.massEdit ? rowData.length : 0,
            this.bulkUploadValues.apiPostRoute,
          );

          let displayName = this.bulkUploadValues.downloadName.toLowerCase();
          if (rowData.length > 1 && !displayName.endsWith('s'))
            displayName += 's';
          else if (rowData.length === 1 && displayName.endsWith('s'))
            displayName = displayName.substring(0, displayName.length - 1);

          const message = `${displayName} ${
            this.massEdit ? 'edited' : 'created'
          }, \n it took ${this.getDisplayTime()}`;
          this.showError(`${rowData.length} ${message}`, [], true);
          this.saving = false;
          return;
        }

        const fileName = Date.now() + '_' + this.file.name;

        const path = `customers/${
          this.perms.activeCustomerId
        }/bulkUpload/${this.bulkUploadValues.downloadName.replace(
          / /g,
          '',
        )}/${fileName}`;
        // Reference to storage bucket
        const ref = this.storage.ref(path);
        // The main task
        const task = this.storage.upload(path, file);
        // wait for it to upload
        await lastValueFrom(task.snapshotChanges());
        // now we can get the url of the file
        const url = await lastValueFrom(ref.getDownloadURL());

        const newRecordIds: string[] = [];
        let countCompleted = 0;
        let currentBatchIndex = 0;
        let currentBatchCount = 0;
        const batches = [this.afs.firestore.batch()];

        rowData.forEach((row) => {
          // add the url to the row so we can delete it based on the file.
          row.url = url;
          row.fileName = fileName;
          row.user = {
            uid: this.perms.userData.uid,
            email: this.perms.userData.email,
          };
          row.createdAt = this._dateService.momentToUtc(Date.now());
          countCompleted++;
          newRecordIds.push(row[this.bulkUploadValues.idKey]);
          const ref = this.afs
            .collection('customers')
            .doc(this.perms.activeCustomerId)
            .collection(this.bulkUploadValues.collection)
            .doc(row[this.bulkUploadValues.idKey]?.toString()).ref;
          if (this.bulkUploadValues.yearInPath) {
            // do some error catching to give the user a more descriptive error then undefined not allowed
            this._progressStatusDialog.close(progressDialog);
            this.saving = false;
            throw 'Iconic air did not format this correctly, please reach out to them to fix this.';
          }
          if (this.massEdit) batches[currentBatchIndex].update(ref, row);
          else batches[currentBatchIndex].set(ref, row);

          currentBatchCount++;
          if (currentBatchCount === 500) {
            currentBatchCount = 0;
            currentBatchIndex++;
            batches.push(this.afs.firestore.batch());
          }
        });
        // Set user permissions for new facilities
        if (
          newRecordIds.length &&
          this.bulkUploadValues.collection === 'facilities'
        ) {
          newRecordIds.forEach((id) => {
            batches[currentBatchIndex].set(
              this.afs
                .collection(
                  `users/${this.perms.userData.uid}/customers/${this.perms.activeCustomerId}/facilities`,
                )
                .doc(id).ref,
              {
                facilityId: id,
              },
            );

            currentBatchCount++;
            if (currentBatchCount === 500) {
              currentBatchCount = 0;
              currentBatchIndex++;
              batches.push(this.afs.firestore.batch());
            }
          });
        }

        // Save changes
        const promises: any[] = [];

        batches.forEach((batch) => {
          promises.push(batch.commit());
        });
        let batchesSaved = 0;
        for (let i = 0; i < promises.length; i++) {
          promises[i].then(() => {
            progressDialog.changeMessageProgress(
              'Saving ' +
                this.bulkUploadValues.downloadName +
                ' Data, ' +
                batchesSaved * 500 +
                ' of ' +
                totalRows +
                ' Done',
              25 + ((batchesSaved * 500) / totalRows) * 75,
            );
            batchesSaved++;
            if (batchesSaved === promises.length) {
              let displayName =
                this.bulkUploadValues.downloadName.toLowerCase();
              if (newRecordIds?.length > 1) {
                // plural check that it doesn't already ends in an s
                if (
                  !this.bulkUploadValues.downloadName
                    .toLowerCase()
                    ?.toString()
                    ?.endsWith('s')
                )
                  displayName = displayName + 's';
              } else {
                // singular, check to see if it ends in an s
                if (
                  this.bulkUploadValues.downloadName
                    .toLowerCase()
                    ?.toString()
                    ?.endsWith('s')
                )
                  displayName = displayName.substring(
                    0,
                    displayName.length - 1,
                  );
              }
              const message = `${displayName} ${
                this.massEdit ? 'edited' : 'created'
              }, \n it took ${this.getDisplayTime()}`;
              this.showError(`${countCompleted} ${message}`, [], true);
              this.saving = false;
              this._progressStatusDialog.close(progressDialog);
            }
          });
        }
      } else {
        if (newErrors?.length) this.showError('Error', newErrors, false);
        else this.showError('No changes found', newErrors, false);

        this._progressStatusDialog.close(progressDialog);
      }
    } catch (e) {
      this._progressStatusDialog.close(progressDialog);
      throw e;
    } finally {
      this.saving = false;
    }
  }

  private _diff(obj1, obj2) {
    const result = {};

    for (const key in obj1) {
      if (!obj2 || !(key in obj2)) {
        result[key] = obj1[key];
      } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const value = this._diff(obj1[key], obj2[key]);
          if (Object.keys(value).length > 0) {
            result[key] = value;
          }
        } else {
          result[key] = obj1[key];
        }
      }
    }

    for (const key in obj2) {
      if (!obj1 || !(key in obj1)) {
        result[key] = obj2[key];
      }
    }

    return result;
  }

  private optionData(column: BulkUploadColumn, keepCasing?: boolean) {
    let options: string[] = [];
    if (column?.options) {
      if (typeof column?.options === 'string') {
        options = column?.options
          ?.split(',')
          ?.map((value) => (keepCasing ? value : value?.toLowerCase()));
        if (column?.options?.startsWith('customer.')) {
          options = this.customerData.customerRecord[
            column.options.split('.')[1]
          ]?.map((value) => (keepCasing ? value : value?.toLowerCase()));
        } else if (column?.options?.startsWith('staticCollection.')) {
          // now we have the values so we can check if it has that option
          options = this._staticData[column?.options.split('.')[1]];
        } else if (column?.options?.startsWith('bulkUpload.')) {
          options = this[column?.options.split('.')[1]];
        }
      } else if (Array.isArray(column?.options)) {
        options = column.options;
      } else if (column?.options?.option) {
        // this is a more complex option type
        const optionsObject = column.options;
        const collectionName = optionsObject.option.split('.')[1];
        if (column?.options?.option?.startsWith('customerCollection.')) {
          // check to see if we have already grabbed it.
          if (!this.customerCollection[collectionName]) {
            this.afs
              .collection('customers')
              .doc(this.perms.activeCustomerId)
              .collection(collectionName)
              .get()
              .subscribe((results) => {
                this.customerCollection[collectionName] = results.docs.map(
                  (doc) => {
                    const newDoc = { ...doc.data() };
                    newDoc.id = doc.id;
                    return newDoc;
                  },
                );
              });
          }
          if (this.customerCollection[collectionName]) {
            // now we have the values so we can check if it has that option
            options = this.customerCollection[collectionName].map((doc) =>
              keepCasing
                ? doc[optionsObject.value]?.toString()
                : doc[optionsObject.value]?.toString()?.toLowerCase(),
            );
          }
        } else if (column?.options?.option?.startsWith('backend.')) {
          // check to see if we have already grabbed it.
          if (!this.customerCollection[collectionName]) {
            this._http
              .get('/api/' + kebabCase(collectionName))
              .subscribe((results: any) => {
                this.customerCollection[collectionName] = results;
              });
          }
          if (this.customerCollection[collectionName]) {
            // now we have the values so we can check if it has that option
            options = this.customerCollection[collectionName].map((doc) =>
              keepCasing
                ? doc[optionsObject.value]?.toString()
                : doc[optionsObject.value]?.toString()?.toLowerCase(),
            );
          }
        } else if (column?.options?.option?.startsWith('staticCollection.')) {
          options = this._staticData[collectionName]?.map((doc) =>
            doc[optionsObject?.value]?.toString()?.toLowerCase(),
          );
        }
      }
    } else if (column?.type === 'subBasin') {
      options = this.customerData.subBasins?.map(
        (subBasin: SubBasin) => subBasin.subBasinFullNameMapped,
      ) as string[];
    }
    return options;
  }

  private showError(title: string, messages: string[], redirect: boolean) {
    const dialogRef = this.dialog.open(UploadErrorCompletedComponent, {
      autoFocus: false,
      data: {
        title: title,
        messages,
      },
    });

    if (redirect) {
      dialogRef.afterClosed().subscribe(() => {
        if (!this.isAdminPage) {
          this.navigateBack();
        } else {
          this.router.navigate(['/admin/company-info']);
        }
      });
    }
  }

  setColumnDefs() {
    // set column definitions of ag-grid for both components and facility tables.
    const columnDefinitions: ColDef[] = [
      {
        headerName: 'Row',
        field: 'rowNumberForViewing',
        editable: false,
        maxWidth: 100,
        resizable: false,
        pinned: 'left',
      },
    ];
    this.bulkUploadValues.columns.forEach((column) => {
      const currentDefinition: ColDef = {
        headerName: column?.description
          ? column.description
          : startCase(column.key),
        field: column?.description ? column.description : column.key,
        editable: this.getEditable,
      };
      if (column?.key === this.bulkUploadValues.idKey)
        currentDefinition.editable = false;
      if (column.required && !column?.validator?.key)
        currentDefinition.headerName = `* ${currentDefinition.headerName}`;
      switch (column?.type) {
        case 'date':
          currentDefinition.type = 'date';
          break;
        case 'state':
          currentDefinition.cellEditor = 'agSelectCellEditor';
          // eslint-disable-next-line no-case-declarations
          const cellOptions: string[] = this._staticData.states.map(
            (state: { abbreviation: string }) => state.abbreviation,
          );
          if (!column.required || column?.validator?.key) {
            // add a blank value to clear it out
            cellOptions.unshift('');
          }
          currentDefinition.cellEditorParams = {
            values: cellOptions,
          };
          currentDefinition.valueFormatter = (params) => {
            if (params?.data?.state) {
              const foundState = this._staticData.states.find(
                (state) =>
                  state.abbreviation === params.data?.state?.toUpperCase(),
              );
              if (foundState) return foundState.name;
            }
            return '';
          };
          break;
        case 'boolean':
          currentDefinition.cellRenderer = 'checkboxRenderer';
          break;
      }
      if (['number', 'latitude', 'longitude'].indexOf(column?.type) > -1) {
        currentDefinition.valueSetter = (params: ValueSetterParams) => {
          //to make sure user entered number only
          const newValInt = parseInt(params.newValue);
          const valueChanged = params.data[column.key] !== newValInt;
          if (valueChanged) {
            params.data[column.key] = newValInt ? newValInt : params.oldValue;
          }
          return valueChanged;
        };
      }
      if (column.options || column.type === 'subBasin') {
        currentDefinition.cellEditor = 'agSelectCellEditor';
        const values = this.optionData(column, true);
        if ((!column.required || column?.validator?.key) && !column.multiple) {
          // add a blank value to clear it out
          values.unshift('');
        }
        currentDefinition.cellEditorParams = {
          values,
        };
      }
      columnDefinitions.push(currentDefinition);
    });
    columnDefinitions.push({
      headerName: '',
      cellRenderer: 'actionColumn',
      field: 'alreadySaved',
      pinned: 'right',
      maxWidth: 80,
      filter: false,
      sortable: false,
    });
    this.columnDefs = columnDefinitions;
  }
}
