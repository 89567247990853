import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkUploadComponent } from './bulk-upload.component';
import { MaterialModule } from '../../theme/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { FileValidationDialogComponent } from './file-validation-dialog/file-validation-dialog.component';
import { PipesModule } from '../../pipes/pipes.module';
import { ActionColumnComponent } from './action-column/action-column.component';

@NgModule({
  declarations: [
    BulkUploadComponent,
    FileValidationDialogComponent,
    ActionColumnComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    AgGridModule.withComponents([]),
  ],
  exports: [BulkUploadComponent],
})
export class BulkUploadModule {}
