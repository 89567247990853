import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-blank-cell-renderer',
  templateUrl: './blank-cell-renderer.component.html',
  styleUrls: ['./blank-cell-renderer.component.scss'],
})
export class BlankCellRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  hasValue() {
    return (
      this.params?.value ||
      this.params?.value === false ||
      this.params?.value === 0 ||
      this.params?.value === '0'
    );
  }

  refresh(params: any): boolean {
    return false;
  }
}
