import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../theme/material.module';
import { UserSnackbarComponent } from './user-snackbar.component';
import { UploadDialogModule } from '../upload-dialog/upload-dialog.module';

@NgModule({
  declarations: [UserSnackbarComponent],
  imports: [CommonModule, MaterialModule, UploadDialogModule],
  exports: [UserSnackbarComponent],
})
export class UserSnackbarModule {}
