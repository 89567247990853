<div class="flex justify-between w-full">
  <h1 mat-dialog-title class="m-2">Release notes</h1>
  <button (click)="dismissModal()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="w-[640px]">
  <div class="text-lg text-primary m-4 font-bold label-large">
    Version {{version}}
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div *ngIf="!loading" class="p-4">
    <div
      class="iframe-container"
      style="position: relative; padding-bottom: 64.63195691202873%; height: 0"
    >
      <iframe
        [src]="cleanVideoLink"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        class="video-iframe"
      ></iframe>
    </div>
    <div class="my-4" *ngIf="improvements?.length">
      <div class="mb-2 font-bold label-large">Improvements</div>
      <ul>
        <li
          class="ml-4 body-large-text"
          *ngFor="let improvement of improvements"
        >
          <h3>{{improvement}}</h3>
        </li>
      </ul>
    </div>
    <div class="my-4" *ngIf="newFeatures?.length">
      <div class="mb-2 font-bold label-large">New features</div>
      <ul>
        <li class="ml-4 body-large-text" *ngFor="let feature of newFeatures">
          <h3>{{feature}}</h3>
        </li>
      </ul>
    </div>
    <div class="my-4" *ngIf="fixes?.length">
      <div class="mb-2 font-bold label-large">Bug fixes</div>
      <ul>
        <li class="ml-4 body-large-text" *ngFor="let fix of fixes">
          <h3>{{fix}}</h3>
        </li>
      </ul>
    </div>
    <div class="my-4" *ngIf="supplementalVideoLinks?.length">
      <div class="mb-2 font-bold label-large">Supplemental videos</div>
      <ul>
        <li
          class="ml-4 body-large-text"
          *ngFor="let supplemental of supplementalVideoLinks"
        >
          <a
            class="ml-4"
            *ngFor="let supplemental of supplementalVideoLinks"
            href="{{supplemental.link}}"
            target="_blank"
          >
            {{supplemental?.display}}
            <mat-icon class="text-sm">open_in_new</mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</mat-dialog-content>
