<!-- <div class="flex w-full h-full p-8 gap-4"> -->
<div
  class="flex flex-col justify-center items-center h-full w-min sm:w-full md:w-full sm:p-4 md:p-8 md:gap-4"
>
  <!-- flex flex-col justify-center items-center h-full w-full -->
  <div
    class="flex flex-col sm:flex-row items-center justify-between w-3/5 sm:w-auto sm:h-min md:h-full md:w-full"
  >
    <!-- <div class="flex flex-col sm:flex-row items-center justify-between w-min sm:w-auto md:h-full md:w-full"> -->
    <div class="bg-primary-container shrink rounded m-4 sm:m-0 sm:mr-4">
      <div class="text-3xl text-center font-semibold m-4">
        {{companyId ? "" : "Iconic Air"}} Leak ID - {{companyId ? companyId :
        id}}
      </div>
      <table class="flex flex-col items-center m-4">
        <tr class="flex justify-between w-4/5 mt-2">
          <td><strong>Component Type:</strong></td>
          <td>
            {{(componentType ? componentType.toString() : '-') | titlecase}}
          </td>
        </tr>
        <tr class="flex justify-between w-4/5 mt-2">
          <td><strong>Component Size:</strong></td>
          <td>
            {{componentSize ? componentSize : '-'}}<span *ngIf="componentSize">
              in.</span
            >
          </td>
        </tr>
        <tr class="flex justify-between w-4/5 mt-2">
          <td><strong>Latitude:</strong></td>
          <td>{{latitude ? latitude : '-'}}</td>
        </tr>
        <tr class="flex justify-between w-4/5 mt-2">
          <td><strong>Longitude:</strong></td>
          <td>{{longitude ? longitude : '-'}}</td>
        </tr>
        <tr class="flex justify-between w-4/5 mt-2">
          <td><strong>PPM Reading:</strong></td>
          <td>{{ppm ? ppm : '-'}}<span *ngIf="ppm"> ppm</span></td>
        </tr>
        <tr class="flex justify-between w-4/5 mt-2">
          <td><strong>Description:</strong></td>
          <td>{{(description ? description.toString() : '-') | titlecase}}</td>
        </tr>
      </table>
    </div>
    <!-- <img *ngIf="downloadURL" class="sm:w-1/2 bg-no-repeat object-scale-down ml-1 rounded" data-src="{{downloadURL}}" /> -->
    <!-- <img *ngIf="downloadURL" class="w-[70%] sm:w-[25%] md:w-[50%] bg-no-repeat object-scale-down ml-1 rounded" data-src="{{downloadURL}}" /> -->
    <img
      *ngIf="downloadURL"
      class="w-[70%] sm:w-[25%] md:w-[50%] bg-no-repeat object-scale-down ml-1 rounded"
      data-src="{{downloadURL}}"
    />
    <div
      *ngIf="!downloadURL"
      class="flex flex-col items-center justify-center w-1/2 gap-2 sm:gap-8 opacity-30"
    >
      <mat-icon class="text-5xl sm:text-7xl md:text-9xl text-primary-400"
        >hide_image</mat-icon
      >
      <div class="text-3xl sm:text-5xl text-primary-400 text-center">
        No photos exist for this leak
      </div>
    </div>
  </div>
  <button
    mat-raised-button
    color="primary"
    class="text-lg mt-4 mb-4"
    (click)="closeModal()"
  >
    Close
  </button>
  <!-- <div class="w-1/2">
    <img class="bg-no-repeat bg-cover" data-src="{{downloadURL}}" />
  </div> -->
</div>
