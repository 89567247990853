import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { ThemeModule } from './../theme/theme.module';
import { MaterialModule } from '../theme/material.module';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { Route } from './shell.service';
import { ShellComponent } from './shell/shell.component';
import { UserSnackbarModule } from '../components/user-snackbar/user-snackbar.module';
import { ReleaseDialogModule } from '../components/release-dialog/release-dialog.module';

@NgModule({
  declarations: [MenuComponent, FooterComponent, ShellComponent],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    RouterModule,
    ThemeModule,
    UserSnackbarModule,
    ReleaseDialogModule,
  ],
  providers: [Route],
})
export class ShellModule {}
