import {
  Component,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { Subscription, lastValueFrom } from 'rxjs';
import { UserSnackbar } from '@iconic-air/models';
import { DownloadFileService } from '../../services/download-file/download-file.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';

interface UserSnackbarWithDateAndDuration extends UserSnackbar {
  createdDatePretty: string;
  duration: string;
}
@Component({
  selector: 'app-user-snackbar',
  templateUrl: './user-snackbar.component.html',
  styleUrls: ['./user-snackbar.component.scss'],
})
export class UserSnackbarComponent implements OnInit, OnDestroy {
  @HostBinding('class.hidden') get isHidden() {
    return !this.ids || this.ids.length === 0;
  }
  minimized: boolean;
  snackbars: {
    [id: string]: UserSnackbarWithDateAndDuration;
  } = {};

  timers: {
    [id: string]: NodeJS.Timeout;
  } = {};

  get ids() {
    return Object.keys(this.snackbars || {}) || [];
  }

  #subscriptions$: Subscription[] = [];

  constructor(
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
    private _downloadFile: DownloadFileService,
    private _ngZone: NgZone,
    private _router: Router,
    private _dialog: MatDialog,
  ) {}

  #updateItem(snackbar: UserSnackbarWithDateAndDuration, id: string) {
    if (this.snackbars[id]) {
      Object.keys(snackbar).forEach((key) => {
        this.snackbars[id][key] = snackbar[key];
      });
    } else this.snackbars[id] = snackbar;
    if (this.snackbars[id].createdDate && !this.snackbars[id].createdDatePretty)
      snackbar.createdDatePretty = this.#createdDatePretty(
        snackbar.createdDate,
      );
    if (snackbar?.showDuration) this.#startTimer(id);
  }

  #startTimer(id: string) {
    if (!this.timers[id]) {
      this._ngZone.runOutsideAngular(() => {
        this.timers[id] = setInterval(() => {
          this._ngZone.run(() => {
            if (this.snackbars[id])
              this.snackbars[id].duration = this.duration(this.snackbars[id]);
          });
        }, 500);
      });
    }
  }

  openModal(id: string, snackbar: UserSnackbar) {
    if (snackbar.modalName === 'UploadDialogComponent')
      this._dialog.open(UploadDialogComponent, { data: { id } });
  }

  #createdDatePretty(date: number) {
    const currentDateString = new Date().toLocaleString();
    if (
      currentDateString.split(',')[0] ===
      new Date(date).toLocaleString().split(',')[0]
    )
      return new Date(date).toLocaleTimeString();
    return new Date(date).toLocaleString();
  }

  duration(snackbar: UserSnackbar) {
    // this is in seconds
    const seconds = (Number(new Date()) - Number(snackbar.createdDate)) / 1000;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const parts: string[] = [];

    // Add hours to the parts array if it's more than 0
    if (hours > 0) {
      parts.push(`${hours} hr`);
    }

    // Add minutes to the parts array if it's more than 0
    if (minutes > 0) {
      parts.push(`${minutes} min`);
    }

    // Add seconds to the parts array if it's more than 0 or if it's the only unit (i.e., seconds < 60)
    if (remainingSeconds > 0 || parts.length === 0) {
      parts.push(`${remainingSeconds} seconds`);
    }

    // Join the parts array into a string separated by space
    return parts.join(' ');
  }

  async ngOnInit() {
    await this.#getCurrentSnackbars();
    this.#subscribeToChanges();
  }

  ngOnDestroy() {
    this.#clearSubscriptions();
  }

  async #getCurrentSnackbars() {
    const docs = await lastValueFrom(
      this._afs
        .collection('users')
        .doc(this._perms.userData?.uid)
        .collection('snackbars', (ref) => ref.where('closed', '==', false))
        .get(),
    );
    docs.forEach((doc) => {
      const data = doc.data();
      const id = doc.id;
      if (data?.closed) return;
      this.#updateItem(data as UserSnackbarWithDateAndDuration, id);
    });
  }

  #subscribeToChanges() {
    this.#clearSubscriptions();
    this.#subscriptions$.push(
      this._afs
        .collection('users')
        .doc(this._perms.userData?.uid)
        .collection('snackbars')
        .snapshotChanges()
        .subscribe((docs) => {
          docs.forEach((doc) => {
            const data = doc.payload.doc.data(),
              id = doc.payload.doc.id;
            if (data?.closed && this.snackbars[id]) delete this.snackbars[id];
            else if (!data?.closed)
              this.#updateItem(data as UserSnackbarWithDateAndDuration, id);
          });
        }),
    );
  }

  #clearSubscriptions() {
    this.#subscriptions$?.forEach((sub) => sub?.unsubscribe());
    this.#subscriptions$ = [];
  }

  cleanError(error: string) {
    try {
      const parsed = JSON.parse(error);
      if (parsed?.message) return parsed.message;
      return error;
    } catch (e) {
      return error;
    }
  }

  async download(id: string, event) {
    if (this.snackbars[id].downloadLink) {
      event?.stopPropagation();
      const fileName = this.snackbars[id].downloadName;
      await this._downloadFile.downloadFileWithCustomName(
        this.snackbars[id].downloadLink as string,
        fileName || '',
      );
      await this.close(id);
    }
  }

  async close(id: string, event?) {
    event?.stopPropagation();
    await this._afs
      .collection('users')
      .doc(this._perms.userData?.uid)
      .collection('snackbars')
      .doc(id)
      .update({ closed: true });
    delete this.snackbars[id];
    if (this.timers[id]) clearInterval(this.timers[id]);
  }

  toggleMinimize() {
    this.minimized = !this.minimized;
  }

  navigate(id: string, event?) {
    event?.stopPropagation();
    this._router.navigate([this.snackbars[id].route]);
  }
}
