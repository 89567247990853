import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ObservableService } from '../../../services/observable-service/observable.service';

@Component({
  selector: 'app-bulk-upload-action-column',
  templateUrl: './action-column.component.html',
  styleUrls: ['./action-column.component.scss'],
})
export class ActionColumnComponent implements AgRendererComponent {
  public allowDelete = true;
  private params;
  constructor(private observableService: ObservableService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.allowDelete = !params.data.alreadySaved;
  }

  refresh(params) {
    this.allowDelete = !params.value;
    return true;
  }

  public deleteRow() {
    this.observableService.publish('bulkUpload-deleteRow', this.params.data);
  }
}
