<div>
  <div *ngIf="!isEditMode" class="flex justify-center items-center">
    <div class="text-2xl font-semibold tracking-tight leading-8 text-secondary">
      {{customerTitle ? customerTitle : defaultTitle}}
    </div>
    <!-- <ng-container *ngIf="isAdmin && this.canEditOverviewTitles">
      <div class="h-8 flex justify-center items-center hover:cursor-pointer">
        <mat-icon class="ml-2 text-lg" (click)="this.isEditMode = true"
          >edit</mat-icon
        >
      </div>
    </ng-container> -->
    <!-- Add tooltip in div provided below -->
    <div class="h-8 flex justify-center items-center">
      <mat-icon
        class="text-xl text-secondary ml-1 leading-8"
        appFormulaTooltip
        [tooltipText]="tooltipText"
        [showTooltip]="true"
        >help_outline
      </mat-icon>
    </div>
  </div>
  <div *ngIf="isEditMode" class="flex justify-center items-center">
    <mat-form-field appearance="standard">
      <input matInput [(ngModel)]="newTitle" />
    </mat-form-field>
    <div class="flex justify-center items-center">
      <button mat-button color="primary" class="ml-2" (click)="saveTitle()">
        Save
      </button>
    </div>
  </div>
</div>
