import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-map',
  templateUrl: './chart-map.component.html',
  styleUrls: ['./chart-map.component.scss']
})
export class ChartMapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
