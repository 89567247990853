import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { TitleCasePipe } from '@angular/common';
import { isNumber } from 'lodash';

import {
  TemplateColumnValidation,
  TemplateDataValidation,
  Facility,
  Leak,
  Survey,
  Repair,
  CodeAndDescription,
  SheetValidationError,
} from '@iconic-air/models';

import { StaticDataService } from '../static-data/static.data.service';
import { ExcelService } from '../excel/excel.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  facilityName: string;
  skyConditions: string[] = [];
  gasTypes: CodeAndDescription[] = [];
  existingOgiCameras: string[] = [];
  repairStatus: CodeAndDescription[] = [];
  repairMethod: string[] = [];
  confirmationMethod: string[] = [];

  constructor(
    private titlecasePipe: TitleCasePipe,
    private _staticData: StaticDataService,
    private _excelService: ExcelService,
  ) {
    this.skyConditions = this._staticData.skyConditions;
    this.gasTypes = this._staticData.gasTypes;
    this.existingOgiCameras = this._staticData.cameras;
    this.repairStatus = this._staticData.repairStatus;
    this.repairMethod = this._staticData.repairMethods;
    this.confirmationMethod = this._staticData.repairConfMethods;
  }

  async validateTemplate(
    templateName: string,
    wb: XLSX.WorkBook,
    sheetNames: string[],
  ) {
    let validationErrors: SheetValidationError[] = [];

    const templateColumnValidation: Array<TemplateColumnValidation> =
      this._staticData.templateColumnValidation[templateName];

    Object.keys(wb.Sheets).forEach((sheet, sheetIndex) => {
      if (sheetNames.includes(sheet)) {
        const sheetTemplateColumnValidation = templateColumnValidation.filter(
          (val) => val.sheetName === sheet,
        );
        const newValidationErrors = this.getTemplateValidationErrors(
          wb,
          sheet,
          sheetIndex,
          sheetTemplateColumnValidation,
        );
        if (newValidationErrors !== null) {
          validationErrors = [...validationErrors, ...newValidationErrors];
        }
      } else if (sheet !== 'hidden') {
        validationErrors = [
          {
            sheetName: sheet,
            sheetIndex: sheetIndex,
            labelCell: 'None',
            label: 'Sheet Name',
            currentValueCell: `Sheet ${sheetIndex}`,
            currentValue: sheet,
            expectedValue: `The sheet name is not recognized. Please ensure you are using the Iconic Air Template`,
          },
        ];
      }
    });

    return validationErrors.length === 0 ? null : validationErrors;
  }

  // Get errors from template
  getTemplateValidationErrors(
    wb: XLSX.WorkBook,
    sheetName: string,
    sheetIndex: number,
    titlesAndData: Array<TemplateColumnValidation>,
  ): SheetValidationError[] | null {
    const newErrors: SheetValidationError[] = titlesAndData.reduce(
      (accErrors, currentTitleAndData) => {
        const currentValueCell = currentTitleAndData.currentValueCell;
        const currentValue = wb.Sheets[sheetName][currentValueCell]?.w;
        if (currentTitleAndData.expectedValue !== currentValue) {
          const newError = {
            sheetName,
            sheetIndex,

            labelCell: '',
            label: currentTitleAndData.expectedValue,

            currentValueCell,
            currentValue,
            expectedValue: `However, [${currentTitleAndData.expectedValue}] was expected. Please use the latest Iconic Air Template.`,
          };
          return [...accErrors, newError];
        }
        return accErrors;
      },
      [],
    );

    if (newErrors && newErrors?.length > 0) {
      return newErrors;
    } else return null;
  }

  async validateData(
    templateName: string,
    wb: XLSX.WorkBook,
    sheetNames: string[],
    surveys: Survey[],
    leaks: Leak[],
    repairs: Repair[],
    facilities: Facility[],
  ): Promise<SheetValidationError[] | null> {
    let validationErrors: SheetValidationError[] = [];

    const templateDataValidation: TemplateDataValidation[] =
      this._staticData.templateDataValidation[templateName];

    const templateColumnValidation: TemplateColumnValidation[] =
      this._staticData.templateColumnValidation[templateName];

    const facilityMap = new Map();

    facilities.forEach((facility) => {
      if (facility?.facilityName?.toLowerCase())
        facilityMap.set(facility.facilityName?.toLowerCase(), facility);
    });

    Object.keys(wb.Sheets).forEach((sheet, sheetIndex) => {
      if (sheetNames.includes(sheet)) {
        const newValidationErrors = this.#getDataValidationErrors(
          wb,
          sheet,
          sheetIndex,
          templateDataValidation,
          facilityMap,
        );

        if (newValidationErrors !== null) {
          validationErrors = [...validationErrors, ...newValidationErrors];
        }
      } else if (sheet !== 'hidden') {
        validationErrors = [
          {
            sheetName: sheet,
            sheetIndex,
            labelCell: 'None',
            label: 'Sheet Name',
            currentValueCell: `Sheet ${sheetIndex}`,
            currentValue: sheet,
            expectedValue: `The sheet name is not recognized. Please ensure you are using the Iconic Air Template`,
          },
        ];
      }
    });

    const dupErrors: SheetValidationError[] | null =
      await this.#checkForDuplicatesWithinSheet(
        wb,
        sheetNames,
        templateColumnValidation,
        surveys,
        leaks,
        repairs,
        facilityMap,
      );
    if (dupErrors) validationErrors = [...validationErrors, ...dupErrors];

    return validationErrors.length === 0 ? null : validationErrors;
  }

  #getDataValidationErrors(
    wb: XLSX.WorkBook,
    sheetName: string,
    sheetIndex: number,
    templateDataValidation: Array<TemplateDataValidation>,
    facilityMap: Map<string, Facility>,
  ): SheetValidationError[] | null {
    const allSheetDataValidation: Array<TemplateDataValidation> = [];

    // Get data validation for current sheet name
    const sheetDataValidation = templateDataValidation.filter(
      (data) => data.sheetName === sheetName,
    );

    // Get final row of data
    const finalRow = this._excelService.getNumberOfRows(wb, sheetName, 1);

    // Row where the labels are written
    const labelCellRow = 1;

    // Create data validation for all cell in the sheet
    sheetDataValidation.forEach((data) => {
      for (let i = 2; i <= finalRow; i++) {
        allSheetDataValidation.push({
          currentValueCell: data.column + String(i),
          rowNumber: i,
          labelCell: data.column + String(labelCellRow),
          type: data.type,
          dependentColumn: data.dependentColumn + String(i),
          required: data.required,
          expectedValue: data.expectedValue,
        });
      }
    });

    const newErrors: SheetValidationError[] = allSheetDataValidation.reduce(
      (accErrors, currentTitleAndData) => {
        // Data from current sheet for current cell
        const labelCell = currentTitleAndData.labelCell;
        const label = wb.Sheets[sheetName][labelCell]?.w;
        const currentValueCell = currentTitleAndData.currentValueCell;
        const currentValue = wb.Sheets[sheetName][currentValueCell]?.w;
        const dependentValue =
          wb.Sheets[sheetName][currentTitleAndData.dependentColumn]?.w;

        // Dependent Value Arrays
        const dependentConfirmationMethods = [
          'snoop',
          'ppm monitor',
          'optical gas imaging camera',
        ];

        const dependentRepairStatus = ['successful'];

        // Validation
        const validNumber = isNumber(Number(currentValue));

        const validSkyCondition = this.skyConditions.find(
          (skyCondition: string) =>
            skyCondition?.toLowerCase() ===
            currentValue?.toString()?.toLowerCase(),
        );
        const validGasType = this.gasTypes.find(
          (gasType: CodeAndDescription) =>
            gasType.description?.toLowerCase() ===
            currentValue?.toString()?.toLowerCase(),
        );
        const validOgiCamera = this.existingOgiCameras.find(
          (existingOgiCamera: string) =>
            existingOgiCamera?.toLowerCase() ===
            currentValue?.toString()?.toLowerCase(),
        );
        const validDateTime = moment(
          wb.Sheets[sheetName][currentValueCell]?.w,
          'MM/DD/YY h:mm A',
        )?.isValid();
        const validDate =
          moment(
            wb.Sheets[sheetName][currentValueCell]?.w,
            'M/D/YY',
          )?.isValid() ||
          moment(
            wb.Sheets[sheetName][currentValueCell]?.w,
            'MM/DD/YY',
          )?.isValid() ||
          moment(
            wb.Sheets[sheetName][currentValueCell]?.w,
            'MM/DD/YYYY',
          )?.isValid();
        const validLocation = facilityMap.get(
          currentValue?.toString()?.toLowerCase(),
        );
        const validRepairStatus = this.repairStatus.find(
          (status: CodeAndDescription) =>
            status?.description?.toLowerCase() ===
            currentValue?.toString()?.toLowerCase(),
        );
        const validRepairMethod = this.repairMethod.find(
          (method: string) =>
            method?.toLowerCase() === currentValue?.toString()?.toLowerCase(),
        );
        const validConfirmationMethod = this.confirmationMethod.find(
          (method: string) =>
            method?.toLowerCase() === currentValue?.toString()?.toLowerCase(),
        );
        const validYesOrNo = ['yes', 'no'].find(
          (v) => v == currentValue?.toString()?.toLowerCase(),
        );

        // Error text generation (corresponds to one of the validation constants above)
        if (
          (currentValue === undefined ||
            currentValue === null ||
            currentValue === '') &&
          (currentTitleAndData.type === 'nonEmpty' ||
            currentTitleAndData.required)
        ) {
          let nonEmptyMessage = 'However, a non-empty field was expected.';
          if (currentTitleAndData.required)
            nonEmptyMessage = 'However, this field is required.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue: nonEmptyMessage,
          };
          return [...accErrors, newError];
        } else if (
          currentValue !== undefined &&
          currentTitleAndData.type === 'yesOrNo' &&
          !validYesOrNo
        ) {
          const nonEmptyMessage = 'However, yes or no was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue: nonEmptyMessage,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validDate' &&
          !validDate &&
          ((dependentValue &&
            dependentConfirmationMethods.includes(dependentValue)) ||
            currentValue !== undefined)
        ) {
          const expectedValue =
            'However, a valid date (Ex. 12/25/22) was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validDateTime' &&
          !validDateTime &&
          ((dependentValue &&
            dependentConfirmationMethods.includes(dependentValue)) ||
            currentValue !== undefined)
        ) {
          const expectedValue =
            'However, a valid date (Ex. 12/25/22 5:12 PM) was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validSkyCondition' &&
          !validSkyCondition &&
          currentValue !== undefined
        ) {
          const expectedValue =
            'However, a valid sky condition (' +
            this.skyConditions.join(', ') +
            ') was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,

            labelCell,
            label,

            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validGasType' &&
          !validGasType &&
          currentValue !== undefined
        ) {
          const expectedValue =
            'However, a valid gas type (' +
            this.gasTypes.map((gasType) => gasType.description).join(', ') +
            ') was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validOgiCameraType' &&
          !validOgiCamera &&
          currentValue !== undefined
        ) {
          const expectedValue =
            'However, a valid camera (' +
            this.existingOgiCameras.join(', ') +
            ') was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'number' &&
          !validNumber &&
          currentValue !== undefined
        ) {
          const expectedValue = 'However, a valid number was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'number' &&
          currentValue !== undefined &&
          currentTitleAndData?.expectedValue
        ) {
          const validMin =
            currentTitleAndData.expectedValue.min !== null &&
            currentTitleAndData.expectedValue.min !== undefined
              ? currentValue >= currentTitleAndData.expectedValue.min
              : true;
          const validMax =
            currentTitleAndData.expectedValue.max !== null &&
            currentTitleAndData.expectedValue.max !== undefined
              ? currentValue <= currentTitleAndData.expectedValue.max
              : true;

          let errors: SheetValidationError[] = [];
          // now check if there is a composite min and max
          if (currentTitleAndData?.expectedValue?.compositeMinMax) {
            const compositeMinMax = JSON.parse(
              JSON.stringify(currentTitleAndData?.expectedValue),
            )?.compositeMinMax as {
              cells: string[];
              labels: string[];
              min: number;
              max: number;
            };
            compositeMinMax.labels = [label];
            compositeMinMax.cells = compositeMinMax.cells?.map((cell) => {
              compositeMinMax.labels.push(
                wb.Sheets[sheetName][`${cell}${labelCellRow?.toString()}`]?.w,
              );
              return `${cell}${currentTitleAndData.rowNumber?.toString()}`;
            });
            let value = currentValue;
            for (const cell of compositeMinMax.cells) {
              value = Number(value ? value : 0);
              if (isFinite(Number(wb.Sheets[sheetName][cell]?.w)))
                value += Number(wb.Sheets[sheetName][cell]?.w);
            }
            const validCompositeMin =
              compositeMinMax.min !== null && compositeMinMax.min !== undefined
                ? value >= compositeMinMax.min
                : true;
            const validCompositeMax =
              compositeMinMax.max !== null && compositeMinMax.max !== undefined
                ? value <= compositeMinMax.max
                : true;
            if (!validCompositeMin) {
              errors.push({
                sheetName,
                sheetIndex,
                labelCell,
                label: compositeMinMax.labels?.toString(),
                currentValueCell: compositeMinMax.cells
                  .concat([currentValueCell])
                  ?.toString(),
                currentValue: value ? value : '',
                expectedValue: `However, the sum of these cells cannot be less than ${compositeMinMax.min}.`,
              });
            }
            if (!validCompositeMax) {
              errors.push({
                sheetName,
                sheetIndex,
                labelCell,
                label: compositeMinMax.labels?.toString(),
                currentValueCell: compositeMinMax.cells
                  .concat([currentValueCell])
                  ?.toString(),
                currentValue: value ? value : '',
                expectedValue: `However, the sum of these cells cannot be greater than ${compositeMinMax.max}.`,
              });
            }
          }

          if (!validMin || !validMax) {
            const expectedValue =
              !validMin && !validMax
                ? `However, a value between ${currentTitleAndData.expectedValue.min} and ${currentTitleAndData.expectedValue.max} was expected.`
                : !validMin
                ? `However, a value greater than ${currentTitleAndData.expectedValue.min} was expected.`
                : `However, a value less than ${currentTitleAndData.expectedValue.max} was expected.`;

            errors.push({
              sheetName,
              sheetIndex,
              labelCell,
              label,
              currentValueCell,
              currentValue: currentValue ? currentValue : '',
              expectedValue,
            });
          }
          if (errors.length > 0) {
            return [...accErrors, ...errors];
          }
        } else if (
          currentTitleAndData.type === 'validLocation' &&
          !validLocation &&
          currentValue !== undefined
        ) {
          const expectedValue = 'However, a valid location was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validRepairStatus' &&
          !validRepairStatus &&
          currentValue !== undefined
        ) {
          const expectedValue = 'However, a valid repair status was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validRepairMethod' &&
          !validRepairMethod &&
          ((dependentValue && dependentRepairStatus.includes(dependentValue)) ||
            currentValue !== undefined)
        ) {
          const expectedValue = 'However, a valid repair method was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        } else if (
          currentTitleAndData.type === 'validConfirmationMethod' &&
          !validConfirmationMethod &&
          currentValue !== undefined
        ) {
          const expectedValue =
            'However, a valid confirmation method was expected.';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell,
            currentValue: currentValue ? currentValue : '',
            expectedValue,
          };
          return [...accErrors, newError];
        }
        return accErrors;
      },
      [],
    );

    if (newErrors && newErrors?.length > 0) {
      return newErrors;
    } else return null;
  }

  async #checkForDuplicatesWithinSheet(
    wb: XLSX.WorkBook,
    sheetNames: string[],
    templateColumnValidation: TemplateColumnValidation[],
    dbSurveys: Survey[],
    dbLeaks: Leak[],
    dbRepairs: Repair[],
    facilityMap: Map<string, Facility>,
  ): Promise<SheetValidationError[] | null> {
    const validationErrors: SheetValidationError[] = [];

    const surveys: any[] = [];
    const leaks: any[] = [];
    const repairs: any[] = [];
    let surveysLastRow = 0;
    let leaksLastRow = 0;
    let repairsLastRow = 0;

    sheetNames.forEach((name) => {
      if (name === 'surveyDetail')
        surveysLastRow = this._excelService.getNumberOfRows(wb, name, 1);
      if (name === 'leakDetail')
        leaksLastRow = this._excelService.getNumberOfRows(wb, name, 1);
      if (name === 'repairDetail')
        repairsLastRow = this._excelService.getNumberOfRows(wb, name, 1);
    });

    const companySurveyIdColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'companySurveyId' &&
        dataValidation.sheetName === 'surveyDetail',
    )?.column;
    const locationNameColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'location' &&
        dataValidation.sheetName === 'surveyDetail',
    )?.column;
    const inspectionStartDateColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'inspectionStartDate' &&
        dataValidation.sheetName === 'surveyDetail',
    )?.column;
    const leakingColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'leaking' &&
        dataValidation.sheetName === 'surveyDetail',
    )?.column;
    const surveyDescriptionColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'description' &&
        dataValidation.sheetName === 'surveyDetail',
    )?.column;
    const companyLeakIdLeakDetailColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'companyLeakId' &&
        dataValidation.sheetName === 'leakDetail',
    )?.column;
    const detectionDateColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'detectionDate' &&
        dataValidation.sheetName === 'leakDetail',
    )?.column;
    const leakDescriptionColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'description' &&
        dataValidation.sheetName === 'leakDetail',
    )?.column;
    const componentTypeColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'componentType' &&
        dataValidation.sheetName === 'leakDetail',
    )?.column;
    const companyLeakIdRepairDetailColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'companyLeakId' &&
        dataValidation.sheetName === 'repairDetail',
    )?.column;
    const repairAttemptDateColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'repairAttemptDate' &&
        dataValidation.sheetName === 'repairDetail',
    )?.column;
    const repairMethodColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'repairMethod' &&
        dataValidation.sheetName === 'repairDetail',
    )?.column;
    const maintenanceTechColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'maintenanceTech' &&
        dataValidation.sheetName === 'repairDetail',
    )?.column;
    const notesColumn = templateColumnValidation.find(
      (dataValidation) =>
        dataValidation.expectedValue === 'notes' &&
        dataValidation.sheetName === 'repairDetail',
    )?.column;

    for (let i = 2; i <= surveysLastRow; i++) {
      if (wb.Sheets['surveyDetail'][companySurveyIdColumn + i]?.w) {
        // create survey data
        const companySurveyId =
          wb.Sheets['surveyDetail'][companySurveyIdColumn + i]?.w;
        const facilityName =
          wb.Sheets['surveyDetail'][locationNameColumn + i]?.w?.toLowerCase();
        const facilityId = facilityMap.get(facilityName)?.id as string;
        const inspectionStartDate = moment(
          wb.Sheets['surveyDetail'][inspectionStartDateColumn + i]?.w,
          'MM/DD/YY h:mm A',
        ).valueOf();
        const surveyContainsLeaks =
          wb.Sheets['surveyDetail'][leakingColumn + i]?.w?.toLowerCase();
        const surveyDescription =
          wb.Sheets['surveyDetail'][
            surveyDescriptionColumn + i
          ]?.w?.toLowerCase();

        // check if survey is duplicated in sheet
        const isASurveyDuplicateWithinSheet = surveys.find((survey) => {
          return (
            survey.facilityId === facilityId &&
            survey.inspectionStartDate === inspectionStartDate &&
            survey.surveyContainsLeaks?.toLowerCase() === surveyContainsLeaks &&
            survey.description?.toLowerCase() === surveyDescription
          );
        });
        if (!isASurveyDuplicateWithinSheet) {
          surveys.push({
            facilityId: facilityId,
            inspectionStartDate: inspectionStartDate,
            surveyContainsLeaks: surveyContainsLeaks,
            description: surveyDescription,
            rowIndex: i,
          });
        } else {
          const duplicateRow = isASurveyDuplicateWithinSheet.rowIndex;
          validationErrors.push({
            sheetName: '',
            sheetIndex: 1,
            labelCell: '',
            label: 'duplicates',
            currentValueCell: `survey at ${this.titlecasePipe.transform(
              facilityName,
            )} on ${moment(
              wb.Sheets['surveyDetail'][inspectionStartDateColumn + i]?.w,
              'MM/DD/YY h:mm A',
            ).format(
              'L',
            )} in row ${i} within the document on the sureyDetail sheet in row ${duplicateRow}`,
            currentValue: ``,
            expectedValue: ``,
          });
        }

        // check if survey is duplicated from db
        const isASurveyDuplicateFromDB = dbSurveys.find((survey) => {
          return (
            survey.facilityId == facilityId &&
            (survey.inspectionStartDate as unknown as number) ==
              inspectionStartDate &&
            survey.description &&
            surveyDescription
          );
        });

        if (isASurveyDuplicateFromDB) {
          validationErrors.push({
            sheetName: '',
            sheetIndex: 1,
            labelCell: '',
            label: 'duplicates',
            currentValueCell: `${this.titlecasePipe.transform(
              facilityName,
            )} at ${moment(inspectionStartDate).format(
              'L',
            )} in row ${i} from already uploaded data`,
            currentValue: ``,
            expectedValue: ``,
          });
        }

        for (let j = 2; j <= leaksLastRow; j++) {
          if (wb.Sheets['leakDetail']['A' + j]?.w === companySurveyId) {
            // create leak data
            const companyLeakId =
              wb.Sheets['leakDetail'][companyLeakIdLeakDetailColumn + j]?.w;
            const detectionDate = moment(
              wb.Sheets['leakDetail'][detectionDateColumn + j]?.w,
              'MM/DD/YY',
            ).valueOf();
            const leakDescription =
              wb.Sheets['leakDetail'][
                leakDescriptionColumn + j
              ]?.w?.toLowerCase() || '';
            const componentType =
              wb.Sheets['leakDetail'][
                componentTypeColumn + j
              ]?.w?.toLowerCase();

            // checking for leak duplicates within sheet
            const isALeakDuplicateWithinSheet = leaks.find((leak) => {
              return (
                leak.companySurveyId === companySurveyId &&
                leak.detectionDate === detectionDate &&
                leak.description?.toLowerCase() === leakDescription &&
                leak.componentType?.toLowerCase() === componentType
              );
            });

            if (!isALeakDuplicateWithinSheet) {
              leaks.push({
                detectionDate: detectionDate,
                description: leakDescription,
                componentType: componentType,
                companySurveyId: companySurveyId,
                rowIndex: j,
              });
            } else {
              const duplicateRow = isALeakDuplicateWithinSheet.rowIndex;
              const dupError = {
                sheetName: '',
                sheetIndex: 1,
                labelCell: '',
                label: 'duplicates',
                currentValueCell: `leak for ${this.titlecasePipe.transform(
                  facilityName,
                )} detected on ${moment(
                  wb.Sheets['leakDetail'][detectionDateColumn + j]?.w,
                  'MM/DD/YY h:mm A',
                ).format(
                  'L',
                )} in row ${i} within the document on the leakDetail sheet in row ${duplicateRow}`,
                currentValue: '',
                expectedValue: ``,
              };
              if (
                !validationErrors.find(
                  (error) =>
                    error.currentValueCell === dupError.currentValueCell,
                )
              ) {
                validationErrors.push({
                  sheetName: '',
                  sheetIndex: 1,
                  labelCell: '',
                  label: 'duplicates',
                  currentValueCell: `leak for ${this.titlecasePipe.transform(
                    facilityName,
                  )} detected on ${moment(
                    wb.Sheets['leakDetail'][detectionDateColumn + j]?.w,
                    'MM/DD/YY',
                  ).format(
                    'L',
                  )} in row ${i} within the document on the leakDetail sheet in row ${duplicateRow}`,
                  currentValue: '',
                  expectedValue: ``,
                });
              }
            }

            // checking for leak duplicates from db
            const isALeakDuplicateFromDB = dbLeaks.find((leak) => {
              return (
                (leak.detectionDate as unknown as number) === detectionDate &&
                leak.description?.toLowerCase() === leakDescription &&
                leak.componentType.description?.toLowerCase() ===
                  componentType &&
                leak.facilityId == facilityId
              );
            });

            if (isALeakDuplicateFromDB) {
              const dupError = {
                sheetName: '',
                sheetIndex: 1,
                labelCell: '',
                label: 'duplicates',
                currentValueCell: `leak detected on ${moment(
                  detectionDate,
                ).format('L')} in row ${j} from already uploaded data`,
                currentValue: '',
                expectedValue: ``,
              };
              if (
                !validationErrors.find(
                  (error) =>
                    error.currentValueCell === dupError.currentValueCell,
                )
              ) {
                validationErrors.push({
                  sheetName: '',
                  sheetIndex: 1,
                  labelCell: '',
                  label: 'duplicates',
                  currentValueCell: `leak detected on ${moment(
                    detectionDate,
                  ).format('L')} in row ${j} from already uploaded data`,
                  currentValue: '',
                  expectedValue: ``,
                });
              }
            }

            // checking for repair duplicates within sheet
            for (let k = 2; k <= repairsLastRow; k++) {
              if (
                wb.Sheets['repairDetail'][companyLeakIdRepairDetailColumn + k]
                  ?.w === companyLeakId
              ) {
                // create repair data
                const repairAttemptDate = moment(
                  wb.Sheets['repairDetail'][repairAttemptDateColumn + k]?.w,
                  'MM/DD/YY h:mm A',
                ).valueOf();
                const repairMethod =
                  wb.Sheets['repairDetail'][
                    repairMethodColumn + k
                  ]?.v?.toLowerCase();
                const maintenanceTech =
                  wb.Sheets['repairDetail'][
                    maintenanceTechColumn + k
                  ]?.v?.toLowerCase();
                const notes =
                  wb.Sheets['repairDetail'][notesColumn + k]?.v?.toLowerCase();

                // check if repair is duplicated within sheet
                const isARepairDuplicateWithinSheet = repairs.find((repair) => {
                  return (
                    repair.repairAttemptedDate === repairAttemptDate &&
                    repair.repairMethod?.toLowerCase() === repairMethod &&
                    repair.maintenanceTech?.toLowerCase() === maintenanceTech &&
                    repair.notes?.toLowerCase() === notes &&
                    repair.companyLeakId === companyLeakId
                  );
                });

                if (!isARepairDuplicateWithinSheet) {
                  repairs.push({
                    repairAttemptedDate: repairAttemptDate,
                    repairMethod: repairMethod,
                    maintenanceTech: maintenanceTech,
                    notes: notes,
                    companyLeakId: companyLeakId,
                    rowIndex: k,
                  });
                } else {
                  const duplicateRow = isARepairDuplicateWithinSheet.rowIndex;
                  const dupError = {
                    sheetName: '',
                    sheetIndex: 1,
                    labelCell: '',
                    label: 'duplicates',
                    currentValueCell: `repair for ${this.titlecasePipe.transform(
                      facilityName,
                    )} attempted on ${moment(
                      wb.Sheets['repairDetail'][repairAttemptDateColumn + k]?.w,
                      'MM/DD/YY h:mm A',
                    ).format(
                      'L',
                    )} in row ${i} within the document on the repairDetail sheet in row ${duplicateRow}`,
                    currentValue: '',
                    expectedValue: ``,
                  };
                  if (
                    !validationErrors.find(
                      (error) =>
                        error.currentValueCell === dupError.currentValueCell,
                    )
                  ) {
                    validationErrors.push({
                      sheetName: '',
                      sheetIndex: 1,
                      labelCell: '',
                      label: 'duplicates',
                      currentValueCell: `repair for ${this.titlecasePipe.transform(
                        facilityName,
                      )} attempted on ${moment(
                        wb.Sheets['repairDetail'][repairAttemptDateColumn + k]
                          ?.w,
                        'MM/DD/YY h:mm A',
                      ).format(
                        'L',
                      )} in row ${i} within the document on the repairDetail sheet in row ${duplicateRow}`,
                      currentValue: '',
                      expectedValue: ``,
                    });
                  }
                }
              }
            }
          }
        }
      }
    }
    return validationErrors.length === 0 ? null : validationErrors;
  }

  async validateDates(
    wb: XLSX.WorkBook,
    sheetNames: string[],
    labelCell: string,
    label: string,
    templateDataValidation: Array<TemplateDataValidation>,
  ): Promise<null | SheetValidationError[]> {
    let validationErrors: SheetValidationError[] = [];
    const surveyEndDateTimeLabelCellValue =
      wb.Sheets[wb.SheetNames[0]][label]?.v;

    Object.keys(wb.Sheets).forEach(async (sheet, sheetIndex) => {
      if (sheetNames.includes(sheet)) {
        const newValidationErrors = await this.#getSheetDateValidationErrors(
          wb,
          sheet,
          sheetIndex,
          labelCell,
          surveyEndDateTimeLabelCellValue,
          templateDataValidation,
        );

        if (newValidationErrors !== null) {
          validationErrors = [...validationErrors, ...newValidationErrors];
        }
      } else if (sheet?.toLowerCase() !== 'hidden') {
        validationErrors = [
          {
            sheetName: sheet,
            sheetIndex: sheetIndex,
            labelCell: 'None',
            label: 'Sheet Name',
            currentValueCell: `Sheet ${sheetIndex}`,
            currentValue: sheet,
            expectedValue: `The sheet name is not recognized. Please ensure you are using the Iconic Air Template`,
          },
        ];
      }
    });

    return validationErrors.length === 0 ? null : validationErrors;
  }

  async #getSheetDateValidationErrors(
    wb: XLSX.WorkBook,
    sheetName: string,
    sheetIndex: number,
    labelCell: string,
    label: string,
    templateDataValidation: Array<TemplateDataValidation>,
  ): Promise<SheetValidationError[] | null> {
    const newErrors: SheetValidationError[] = [];
    // Get final row of data
    const finalRow = await this._excelService.getNumberOfRows(wb, sheetName, 1);

    // Get survey, leak and repair date columns
    const surveyStartDateColumn = templateDataValidation.find(
      (data) => data.labelCell === 'inspectionStartDate',
    ).column;
    const surveyEndDateColumn = templateDataValidation.find(
      (data) => data.labelCell === 'inspectionEndDate',
    ).column;
    const leakDetectionDateColumn = templateDataValidation.find(
      (data) => data.labelCell === 'detectionDate',
    ).column;
    const repairAtemptDateColumn = templateDataValidation.find(
      (data) => data.labelCell === 'repairAttemptDate',
    ).column;
    const confirmationDateColumn = templateDataValidation.find(
      (data) => data.labelCell === 'confirmationDate',
    ).column;

    for (let i = 2; i <= finalRow; i++) {
      // check survey start and end date
      if (surveyStartDateColumn !== '' && surveyEndDateColumn !== '') {
        const surveyStartDateTime = moment(
          wb.Sheets[wb.SheetNames[0]][`${surveyStartDateColumn}${i}`]?.w,
          'MM/DD/YY h:mm A',
        );
        const surveyEndDateTime = moment(
          wb.Sheets[wb.SheetNames[0]][`${surveyEndDateColumn}${i}`]?.w,
          'MM/DD/YY h:mm A',
        );

        const validDate =
          moment(surveyEndDateTime).isSameOrAfter(surveyStartDateTime);

        if (!validDate) {
          const expectedValue =
            'However, a valid date was expected (Ex. A date AFTER or the SAME as the survey start time of' +
            surveyStartDateTime.format('MM/DD/YY h:mm A') +
            ').';
          const newError: SheetValidationError = {
            sheetName,
            sheetIndex,
            labelCell,
            label,
            currentValueCell: `${surveyEndDateColumn}${i}`,
            currentValue: surveyEndDateTime.format('MM/DD/YY h:mm A'),
            expectedValue,
          };
          newErrors.push(newError);
        }
      }

      if (
        surveyStartDateColumn !== '' &&
        surveyEndDateColumn !== '' &&
        leakDetectionDateColumn !== ''
      ) {
        const companySurveyIdSurveyDetail =
          wb.Sheets['surveyDetail'][`A${i}`]?.v;
        const companySurveyIdLeakDetail = wb.Sheets['leakDetail'][`A${i}`]?.v;

        if (
          companySurveyIdSurveyDetail &&
          companySurveyIdLeakDetail &&
          companySurveyIdSurveyDetail === companySurveyIdLeakDetail
        ) {
          const surveyStartDateTime = moment(
            wb.Sheets[wb.SheetNames[0]][`${surveyStartDateColumn}${i}`]?.w,
            'MM/DD/YY h:mm A',
          );
          const surveyEndDateTime = moment(
            wb.Sheets[wb.SheetNames[0]][`${surveyEndDateColumn}${i}`]?.w,
            'MM/DD/YY h:mm A',
          );
          const detectionDate = moment(
            wb.Sheets[wb.SheetNames[0]][`${leakDetectionDateColumn}${i}`]?.w,
            'MM/DD/YY',
          );

          const validDate =
            moment(detectionDate).isSameOrAfter(surveyStartDateTime, 'day') ||
            moment(detectionDate).isSameOrAfter(surveyEndDateTime, 'day');

          if (!validDate) {
            const expectedValue =
              'However, a valid date was expected (Ex. A date AFTER or the SAME as the survey start time of' +
              surveyStartDateTime.format('MM/DD/YY h:mm A') +
              ' and BEFORE of the SAME as the survey end time of' +
              surveyEndDateTime.format('MM/DD/YY h:mm A') +
              ' ).';
            const newError: SheetValidationError = {
              sheetName,
              sheetIndex,
              labelCell,
              label,
              currentValueCell: `${surveyEndDateColumn}${i}`,
              currentValue: detectionDate.format('MM/DD/YY'),
              expectedValue,
            };
            newErrors.push(newError);
          }
        }
      }

      if (leakDetectionDateColumn !== '' && repairAtemptDateColumn !== '') {
        const companyLeakIdLeakDetail = wb.Sheets['leakDetail'][`B${i}`]?.v;
        const companyLeakIdRepairDetail = wb.Sheets['repairDetail'][`A${i}`]?.v;

        if (
          companyLeakIdLeakDetail &&
          companyLeakIdRepairDetail &&
          companyLeakIdLeakDetail === companyLeakIdRepairDetail
        ) {
          const leakDetectionDate = moment(
            wb.Sheets[wb.SheetNames[0]][`${leakDetectionDateColumn}${i}`]?.w,
            'MM/DD/YY',
          );
          const repairAttemptDate = moment(
            wb.Sheets[wb.SheetNames[0]][`${repairAtemptDateColumn}${i}`]?.w,
            'MM/DD/YY',
          );

          if (repairAttemptDate) {
            const validDate = moment(repairAttemptDate).isSameOrAfter(
              leakDetectionDate,
              'day',
            );

            if (!validDate) {
              const expectedValue =
                'However, a valid date was expected (Ex. A date AFTER or the SAME as the leak detection date of' +
                leakDetectionDate.format('MM/DD/YY') +
                ' ).';
              const newError: SheetValidationError = {
                sheetName,
                sheetIndex,
                labelCell,
                label,
                currentValueCell: `${surveyEndDateColumn}${i}`,
                currentValue: repairAttemptDate.format('MM/DD/YY'),
                expectedValue,
              };
              newErrors.push(newError);
            }
          }
        }
      }

      if (repairAtemptDateColumn !== '' && confirmationDateColumn !== '') {
        const companyLeakIdLeakDetail = wb.Sheets['leakDetail'][`B${i}`]?.v;
        const companyLeakIdRepairDetail = wb.Sheets['repairDetail'][`A${i}`]?.v;

        if (
          companyLeakIdLeakDetail &&
          companyLeakIdRepairDetail &&
          companyLeakIdLeakDetail === companyLeakIdRepairDetail
        ) {
          const repairAttemptDate = moment(
            wb.Sheets[wb.SheetNames[0]][`${repairAtemptDateColumn}${i}`]?.w,
            'MM/DD/YY',
          );
          const confirmationDate = moment(
            wb.Sheets[wb.SheetNames[0]][`${confirmationDateColumn}${i}`]?.w,
            'MM/DD/YY',
          );

          if (repairAttemptDate) {
            const validDate = moment(repairAttemptDate).isSameOrAfter(
              confirmationDate,
              'day',
            );

            if (!validDate) {
              const expectedValue =
                'However, a valid date was expected (Ex. A date AFTER or the SAME as the repair attempt date of' +
                confirmationDate.format('MM/DD/YY') +
                ' ).';
              const newError: SheetValidationError = {
                sheetName,
                sheetIndex,
                labelCell,
                label,
                currentValueCell: `${surveyEndDateColumn}${i}`,
                currentValue: repairAttemptDate.format('MM/DD/YY'),
                expectedValue,
              };
              newErrors.push(newError);
            }
          }
        }
      }
    }
    if (newErrors && newErrors?.length > 0) {
      return newErrors;
    } else return null;
  }
}
