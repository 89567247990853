import { Component, Input, OnInit, ViewChild } from '@angular/core';
import _ from 'lodash';
import { ChartComponent } from 'ng-apexcharts';
import { ApexChartOptions } from '@iconic-air-monorepo/models';
import * as colors from '../../theme/global-style-variables';

@Component({
  selector: 'app-chart-treemap',
  templateUrl: './chart-treemap.component.html',
  styleUrls: ['./chart-treemap.component.scss'],
})
export class ChartTreemapComponent implements OnInit {
  private otherToolTipData; // only 'other' data
  private individualToolTipData; // the rest
  @ViewChild('chart') chart: ChartComponent;
  chartOptions: Partial<ApexChartOptions>;

  @Input()
  private toolTipSuffix: string;
  @Input()
  private componentHeight = '425px';
  @Input()
  private mergeBottom;
  private _treeMapData: any;

  @Input()
  set treeMapData(value: any) {
    this._treeMapData = value;
    this.getData(); // run each time treeMapData is updated
  }
  get treeMapData() {
    return this._treeMapData;
  }
  private otherDataItemOffset = 0;

  constructor() {}

  ngOnInit(): void {
    this.getData();
  }

  private mergeSmallTreeMapDataValues(
    data: any,
    mergeValuesLessThan: number | null = null,
    mergeSmallestValues: number | null = 5,
  ): Array<any> {
    const filteredData: Array<any> = _.reduce(
      data,
      (sum: any[], curr) => {
        sum.push({ label: curr.label, value: curr.value });
        return sum;
      },
      [],
    );
    const sortedData: Array<any> = _.sortBy(filteredData, ['value']);

    let dataWithMergedValues;

    if (
      mergeValuesLessThan &&
      mergeSmallestValues === null &&
      mergeValuesLessThan > 0 &&
      data.length > 2 // make sure we don't merge all values into 'other' category
    ) {
      const sortedFilteredData = _.filter(sortedData, (data) => {
        return data.value < mergeValuesLessThan;
      });
      this.otherToolTipData = sortedFilteredData;
      this.individualToolTipData = sortedData;

      const mergedData = _.reduce(sortedFilteredData, (sum, curr) => {
        if (sum.label === undefined) return curr;
        const value = curr.value + sum.value;
        const total: { label: string; value: number } = { label: '', value };
        return total;
      });
      mergedData.label = 'Other';
      // add offset to account for added 'other' data item
      this.otherDataItemOffset = -1;

      const otherValues = sortedData.slice(sortedFilteredData.length);
      dataWithMergedValues = [mergedData, ...otherValues];
    }
    if (
      mergeSmallestValues &&
      mergeValuesLessThan === null &&
      mergeSmallestValues < data.length // make sure that all values are never in 'other' catagory
    ) {
      const sortedSmallestData = sortedData.slice(0, mergeSmallestValues);
      this.otherToolTipData = sortedSmallestData;
      this.individualToolTipData = sortedData;

      const mergedData = _.reduce(sortedSmallestData, (sum, curr) => {
        if (sum.label === undefined) return curr;
        const value = curr.value + sum.value;
        const total: { label: string; value: number } = { label: '', value };
        return total;
      });
      mergedData.label = 'Other';
      // add offset to account for added 'other' data item
      this.otherDataItemOffset = -1;

      const otherValues = sortedData.slice(sortedSmallestData.length);
      dataWithMergedValues = [mergedData, ...otherValues];
    }

    if (dataWithMergedValues === undefined) {
      this.otherToolTipData = sortedData;
      this.individualToolTipData = sortedData;
      return sortedData;
    }
    return dataWithMergedValues;
  }

  public getCommaNumber(n: number) {
    return parseFloat(n.toFixed(3)).toLocaleString();
  }

  getData() {
    const defaultMergeBottom = 5;
    let chartData: Array<any> = this.mergeSmallTreeMapDataValues(
      JSON.parse(JSON.stringify(this.treeMapData)),
      null,
      this.mergeBottom || defaultMergeBottom,
    );
    this.otherToolTipData = this.otherToolTipData.map((a) => {
      return {
        x: a.label,
        y: this.getCommaNumber(a.value),
      };
    });

    chartData = chartData.map((a, index) => {
      const maxLabelCharacters = 20;
      const label =
        a.label.length > maxLabelCharacters
          ? String(a.label)
              .slice(0, maxLabelCharacters - 1)
              .concat('...')
          : a.label;
      return {
        x: label,
        y: a.value,
        fillColor:
          colors.lineChartColors[index % colors.lineChartColors.length]
            .borderColor,
      };
    });

    this.chartOptions = {
      series: [
        {
          data: chartData,
        },
      ],
      tooltip: {
        shared: false,
        intersect: false,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          if (String(data.x).includes('Other')) {
            const breakoutData: string = _.reduce(
              this.otherToolTipData,
              (sum, curr) => {
                return (
                  sum +
                  '<li>' +
                  curr.x +
                  ': <b>' +
                  curr.y +
                  this.toolTipSuffix +
                  '</b></li>'
                );
              },
              '',
            );
            return "<div class='m-4'><ul>" + breakoutData + '</ul></div>';
          }

          const indexOfLabel =
            dataPointIndex +
            (this.otherDataItemOffset &&
              this.otherToolTipData.length + this.otherDataItemOffset);

          return (
            "<div class='m-4'><ul>" +
            '<li>' +
            this.individualToolTipData[indexOfLabel].label +
            ': <b>' +
            this.getCommaNumber(data.y) +
            ' ' +
            this.toolTipSuffix +
            '</b></li>' +
            '</ul></div>'
          );
        },
      },
      chart: {
        width: '99%',
        height: this.componentHeight,
        type: 'treemap',
        redrawOnWindowResize: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '25px',
          fontWeight: 'bold',
          fontFamily: 'Roobert-Regular',
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: '#fff',
        },
      },
    };
  }
}
